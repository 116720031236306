function getPrescricao(medicamento, counter) {
  let prescricao = '';
  if (
    medicamento === 'Maleato de Enalapril 10mg' ||
    medicamento === 'Vasopril 10mg' ||
    medicamento === 'Carvedilol 3,125mg' ||
    medicamento === 'Carvedilol 6,25mg' ||
    medicamento === 'Ticagrelor 90mg' ||
    medicamento === 'Brilinta 90mg' ||
    medicamento === 'Ritmonorm 300mg' ||
    medicamento === 'Galvus MET 50/500mg' ||
    medicamento === 'Galvus MET 50/850mg' ||
    medicamento === 'Sacubitril/Valsartana 50mg' ||
    medicamento === 'Entresto 50mg' ||
    medicamento === 'Adalat R 20mg' ||
    medicamento === 'Apixabana 5mg' ||
    medicamento === 'Eliquis 5mg' ||
    medicamento === 'Embo 5mg' ||
    medicamento === 'Vatis 150mg'
  ) {
    prescricao += `${counter} - ${medicamento}\n`;
    prescricao += '      Tomar 1cp VO cedo e 1cp à noite.\n';
    return prescricao;
  }
  if (medicamento === 'Pradaxa 110mg' || medicamento === 'Pradaxa 150mg') {
    prescricao += `${counter} - ${medicamento}\n`;
    prescricao += '      Tomar 1cp VO cedo e à noite (12/12 horas).\n';
    return prescricao;
  }
  if (medicamento === 'Captopril 25mg' || medicamento === 'Propranolol 40mg') {
    prescricao += `${counter} - ${medicamento}\n`;
    prescricao += '      Tomar 1cp VO cedo, tarde e à noite.\n';
    return prescricao;
  }
  if (
    medicamento === 'AAS 100mg' ||
    medicamento === 'Aspirina Prevent 100mg' ||
    medicamento === 'Somalgim Cardio 100mg' ||
    medicamento === 'Clopidogrel 75mg' ||
    medicamento === 'Plagrel 75mg' ||
    medicamento === 'Lipanon 250mg' ||
    medicamento === 'Xarelto 15mg' ||
    medicamento === 'Xarelto 20mg' ||
    medicamento === 'Rivaroxabana 20mg'
  ) {
    prescricao += `${counter} - ${medicamento}\n`;
    prescricao += '      Tomar 1cp VO após o almoço.\n';
    return prescricao;
  }
  if (medicamento === 'Marevan 5mg' || medicamento === 'Coumadin 5mg') {
    prescricao += `${counter} - ${medicamento}\n`;
    prescricao += '      Tomar 1cp VO à tarde.\n';
    return prescricao;
  }
  if (
    medicamento === 'Metformina 500mg' ||
    medicamento === 'Metformina 850mg' ||
    medicamento === 'Glifage XR 500mg' ||
    medicamento === 'Glifage XR 750mg'
  ) {
    prescricao += `${counter} - ${medicamento}\n`;
    prescricao += '      Tomar 1cp VO após o almoço e 1cp após o jantar.\n';
    return prescricao;
  }
  if (
    medicamento === 'Puran T4 50mcg' ||
    medicamento === 'Euthyrox 50mcg' ||
    medicamento === 'Synthroid 50mcg' ||
    medicamento === 'Levotiroxina 50mcg'
  ) {
    prescricao += `${counter} - ${medicamento}\n`;
    prescricao +=
      '      Tomar 1cp VO cedo (30 minutos antes do café da manhã).\n';
    return prescricao;
  }
  if (
    medicamento === 'Sinvastatina 20mg' ||
    medicamento === 'Clinfar 20mg' ||
    medicamento === 'Sinvastacor 20mg' ||
    medicamento === 'Trezor 20mg' ||
    medicamento === 'Ciprofibrato 100mg' ||
    medicamento === 'Lipless 100mg' ||
    medicamento === 'Lopid 600mg' ||
    medicamento === 'Bromazepan 3mg' ||
    medicamento === 'Lexotan 3mg' ||
    medicamento === 'Alprazolan 2mg' ||
    medicamento === 'Rivotril 0,5mg' ||
    medicamento === 'Pivast 2mg' ||
    medicamento === 'Zetsim 10/40mg' ||
    medicamento === 'Ezetimiba 10mg'
  ) {
    prescricao += `${counter} - ${medicamento}\n`;
    prescricao += '      Tomar 1cp VO à noite.\n';
    return prescricao;
  }
  if (
    medicamento === 'Atorvastatina 20mg' ||
    medicamento === 'Lipitor 20mg' ||
    medicamento === 'Citalor 20mg' ||
    medicamento === 'Rosuvastatina 20mg' ||
    medicamento === 'Crestor 20mg' ||
    medicamento === 'Rosucor 20mg' ||
    medicamento === 'Trezor 20mg' ||
    medicamento === 'Trezete 20/10mg' ||
    medicamento === 'Zinpass Eze 20/10mg' ||
    medicamento === 'Zinpass Eze 40/10mg'
  ) {
    prescricao += `${counter} - ${medicamento}\n`;
    prescricao += '      Tomar 1cp VO ao dia.\n';
    return prescricao;
  }
  if (medicamento === 'Omeprazol 40mg' || medicamento === 'Pantoprazol 40mg') {
    prescricao += `${counter} - ${medicamento}\n`;
    prescricao += '      Tomar 1cp VO cedo em jejum.\n';
    return prescricao;
  }
  if (medicamento === 'Janumet 50/850mg') {
    prescricao += `${counter} - ${medicamento}\n`;
    prescricao +=
      '      Tomar 1cp VO após café da manhã e 1cp após o jantar.\n';
    return prescricao;
  }
  if (medicamento === 'Benzetacil 1.200.000UI') {
    prescricao += `${counter} - ${medicamento}\n`;
    prescricao += '      Aplicar IM em musculatura profunda de 21/21 dias.\n';
    return prescricao;
  }
  if (medicamento === 'Ozempic 1,5 ml (doses 0,25/0,5 mg)') {
    prescricao += `${counter} - ${medicamento}\n`;
    prescricao +=
      '      Aplicar SC 1x/semana (sempre no mesmo horário)\n      Manter em geladeira\n            - 0,25 mg SC 1x/semana por 4 semanas, após\n            - 0,5 mg SC 1x/semana por pelo menos 4 semanas.\n';
    return prescricao;
  }
  if (medicamento === 'Ozempic 3 ml ( dose 1 mg)') {
    prescricao += `${counter} - ${medicamento}\n`;
    prescricao +=
      '      Aplicar SC 1x/semana (sempre no mesmo horário)\n      Manter em geladeira\n            - 17 cliques SC 1x/semana por 4 semanas, após\n            - 34 cliques SC 1x/semana por 4 semanas, após\n            - 1 mg SC 1x/semana por 4 semanas pelo menos (dose máxima)\n';
    return prescricao;
  }
  if (medicamento === 'Saxenda') {
    prescricao += `${counter} - ${medicamento}\n`;
    prescricao +=
      '      Agulha Novofine 4 mm\n      Manter em geladeira\n      Aplicar SC 1x/dia (sempre no mesmo horário)\n            - 0,6 mg SC 1x/dia por 1 semana, após aumentar +2 cliques a cada 2 dias\n            - 1,2 mg SC 1x/dia por 1 semana\n            - 1,8 mg SC 1x/dia por 1 semana\n            - 2,4 mg SC 1x/dia por 1 semana\n            - 3 mg SC 1x/dia (dose máxima)\n';
    return prescricao;
  }
  prescricao += `${counter} - ${medicamento}\n`;
  prescricao += '      Tomar 1cp VO cedo.\n';
  return prescricao;
}

export default getPrescricao;
