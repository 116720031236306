import React, { useState } from 'react';
import { Card, Form, Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import InputMask from 'react-bootstrap-maskedinput';
import logo from '../../../assets/logo.png';
import api from '../../../services/api';
import history from '../../../services/history';

export default function PerfilPaciente() {
  const [show, setShow] = useState(true);
  const [cpf, setCpf] = useState('');
  const [celular, setCelular] = useState('');
  const [data, setData] = useState({
    cpf: '',
    codigo: '',
    newPassword: '',
    confirmPassword: '',
  });
  const [showVerificationCodeModal, setShowVerificationCodeModal] = useState(
    false
  );

  const handleUpdatePassword = async event => {
    event.preventDefault();

    const hasEmptyField = Object.values(data).some(value => !value);
    if (hasEmptyField) return toast.warn('Preencha todos os campos.');

    if (data.newPassword !== data.confirmPassword) {
      return toast.warn('As senhas não coincidem.');
    }

    try {
      const res = await api.put('updatepac', {
        cpf: data.cpf,
        codigo: data.codigo,
        newPassword: data.newPassword,
      });

      toast.success(res.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const sendVerificationCode = async () => {
    const formattedPhoneNumber = celular.replace(/[^\d+]/g, '');

    try {
      const res = await api.put('request-password-change', {
        cpf,
        celular: formattedPhoneNumber,
      });

      setShowVerificationCodeModal(false);
      toast.success(res.data.message);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      <div className="row justify-content-md-center p-5">
        <div className="col-lg-auto">
          <Card className="m-2" style={{ width: '22rem' }}>
            <Card.Body>
              <Form>
                <div className="p-3">
                  <div className="text-center">
                    <img
                      className="img-fluid rounded-circle center-block hoverable img-profile mx-2 mb-2"
                      src={logo}
                      alt="logo"
                      width={50}
                      height={50}
                    />
                  </div>
                  <Form.Group>
                    <Form.Label>CPF do Paciente</Form.Label>
                    <InputMask
                      type="text"
                      placeholder="Digite seu CPF"
                      mask="111.111.111-11"
                      value={data.cpf}
                      onChange={e => setData({ ...data, cpf: e.target.value })}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Código de verificação</Form.Label>
                    <Form.Control
                      value={data.codigo}
                      onChange={e =>
                        setData({ ...data, codigo: e.target.value })
                      }
                      maxLength={6}
                      autoComplete="off"
                      type="text"
                      placeholder="Código de verificação"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Senha</Form.Label>
                    <Form.Control
                      autoComplete="off"
                      value={data.newPassword}
                      onChange={e =>
                        setData({ ...data, newPassword: e.target.value })
                      }
                      minLength="4"
                      type="password"
                      placeholder="Sua senha secreta"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Confirme sua senha</Form.Label>
                    <Form.Control
                      autoComplete="off"
                      value={data.confirmPassword}
                      onChange={e =>
                        setData({ ...data, confirmPassword: e.target.value })
                      }
                      minLength="4"
                      type="password"
                      placeholder="Confirme sua senha"
                    />
                  </Form.Group>

                  <div className="text-center p-1">
                    <Button
                      variant="success"
                      block
                      onClick={handleUpdatePassword}
                    >
                      Atualizar senha
                    </Button>
                    <Button
                      block
                      onClick={() => setShowVerificationCodeModal(true)}
                    >
                      Gerar código de verificação
                    </Button>
                    <Button
                      variant="warning"
                      block
                      onClick={() => history.push('/plogin')}
                    >
                      Voltar
                    </Button>
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>

      <Modal
        show={showVerificationCodeModal}
        onHide={() => setShowVerificationCodeModal(false)}
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title>Gerar código de verificação</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>CPF</Form.Label>
            <InputMask
              required
              type="text"
              placeholder="Digite seu CPF"
              mask="111.111.111-11"
              onChange={e => setCpf(e.target.value)}
              value={cpf}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Celular</Form.Label>
            <InputMask
              required
              type="text"
              placeholder="Digite seu celular"
              mask="+55 (11) 11111-1111"
              value={celular}
              onChange={e => setCelular(e.target.value)}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={sendVerificationCode}>
            Receber código
          </Button>
          <Button
            variant="secondary"
            onClick={() => setShowVerificationCodeModal(false)}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={show}
        onHide={() => {
          setShowVerificationCodeModal(true);
          setShow(false);
        }}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Bem-vindo ao nosso sistema!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Para proteger a privacidade e segurança dos seus dados, é importante
            que você esteja ciente e concorde com nossos termos e políticas de
            privacidade, em conformidade com a Lei Geral de Proteção de Dados
            (LGPD).
          </p>
          <p>
            A LGPD é uma legislação que estabelece regras e diretrizes para o
            tratamento de dados pessoais, com o objetivo de garantir a proteção
            e a privacidade das informações que coletamos.{' '}
          </p>
          <p>
            Como parte da nossa responsabilidade para com a segurança dos dados
            registrados, pedimos que todos os usuários leiam e aceitem nossos
            termos antes de continuar.
          </p>
          <p>
            Ao continuar com o login, você concorda com nossos termos e
            políticas de privacidade, comprometendo-se a agir de acordo com as
            normas da LGPD.{' '}
          </p>
          <p>
            Certifique-se de que compreende suas responsabilidades em relação
            aos dados pessoais e siga as melhores práticas para garantir a
            conformidade com a lei.{' '}
          </p>
          <p>
            Caso tenha alguma dúvida ou preocupação sobre como tratamos os dados
            pessoais, não hesite em entrar em contato com nossa equipe de
            suporte. Estamos comprometidos em manter a integridade e a segurança
            dos seus dados pessoais.
          </p>
          <p>
            Obrigado por confiar em nós para cuidar dos seus dados de forma
            segura e responsável.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setShowVerificationCodeModal(true);
              setShow(false);
            }}
          >
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
