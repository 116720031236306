/* eslint-disable consistent-return */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../../services/api';

// createSlice makes all action creators and reducers in the same file so no separation of logic is necessary

/** *************STATE SLICE************** */
export const sliceAgenda = createSlice({
  name: 'agenda',
  initialState: {
    agenda: {},
    horarios: [],
  },
  reducers: {
    agendaSuccess: (state, action) => {
      const { agenda, horarios } = action.payload;
      if (agenda !== undefined) {
        state.agenda = agenda;
      }
      if (horarios !== undefined) {
        state.horarios = horarios;
      }
    },
    agendaRequest: state => {
      state.agenda = {};
      state.horarios = [];
    },
    agendaReset: state => {
      state.agenda = {};
      state.horarios = [];
    },
    agendaFailure: state => {
      state.agenda = {};
      state.horarios = [];
    },
  },
});

/** *************EXPORTED ACTIONS & REDUCERS************** */

export const {
  agendaRequest,
  agendaSuccess,
  agendaFailure,
  agendaReset,
} = sliceAgenda.actions;

export default sliceAgenda.reducer;

// API REQUEST ACTIONS HANDLED WITH REDUX-THUNK MIDDLEWARE BUILT INTO REDUX TOOLKIT -->

/** *************THUNKS************** */

export const selectAgendaById = payload => {
  return async dispatch => {
    try {
      dispatch(agendaRequest());
      const result = await api.get(`agendabycodpac?id=${payload.CODPAC}`);
      const { agenda } = result.data;
      dispatch(agendaSuccess({ agenda }));
    } catch (err) {
      toast.error(err.response.data.error);
      dispatch(agendaFailure());
    }
  };
};

export const updateAgendaAtendido = payload => {
  return async dispatch => {
    try {
      const response = await api.put(`/agenda/atendido/`, payload);
      return response;
    } catch (err) {
      toast.error(err.response.data.error);
      dispatch(agendaFailure());
    }
  };
};

export const selectAllAgendaHorario = () => {
  return async dispatch => {
    try {
      const response = await api.get(`horarios`);
      const horarios = response.data;
      dispatch(agendaSuccess({ horarios }));
    } catch (error) {
      toast.error(`Falha na busca de horarios.`);
    }
  };
};
