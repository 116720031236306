export function signInRequest(cpf, senha) {
  return { type: '@patient/SIGN_IN_REQUEST', payload: { cpf, senha } };
}

export function signInSuccess(token, patient) {
  return { type: '@patient/SIGN_IN_SUCCESS', payload: { token, patient } };
}

export function signFailure() {
  return { type: '@patient/SIGN_FAILURE' };
}

export function signOutPac() {
  return { type: '@patient/SIGN_OUT' };
}
