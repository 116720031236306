const medicoAgenda = [
  {
    medico: 'Dr Fabio',
    codigo: 5,
  },
  {
    medico: 'Dr Alexandre',
    codigo: 958,
  },
  {
    medico: 'Drª Cassia',
    codigo: 957,
  },
  {
    medico: 'Dr Valdyr',
    codigo: 24,
  },
  {
    medico: 'Dr Roberto',
    codigo: 1628,
  },
  {
    medico: 'Drª Marina',
    codigo: 2657,
  },
  {
    medico: 'Dr João',
    codigo: 2796,
  },
  {
    medico: 'Drª Fernanda',
    codigo: 2030,
  },
  {
    medico: 'Dr Igor',
    codigo: 3283,
  },
  {
    medico: 'Dr Raphael',
    codigo: 3794,
  },
  {
    medico: 'Dr Luiz',
    codigo: 4860,
  },
  {
    medico: 'Bio',
    codigo: 1981,
  },
  {
    medico: 'MG',
    codigo: 2921,
  },
  {
    medico: 'EcoInt',
    codigo: 3072,
  },
  {
    medico: 'EcgExt',
    codigo: 3804,
  },
  {
    medico: 'Estudo',
    codigo: 4233,
  },
];

export default medicoAgenda;
