/* eslint-disable no-return-assign */
/* eslint-disable no-shadow */
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import { format, parseISO, addDays } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from '../../../services/api';

export default function AgendaPaciente() {
  const paciente = useSelector(state => state.patient.profile);
  const [agenda, setAgenda] = useState([]);
  const [count, setCount] = useState(0);

  function formatarHora(hrAgenda) {
    let ao_hora = String(hrAgenda);
    if (ao_hora.length < 4) {
      ao_hora = `0${ao_hora}`;
    }
    ao_hora = ao_hora.replace(/(\d{2})(\d{2})$/, '$1:$2'); // Coloca um hífen entre o terceiro e o quarto dígitos
    return ao_hora;
  }

  useEffect(() => {
    let c = 0;
    async function loadAgendaPac() {
      const page = 1;
      const codPaciente = paciente.codPac;

      const response = await api.get(
        `paciente/agenda?page=${page}&id=${codPaciente}`
      );
      const data = response.data.map(agenda => ({
        ...agenda,
        dataFormatada: format(
          addDays(parseISO(agenda.dataAgenda), 1),
          'dd/MM/yyyy',
          { locale: pt }
        ),
        counter: (c += 1),
      }));
      setAgenda(data);
      setCount(c);
    }

    loadAgendaPac();
  }, [paciente.codPac]);

  return (
    <>
      <h4 style={{ marginTop: 10 }}>
        <span className="text-primary">Meus agendamentos</span>
      </h4>
      <Table
        style={{ marginTop: 10 }}
        responsive="sm"
        striped
        bordered
        hover
        size="sm"
        variant="primary"
      >
        <thead>
          <tr>
            <th>#</th>
            <th>Atividade</th>
            <th>Data</th>
            <th>Horário</th>
            <th>Médico</th>
            <th>Convênio</th>
          </tr>
        </thead>
        <tbody>
          {agenda.map(a => (
            <tr key={a.counter}>
              <td>{a.counter}</td>
              <td>{a.atividade}</td>
              <td>{a.dataFormatada}</td>
              <td>{formatarHora(a.horario)}</td>
              <td>{a.medico.apelido}</td>
              <td>{a.convenio.sigla}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td style={{ textAlign: 'right' }} colSpan="5">
              TOTAL DE AGENDAMENTOS
            </td>
            <td>{count}</td>
          </tr>
        </tfoot>
      </Table>
    </>
  );
}
