import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Card, Form, Button, Modal } from 'react-bootstrap';
import logo from '../../../assets/logo.png';
import { signUpRequest } from '../../../store/modules/auth/doctor/actions';

export default function PerfilMedico() {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(true);

  const handleSubmit = event => {
    event.preventDefault();

    if (!event.currentTarget.checkValidity()) {
      event.stopPropagation();
    } else {
      const cpf = event.target.elements.formBasicCPF.value;
      const validacao = event.target.elements.formBasicValida.value;
      const senha = event.target.elements.formBasicSenha.value;
      const confirmPassword = event.target.elements.formBasicConfirmSenha.value;
      dispatch(signUpRequest(cpf, validacao, senha, confirmPassword));
    }

    setValidated(true);
  };

  return (
    <>
      <div className="row justify-content-md-center p-5">
        <div className="col-lg-auto">
          <h3 className="text-center text-success font-weight-bold">
            Instituto do Coração de Marília
          </h3>
          <div className="text-center">
            <img
              className="img-fluid rounded-circle center-block hoverable img-profile"
              src={logo}
              alt="logoImagem"
              width={70}
              height={70}
            />
          </div>
          <Card className="m-2">
            <Card.Body>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <div className="p-3">
                  <Form.Group controlId="formBasicCPF">
                    <Form.Label>CPF do Médico</Form.Label>
                    <Form.Control required type="text" placeholder="Seu CPF" />
                    <Form.Control.Feedback type="invalid">
                      Digite seu CPF.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formBasicValida">
                    <Form.Label>Código de Validação</Form.Label>
                    <Form.Control
                      required
                      type="text"
                      placeholder="Código de Validação"
                    />
                    <Form.Control.Feedback type="invalid">
                      Digite seu Código de Validação.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formBasicSenha">
                    <Form.Label>Senha</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      placeholder="Sua senha secreta"
                    />
                    <Form.Control.Feedback type="invalid">
                      Digite sua senha.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group controlId="formBasicConfirmSenha">
                    <Form.Label>Confirme sua Senha</Form.Label>
                    <Form.Control
                      required
                      type="password"
                      placeholder="Confirme sua senha"
                    />
                    <Form.Control.Feedback type="invalid">
                      Confirme sua senha.
                    </Form.Control.Feedback>
                  </Form.Group>
                  <div className="text-center p-1">
                    <Button block type="submit">
                      Atualizar senha
                    </Button>
                  </div>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>

      <Modal show={show} onHide={() => setShow(false)} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Bem-vindo ao nosso sistema!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Para proteger a privacidade e segurança dos seus dados, é importante
            que você esteja ciente e concorde com nossos termos e políticas de
            privacidade, em conformidade com a Lei Geral de Proteção de Dados
            (LGPD).
          </p>
          <p>
            A LGPD é uma legislação que estabelece regras e diretrizes para o
            tratamento de dados pessoais, com o objetivo de garantir a proteção
            e a privacidade das informações que coletamos.{' '}
          </p>
          <p>
            Como parte da nossa responsabilidade para com a segurança dos dados
            registrados, pedimos que todos os usuários leiam e aceitem nossos
            termos antes de continuar.
          </p>
          <p>
            Ao continuar com o login, você concorda com nossos termos e
            políticas de privacidade, comprometendo-se a agir de acordo com as
            normas da LGPD.{' '}
          </p>
          <p>
            Certifique-se de que compreende suas responsabilidades em relação
            aos dados pessoais e siga as melhores práticas para garantir a
            conformidade com a lei.{' '}
          </p>
          <p>
            Caso tenha alguma dúvida ou preocupação sobre como tratamos os dados
            pessoais, não hesite em entrar em contato com nossa equipe de
            suporte. Estamos comprometidos em manter a integridade e a segurança
            dos seus dados pessoais.
          </p>
          <p>
            Obrigado por confiar em nós para cuidar dos seus dados de forma
            segura e responsável.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShow(false)}>
            Fechar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
