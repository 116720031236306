import { createSlice } from '@reduxjs/toolkit';

export const sliceContext = createSlice({
  name: 'contexto',
  initialState: { printing: false, loading: false, signing: false },
  reducers: {
    printClose: state => {
      state.printing = false;
    },
    printOpen: state => {
      state.printing = true;
    },
    loadingOpen: state => {
      state.loading = true;
    },
    loadingClose: state => {
      state.loading = false;
    },
    signingOpen: state => {
      state.signing = true;
    },
    signingClose: state => {
      state.signing = false;
    },
  },
});

/** *******EXPORTED ACTIONS & REDUCERS******** */

export const {
  printClose,
  printOpen,
  loadingOpen,
  loadingClose,
  signingOpen,
  signingClose,
} = sliceContext.actions;

export default sliceContext.reducer;
