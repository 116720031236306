import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { addDays, parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from '../../../services/api';

// createSlice makes all action creators and reducers in the same file so no separation of logic is necessary

/** *************STATE SLICE************** */
export const sliceProntuario = createSlice({
  name: 'prontuario',
  initialState: {
    loading: false,
    prontuario: {},
    receituario: {},
    receita: {},
    atestado: {},
    atestadomedico: {},
    agenda: {},
    pedido: {},
  },
  reducers: {
    prontuarioSuccess: (state, action) => {
      const {
        atestado,
        atestadoMedico,
        receita,
        receituario,
        prontuario,
        agendaPac,
        requisicao,
        pedidoExame,
      } = action.payload;
      state.loading = false;
      if (receituario !== undefined) {
        state.receituario = receituario;
      }
      if (atestado !== undefined) {
        state.atestado = atestado;
      }
      if (atestadoMedico !== undefined) {
        state.atestadomedico = atestadoMedico;
      }
      if (receita !== undefined) {
        state.receita = receita;
      }
      if (requisicao !== undefined) {
        state.pedido = requisicao;
      }
      if (pedidoExame !== undefined) {
        state.pedido = pedidoExame;
      }
      if (agendaPac !== undefined) {
        state.agenda = agendaPac;
      }
      if (prontuario !== undefined) {
        state.prontuario = prontuario;
      }
    },
    receitaSuccess: (state, action) => {
      const { receita, receituario } = action.payload;
      if (receituario !== undefined) {
        state.receituario = receituario;
      }
      if (receita !== undefined) {
        state.receita = receita;
      }
    },
    prontuarioRequest: state => {
      state.loading = true;
      state.receita = {};
      state.receituario = {};
      state.prontuario = {};
      state.agenda = {};
      state.atestado = {};
      state.atestadomedico = {};
      state.pedido = {};
    },
    prontuarioReset: state => {
      state.loading = false;
      state.receita = {};
      state.receituario = {};
      state.prontuario = {};
      state.agenda = {};
      state.atestado = {};
      state.atestadomedico = {};
      state.pedido = {};
    },
    prontuarioFailure: state => {
      state.loading = false;
      state.receita = {};
      state.receituario = {};
      state.prontuario = {};
      state.agenda = {};
      state.atestado = {};
      state.atestadomedico = {};
      state.pedido = {};
    },
  },
});

/** *************EXPORTED ACTIONS & REDUCERS************** */

export const {
  prontuarioRequest,
  prontuarioSuccess,
  receitaSuccess,
  prontuarioFailure,
  prontuarioReset,
} = sliceProntuario.actions;

export default sliceProntuario.reducer;

// API REQUEST ACTIONS HANDLED WITH REDUX-THUNK MIDDLEWARE BUILT INTO REDUX TOOLKIT -->

/** *************THUNKS************** */

export const selectProntuarioById = payload => {
  return async dispatch => {
    try {
      dispatch(prontuarioRequest());
      let c = 0;
      const result = await api.get(`paciente/prontuario?id=${payload}`);

      const prontuarioAtual = Object.assign(result.data.atual, {
        dataFormatada:
          result.data.atual.DATACONSULTA &&
          format(
            addDays(parseISO(result.data.atual.DATACONSULTA), 1),
            'dd/MM/yyyy',
            { locale: pt }
          ),
        dataNasc:
          result.data.atual.DTNPAC &&
          format(addDays(parseISO(result.data.atual.DTNPAC), 1), 'dd/MM/yyyy', {
            locale: pt,
          }),
        idadePAC: Math.floor(
          (parseISO(result.data.atual.DATACONSULTA) -
            parseISO(result.data.atual.DTNPAC)) /
            (1000 * 3600 * 24) /
            365.25
        ),
      });
      const prontuarioAnterior = Object.assign(result.data.anterior, {
        dataFormatada:
          result.data.anterior.DATACONSULTA &&
          format(
            addDays(parseISO(result.data.anterior.DATACONSULTA), 1),
            'dd/MM/yyyy',
            { locale: pt }
          ),
        dataNasc:
          result.data.anterior.DTNPAC &&
          format(
            addDays(parseISO(result.data.anterior.DTNPAC), 1),
            'dd/MM/yyyy',
            { locale: pt }
          ),
        idadePAC: Math.floor(
          (parseISO(result.data.anterior.DATACONSULTA) -
            parseISO(result.data.anterior.DTNPAC)) /
            (1000 * 3600 * 24) /
            365.25
        ),
      });
      const prontuarioAnteriores = result.data.anteriores.map(consulta => ({
        ...consulta,
        dataFormatada:
          consulta.DATACONSULTA &&
          format(addDays(parseISO(consulta.DATACONSULTA), 1), 'dd/MM/yyyy', {
            locale: pt,
          }),
        idadePAC: Math.floor(
          (parseISO(consulta.DATACONSULTA) - parseISO(consulta.DTNPAC)) /
            (1000 * 3600 * 24) /
            365.25
        ),
        counter: (c += 1),
      }));

      const prontuario = Object.assign(
        { atual: prontuarioAtual },
        { anterior: prontuarioAnterior },
        { anteriores: prontuarioAnteriores }
      );

      dispatch(prontuarioSuccess({ prontuario }));
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(prontuarioFailure());
    }
  };
};

export const selectLastProntuarioByPac = payload => {
  return async dispatch => {
    try {
      const result = await api.get(`/prontuario/ultimo?id=${payload}`);
      return result && result.data;
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(prontuarioFailure());
    }
  };
};

export const insertFatura = payload => {
  return async dispatch => {
    try {
      const result = await api.post(`/atividade/fatura/`, payload);
      return result.data.message;
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(prontuarioFailure());
    }
  };
};

export const insertProntuarioById = payload => {
  return async dispatch => {
    try {
      let prontuario = '';
      let c = 0;
      const result = await api.post(`/prontuario/`, payload);

      const prontuarioAtual = Object.assign(result.data.prontuario.atual, {
        dataFormatada:
          result.data.prontuario.atual.DATACONSULTA &&
          format(
            addDays(parseISO(result.data.prontuario.atual.DATACONSULTA), 1),
            'dd/MM/yyyy',
            { locale: pt }
          ),
        dataNasc:
          result.data.prontuario.atual.DTNPAC &&
          format(
            addDays(parseISO(result.data.prontuario.atual.DTNPAC), 1),
            'dd/MM/yyyy',
            { locale: pt }
          ),
        idadePAC: Math.floor(
          (parseISO(result.data.prontuario.atual.DATACONSULTA) -
            parseISO(result.data.prontuario.atual.DTNPAC)) /
            (1000 * 3600 * 24) /
            365.25
        ),
      });
      const prontuarioAnterior = Object.assign(
        result.data.prontuario.anterior,
        {
          dataFormatada:
            result.data.prontuario.anterior.DATACONSULTA &&
            format(
              addDays(
                parseISO(result.data.prontuario.anterior.DATACONSULTA),
                1
              ),
              'dd/MM/yyyy',
              { locale: pt }
            ),
          dataNasc:
            result.data.prontuario.anterior.DTNPAC &&
            format(
              addDays(parseISO(result.data.prontuario.anterior.DTNPAC), 1),
              'dd/MM/yyyy',
              { locale: pt }
            ),
          idadePAC: Math.floor(
            (parseISO(result.data.prontuario.anterior.DATACONSULTA) -
              parseISO(result.data.prontuario.anterior.DTNPAC)) /
              (1000 * 3600 * 24) /
              365.25
          ),
        }
      );

      const prontuarioAnteriores = result.data.prontuario.anteriores.map(
        consulta => ({
          ...consulta,
          dataFormatada:
            consulta.DATACONSULTA &&
            format(addDays(parseISO(consulta.DATACONSULTA), 1), 'dd/MM/yyyy', {
              locale: pt,
            }),
          idadePAC: Math.floor(
            (parseISO(consulta.DATACONSULTA) - parseISO(consulta.DTNPAC)) /
              (1000 * 3600 * 24) /
              365.25
          ),
          counter: (c += 1),
        })
      );

      prontuario = Object.assign(
        { atual: prontuarioAtual },
        { anterior: prontuarioAnterior },
        { anteriores: prontuarioAnteriores },
        { message: result.data.message }
      );

      dispatch(prontuarioSuccess({ prontuario }));
      return prontuario;
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(prontuarioFailure());
    }
  };
};

export const updateProntuarioById = payload => {
  return async dispatch => {
    try {
      const { codconsulta } = payload;
      let prontuario = '';
      let c = 0;
      const result = await api.put(`/prontuario/${codconsulta}`, payload);

      const prontuarioAtual = Object.assign(result.data.prontuario.atual, {
        dataFormatada:
          result.data.prontuario.atual.DATACONSULTA &&
          format(
            addDays(parseISO(result.data.prontuario.atual.DATACONSULTA), 1),
            'dd/MM/yyyy',
            { locale: pt }
          ),
        dataNasc:
          result.data.prontuario.atual.DTNPAC &&
          format(
            addDays(parseISO(result.data.prontuario.atual.DTNPAC), 1),
            'dd/MM/yyyy',
            { locale: pt }
          ),
        idadePAC: Math.floor(
          (parseISO(result.data.prontuario.atual.DATACONSULTA) -
            parseISO(result.data.prontuario.atual.DTNPAC)) /
            (1000 * 3600 * 24) /
            365.25
        ),
      });
      const prontuarioAnterior = Object.assign(
        result.data.prontuario.anterior,
        {
          dataFormatada:
            result.data.prontuario.anterior.DATACONSULTA &&
            format(
              addDays(
                parseISO(result.data.prontuario.anterior.DATACONSULTA),
                1
              ),
              'dd/MM/yyyy',
              { locale: pt }
            ),
          dataNasc:
            result.data.prontuario.anterior.DTNPAC &&
            format(
              addDays(parseISO(result.data.prontuario.anterior.DTNPAC), 1),
              'dd/MM/yyyy',
              { locale: pt }
            ),
          idadePAC: Math.floor(
            (parseISO(result.data.prontuario.anterior.DATACONSULTA) -
              parseISO(result.data.prontuario.anterior.DTNPAC)) /
              (1000 * 3600 * 24) /
              365.25
          ),
        }
      );

      const prontuarioAnteriores = result.data.prontuario.anteriores.map(
        consulta => ({
          ...consulta,
          dataFormatada:
            consulta.DATACONSULTA &&
            format(addDays(parseISO(consulta.DATACONSULTA), 1), 'dd/MM/yyyy', {
              locale: pt,
            }),
          idadePAC: Math.floor(
            (parseISO(consulta.DATACONSULTA) - parseISO(consulta.DTNPAC)) /
              (1000 * 3600 * 24) /
              365.25
          ),
          counter: (c += 1),
        })
      );

      prontuario = Object.assign(
        { atual: prontuarioAtual },
        { anterior: prontuarioAnterior },
        { anteriores: prontuarioAnteriores },
        { message: result.data.message }
      );

      dispatch(prontuarioSuccess({ prontuario }));
      return prontuario;
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(prontuarioFailure());
    }
  };
};

export const liberarLaudoByPac = payload => {
  return async () => {
    try {
      const { fatura } = payload;
      const response = await api.put(`/atividade/liberacao/`, fatura);
      return response.data.message;
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };
};

export const atualizarGuiaConvenio = payload => {
  return async () => {
    try {
      const response = await api.put(`/atividade/guia/`, payload);
      return response.data.message;
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };
};

export const liberarLaudoById = payload => {
  return async () => {
    try {
      const { fatura } = payload;
      let liberado = '';
      const result = await api.put(
        `/liberar/atividade/${fatura.codFatura}`,
        fatura
      );

      if (result.statusText === 'OK') liberado = result.data;

      return liberado;
    } catch (error) {
      toast.error(error.response.data.error);
    }
  };
};

export const selectReceitaById = payload => {
  return async dispatch => {
    try {
      dispatch(prontuarioRequest());
      let c = 0;
      // /paciente/receita/:codrec
      const result = await api.get(
        `paciente/receita/${payload.receita.CODRECEITA}`
      );

      const data = result.data.map(rec => ({
        ...rec,
        dataFormatada: format(
          addDays(parseISO(rec.DATARECEITA), 1),
          'dd/MM/yyyy',
          { locale: pt }
        ),
        counter: (c += 1),
      }));
      dispatch(receitaSuccess({ data }));
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(prontuarioFailure());
    }
  };
};

export const insertReceita = payload => {
  return async dispatch => {
    try {
      const response = await api.post(`receita/`, payload);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(prontuarioFailure());
    }
  };
};

export const insertAtestado = payload => {
  return async dispatch => {
    try {
      const response = await api.post(`atestado/`, payload);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(prontuarioFailure());
    }
  };
};

export const insertRequisicao = payload => {
  return async dispatch => {
    try {
      const response = await api.post(`requisicao/`, payload);
      return response.data;
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(prontuarioFailure());
    }
  };
};
