export default function dataExtenso(data_informada) {
  const meses = [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ];

  const dia_informado = data_informada.split('/')[0];
  const mes_informado = data_informada.split('/')[1];
  const ano_informado = data_informada.split('/')[2];
  const data = `${ano_informado}-${mes_informado}-${dia_informado} 00:00:00`;
  const dataInfo = new Date(data);
  const dia = dataInfo.getDate();
  const mes = dataInfo.getMonth();
  const ano = dataInfo.getFullYear();
  const diaext = `${dia} de ${meses[mes]} de ${ano}`;
  return diaext;
}
