import api from './api';

export const getScan = async id => {
  const { data } = await api.get(`/carotida/${id}`);
  return data;
};

export const saveScan = async data => {
  await api.post(`/carotida/scan`, data);
};

export const deleteScan = async (scanRow, tipo) => {
  const response = await api.delete('/carotida/scan', {
    data: { scanRow, tipo },
  });
  return response.data;
};
