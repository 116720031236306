/* eslint-disable consistent-return */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { addDays, parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from '../../../services/api';

// createSlice makes all action creators and reducers in the same file so no separation of logic is necessary

/** *************STATE SLICE************** */
export const sliceFetal = createSlice({
  name: 'fetal',
  initialState: {
    loading: false,
    fetal: {},
  },
  reducers: {
    fetalSuccess: (state, action) => {
      const { fetal } = action.payload;
      state.loading = false;
      state.fetal = fetal;
    },
    fetalRequest: (state, action) => {
      const { fetal } = action.payload;
      state.loading = true;
      state.fetal = fetal;
    },
    fetalReset: state => {
      state.loading = false;
      state.fetal = {};
    },
    fetalFailure: state => {
      state.loading = false;
      state.fetal = {};
    },
  },
});

/** *************EXPORTED ACTIONS & REDUCERS************** */

export const {
  fetalRequest,
  fetalSuccess,
  fetalFailure,
  fetalReset,
} = sliceFetal.actions;

export default sliceFetal.reducer;

// API REQUEST ACTIONS HANDLED WITH REDUX-THUNK MIDDLEWARE BUILT INTO REDUX TOOLKIT -->

/** *************THUNKS************** */

export const selectFetalById = payload => {
  return async dispatch => {
    try {
      dispatch(fetalReset());
      const response = await api.get(`fetal/${payload}`);
      const result = response.data.map(st => ({
        ...st,
        dataFetal: format(addDays(parseISO(st.DATAFETAL), 1), 'dd/MM/yyyy', {
          locale: pt,
        }),
        dataNasc: format(addDays(parseISO(st.DTNPAC), 1), 'dd/MM/yyyy', {
          locale: pt,
        }),
        idadePAC: Math.floor(
          (parseISO(st.DATAFETAL) - parseISO(st.DTNPAC)) /
            (1000 * 3600 * 24) /
            365.25
        ),
      }));
      const fetal = result[0];

      await dispatch(fetalSuccess({ fetal }));
    } catch (error) {
      toast.error(`ERRO ao buscar EcoFetal.`);
      dispatch(fetalFailure());
    }
  };
};

export const updateFetalById = payload => {
  return async dispatch => {
    try {
      const { CODFETAL } = payload;
      let fetal = '';
      const result = await api.put(`/fetal/${CODFETAL}`, payload);
      if (result.statusText === 'OK') {
        fetal = result.data;
      }
      return fetal;
    } catch (error) {
      toast.error(`ERRO na atualização de um ECO-FETAL.`);
      dispatch(fetalFailure());
    }
  };
};
