/* eslint-disable consistent-return */
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import validarCPF from 'validar-cpf';
import history from '../../../../services/history';
import api from '../../../../services/api';
import { signInSuccess, signFailure } from './actions';

export function* signIn({ payload }) {
  try {
    const { cpf, senha } = payload;

    if (!validarCPF(cpf)) return toast.error('CPF inválido.');

    const response = yield call(api.post, 'fsessions', { cpf, senha });

    const { token, funcionario } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;
    yield put(signInSuccess(token, funcionario));
    history.push('/agenda');
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;
  const { token } = payload.funcionario;
  if (token) api.defaults.headers.Authorization = `Bearer ${token}`;
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@funcionario/SIGN_IN_REQUEST', signIn),
  takeLatest('@funcionario/SIGN_OUT', signOut),
]);
