/* eslint-disable consistent-return */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../../services/api';

// createSlice makes all action creators and reducers in the same file so no separation of logic is necessary

export const slicePaciente = createSlice({
  name: 'paciente',
  initialState: {
    patient: {},
    patients: [],
  },
  reducers: {
    pacienteSuccess: (state, action) => {
      const { paciente, pacientes } = action.payload;
      if (paciente !== undefined) {
        state.patient = paciente;
      }
      if (pacientes !== undefined) {
        state.patients = pacientes;
      }
    },
    pacienteRequest: state => {
      state.patient = {};
      state.patients = [];
    },
    pacienteReset: state => {
      state.patient = {};
      state.patients = [];
    },
    pacienteFailure: state => {
      state.patient = {};
      state.patients = [];
    },
  },
});

/** *************EXPORTED ACTIONS & REDUCERS************** */

export const {
  pacienteRequest,
  pacienteSuccess,
  pacienteFailure,
  pacienteReset,
} = slicePaciente.actions;

export default slicePaciente.reducer;

// API REQUEST ACTIONS HANDLED WITH REDUX-THUNK MIDDLEWARE BUILT INTO REDUX TOOLKIT -->

/** *************THUNKS************** */
export const selectPacienteById = payload => {
  return async dispatch => {
    try {
      dispatch(pacienteRequest());
      // /pacientebycodpac
      const result = await api.get(`pacientebycodpac?id=${payload.CODPAC}`);
      const { paciente } = result.data;
      dispatch(pacienteSuccess({ paciente }));
      return paciente;
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(pacienteFailure());
    }
  };
};

export const selectPacienteByName = payload => {
  return async dispatch => {
    try {
      dispatch(pacienteRequest());
      // /pacientebynome
      const result = await api.get(`/pacientebynome`, {
        params: { nome: payload },
      });
      const pacientes = result.data;
      dispatch(pacienteSuccess({ pacientes }));
      return pacientes;
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(pacienteFailure());
    }
  };
};
