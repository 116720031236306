import React from 'react';

const OrientacaoMarevan = React.forwardRef((props, ref) => (
  <div
    ref={ref}
    style={{
      margin: '0 auto',
      padding: '20px',
      maxWidth: '800px',
      fontSize: '25px',
      textAlign: 'center',
    }}
  >
    <p>
      <strong>MAREVAN (VARFARINA)</strong>
    </p>
    <ol style={{ paddingLeft: '20px' }}>
      <li style={{ marginBottom: '10px' }}>
        <strong>EVITAR USAR SE FOR POSSÍVEL</strong>
        <ul style={{ textAlign: 'left' }}>
          <li>
            Anti-inflamatórios não esteróidais:{' '}
            <strong>ibuprofeno, naproxeno, diclofenaco e aspirina</strong>
          </li>
        </ul>
      </li>
      <li style={{ marginBottom: '10px' }}>
        <strong>REDUZIR A DOSE DO MAREVAN QUANDO FOR UTILIZAR:</strong>
        <ul style={{ textAlign: 'left' }}>
          <li>
            Amiodarona (<strong>Ancorom</strong>)
          </li>
          <li>
            Metronidazol (
            <strong>
              Flagyl, Helmizol, Meflagin, Neoetrodazol, Terconazol, Rozex
            </strong>
            )
          </li>
          <li>
            Sulfonamidas (
            <strong>
              Mafenide, Sulfacetamida, Sulfadiazina, Sulfadoxina, Sulfametizol,
              Sulfametoxazol, Sulfanilamida, Sulfassalazina
            </strong>
            )
          </li>
          <li>
            Alopurinol (<strong>Zyloric</strong>)
          </li>
          <li>Fluvastatina</li>
          <li>
            Genfibrosila (<strong>Lopid</strong>)
          </li>
          <li>
            Fluoruracila (<strong>tratamento de câncer</strong>)
          </li>
        </ul>
      </li>
      <li style={{ marginBottom: '10px' }}>
        <strong>AUMENTAR A DOSE DO MAREVAN</strong>
        <ul style={{ textAlign: 'left' }}>
          <li>
            Carbamazepina (<strong>Tegretol, Tegretard</strong>)
          </li>
        </ul>
      </li>
      <li style={{ marginBottom: '10px' }}>
        <strong>MONITORAR O INR CUIDADOSAMENTE</strong>
        <ul style={{ textAlign: 'left' }}>
          <li>Estatinas</li>
          <li>
            Antibióticos:{' '}
            <strong>
              Penicilinas, Macrolideos (eritromicina, azitromicina,
              claritromicina), Quinolonas (norfloxacino, ciprofloxacino,
              levofloxacino), Rifampicina
            </strong>
          </li>
          <li>
            Metotrexato (<strong>Tecnomet</strong>)
          </li>
          <li>
            Ritonavir (<strong>tratamento de HIV-Norvir</strong>)
          </li>
          <li>
            Fenitoína (<strong>anticonvulsivo-Hidantal</strong>)
          </li>
          <li>
            Valproato de sódio, ácido Valpróico (<strong>Depakene</strong>)
          </li>
          <li>
            Tamoxifeno (<strong>câncer de mama</strong>)
          </li>
          <li>
            <strong>
              DIMINUIR O CONSUMO DE BEBIDAS ALCOOLICAS, SUCOS TORANJA,
              ERVA-DE-SÃO-JOÃO (Hypericum perforatum)
            </strong>
          </li>
        </ul>
      </li>
    </ol>
  </div>
));

export default OrientacaoMarevan;
