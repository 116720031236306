import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, Card } from 'react-bootstrap';
import espmapa from '../../../assets/esp-mapa.png';

export default function EspelhoMapa() {
  const { mapa } = useSelector(state => state.mapa);

  function prefixo() {
    if (
      mapa[0]?.CODREALIZOU === 1 ||
      mapa[0]?.CODREALIZOU === 5 ||
      mapa[0]?.CODREALIZOU === 2796 ||
      mapa[0]?.CODREALIZOU === 958
    ) {
      return 'Prof. Dr. ';
    } else {
      return 'Dr. ';
    }
  }

  return (
    <Card style={{ fontSize: 24 }}>
      <Card.Header className="text-center">
        <Card.Title>LAUDO DE MAPA</Card.Title>
      </Card.Header>

      <Card.Body>
        <Form noValidate>
          <strong className="mr-auto">Cod MAPA: </strong>
          {mapa[0]?.CODMAPA}&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Nome do Paciente: </strong>
          {`${mapa[0]?.CODPAC} - ${mapa[0]?.NOMEPAC}`}
          <br></br>
          <strong className="mr-auto">Sexo: </strong>
          {`${mapa[0]?.SEXOPAC}`}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Data de Nasc: </strong>
          {`${mapa[0]?.dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Idade: </strong>
          {`${mapa[0]?.idadePAC} anos`}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Alt: </strong>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Peso: </strong>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">SC: </strong>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <br></br>
          <strong className="mr-auto">Convênio: </strong>
          {mapa[0]?.descriconv}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Data do Exame: </strong>
          {mapa[0]?.dataMapa}
          <br></br>
          <strong className="mr-auto">
            Médico que Solicitou: {prefixo()}{' '}
          </strong>
          {`${mapa[0]?.Solicitou}`}
          <hr></hr>
          <div>
            <img
              width="95%"
              height="60%"
              loading="lazy"
              src={espmapa}
              alt="espelho mapa"
            />{' '}
          </div>
          <div id="removeMe" className="text-right">
            <Button variant="outline-primary" onClick={() => window.print()}>
              Imprimir
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
}
