import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Form, Col, Row, Button, Card, Alert, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import {
  updateStressById,
  selectStressById,
} from '../../../redux/features/laudo/stressSlice';
import {
  loadingOpen,
  loadingClose,
  printOpen,
  printClose,
} from '../../../redux/features/context/contextSlice';
import { liberarLaudoByPac } from '../../../redux/features/prontuario/prontuarioSlice';
import Loading from '../../../pages/Index/Loading';
import api from '../../../services/api';
import s3 from '../../../util/s3';
import dblaudo from '../../../util/laudo';
import history from '../../../services/history';
import CustomFileInput from '../../../components/CustomFileInput';

export default function FormStress() {
  const dispatch = useDispatch();
  const { stress } = useSelector(state => state.stress);
  const { loading } = useSelector(state => state.contexto);
  const medico = useSelector(state => state.doctor.profile);
  const [show, setShow] = useState(false);
  const [files, setFiles] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const [mensagem, setMensagem] = useState('');
  const [stressLaudo, setStressLaudo] = useState({});

  useEffect(() => {
    if (medico?.tipo === 'Externo') history.push('/notAllowed');
  }, [medico]);

  useEffect(() => {
    if (stress.length) {
      setStressLaudo({
        ...stress[0],
        CODSTRESS: stress[0]?.CODSTRESS || '',
        ISQUEMIA: stress[0]?.ISQUEMIA || '',
        TIPOSTRESS: stress[0]?.TIPOSTRESS || '',
      });
    }
  }, [stress]);

  useEffect(() => {
    s3.listObjectsV2(
      { Bucket: 'prevencor', Prefix: `stress/${stress[0]?.CODSTRESS}` },
      (err, data) => {
        if (err) toast.error('Erro ao buscar arquivos. Entre em contato.');
        else
          setFiles(
            data.Contents.filter(
              item =>
                item.Key.includes('.jpeg') ||
                item.Key.includes('.pdf') ||
                item.Key.includes('.mp4')
            )
          );
      }
    );
  }, [stress]);

  const handleTipoStress = tipo => {
    let descricao = '';
    switch (tipo) {
      /* FISIO Estresse Fisiológico POSITIVO
          Estresse Fisiológico NEGATIVO
          Estresse Fisiológico Alterado POSITIVO
          Estresse Fisiológico Alterado NEGATIVO */
      case 'efPositivo':
        descricao = dblaudo.fisio[0].normalPositivo;
        setStressLaudo({
          ...stressLaudo,
          TIPOSTRESS: 'Estresse Fisiológico POSITIVO',
          ISQUEMIA: descricao,
        });
        document.getElementById('editDescricao').focus();
        break;
      case 'efNegativo':
        descricao = dblaudo.fisio[1].normalNegativo;
        setStressLaudo({
          ...stressLaudo,
          TIPOSTRESS: 'Estresse Fisiológico NEGATIVO',
          ISQUEMIA: descricao,
        });
        document.getElementById('editDescricao').focus();
        break;
      case 'efaPositivo':
        descricao = dblaudo.fisio[2].alteradoPositivo;
        setStressLaudo({
          ...stressLaudo,
          TIPOSTRESS: 'Estresse Fisiológico Alterado POSITIVO',
          ISQUEMIA: descricao,
        });
        document.getElementById('editDescricao').focus();
        break;
      case 'efaNegativo':
        descricao = dblaudo.fisio[3].alteradoNegativo;
        setStressLaudo({
          ...stressLaudo,
          TIPOSTRESS: 'Estresse Fisiológico Alterado NEGATIVO',
          ISQUEMIA: descricao,
        });
        document.getElementById('editDescricao').focus();
        break;
      /* DOBUTA
Isquemia e Viabilidade Miocárdica POSITIVO
Isquemia e Viabilidade Miocárdica NEGATIVO
Isquemia Miocárdica POSITIVO
Isquemia Miocárdica NEGATIVO
Isquemia Miocárdica Alterado POSITIVO
Isquemia Miocárdica Alterado NEGATIVO */
      case 'ivmPositivo':
        descricao = dblaudo.dobuta[0].ivPositivo;
        setStressLaudo({
          ...stressLaudo,
          TIPOSTRESS: 'Isquemia e Viabilidade Miocárdica POSITIVO',
          ISQUEMIA: descricao,
        });
        document.getElementById('editDescricao').focus();
        break;
      case 'ivmNegativo':
        descricao = dblaudo.dobuta[1].ivNegativo;
        setStressLaudo({
          ...stressLaudo,
          TIPOSTRESS: 'Isquemia e Viabilidade Miocárdica NEGATIVO',
          ISQUEMIA: descricao,
        });
        document.getElementById('editDescricao').focus();
        break;
      case 'imPositivo':
        descricao = dblaudo.dobuta[2].imPositivo;
        setStressLaudo({
          ...stressLaudo,
          TIPOSTRESS: 'Isquemia Miocárdica POSITIVO',
          ISQUEMIA: descricao,
        });
        document.getElementById('editDescricao').focus();
        break;
      case 'imNegativo':
        descricao = dblaudo.dobuta[3].imNegativo;
        setStressLaudo({
          ...stressLaudo,
          TIPOSTRESS: 'Isquemia Miocárdica NEGATIVO',
          ISQUEMIA: descricao,
        });
        document.getElementById('editDescricao').focus();
        break;
      case 'imaPositivo':
        descricao = dblaudo.dobuta[4].imaPositivo;
        setStressLaudo({
          ...stressLaudo,
          TIPOSTRESS: 'Isquemia Miocárdica Alterado POSITIVO',
          ISQUEMIA: descricao,
        });
        document.getElementById('editDescricao').focus();
        break;
      case 'imaNegativo':
        descricao = dblaudo.dobuta[5].imaNegativo;
        setStressLaudo({
          ...stressLaudo,
          TIPOSTRESS: 'Isquemia Miocárdica Alterado NEGATIVO',
          ISQUEMIA: descricao,
        });
        document.getElementById('editDescricao').focus();
        break;
      default:
    }
  };

  async function handleGravar(e) {
    e.preventDefault();
    setShow(false);
    dispatch(loadingOpen());

    // const fatura = {
    //   codLaudo: stressLaudo.CODSTRESS,
    //   codPac: stressLaudo.CODPAC,
    // };
    await dispatch(updateStressById(stressLaudo));

    // if (medico) {
    //   try {
    //     const response = await dispatch(liberarLaudoByPac({ fatura }));
    //     setShow(true);
    //     setMensagem(response);
    //   } catch (error) {
    //     setMensagem('Erro ao liberar laudo!');
    //   }
    // }

    await dispatch(selectStressById(stressLaudo.CODSTRESS));
    dispatch(loadingClose());
  }

  async function liberarLaudo(e) {
    e.preventDefault();

    const fatura = {
      codLaudo: stressLaudo.CODSTRESS,
      codPac: stressLaudo.CODPAC,
    };

    try {
      const response = await dispatch(liberarLaudoByPac({ fatura }));
      setShow(true);
      setMensagem(response);
    } catch (error) {
      setMensagem('Erro ao liberar laudo!');
    }
  }

  async function handleUploadFile(file) {
    if (!file) return toast.warn('Selecione um arquivo!');

    const encodedFilename = encodeURIComponent(file.name);

    try {
      const { data } = await api.put(
        `https://j87jesh814.execute-api.us-east-1.amazonaws.com/dev/image-upload/folder/stress/laudo/${stress[0]?.CODSTRESS}/key/${encodedFilename}`
      );

      await fetch(data.preSignedUrl, { method: 'PUT', body: file });
    } catch (error) {
      toast.error('Erro ao enviar arquivo!');
    }
  }

  async function confirmUpload() {
    for (const file of files) {
      await handleUploadFile(file);
    }

    toast.success('Arquivos enviados com sucesso!');
  }

  function closeFileModal() {
    setShowUpload(false);
    setFiles([]);
  }

  return (
    <>
      <Card style={{ marginTop: 10 }}>
        <Card.Header className="text-center">
          <Card.Title>LAUDO DE ESTRESSE</Card.Title>
        </Card.Header>

        <Card.Body>
          <Form noValidate>
            <strong className="mr-auto">Nome do Paciente: </strong>
            {`${stressLaudo.CODPAC} - ${stressLaudo.NOMEPAC}`}
            <br></br>
            <strong className="mr-auto">Data de Nasc: </strong>
            {`${stressLaudo.dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Sexo: </strong>
            {`${stressLaudo.SEXOPAC}`}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Idade: </strong>
            {`${stressLaudo.idadePAC} anos`}
            <br></br>
            <strong className="mr-auto">Convênio: </strong>
            {stressLaudo.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Cod Stress: </strong>
            {stressLaudo.CODSTRESS}&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Data do Exame: </strong>
            {stressLaudo.dataStress}
            <br></br>
            <strong className="mr-auto">Médico que Realizou: Dr. </strong>
            {`${stressLaudo.Realizou}`}
            <br></br>
            <strong className="mr-auto">Médico que Solicitou: Dr. </strong>
            {`${stressLaudo.Solicitou}`}
            <Card className="mt-4">
              <div className="m-3" style={{ fontSize: 12 }}>
                <Row xs={12} sm={6} md={2} lg={2}>
                  <Col sm={6} style={{ align: 'left' }}>
                    <strong>ESTRESSE FISIO</strong>
                    <Form.Check
                      label="Estresse Fisiológico POSITIVO"
                      name="groupStress"
                      type="radio"
                      id="efPositivo"
                      onClick={event => handleTipoStress(event.target.id)}
                    />
                    <Form.Check
                      label="Estresse Fisiológico NEGATIVO"
                      name="groupStress"
                      type="radio"
                      id="efNegativo"
                      onClick={event => handleTipoStress(event.target.id)}
                    />
                    <Form.Check
                      label="Estresse Fisiológico Alterado POSITIVO"
                      name="groupStress"
                      type="radio"
                      id="efaPositivo"
                      onClick={event => handleTipoStress(event.target.id)}
                    />
                    <Form.Check
                      label="Estresse Fisiológico Alterado NEGATIVO"
                      name="groupStress"
                      type="radio"
                      id="efaNegativo"
                      onClick={event => handleTipoStress(event.target.id)}
                    />
                  </Col>
                  <Col sm={6} style={{ align: 'left' }}>
                    <strong>ESTRESSE DOBUTA</strong>
                    <Form.Check
                      label="Isquemia e Viabilidade Miocárdica POSITIVO"
                      name="groupStress"
                      type="radio"
                      id="ivmPositivo"
                      onClick={event => handleTipoStress(event.target.id)}
                    />
                    <Form.Check
                      label="Isquemia e Viabilidade Miocárdica NEGATIVO"
                      name="groupStress"
                      type="radio"
                      id="ivmNegativo"
                      onClick={event => handleTipoStress(event.target.id)}
                    />
                    <Form.Check
                      label="Isquemia Miocárdica POSITIVO"
                      name="groupStress"
                      type="radio"
                      id="imPositivo"
                      onClick={event => handleTipoStress(event.target.id)}
                    />
                    <Form.Check
                      label="Isquemia Miocárdica NEGATIVO"
                      name="groupStress"
                      type="radio"
                      id="imNegativo"
                      onClick={event => handleTipoStress(event.target.id)}
                    />
                    <Form.Check
                      label="Isquemia Miocárdica Alterado POSITIVO"
                      name="groupStress"
                      type="radio"
                      id="imaPositivo"
                      onClick={event => handleTipoStress(event.target.id)}
                    />
                    <Form.Check
                      label="Isquemia Miocárdica Alterado NEGATIVO"
                      name="groupStress"
                      type="radio"
                      id="imaNegativo"
                      onClick={event => handleTipoStress(event.target.id)}
                    />
                  </Col>
                </Row>
              </div>
            </Card>
            &nbsp;
            {show && <Alert variant="success">{mensagem}</Alert>}
            <Form.Row>
              <Col sm>
                <Form.Group size="sm" controlId="editDescricao">
                  <Form.Label>
                    <strong>DESCRIÇÃO</strong>
                  </Form.Label>
                  <Form.Control
                    as="textarea"
                    rows={stressLaudo?.ISQUEMIA?.split('\n').length + 3 || 2}
                    name="ISQUEMIA"
                    value={stressLaudo.ISQUEMIA ?? ''}
                    onChange={e =>
                      setStressLaudo({
                        ...stressLaudo,
                        ISQUEMIA: e.target.value,
                      })
                    }
                    onKeyDown={e => {
                      if (e.key === 'F2') {
                        const posStart = stressLaudo.ISQUEMIA.indexOf('---');
                        const memoIsquemia = document.getElementById(
                          'editDescricao'
                        );
                        memoIsquemia.setSelectionRange(posStart, posStart + 3);
                      }
                    }}
                  />
                </Form.Group>
              </Col>
            </Form.Row>
            {show && <Alert variant="success">{mensagem}</Alert>}
            <Card className="mt-3 text-center">
              <Card.Footer className="text-muted">
                <Button variant="success" onClick={handleGravar}>
                  Gravar
                </Button>{' '}
                <Button
                  variant="dark"
                  disabled={medico ? false : true}
                  onClick={liberarLaudo}
                >
                  Liberar
                </Button>{' '}
                <Button
                  onClick={() => {
                    dispatch(printClose());
                    history.push('/reportStress');
                  }}
                >
                  Imprimir
                </Button>{' '}
                <Button
                  variant="info"
                  onClick={() => {
                    dispatch(printOpen());
                    history.push('/reportStress');
                  }}
                >
                  Timbrado
                </Button>{' '}
                <Button variant="warning" onClick={() => setShowUpload(true)}>
                  Anexar
                </Button>{' '}
                <Button
                  variant="danger"
                  onClick={() => history.push('/agenda')}
                >
                  Fechar
                </Button>
              </Card.Footer>
            </Card>
          </Form>
        </Card.Body>
      </Card>

      {loading && <Loading />}

      {showUpload && (
        <Modal show={showUpload} onHide={closeFileModal}>
          <Modal.Header closeButton>
            <Modal.Title>Anexar arquivo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <CustomFileInput
              id="file-upload"
              label="Selecionar arquivo"
              multiple={true}
              onChange={e => setFiles(Array.from(e.target.files))}
            />
            <ul>{files && files.map(file => <li>{file.name}</li>)}</ul>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={confirmUpload}>Confirmar</Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
