function prefixo(laudo) {
  const codMedico = laudo?.codMedico || laudo?.CODMEDICO;
  if (
    codMedico === 1 ||
    codMedico === 5 ||
    codMedico === 2796 ||
    codMedico === 958
  ) {
    return 'Prof. Dr.';
  }
  return 'Dr.';
}

export default prefixo;
