import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import QRCode from 'react-qr-code';
import md5 from 'md5';
import {
  Alert,
  Form,
  Col,
  Row,
  Button,
  Card,
  Container,
} from 'react-bootstrap';
import api from '../../../services/api';
import Loading from '../../../pages/Index/Loading';

export default function ValidaRequisicao() {
  const { loading } = useSelector(state => state.contexto);
  const [show, setShow] = useState(false);
  const [alert, setAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [requisicao, setRequisicao] = useState();
  const [message, setMessage] = useState('');
  const [validated, setValidated] = useState(false);

  const handleClickICM = event => {
    event.preventDefault();
    window.location.href = 'https://moraestech.netlify.app/';
  };

  const handleSubmit = async event => {
    event.preventDefault();

    if (!event.currentTarget.checkValidity()) event.stopPropagation();

    setValidated(true);
    setAlert(false);
    setShow(false);
    setAlertMessage('');
    setRequisicao();
    const codRequisicao = document.getElementById('inputRequisicao').value;
    const codPaciente = document.getElementById('inputPaciente').value;
    const cpfUpdate = document.getElementById('inputCPF').value;
    const origem = document.getElementById('inputProfissional').value;
    const status = 'Validado';

    const requisicao = { status, origem, cpfUpdate, codPaciente };

    api
      .put(`/paciente/requisicao/${codRequisicao}`, requisicao)
      .then(result => {
        if (result.status === 200) {
          setAlertMessage(result.data.message);
          setRequisicao(result.data.requisicao);
          toast.success(result.data.message);
          setShow(true);
        }
      })
      .catch(err => {
        if (err.response.status === 500) {
          setAlert(true);
          setMessage(err.response.data.message);
          setRequisicao(err.response.data.requisicao);
          toast.warn(err.response.data.message);
        }
      });
  };

  return (
    <Container>
      <Row className="justify-content-md-center mb-1 mt-5">
        <Col md="auto">
          <Card border="success" className="text-center">
            <Card.Header>
              <Card.Title className="text-success">
                <strong>VALIDAÇÃO DE REQUISIÇÃO DE EXAMES</strong>
              </Card.Title>
              {show ? (
                <>
                  <Alert variant="success">{alertMessage}</Alert> <p></p>
                  {requisicao ? (
                    <Card.Text>
                      Autenticação:{' '}
                      {requisicao && md5(JSON.stringify(requisicao?.DESCRICAO))}
                    </Card.Text>
                  ) : null}
                </>
              ) : null}
              {alert ? (
                <>
                  <Alert variant="danger">{message}</Alert>
                  {requisicao ? (
                    <Card.Text>
                      Autenticação:{' '}
                      {requisicao && md5(JSON.stringify(requisicao?.DESCRICAO))}
                    </Card.Text>
                  ) : null}
                </>
              ) : null}
            </Card.Header>

            <Card.Body>
              <Card.Text className="text-success">
                <strong>
                  Informe os dados abaixo para VALIDAR a Requisição de Exames!
                </strong>
              </Card.Text>
              <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Form.Row>
                  <Form.Group as={Col} sm>
                    <Form.Label htmlFor="inputRequisicao">
                      <strong>CÓDIGO DA REQUISIÇÃO</strong>
                    </Form.Label>
                    <Form.Control
                      className="text-center"
                      required
                      type="text"
                      id="inputRequisicao"
                      aria-describedby="requisicaoHelp"
                    />
                    <Form.Text id="requisicaoHelp" muted>
                      <div className="text-primary">
                        Digite o Código da Requisição.
                      </div>
                    </Form.Text>
                  </Form.Group>
                  <Form.Group as={Col} sm>
                    <Form.Label htmlFor="inputPaciente">
                      <strong>CÓDIGO DO PACIENTE</strong>
                    </Form.Label>
                    <Form.Control
                      className="text-center"
                      required
                      type="text"
                      id="inputPaciente"
                      aria-describedby="pacienteHelp"
                    />
                    <Form.Text id="pacienteHelp" muted>
                      <div className="text-primary">
                        Digite o Código do Paciente.
                      </div>
                    </Form.Text>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm>
                    <Form.Label htmlFor="inputCPF">
                      <strong>CPF DO PROFISSIONAL</strong>
                    </Form.Label>
                    <Form.Control
                      className="text-center"
                      required
                      type="text"
                      id="inputCPF"
                      aria-describedby="cpfHelp"
                    />
                    <Form.Text id="cpfHelp" muted>
                      <div className="text-primary">
                        Informe o CPF do Profissional que está realizando a
                        validação da Requisição.
                      </div>
                    </Form.Text>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm>
                    <Form.Label htmlFor="inputProfissional">
                      <strong>NOME DO PROFISSIONAL</strong>
                    </Form.Label>
                    <Form.Control
                      className="text-center"
                      required
                      type="text"
                      id="inputProfissional"
                      aria-describedby="profissionalHelp"
                    />
                    <Form.Text id="profissionalHelp" muted>
                      <div className="text-primary">
                        Informe o Nome do Profissional que está validando a
                        Requisição.
                      </div>
                    </Form.Text>
                  </Form.Group>
                </Form.Row>
                <Form.Row>
                  <Form.Group as={Col} sm className="text-center">
                    <div>
                      <Button variant="primary" type="submit" size="lg">
                        Validar
                      </Button>{' '}
                      <Button
                        variant="secondary"
                        size="lg"
                        onClick={e => handleClickICM(e)}
                      >
                        Fechar
                      </Button>
                    </div>
                  </Form.Group>
                </Form.Row>
              </Form>
            </Card.Body>
            <Card.Footer className="text-muted">
              <div>
                <QRCode size={50} value="https://moraestech.netlify.app/" />
              </div>
              <p className="text-center mt-3 mb-3 text-muted">
                <a
                  href="https://moraestech.netlify.app/"
                  className="fa fa-instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Copyright ©<b>MoraesTech</b>.
                </a>{' '}
                <a
                  href="https://www.instagram.com/moraestechnology/"
                  className="fa fa-instagram"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Todos os direitos reservados.
                </a>
              </p>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
      {loading ? <Loading /> : null}
    </Container>
  );
}
