export function signInRequest(cpf, senha) {
  return {
    type: '@funcionario/SIGN_IN_REQUEST',
    payload: { cpf, senha },
  };
}

export function signInSuccess(token, funcionario) {
  return {
    type: '@funcionario/SIGN_IN_SUCCESS',
    payload: { token, funcionario },
  };
}

export function signFailure() {
  return { type: '@funcionario/SIGN_FAILURE' };
}

export function signOutFunc() {
  return { type: '@funcionario/SIGN_OUT' };
}
