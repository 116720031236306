import { createGlobalStyle } from 'styled-components';

const ReportStyles = createGlobalStyle`
  @media print {
  @page {
    margin-top:4cm;
    margin-bottom: 3cm;
  }

  .page {
    page-break-after: always;
  }

  .page:first-of-type {
    margin-top: 0;
  }

  .page:last-of-type {
    margin-bottom: 0;
    page-break-after: auto;
  }
}`;

export default ReportStyles;
