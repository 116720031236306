import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../../services/api';

// createSlice makes all action creators and reducers in the same file so no separation of logic is necessary

/** *************STATE SLICE************** */
export const sliceProcedimento = createSlice({
  name: 'procedimento',
  initialState: {
    procedimentos: [],
  },
  reducers: {
    procedimentoSuccess: (state, action) => {
      const { procedimentos } = action.payload;
      if (procedimentos !== undefined) {
        state.procedimentos = procedimentos;
      }
    },
    procedimentoRequest: state => {
      state.procedimentos = [];
    },
    procedimentoReset: state => {
      state.procedimentos = [];
    },
    procedimentoFailure: state => {
      state.procedimentos = [];
    },
  },
});

/** *************EXPORTED ACTIONS & REDUCERS************** */

export const {
  procedimentoRequest,
  procedimentoSuccess,
  procedimentoFailure,
  procedimentoReset,
} = sliceProcedimento.actions;

export default sliceProcedimento.reducer;

// API REQUEST ACTIONS HANDLED WITH REDUX-THUNK MIDDLEWARE BUILT INTO REDUX TOOLKIT -->

/** *************THUNKS************** */

export const selectAllProcedimentos = () => {
  return async dispatch => {
    try {
      dispatch(procedimentoRequest());
      const result = await api.get(`procedimentos`);
      const { procedimentos } = result.data;
      dispatch(procedimentoSuccess({ procedimentos }));
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(procedimentoFailure());
    }
  };
};
