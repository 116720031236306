/* eslint-disable consistent-return */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { addDays, parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from '../../../services/api';

// createSlice makes all action creators and reducers in the same file so no separation of logic is necessary

/** *************STATE SLICE************** */
export const sliceStress = createSlice({
  name: 'stress',
  initialState: {
    loading: false,
    stress: {},
  },
  reducers: {
    stressSuccess: (state, action) => {
      const { stress } = action.payload;
      state.loading = false;
      state.stress = stress;
    },
    stressRequest: (state, action) => {
      const { stress } = action.payload;
      state.loading = true;
      state.stress = stress;
    },
    stressReset: state => {
      state.loading = false;
      state.stress = {};
    },
    stressFailure: state => {
      state.loading = false;
      state.stress = {};
    },
  },
});

/** *************EXPORTED ACTIONS & REDUCERS************** */

export const {
  stressRequest,
  stressSuccess,
  stressFailure,
  stressReset,
} = sliceStress.actions;

export default sliceStress.reducer;

// API REQUEST ACTIONS HANDLED WITH REDUX-THUNK MIDDLEWARE BUILT INTO REDUX TOOLKIT -->

/** *************THUNKS************** */
export const selectStressById = payload => {
  return async dispatch => {
    try {
      dispatch(stressReset());
      const response = await api.get(`stress/${payload}`);
      const stress = response.data.map(st => ({
        ...st,
        dataStress: format(addDays(parseISO(st.DATASTRESS), 1), 'dd/MM/yyyy', {
          locale: pt,
        }),
        dataNasc: format(addDays(parseISO(st.DTNPAC), 1), 'dd/MM/yyyy', {
          locale: pt,
        }),
        idadePAC: Math.floor(
          (parseISO(st.DATASTRESS) - parseISO(st.DTNPAC)) /
            (1000 * 3600 * 24) /
            365.25
        ),
      }));
      await dispatch(stressSuccess({ stress }));
    } catch (error) {
      toast.error(`ERRO ao buscar Stress.`);
      dispatch(stressFailure());
    }
  };
};

export const selectStressByIdOLD = payload => {
  return async dispatch => {
    try {
      const response = await api.get(`stress/old/${payload}`);
      const stress = response.data.map(st => ({
        ...st,
        dataStress: format(addDays(parseISO(st.DATASTRESS), 1), 'dd/MM/yyyy', {
          locale: pt,
        }),
        dataNasc: format(addDays(parseISO(st.DTNPAC), 1), 'dd/MM/yyyy', {
          locale: pt,
        }),
        idadePAC: Math.floor(
          (parseISO(st.DATASTRESS) - parseISO(st.DTNPAC)) /
            (1000 * 3600 * 24) /
            365.25
        ),
      }));
      await dispatch(stressSuccess({ stress }));
    } catch (error) {
      toast.error(`ERRO ao buscar Stress(OLD).`);
      dispatch(stressFailure());
    }
  };
};

export const updateStressById = payload => {
  return async dispatch => {
    try {
      const { CODSTRESS } = payload;
      const result = await api.put(`/stress/${CODSTRESS}`, payload);
      toast.success(`Stress gravado com sucesso`);
      return result.data;
    } catch (error) {
      toast.error(`ERRO na atualização de STRESS.`);
      dispatch(stressFailure());
    }
  };
};
