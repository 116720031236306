import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../../services/api';

// createSlice makes all action creators and reducers in the same file so no separation of logic is necessary

/** *************STATE SLICE************** */
export const sliceConvenio = createSlice({
  name: 'convenio',
  initialState: {
    convenios: [],
  },
  reducers: {
    convenioSuccess: (state, action) => {
      const { convenios } = action.payload;
      if (convenios !== undefined) {
        state.convenios = convenios;
      }
    },
    convenioRequest: state => {
      state.convenios = [];
    },
    convenioReset: state => {
      state.convenios = [];
    },
    convenioFailure: state => {
      state.convenios = [];
    },
  },
});

/** *************EXPORTED ACTIONS & REDUCERS************** */

export const {
  convenioRequest,
  convenioSuccess,
  convenioFailure,
  convenioReset,
} = sliceConvenio.actions;

export default sliceConvenio.reducer;

// API REQUEST ACTIONS HANDLED WITH REDUX-THUNK MIDDLEWARE BUILT INTO REDUX TOOLKIT -->

/** *************THUNKS************** */

export const selectAllConvenios = () => {
  return async dispatch => {
    try {
      dispatch(convenioRequest());
      const result = await api.get(`careplan`);
      const { convenios } = result.data;
      dispatch(convenioSuccess({ convenios }));
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(convenioFailure());
    }
  };
};
