import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Card, Row, Col, Form, Button } from 'react-bootstrap';
import md5 from 'md5';
import history from '../../../services/history';
import styles from '../../../styles/styles.module.css';
import header from '../../../assets/icm-header.jpg';
import blank from '../../../assets/icm-blank.jpg';

export default function FormAvaliacao() {
  const medico = useSelector(state => state.doctor.profile);
  const { avaliacao, marcapasso } = useSelector(state => state.avaliacao);
  const { printing } = useSelector(state => state.contexto);

  useEffect(() => {
    if (medico?.tipo === 'Externo') {
      history.push('/notAllowed');
    }
  }, [medico]);

  const handleDescricao = () => {
    return (
      <React.Fragment>
        <div style={{ fontSize: '14px' }} className={styles.preContent}>
          {avaliacao.CARTA}
        </div>
      </React.Fragment>
    );
  };

  const alteraFont = e => {
    let elemento = document.getElementById('cartaObs');
    let fonte = parseInt(elemento.style.fontSize);

    if (e === 'a') fonte++;
    if (e === 'd') fonte--;
    elemento.style.fontSize = fonte + 'px';
  };

  const removeDiv = divName => {
    document.getElementById(divName).style.visibility = 'hidden';
    document.getElementById('btnFonte').style.visibility = 'hidden';
    window.print();
    document.getElementById(divName).style.visibility = 'visible';
    document.getElementById('btnFonte').style.visibility = 'visible';
  };

  const prefixo = () => {
    if (
      avaliacao.CODAVALIOU === 1 ||
      avaliacao.CODAVALIOU === 5 ||
      avaliacao.CODAVALIOU === 2796 ||
      avaliacao.CODAVALIOU === 958
    ) {
      return 'Prof. Dr. ';
    } else {
      return 'Dr. ';
    }
  };

  return (
    <React.Fragment>
      <div>
        {printing ? (
          <div className={styles.imageReport}>
            <img width="550" height="100" src={header} alt="header" />
          </div>
        ) : (
          <div className={styles.imageReport}>
            <img width="550" height="100" src={blank} alt="header" />
          </div>
        )}

        <div id="btnFonte" className="d-flex justify-content-end">
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => alteraFont('a')}
          >
            A+
          </Button>
          <Button
            size="sm"
            variant="outline-primary"
            onClick={() => alteraFont('d')}
          >
            A-
          </Button>
        </div>
        <Card.Header className="text-center">
          <Card.Title className={styles.titleReport}>
            LAUDO DE AVALIAÇÃO DE MARCAPASSO
          </Card.Title>
        </Card.Header>
        <Form
          noValidate
          style={{ fontSize: '14px' }}
          className={styles.contentReport}
        >
          <strong className="mr-auto">Nome do Paciente: </strong>
          {`${avaliacao.CODPAC} - ${avaliacao?.NOMEPAC}`}
          <br></br>
          <strong className="mr-auto">Data de Nasc: </strong>
          {`${avaliacao?.dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Sexo: </strong>
          {`${avaliacao?.SEXOPAC}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Idade: </strong>
          {`${avaliacao?.idadePAC} anos`}
          <br></br>
          <strong className="mr-auto">Convênio: </strong>
          {avaliacao?.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Código: </strong>
          {avaliacao?.CODAVALIACAO}&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Data do Laudo: </strong>
          {avaliacao?.dataAvaliacao}
          <br></br>
          <strong className="mr-auto">Médico que Avaliou: Dra. </strong>
          {`${avaliacao?.Avaliou}`}
          <br></br>
          <strong className="mr-auto">Médico que Solicitou: Dr. </strong>
          {`${avaliacao?.Encaminhou}`}
          <hr></hr>
          <Row>
            <Col sm={6}>
              <strong>Hospital: </strong>
              {marcapasso.HOSPITAL}
            </Col>
            <Col sm={6}>
              <strong>Tipo: </strong>
              {marcapasso.MODOESTIM}
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <strong>Motivo Implante: </strong>
              {marcapasso.motivo && marcapasso.motivo[0]?.DESCRICAO}
            </Col>
            <Col sm={6}>
              <strong>Etiologia: </strong>
              {marcapasso.etiologia && marcapasso.etiologia[0]?.DESCRICAO}
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col sm={3}>
              <strong>Fabricante do Gerador: </strong>
              <br></br>
              {marcapasso.CODFABGER}
            </Col>
            <Col sm={3}>
              <strong>Modelo do Gerador: </strong>
              <br></br>
              {marcapasso.CODMODGER}
            </Col>
            <Col sm={3}>
              <strong>Nr Série: </strong>
              <br></br>
              {marcapasso.SERIEGER}
            </Col>
            <Col sm={3}>
              <strong>Dt Implante: </strong>
              <br></br>
              {marcapasso.dataGer}
            </Col>
          </Row>
          <hr></hr>
          <Row>
            <Col sm={3}>
              <strong>Eletrodo Atrial: </strong>
              <br></br>
              {marcapasso.CODFABELAT}
            </Col>
            <Col sm={3}>
              <strong>Modelo do Eletrodo: </strong>
              <br></br>
              {marcapasso.CODMODELAT}
            </Col>
            <Col sm={3}>
              <strong>Nr Série: </strong>
              <br></br>
              {marcapasso.SERIEELAT}
            </Col>
            <Col sm={3}>
              <strong>Dt Implante: </strong>
              <br></br>
              {marcapasso.dataElAt}
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <strong>Eletrodo VD: </strong>
              <br></br>
              {marcapasso.CODFABELVE}
            </Col>
            <Col sm={3}>
              <strong>Modelo do VD: </strong>
              <br></br>
              {marcapasso.CODMODELVE}
            </Col>
            <Col sm={3}>
              <strong>Nr Série: </strong>
              <br></br>
              {marcapasso.SERIEELVE}
            </Col>
            <Col sm={3}>
              <strong>Dt Implante: </strong>
              <br></br>
              {marcapasso.dataElVEDir}
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <strong>Eletrodo VE: </strong>
              <br></br>
              {marcapasso.CODFABELVEESQ}
            </Col>
            <Col sm={3}>
              <strong>Modelo do VE: </strong>
              <br></br>
              {marcapasso.CODMODELVEESQ}
            </Col>
            <Col sm={3}>
              <strong>Nr Série: </strong>
              <br></br>
              {marcapasso.SERIEELVEESQ}
            </Col>
            <Col sm={3}>
              <strong>Dt Implante: </strong>
              <br></br>
              {marcapasso.dataElVEEsq}
            </Col>
          </Row>
          <hr></hr>
          <div
            style={{
              textAlign: 'right',
              fontSize: '12px',
            }}
          >
            <Row>
              <Col sm={3}>
                <Form.Label></Form.Label>
              </Col>
              <Col sm={1}>
                <Form.Label>Eletrodo Atrial UNI</Form.Label>
              </Col>
              <Col sm={1}>
                <Form.Label>Eletrodo Atrial BI</Form.Label>
              </Col>
              <Col sm={1}>
                <Form.Label>Eletrodo VD UNI</Form.Label>
              </Col>
              <Col sm={1}>
                <Form.Label>Eletrodo VD BI</Form.Label>
              </Col>
              <Col sm={1}>
                <Form.Label>Eletrodo VE UNI</Form.Label>
              </Col>
              <Col sm={1}>
                <Form.Label>Eletrodo VE BI</Form.Label>
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <Form.Label>Impedância (Ohms)</Form.Label>
              </Col>
              <Col sm={1}>
                <Form.Control
                  style={{ fontSize: '10px' }}
                  defaultValue={marcapasso.RESITAT}
                />
              </Col>
              <Col sm={1}>
                <Form.Control
                  style={{ fontSize: '10px' }}
                  defaultValue={marcapasso.RESISTATBI}
                />
              </Col>
              <Col sm={1}>
                <Form.Control
                  style={{ fontSize: '10px' }}
                  defaultValue={marcapasso.RESITVE}
                />
              </Col>
              <Col sm={1}>
                <Form.Control
                  style={{ fontSize: '10px' }}
                  defaultValue={marcapasso.RESISTVDBI}
                />
              </Col>
              <Col sm={1}>
                <Form.Control
                  style={{ fontSize: '10px' }}
                  defaultValue={marcapasso.RESISTVESQUNI}
                />
              </Col>
              <Col sm={1}>
                <Form.Control
                  style={{ fontSize: '10px' }}
                  defaultValue={marcapasso.RESISTVESQBI}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <Form.Label>Sensibilidade (mV)</Form.Label>
              </Col>
              <Col sm={1}>
                <Form.Control
                  style={{ fontSize: '10px' }}
                  defaultValue={marcapasso.ONDAP}
                />
              </Col>
              <Col sm={1}>
                <Form.Control
                  style={{ fontSize: '10px' }}
                  defaultValue={marcapasso.ONDAPBI}
                />
              </Col>
              <Col sm={1}>
                <Form.Control
                  style={{ fontSize: '10px' }}
                  defaultValue={marcapasso.ONDAR}
                />
              </Col>
              <Col sm={1}>
                <Form.Control
                  style={{ fontSize: '10px' }}
                  defaultValue={marcapasso.ONDARVDBI}
                />
              </Col>
              <Col sm={1}>
                <Form.Control
                  style={{ fontSize: '10px' }}
                  defaultValue={marcapasso.ONDARVESQUNI}
                />
              </Col>
              <Col sm={1}>
                <Form.Control
                  style={{ fontSize: '10px' }}
                  defaultValue={marcapasso.ONDARVESQBI}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={3}>
                <Form.Label>Limiar de Comando</Form.Label>
              </Col>
              <Col sm={1}>
                <Form.Control
                  style={{ fontSize: '10px' }}
                  defaultValue={marcapasso.LIMIARAT}
                />
              </Col>
              <Col sm={1}>
                <Form.Control
                  style={{ fontSize: '10px' }}
                  defaultValue={marcapasso.LIMIARATBI}
                />
              </Col>
              <Col sm={1}>
                <Form.Control
                  style={{ fontSize: '10px' }}
                  defaultValue={marcapasso.LIMIARVE}
                />
              </Col>
              <Col sm={1}>
                <Form.Control
                  style={{ fontSize: '10px' }}
                  defaultValue={marcapasso.LIMIARVDBI}
                />
              </Col>
              <Col sm={1}>
                <Form.Control
                  style={{ fontSize: '10px' }}
                  defaultValue={marcapasso.LIMIARVESQUNI}
                />
              </Col>
              <Col sm={1}>
                <Form.Control
                  style={{ fontSize: '10px' }}
                  defaultValue={marcapasso.LIMIARVESQBI}
                />
              </Col>
            </Row>
          </div>
          <hr></hr>
          <strong>Descrição da Avaliação: </strong>
          <br></br>
          <div className="row">
            <div style={{ fontSize: 17 }} id="cartaObs" className="col-12">
              {handleDescricao()}
            </div>
          </div>
          <hr></hr>
          <div className="mt-0 text-center">
            <Card.Body>
              <div className={styles.signatureReport}>
                <br></br>
                <i className="mr-auto">
                  Conferido e assinado eletronicamente por:{' '}
                </i>
                <br></br>
                <strong className="mr-auto">
                  {' '}
                  {prefixo()} {avaliacao.Avaliou}
                </strong>
                <br></br>
                <i className="mr-auto">
                  Assinatura Digital:{' '}
                  {md5(JSON.stringify(avaliacao.CARTA || ''))}
                </i>
              </div>
            </Card.Body>
            <div id="removeMe" className="text-muted">
              <Button variant="primary" onClick={() => removeDiv('removeMe')}>
                Imprimir
              </Button>{' '}
            </div>
          </div>
        </Form>
      </div>
    </React.Fragment>
  );
}
