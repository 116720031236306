import produce from 'immer';

const INITIAL_STATE = { token: null, logged: false, loading: false };

export default function doctor(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@doctor/SIGN_IN_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@doctor/SIGN_IN_SUCCESS': {
        draft.token = action.payload.token;
        draft.doctor = action.payload.doctor;
        draft.profile = action.payload.doctor;
        draft.logged = true;
        draft.loading = false;
        break;
      }
      case '@doctor/SIGN_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@doctor/SIGN_OUT': {
        draft.token = null;
        draft.logged = false;
        draft.profile = null;
        draft.doctor = null;
        break;
      }
      default:
    }
  });
}
