import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../../services/api';

// createSlice makes all action creators and reducers in the same file so no separation of logic is necessary

/** *************STATE SLICE************** */
export const sliceFaturamento = createSlice({
  name: 'faturamento',
  initialState: {
    detalhes: {},
    fechamento: [],
    agendadiaria: [],
  },
  reducers: {
    faturamentoSuccess: (state, action) => {
      const { detalhes, fechamento, agendadiaria } = action.payload;
      if (detalhes !== undefined) {
        state.detalhes = detalhes;
      }
      if (fechamento !== undefined) {
        state.fechamento = fechamento;
      }
      if (agendadiaria !== undefined) {
        state.agendadiaria = agendadiaria;
      }
    },
    faturamentoRequest: state => {
      state.detalhes = {};
    },
    faturamentoReset: state => {
      state.detalhes = {};
    },
    faturamentoFailure: state => {
      state.detalhes = {};
    },
  },
});

/** *************EXPORTED ACTIONS & REDUCERS************** */

export const {
  faturamentoRequest,
  faturamentoSuccess,
  faturamentoFailure,
  faturamentoReset,
} = sliceFaturamento.actions;

export default sliceFaturamento.reducer;

// API REQUEST ACTIONS HANDLED WITH REDUX-THUNK MIDDLEWARE BUILT INTO REDUX TOOLKIT -->

/** *************THUNKS************** */

export const selectAllDetalhes = () => {
  return async dispatch => {
    try {
      dispatch(faturamentoRequest());
      const result = await api.get(`procedimento/convenio`);
      const detalhes = result.data;
      dispatch(faturamentoSuccess({ detalhes }));
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(faturamentoFailure());
    }
  };
};

export const getFechamentoDiario = payload => {
  return async dispatch => {
    try {
      dispatch(faturamentoRequest());
      const response = await api.get(`fechamento/diario?datafatura=${payload}`);
      const fechamento = response.data;
      dispatch(faturamentoSuccess({ fechamento }));
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(faturamentoFailure());
    }
  };
};

export const getAgendaDiaria = payload => {
  return async dispatch => {
    try {
      dispatch(faturamentoRequest());
      const response = await api.get(`agenda/diaria?dataagenda=${payload}`);
      const agendadiaria = response.data;
      dispatch(faturamentoSuccess({ agendadiaria }));
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(faturamentoFailure());
    }
  };
};
