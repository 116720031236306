import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import { Form, Card, Row, Col, Button } from 'react-bootstrap';
import InputMask from 'react-bootstrap-maskedinput';
import Creatable from 'react-select/creatable';
import validarCPF from 'validar-cpf';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import Loading from '../../../pages/Index/Loading';
import profissoes from '../../../util/profissoes';

export default function FormPaciente({ patient, isCreation }) {
  const { funcionario } = useSelector(state => state.funcionario);
  const medico = useSelector(state => state.doctor.profile);
  const { loading } = useSelector(state => state.contexto);
  const memoProfissoes = useMemo(
    () =>
      profissoes
        .map(i => ({ value: i.value, label: `${i.label} - ${i.value}` }))
        .sort((a, b) => a.value.localeCompare(b.value)),
    []
  );
  const initialState = useMemo(
    () => ({
      CODPAC: 0,
      NOMEPAC: '',
      DTNPAC: '',
      CELULARPAC: '',
      ENDERECO_NR: '',
      BAIRROPAC: '',
      ENDRESPAC: '',
      ESTADOPAC: '',
      LOGRA_PAC: '',
      CIDADEPAC: '',
      FONERESPAC: '',
      FONECOMPAC: '',
      INFORMACAO: '',
      EMPRESA: '',
      PROFISSPAC: '',
      CODFUNC: '',
      RGPAC: '',
      DATAPAC: '',
      SEXOPAC: '',
      LOCNASCPAC: '', // carterinha
      SETORPAC: 1,
      CEPPAC: '',
      ENDCOMPAC: '',
      ENDMARPAC: '', // cpf
      FONERECPAC: '',
      EMAILPAC: '',
      ESTCIVIL: '',
      CORPAC: '',
      NACIONPAC: '',
      FOTOPAC: '',
      OBSPAC: '',
      CODINTERNADO: '',
      DATACADPAC: '',
      VALIDADE: '',
      ENDERECO_COMPL: '',
      CODCIDADE_PAC: '',
      SENHA: '',
      FOTOPACIENTE: '',
      EMAIL_PAC: '',
      VALIDADOR: '',
      dataFormatada: '',
    }),
    []
  );
  const numInput = useRef(null);
  const cpfInput = useRef();
  const [paciente, setPaciente] = useState(initialState);
  const [selectedOption, setSelectedOption] = useState('');

  useEffect(() => {
    patient?.CODPAC > 0 ? setPaciente(patient) : setPaciente(initialState);
    // eslint-disable-next-line
  }, []);

  async function handleInsertPaciente(e) {
    e.preventDefault();
    if (!e.currentTarget.checkValidity()) {
      e.stopPropagation();
    } else {
      try {
        await api.post(`/pacientes`, paciente);
        toast.success('Paciente cadastrado com sucesso!');
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  }

  async function handleUpdatePaciente(e) {
    e.preventDefault();
    if (!e.currentTarget.checkValidity()) {
      e.stopPropagation();
    } else {
      try {
        await api.put(`/paciente`, paciente);
        toast.success('Paciente atualizado com sucesso!');
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  }

  const handleChange = e => {
    e.preventDefault();
    setPaciente({
      ...paciente,
      CODFUNC: medico ? medico.idFunc : funcionario.codFunc,
      [e.target.name]: e.target.value,
    });
  };

  const handleCep = () => {
    fetch(`https://viacep.com.br/ws/${paciente?.CEPPAC}/json/`)
      .then(response => response.json())
      .then(data => {
        setPaciente({
          ...paciente,
          ENDRESPAC: data.logradouro,
          BAIRROPAC: data.bairro,
          CIDADEPAC: data.localidade,
          ESTADOPAC: data.uf,
        });
        numInput.current.focus();
      })
      .catch(() => toast.error('CEP não encontrado!'));
  };

  const handleValidarCPF = e => {
    e.preventDefault();
    if (!paciente?.ENDMARPAC === '111.111.111-11')
      if (!validarCPF(paciente?.ENDMARPAC)) {
        toast.info('Número do CPF é inválido!');
        setPaciente({ ...paciente, [e.target.name]: '' });
      }
  };

  const handleOnEnterPress = (e, next) => {
    e.preventDefault();
    if (e.keyCode === 13) document.getElementById(next).select();
  };

  return (
    <Card>
      <Card.Body>
        <Form>
          <Row>
            <Col sm={2}>
              <Form.Group controlId="formCodPac">
                <Form.Label>Código</Form.Label>
                <Form.Control
                  type="text"
                  value={paciente?.CODPAC || ''}
                  name="CODPAC"
                  onChange={e => handleChange(e)}
                  readOnly
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="formNome">
                <Form.Label>Nome (*)</Form.Label>
                <Form.Control
                  style={{
                    textTransform: 'uppercase',
                    borderColor: 'green',
                    backgroundColor: 'rgba(255, 255, 0, 0.3)',
                  }}
                  type="text"
                  value={paciente?.NOMEPAC || ''}
                  name="NOMEPAC"
                  autoFocus
                  onChange={e => handleChange(e)}
                  onKeyUp={e => handleOnEnterPress(e, 'formDtNasc')}
                />
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group controlId="formSexo">
                <Form.Label>Sexo (*)</Form.Label>
                <Col>
                  <Form.Check
                    className="my-0 mr-1"
                    style={{
                      borderColor: 'green',
                      backgroundColor: 'rgba(255, 255, 0, 0.3)',
                    }}
                    inline
                    label="Masculino"
                    type="radio"
                    id="radioMasculino"
                    checked={paciente?.SEXOPAC === 'M'}
                    name="SEXOPAC"
                    onChange={() => setPaciente({ ...paciente, SEXOPAC: 'M' })}
                  />
                  <Form.Check
                    inline
                    style={{
                      borderColor: 'green',
                      backgroundColor: 'rgba(255, 255, 0, 0.3)',
                    }}
                    label="Feminino"
                    type="radio"
                    id="radioFeminino"
                    name="SEXOPAC"
                    checked={paciente?.SEXOPAC === 'F'}
                    onChange={() => setPaciente({ ...paciente, SEXOPAC: 'F' })}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col sm={2}>
              <Form.Group controlId="formStatus">
                <Form.Label>Status (*)</Form.Label>
                <Col>
                  <Form.Check
                    className="my-0 mr-1"
                    style={{
                      borderColor: 'green',
                      backgroundColor: 'rgba(255, 255, 0, 0.3)',
                    }}
                    inline
                    label="Ativo"
                    type="radio"
                    id="radioAtivo"
                    checked={paciente?.SETORPAC === 1}
                    name="SETORPAC"
                    onChange={() => setPaciente({ ...paciente, SETORPAC: 1 })}
                  />
                  <Form.Check
                    inline
                    style={{
                      borderColor: 'green',
                      backgroundColor: 'rgba(255, 255, 0, 0.3)',
                    }}
                    label="Inativo"
                    type="radio"
                    id="radioInativo"
                    name="SETORPAC"
                    checked={paciente?.SETORPAC === 0}
                    onChange={() => setPaciente({ ...paciente, SETORPAC: 0 })}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Form.Group controlId="formDtNasc">
                <Form.Label>Data de Nascimento (*)</Form.Label>
                <InputMask
                  type="text"
                  style={{
                    textTransform: 'uppercase',
                    borderColor: 'green',
                    backgroundColor: 'rgba(255, 255, 0, 0.3)',
                  }}
                  mask="11/11/1111"
                  onChange={e => handleChange(e)}
                  onKeyUp={e => handleOnEnterPress(e, 'formCPF')}
                  name="DTNPAC"
                  value={paciente?.dataFormatada || ''}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group controlId="formCPF">
                <Form.Label>CPF</Form.Label>
                <InputMask
                  type="text"
                  ref={cpfInput}
                  style={{
                    textTransform: 'uppercase',
                    borderColor: 'green',
                    backgroundColor: 'rgba(255, 255, 0, 0.3)',
                  }}
                  mask="111.111.111-11"
                  onChange={e => handleChange(e)}
                  onBlur={e => handleValidarCPF(e)}
                  onKeyUp={e => handleOnEnterPress(e, 'formNrCart')}
                  name="ENDMARPAC"
                  value={paciente?.ENDMARPAC || ''}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group controlId="formNrCart">
                <Form.Label>Nr Carteirinha</Form.Label>
                <Form.Control
                  type="text"
                  value={paciente?.LOCNASCPAC || ''}
                  name="LOCNASCPAC"
                  onKeyUp={e => handleOnEnterPress(e, 'formProfiss')}
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group controlId="formProfiss">
                <Form.Label>Profissão</Form.Label>
                <Creatable
                  name="PROFISSPAC"
                  id="formProfiss"
                  menuPlacement="top"
                  placeholder={paciente?.PROFISSPAC || 'Selecione Profissão'}
                  options={memoProfissoes}
                  value={profissoes.filter(opt => opt.label === selectedOption)}
                  onKeyUp={e => handleOnEnterPress(e, 'formCep')}
                  onChange={option => {
                    setSelectedOption(option.label);
                    setPaciente({ ...paciente, PROFISSPAC: option.value });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col sm={2}>
              <Form.Group controlId="formCep">
                <Form.Label>CEP (*)</Form.Label>
                <InputMask
                  type="text"
                  style={{
                    textTransform: 'uppercase',
                    borderColor: 'green',
                    backgroundColor: 'rgba(255, 255, 0, 0.3)',
                  }}
                  mask="11111-111"
                  onChange={e => handleChange(e)}
                  name="CEPPAC"
                  value={paciente?.CEPPAC || ''}
                  onBlur={() => handleCep()}
                  onKeyUp={e => handleOnEnterPress(e, 'formNumero')}
                  maxLength="8"
                />
              </Form.Group>
            </Col>
            <Col sm={6}>
              <Form.Group controlId="formEndereco">
                <Form.Label>Endereço</Form.Label>
                <Form.Control
                  type="text"
                  value={paciente?.ENDRESPAC || ''}
                  name="ENDRESPAC"
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group controlId="formNumero">
                <Form.Label>Número</Form.Label>
                <Form.Control
                  onChange={e => handleChange(e)}
                  name="ENDERECO_NR"
                  value={paciente?.ENDERECO_NR || ''}
                  onKeyUp={e => handleOnEnterPress(e, 'foneCelular')}
                  ref={numInput}
                />
              </Form.Group>
            </Col>

            <Col sm={2}>
              <Form.Group>
                <Form.Label>Complemento</Form.Label>
                <Form.Control
                  onChange={e => handleChange(e)}
                  name="ENDERECO_COMPL"
                  value={paciente?.ENDERECO_COMPL || ''}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={4}>
              <Form.Group controlId="formCidade">
                <Form.Label>Cidade</Form.Label>
                <Form.Control
                  type="text"
                  value={paciente?.CIDADEPAC || ''}
                  name="CIDADEPAC"
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="formBairro">
                <Form.Label>Bairro</Form.Label>
                <Form.Control
                  type="email"
                  value={paciente?.BAIRROPAC || ''}
                  name="BAIRROPAC"
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={4}>
              <Form.Group controlId="formEstado">
                <Form.Label>Estado</Form.Label>
                <Form.Control
                  type="text"
                  value={paciente?.ESTADOPAC || ''}
                  name="ESTADOPAC"
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={3}>
              <Form.Group controlId="foneCelular">
                <Form.Label>Fone Celular (*)</Form.Label>
                <InputMask
                  type="text"
                  style={{
                    textTransform: 'uppercase',
                    borderColor: 'green',
                    backgroundColor: 'rgba(255, 255, 0, 0.3)',
                  }}
                  mask="(111)11111-1111"
                  onChange={e => handleChange(e)}
                  onKeyUp={e => handleOnEnterPress(e, 'formFoneRes')}
                  name="CELULARPAC"
                  value={paciente?.CELULARPAC || ''}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group controlId="formFoneRes">
                <Form.Label>Fone Residencial</Form.Label>
                <Form.Control
                  type="text"
                  value={paciente?.FONERESPAC || ''}
                  name="FONERESPAC"
                  onKeyUp={e => handleOnEnterPress(e, 'formFoneComer')}
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group controlId="formFoneComer">
                <Form.Label>Fone Comercial</Form.Label>
                <Form.Control
                  type="text"
                  value={paciente?.FONECOMPAC || ''}
                  name="FONECOMPAC"
                  onKeyUp={e => handleOnEnterPress(e, 'formEmpresa')}
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>
            <Col sm={3}>
              <Form.Group controlId="formEmpresa">
                <Form.Label>Empresa</Form.Label>
                <Form.Control
                  type="text"
                  value={paciente?.EMPRESA || ''}
                  name="EMPRESA"
                  onKeyUp={e => {
                    if (e.key === 'Enter')
                      document.getElementById('formObs').focus();
                  }}
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <Form.Group controlId="formObs">
                <Form.Label>Observações</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={paciente?.INFORMACAO || ''}
                  name="INFORMACAO"
                  onChange={e => handleChange(e)}
                />
              </Form.Group>
            </Col>
          </Row>
        </Form>
      </Card.Body>
      <Card.Footer className="text-center">
        <Button className="mr-1" onClick={e => handleUpdatePaciente(e)}>
          Atualizar
        </Button>
        {isCreation && (
          <Button variant="success" onClick={e => handleInsertPaciente(e)}>
            Inserir
          </Button>
        )}
      </Card.Footer>

      {loading && <Loading />}
    </Card>
  );
}
