import React from 'react';

const OrientacaoDOC = React.forwardRef((props, ref) => {
  const dados = [
    { APIXABANA: '', RIVAROXABANA: '' },
    { APIXABANA: 'EVITE UTILIZAR SE POSSÍVEL', RIVAROXABANA: '' },
    {
      APIXABANA: 'Carbamazepina (Tegretol)',
      RIVAROXABANA: 'Carbamazepina (Tegretol)',
    },
    {
      APIXABANA: 'Fenitoina, Fenobarbital',
      RIVAROXABANA: 'Fenitoína, Fenobarbital',
    },
    { APIXABANA: '(Hidantal, Gardenal)', RIVAROXABANA: '(Hidantal, Gardenal)' },
    {
      APIXABANA: 'Rifampinina, Ritonavir',
      RIVAROXABANA: 'Rifampicina, Itraconazol',
    },
    {
      APIXABANA: 'Itraconazol, Cetoconazol',
      RIVAROXABANA: 'Cetoconazol, Posaconazol',
    },
    { APIXABANA: '', RIVAROXABANA: 'Variconazol' },
    { APIXABANA: '', RIVAROXABANA: '' },
    {
      APIXABANA: '',
      RIVAROXABANA: 'REDUZIR A DOSE SE O CLEARANCE ESTIVER ENTRE 15 E 49%',
    },
    {
      APIXABANA: 'EVITE UTILIZAR OU REDUZA A DOSE DO ANTICOAGULANTE',
      RIVAROXABANA: '',
    },
    { APIXABANA: 'Variconazol e Posaconazol', RIVAROXABANA: '' },
    {
      APIXABANA: 'Drogas utilizadas no tratamento de HIV e hepatite',
      RIVAROXABANA: 'Drogas utilizadas no tratamento de HIV e hepatite',
    },
    {
      APIXABANA:
        'DIMINUIR O CONSUMO DE SUCOS grapefruit (Toranja), ERVA-DE-SÃO-  JOÃO (Hypericum perforatum)',
      RIVAROXABANA: '',
    },
  ];

  return (
    <div ref={ref}>
      <table
        border="1"
        cellPadding="8"
        style={{
          width: '90%',
          textAlign: 'left',
          marginTop: '20px',
          marginLeft: 'auto',
          marginRight: 'auto',
          borderCollapse: 'collapse',
        }}
      >
        <thead>
          <tr>
            <th>APIXABANA</th>
            <th>RIVAROXABANA</th>
          </tr>
        </thead>
        <tbody>
          {dados.map((linha, index) => (
            <>
              {linha.APIXABANA.includes('EVITE') ||
              linha.APIXABANA.includes('DIMINUIR') ? (
                <tr key={index}>
                  <td
                    colSpan="2"
                    style={{ textAlign: 'center', fontWeight: 'bold' }}
                  >
                    {linha.APIXABANA || linha.RIVAROXABANA}
                  </td>
                </tr>
              ) : (
                <tr key={index}>
                  <td>{linha.APIXABANA}</td>
                  <td>{linha.RIVAROXABANA}</td>
                </tr>
              )}
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
});

export default OrientacaoDOC;
