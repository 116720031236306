import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import md5 from 'md5';
import { Form, Button, Card, Modal } from 'react-bootstrap';
import Loading from '../../../pages/Index/Loading';
import api from '../../../services/api';
import s3 from '../../../util/s3';
import header from '../../../assets/icm-header.jpg';
import blank from '../../../assets/icm-blank.jpg';

import history from '../../../services/history';

export default function ReportMapa() {
  const { mapa } = useSelector(state => state.mapa);
  const { loading, printing } = useSelector(state => state.contexto);
  const [urls, setURLs] = useState([]);
  const [files, setFiles] = useState([]);
  const [showUpload, setShowUpload] = useState(false);
  const [loadingUploadFile, setLoadingUploadFile] = useState(false);
  const [fileToUpload, setFileToUpload] = useState();

  useEffect(() => {
    s3.listObjectsV2(
      { Bucket: 'prevencor', Prefix: `mapa/${mapa[0]?.CODMAPA}` },
      (err, data) => {
        if (err) toast.error('Erro ao buscar arquivos. Entre em contato.');
        else setFiles(data.Contents.filter(item => item.Key.includes('.pdf')));
      }
    );
  }, [mapa]);

  useEffect(() => {
    async function getFiles(f) {
      f = f.Key.split('/');

      return await api.get(
        `https://j87jesh814.execute-api.us-east-1.amazonaws.com/dev/image-upload/folder/mapa/laudo/${mapa[0]?.CODMAPA}/key/${f[2]}`
      );
    }

    async function showFiles() {
      const array = [];

      for (let file of files) {
        const url = await getFiles(file);
        array.push(url);
      }

      setURLs(array);
    }

    showFiles();
  }, [mapa, files]);

  function removeDiv(divName) {
    let newdiv = document.createElement('div');
    let originalContents = document.body.innerHTML;
    newdiv.innerHTML = originalContents;
    document.getElementById(divName).remove(newdiv);
    window.print();
    document.body.innerHTML = originalContents;
  }

  async function handleUploadFile(file) {
    if (!file) return toast.warn('Selecione um arquivo!');

    const encodedFilename = encodeURIComponent(file.name);

    try {
      const { data } = await api.put(
        `https://j87jesh814.execute-api.us-east-1.amazonaws.com/dev/image-upload/folder/mapa/laudo/${mapa[0]?.CODMAPA}/key/${encodedFilename}`
      );

      await fetch(data.preSignedUrl, { method: 'PUT', body: file });
      setLoadingUploadFile(true);
    } catch (error) {
      toast.error('Erro ao fazer upload do arquivo!');
    }
  }

  function closeFileModal() {
    setShowUpload(false);
    setLoadingUploadFile(false);
    setFileToUpload();
  }

  function prefixo() {
    if (
      mapa[0]?.CODREALIZOU === 1 ||
      mapa[0]?.CODREALIZOU === 5 ||
      mapa[0]?.CODREALIZOU === 2796 ||
      mapa[0]?.CODREALIZOU === 958
    ) {
      return 'Prof. Dr. ';
    } else {
      return 'Dr. ';
    }
  }

  return (
    <>
      <Card>
        {printing ? (
          <div className="mt-3" style={{ textAlign: 'center' }}>
            <img width="550" height="100" src={header} alt="header" />
          </div>
        ) : (
          <div className="mt-3" style={{ textAlign: 'center' }}>
            <img width="550" height="100" src={blank} alt="header" />
          </div>
        )}

        <Card.Header className="text-center">
          <Card.Title>LAUDO DE MAPA</Card.Title>
        </Card.Header>
        <Card.Body>
          <Form noValidate>
            <strong className="mr-auto">Nome do Paciente: </strong>
            {`${mapa[0]?.CODPAC} - ${mapa[0]?.NOMEPAC}`}
            <br></br>
            <strong className="mr-auto">Data de Nasc: </strong>
            {`${mapa[0]?.dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Sexo: </strong>
            {`${mapa[0]?.sexopac}`}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Idade: </strong>
            {`${mapa[0]?.idadePAC} anos`}
            <br></br>
            <strong className="mr-auto">Convênio: </strong>
            {mapa[0]?.descriconv}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Cod MAPA: </strong>
            {mapa[0]?.CODMAPA}&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Data do Exame: </strong>
            {mapa[0]?.dataMapa}
            <br></br>
            <strong className="mr-auto">
              Médico que Realizou: {prefixo()}{' '}
            </strong>
            {`${mapa[0]?.Realizou}`}
            <br></br>
            <strong className="mr-auto">
              Médico que Solicitou: {prefixo()}{' '}
            </strong>
            {`${mapa[0]?.NOMEMED}`}
            <hr></hr>
            {urls?.map(url => (
              <div style={{ textAlign: 'center', display: 'block' }}>
                <embed
                  src={url.data.preSignedUrl}
                  width="800px"
                  height="900px"
                />
              </div>
            ))}
            <Card className="mt-3 text-center">
              <Card.Body>
                <Card.Text>
                  <i className="mr-auto">
                    Conferido e assinado eletronicamente por:
                  </i>
                  <strong className="mr-auto"> Dr. {mapa[0]?.Realizou}</strong>
                  <br></br>
                  <i className="mr-auto">
                    Assinatura Digital: {md5(JSON.stringify(mapa[0] || ''))}
                  </i>
                </Card.Text>
              </Card.Body>
              <Card.Footer id="removeMe" className="text-muted">
                <Button onClick={() => removeDiv('removeMe')}>Imprimir</Button>{' '}
                <Button variant="warning" onClick={() => setShowUpload(true)}>
                  Anexar
                </Button>{' '}
                <Button
                  variant="danger"
                  onClick={() => history.push('/agenda')}
                >
                  Fechar
                </Button>
                <br />
              </Card.Footer>
            </Card>
          </Form>
        </Card.Body>
      </Card>

      {loading && <Loading />}

      {showUpload && (
        <Modal show={showUpload} onHide={closeFileModal}>
          <Modal.Header closeButton>
            <Modal.Title>Anexar arquivo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label for="laudos">Selecione pelo menos 1 arquivo</label>
            <br />
            <input
              type="file"
              style={{ color: 'white' }}
              onChange={e => setFileToUpload(e.target.files[0])}
            />
            <ul>{fileToUpload && <li>{fileToUpload.name}</li>}</ul>
            <div>
              <br />
              {loadingUploadFile && (
                <text style={{ color: 'green', marginRight: '100px' }}>
                  Upload finalizado com sucesso!
                </text>
              )}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => handleUploadFile(fileToUpload)}>
              Confirmar
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}
