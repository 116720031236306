/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { addDays, parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from '../../../services/api';

/** *************STATE SLICE************** */
export const sliceTrans = createSlice({
  name: 'trans',
  initialState: {
    loading: false,
    trans: {},
  },
  reducers: {
    transSuccess: (state, action) => {
      const { trans } = action.payload;
      state.loading = false;
      state.trans = trans;
    },
    transRequest: (state, action) => {
      const { trans } = action.payload;
      state.loading = true;
      state.trans = trans;
    },
    transReset: state => {
      state.loading = false;
      state.trans = {};
    },
    transFailure: state => {
      state.loading = false;
      state.trans = {};
    },
  },
});

/** *************EXPORTED ACTIONS & REDUCERS************** */

export const {
  transRequest,
  transSuccess,
  transFailure,
  transReset,
} = sliceTrans.actions;

export default sliceTrans.reducer;

// API REQUEST ACTIONS HANDLED WITH REDUX-THUNK MIDDLEWARE BUILT INTO REDUX TOOLKIT -->

/** *************THUNKS************** */

export const selectTransById = payload => {
  return async dispatch => {
    try {
      const response = await api.get(`trans/${payload}`);
      let c = 0;
      const trans = response.data.map(transeso => ({
        ...transeso,
        dataTrans: format(
          addDays(parseISO(transeso.DATATRANS), 1),
          'dd/MM/yyyy',
          { locale: pt }
        ),
        dataNasc: format(addDays(parseISO(transeso.DTNPAC), 1), 'dd/MM/yyyy', {
          locale: pt,
        }),
        idadePAC: Math.floor(
          (parseISO(transeso.DATATRANS) - parseISO(transeso.DTNPAC)) /
            (1000 * 3600 * 24) /
            365.25
        ),
        counter: (c += 1),
      }));
      await dispatch(transSuccess({ trans }));
      return response.data;
    } catch (error) {
      toast.error(`ERRO ao buscar Trans.`);
      dispatch(transFailure());
    }
  };
};

export const updateTransById = payload => {
  return async dispatch => {
    try {
      let trans = '';
      const result = await api.put(`/trans/${payload.CODTRANS}`, payload);
      if (result.statusText === 'OK') trans = result.data;
      return trans;
    } catch (error) {
      toast.error(`ERRO na atualização de um TRANS.`);
      dispatch(transFailure());
    }
  };
};
