import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Card, Button } from 'react-bootstrap';
import { signInRequest } from '../../../store/modules/auth/funcionario/actions';
import logo from '../../../assets/logo.png';

export default function SignInFunc() {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    const cpf = event.currentTarget.elements.formBasicCPF.value;
    const senha = event.currentTarget.elements.formBasicSenha.value;
    dispatch(signInRequest(cpf, senha));
    setValidated(true);
  }

  return (
    <div className="row justify-content-md-center p-5">
      <div className="col-lg-auto">
        <Card className="m-2">
          <Card.Body>
            <div className="text-center">
              <img src={logo} alt="logoImagem" width={70} height={70} />
            </div>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="p-3">
                <Form.Group controlId="formBasicCPF">
                  <Form.Label>CPF do Funcionário</Form.Label>
                  <Form.Control required type="text" placeholder="Seu CPF" />
                  <Form.Control.Feedback type="invalid">
                    Digite seu CPF.
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group controlId="formBasicSenha">
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    placeholder="Sua senha secreta"
                  />
                  <Form.Control.Feedback type="invalid">
                    Digite sua senha.
                  </Form.Control.Feedback>
                </Form.Group>
                <Button block type="submit">
                  Entrar
                </Button>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
