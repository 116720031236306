/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-named-as-default */
import { all } from 'redux-saga/effects';
import patient from './auth/patient/sagas';
import funcionario from './auth/funcionario/sagas';
import doctor from './auth/doctor/sagas';
import prontuario from '../../redux/features/prontuario/prontuarioSlice';

export default function* rootSaga() {
  return yield all([patient, funcionario, doctor, prontuario]);
}
