import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Nav, Navbar, NavDropdown } from 'react-bootstrap';
import { signOutPac } from '../../../store/modules/auth/patient/actions';
import { pacienteReset } from '../../../redux/features/prontuario/pacienteSlice';
import { medicoReset } from '../../../redux/features/prontuario/medicoSlice';
import { prontuarioReset } from '../../../redux/features/prontuario/prontuarioSlice';
import { laboratorioReset } from '../../../redux/features/prontuario/laboratorioSlice';
import { ergoReset } from '../../../redux/features/laudo/ergoSlice';
import { ecoReset } from '../../../redux/features/laudo/ecoSlice';
import { ecgReset } from '../../../redux/features/laudo/ecgSlice';
import { stressReset } from '../../../redux/features/laudo/stressSlice';
import { transReset } from '../../../redux/features/laudo/transSlice';
import { fetalReset } from '../../../redux/features/laudo/fetalSlice';
import { convenioReset } from '../../../redux/features/context/convenioSlice';
import { procedimentoReset } from '../../../redux/features/context/procedimentoSlice';
import { agendaReset } from '../../../redux/features/prontuario/agendaSlice';
import { faturamentoReset } from '../../../redux/features/context/faturamentoSlice';
import logo from '../../../assets/logo.png';
import styles from '../../../styles/styles.module.css';
import { certificateReset } from '../../../redux/features/certificate/certificateSlice';
import history from '../../../services/history';

export default function NavBar() {
  const profile = useSelector(state => state.patient.profile);
  const dispatch = useDispatch();

  function handleSignOut() {
    dispatch(prontuarioReset());
    dispatch(laboratorioReset());
    dispatch(ergoReset());
    dispatch(ecoReset());
    dispatch(ecgReset());
    dispatch(stressReset());
    dispatch(transReset());
    dispatch(fetalReset());
    dispatch(pacienteReset());
    dispatch(medicoReset());
    dispatch(convenioReset());
    dispatch(procedimentoReset());
    dispatch(agendaReset());
    dispatch(faturamentoReset());
    dispatch(certificateReset());
    dispatch(signOutPac());
  }

  return (
    <Navbar bg="info" expand="lg" className={styles.navbarLink}>
      <Navbar.Brand>
        <img src={logo} alt="logo" width={60} height={60} />
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mr-auto">
          <Nav.Link onClick={() => history.push('/dashboardpac')}>
            Home
          </Nav.Link>
          <NavDropdown title="Agendamentos">
            <NavDropdown.Item onClick={() => history.push('/panelpac')}>
              Novo Agendamento
            </NavDropdown.Item>
            <NavDropdown.Item onClick={() => history.push('/agendapac')}>
              Meus Agendamentos
            </NavDropdown.Item>
          </NavDropdown>
          <Nav.Link onClick={() => history.push('/frmatvpac')}>
            Resultado de Exames
          </Nav.Link>
          <Nav.Link onClick={handleSignOut}>Sair</Nav.Link>
        </Nav>
        <Navbar.Text>
          {profile
            ? `${profile.nome
                .toUpperCase()
                .split(' ')
                .slice(0, 2)
                .join(' ')} - ${profile.codPac}`
            : new Date().toDateString()}
        </Navbar.Text>
      </Navbar.Collapse>
    </Navbar>
  );
}
