import React from 'react';
import { Card } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import md5 from 'md5';
import RichTextEditor from '../../../components/RichTextEditor';

export default function ReportEcg() {
  const { ecg } = useSelector(state => state.ecg);
  const md5Data = `${ecg[0]?.CODPAC} - ${ecg[0]?.CODECG}`;
  const contentFooter = `<u>Autenticação</u> -
  <strong className="mr-auto">
    ${prefixo()} ${ecg[0]?.Realizou}
  </strong>
  <i className="mr-auto">
    - ${md5(JSON.stringify(ecg[0] || ''))}
  </i>`;

  function prefixo() {
    if (
      ecg[0]?.CODREALIZOU === 1 ||
      ecg[0]?.CODREALIZOU === 5 ||
      ecg[0]?.CODREALIZOU === 2796 ||
      ecg[0]?.CODREALIZOU === 958
    ) {
      return 'Prof. Dr. ';
    } else {
      return 'Dr. ';
    }
  }

  return (
    <Card style={{ marginTop: 10 }}>
      <Card.Header className="text-center">
        <Card.Title>LAUDO DE ELETROCARDIOGRAMA</Card.Title>
      </Card.Header>
      <Card.Body>
        <img
          alt="laudo"
          loading="lazy"
          src={ecg[0]?.ARQUIVO_URL}
          width="100%"
          height="80%"
        />
        <RichTextEditor
          content={ecg[0]?.OBSERVACAO}
          contentFooter={contentFooter}
          // contentHeader={contentHeader}
          saveContent={() => {}}
          imageUrl={ecg[0]?.ARQUIVO_URL}
          readOnly={true}
        />
        <Card.Footer className="mt-3 text-center">
          <u>Autenticação</u> -{' '}
          <strong className="mr-auto">
            {prefixo()} {ecg[0]?.Realizou}
          </strong>{' '}
          - <i className="mr-auto">{md5(JSON.stringify(md5Data || ''))}</i>
        </Card.Footer>
      </Card.Body>
    </Card>
  );
}
