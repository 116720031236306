import React, { useEffect, useState } from 'react';
import md5 from 'md5';
import useSWR from 'swr';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Form,
  Col,
  Button,
  Card,
  Modal,
  Collapse,
  Spinner,
  Alert,
} from 'react-bootstrap';
import api from '../../../services/api';
import s3 from '../../../util/s3';
import header from '../../../assets/icm-header.jpg';
import styles from '../../../styles/styles.module.css';
import blank from '../../../assets/icm-blank.jpg';
import { getScan } from '../../../services/carotidas';

export default function ReportCarotida() {
  const location = useLocation();
  const { codLaudo } = location.state;
  const { data: carotida, error, isLoading } = useSWR('carotida', () =>
    getScan(codLaudo)
  );
  const { printing } = useSelector(state => state.contexto);
  const medico = useSelector(state => state.doctor.profile);
  const { funcionario } = useSelector(state => state.funcionario);
  const [showModal, setShowModal] = useState(false);
  const [open, setOpen] = useState({});
  const [openDeleteAlert, setOpenDeleteAlert] = useState(null);
  const [urls, setURLs] = useState([]);
  const [files, setFiles] = useState([]);
  const isAllowedToDeleteFiles =
    medico ||
    funcionario?.codFunc === '90035' ||
    funcionario?.codFunc === '90026' ||
    funcionario?.codFunc === '90001';

  useEffect(() => {
    s3.listObjectsV2(
      {
        Bucket: 'prevencor',
        Prefix: `carotida/${carotida?.carotida[0]?.CODSCAN}`,
      },
      (err, data) => {
        if (err) toast.error('Erro ao buscar arquivos. Entre em contato.');
        else
          setFiles(
            data.Contents.filter(
              item =>
                item.Key.includes('.webm') ||
                item.Key.includes('.pdf') ||
                item.Key.includes('.mp4')
            )
          );
      }
    );
  }, [carotida?.carotida[0]?.CODSCAN]);

  async function getFiles(f) {
    f = f.Key.split('/');

    return await api.get(
      `https://j87jesh814.execute-api.us-east-1.amazonaws.com/dev/image-upload/folder/carotida/laudo/${carotida?.carotida[0]?.CODSCAN}/key/${f[2]}`
    );
  }

  async function showFiles() {
    setShowModal(true);

    const array = [];

    for (let file of files) {
      const url = await getFiles(file);
      const obj = { url, ...file }; // coloco num obj só a presigned URL e o file, pq preciso da Key p/ deletar
      array.push(obj);
    }

    setURLs(array);
  }

  function removeDiv(divName) {
    document.getElementById(divName).style.visibility = 'hidden';
    window.scrollTo(0, 0);
    window.print();
    document.getElementById(divName).style.visibility = 'visible';
  }

  function prefixo() {
    if (
      carotida?.carotida[0]?.CODREALIZOU === 1 ||
      carotida?.carotida[0]?.CODREALIZOU === 5 ||
      carotida?.carotida[0]?.CODREALIZOU === 2796 ||
      carotida?.carotida[0]?.CODREALIZOU === 958
    ) {
      return 'Prof. Dr. ';
    } else {
      return 'Dr. ';
    }
  }

  function handleClick(id) {
    setOpen(prevState => ({ ...prevState, [id]: !prevState[id] }));
  }

  async function deleteS3Object(file) {
    try {
      await s3.deleteObject({ Bucket: 'prevencor', Key: file }).promise();
      toast.success('Arquivo deletado com sucesso!');
      setOpenDeleteAlert(null);
      setShowModal(false);
    } catch (error) {
      toast.error('Erro ao deletar arquivo. Entre em contato.');
    }
  }

  if (error) {
    return (
      <Alert variant="danger" style={{ marginTop: 10 }}>
        <Alert.Heading>Erro ao carregar laudo!</Alert.Heading>
        <p>Tente novamente.</p>
      </Alert>
    );
  }

  if (isLoading) {
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '80vh',
        }}
      >
        <Spinner animation="border" variant="dark" size="lg" />
      </div>
    );
  }

  return (
    <>
      <Card style={{ marginTop: 10 }}>
        {printing ? (
          <div className={styles.imageReport}>
            <img width="600" height="110" src={header} alt="header" />
          </div>
        ) : (
          <div className={styles.imageReport}>
            <img width="600" height="110" src={blank} alt="header" />
          </div>
        )}

        <Card.Header className="text-center">
          <h3 style={{ fontWeight: 'bold' }}>LAUDO DE SCAN DE CARÓTIDAS</h3>
        </Card.Header>

        <Card.Body>
          <Form noValidate className={styles.contentReport}>
            <strong className="mr-auto">Nome do Paciente: </strong>
            {`${carotida?.carotida[0]?.CODPAC} - ${carotida?.carotida[0]?.NOMEPAC}`}
            <br></br>
            <strong className="mr-auto">Data de Nasc: </strong>
            {`${carotida?.carotida[0]?.dataNasc}`}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Sexo: </strong>
            {`${carotida?.carotida[0]?.SEXOPAC}`}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Idade: </strong>
            {`${carotida?.carotida[0]?.idadePAC} anos`}
            <br></br>
            <strong className="mr-auto">Convênio: </strong>
            {carotida?.carotida[0]?.SIGLACONV}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Código: </strong>
            {carotida?.carotida[0]?.CODSCAN}&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Data do Laudo: </strong>
            {carotida?.carotida[0]?.dataScanFmt}
            <br></br>
            <strong className="mr-auto">
              Médico que Realizou: {prefixo()}{' '}
            </strong>
            {`${carotida?.carotida[0]?.Realizou}`}
            <br></br>
            <strong className="mr-auto">
              Médico que Solicitou: {prefixo()}{' '}
            </strong>
            {`${carotida?.carotida[0]?.Solicitante}`}
            <hr></hr>
            <Card.Header className="text-center">
              <Card.Title>
                <strong>
                  <u>SISTEMA CAROTÍDEO DIREITO</u>
                </strong>
              </Card.Title>
            </Card.Header>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm">
                  {carotida?.ccd?.length > 0 && (
                    <>
                      <u className="mr-auto">Carótida Comum Direita: </u>
                      {carotida?.ccd?.map(ccdir =>
                        ccdir.CODANATOMIA === 44 ? (
                          <div className="row">
                            <div className="col-12">
                              {ccdir.ANATOMIA} {ccdir.MEDIDA} mm
                              {ccdir.VELOCIDADE} {ccdir.INTENSIDADE}
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-12">
                              {ccdir.ANATOMIA} {ccdir.VELOCIDADE}{' '}
                              {ccdir.INTENSIDADE}
                            </div>
                          </div>
                        )
                      )}
                    </>
                  )}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm">
                  {carotida?.cid?.length > 0 && (
                    <>
                      <u className="mr-auto">Carótida Interna Direita: </u>
                      {carotida?.cid.map(cidir => (
                        <div className="row">
                          <div className="col-12">
                            {cidir.ANATOMIA} {cidir.VELOCIDADE}{' '}
                            {cidir.INTENSIDADE}
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm">
                  {carotida?.ced?.length > 0 ? (
                    <>
                      <u className="mr-auto">Carótida Externa Direita: </u>
                      {carotida?.ced.map(cedir => (
                        <div className="row">
                          <div className="col-12">
                            {cedir.ANATOMIA} {cedir.VELOCIDADE}{' '}
                            {cedir.INTENSIDADE}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm">
                  {carotida?.avd?.length > 0 ? (
                    <>
                      <u className="mr-auto">Artéria Vertebral Direita: </u>
                      {carotida?.avd.map(avdir => (
                        <div className="row">
                          <div className="col-12">
                            {avdir.ANATOMIA} {avdir.VELOCIDADE}{' '}
                            {avdir.INTENSIDADE}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}
                </Form.Group>
              </Col>
            </Form.Row>
            <Card.Header className="text-center">
              <Card.Title>
                <strong>
                  <u>SISTEMA CAROTÍDEO ESQUERDO</u>
                </strong>
              </Card.Title>
            </Card.Header>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm">
                  {carotida?.cce?.length > 0 ? (
                    <>
                      <u className="mr-auto">Carótida Comum Esquerda: </u>
                      {carotida?.cce.map(ccesq =>
                        ccesq.CODANATOMIA === 44 ? (
                          <div className="row">
                            <div className="col-12">
                              {ccesq.ANATOMIA} {ccesq.MEDIDA} mm
                              {ccesq.VELOCIDADE} {ccesq.INTENSIDADE}
                            </div>
                          </div>
                        ) : (
                          <div className="row">
                            <div className="col-12">
                              {ccesq.ANATOMIA} {ccesq.VELOCIDADE}{' '}
                              {ccesq.INTENSIDADE}
                            </div>
                          </div>
                        )
                      )}
                    </>
                  ) : null}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm">
                  {carotida?.cie?.length > 0 ? (
                    <>
                      <u className="mr-auto">Carótida Interna Esquerda: </u>
                      {carotida?.cie.map(ciesq => (
                        <div className="row">
                          <div className="col-12">
                            {ciesq.ANATOMIA} {ciesq.VELOCIDADE}{' '}
                            {ciesq.INTENSIDADE}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm">
                  {carotida?.cee?.length > 0 ? (
                    <>
                      <u className="mr-auto">Carótida Externa Esquerda: </u>
                      {carotida?.cee.map(ceesq => (
                        <div className="row">
                          <div className="col-12">
                            {ceesq.ANATOMIA} {ceesq.VELOCIDADE}{' '}
                            {ceesq.INTENSIDADE}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm">
                  {carotida?.ave?.length > 0 ? (
                    <>
                      <u className="mr-auto">Artéria Vertebral Esquerda: </u>
                      {carotida?.ave.map(avesq => (
                        <div className="row">
                          <div className="col-12">
                            {avesq.ANATOMIA} {avesq.VELOCIDADE}{' '}
                            {avesq.INTENSIDADE}
                          </div>
                        </div>
                      ))}
                    </>
                  ) : null}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm">
                  <strong className="mr-auto">Observações</strong>
                  <div className="row">
                    <div className="col-12">
                      {carotida?.carotida[0]?.OBSERVACOES &&
                        carotida?.carotida[0]?.OBSERVACOES.split('\n').map(
                          (item, i) => {
                            return <div key={i}>{item}</div>;
                          }
                        )}
                    </div>
                  </div>
                </Form.Group>
              </Col>
            </Form.Row>
            <Card className="mt-3 text-center">
              <Card.Body>
                {files.length > 0 && (
                  <div className={styles.messageReport}>
                    Imagens disponíveis no site:{' '}
                    <strong>www.icm.com.br (Resultado de Exames)</strong>
                    <hr></hr>
                  </div>
                )}
                <div className={styles.signatureReport}>
                  <i className="mr-auto">
                    Conferido e assinado eletronicamente por:{' '}
                  </i>
                  <strong className="mr-auto">
                    {prefixo()} {carotida?.carotida[0]?.Realizou}
                  </strong>
                  <br></br>
                  <i className="mr-auto">
                    Assinatura Digital:{' '}
                    {md5(
                      JSON.stringify(carotida?.carotida[0]?.OBSERVACOES || '')
                    )}
                  </i>
                </div>
              </Card.Body>
            </Card>
          </Form>
        </Card.Body>

        <Card.Footer id="removeFooter" className="mt-3 text-center">
          <Button onClick={() => removeDiv('removeFooter')}>Imprimir</Button>{' '}
          <Button variant="success" onClick={showFiles}>
            Ver Imagem
          </Button>
        </Card.Footer>
      </Card>

      {showModal && (
        <Modal show={showModal} onHide={() => setShowModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Visualização</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {urls.map((url, idx) => {
              if (isAllowedToDeleteFiles) {
                return (
                  <div>
                    <Card>
                      <Alert
                        show={openDeleteAlert === url.Key}
                        variant="danger"
                        onClose={() => setOpenDeleteAlert(null)}
                        dismissible
                      >
                        <Alert.Heading>
                          Você tem certeza que quer deletar a imagem?
                        </Alert.Heading>
                        <hr />
                        <div className="d-flex justify-content-">
                          <Button
                            onClick={() => deleteS3Object(url.Key)}
                            variant="danger"
                          >
                            Confirmar
                          </Button>
                        </div>
                      </Alert>
                      <div className="btn-group">
                        <button
                          className="btn btn-primary"
                          onClick={() => handleClick(url.url.data.preSignedUrl)}
                          aria-controls="example-collapse-text"
                          aria-expanded={open}
                          style={{ color: 'white' }}
                        >
                          Imagem {idx + 1}
                        </button>
                        <button
                          className="btn btn-danger"
                          onClick={() => setOpenDeleteAlert(url.Key)}
                          style={{ color: 'white' }}
                        >
                          Deletar
                        </button>
                      </div>
                    </Card>
                    <Collapse in={open[url.url.data.preSignedUrl]}>
                      <div
                        key={url.url.data.preSignedUrl}
                        id="collapse-div"
                        className="collapsedText"
                      >
                        {url.url.data.preSignedUrl.includes('.webm') ||
                        url.url.data.preSignedUrl.includes('.mp4') ? (
                          <div
                            style={{ textAlign: 'center', display: 'block' }}
                          >
                            <video
                              id="video-laudo"
                              width="500"
                              height="500"
                              controls
                            >
                              <source
                                src={url.url.data.preSignedUrl}
                                type={
                                  url.url.data.preSignedUrl.endsWith('.mp4')
                                    ? 'video/mp4'
                                    : 'video/webm'
                                }
                              />
                            </video>
                          </div>
                        ) : (
                          <div
                            style={{ textAlign: 'center', display: 'block' }}
                          >
                            <embed
                              src={url.url.data.preSignedUrl}
                              width="600px"
                              height="900px"
                            />
                          </div>
                        )}
                      </div>
                    </Collapse>
                  </div>
                );
              } else {
                return (
                  <div>
                    <Card>
                      <div className="btn-group">
                        <button
                          className="btn btn-primary"
                          onClick={() => handleClick(url.url.data.preSignedUrl)}
                          aria-controls="example-collapse-text"
                          aria-expanded={open}
                          style={{ color: 'white' }}
                        >
                          Imagem {idx + 1}
                        </button>
                      </div>
                    </Card>
                    <Collapse in={open[url.url.data.preSignedUrl]}>
                      <div
                        key={url.url.data.preSignedUrl}
                        id="collapse-div"
                        className="collapsedText"
                      >
                        {url.url.data.preSignedUrl.includes('.webm') ||
                        url.url.data.preSignedUrl.includes('.mp4') ? (
                          <div
                            style={{ textAlign: 'center', display: 'block' }}
                          >
                            <video
                              id="video-laudo"
                              width="500"
                              height="500"
                              controls
                            >
                              <source
                                src={url.url.data.preSignedUrl}
                                type={
                                  url.url.data.preSignedUrl.endsWith('.mp4')
                                    ? 'video/mp4'
                                    : 'video/webm'
                                }
                              />
                            </video>
                          </div>
                        ) : (
                          <div
                            style={{ textAlign: 'center', display: 'block' }}
                          >
                            <embed
                              src={url.url.data.preSignedUrl}
                              width="600px"
                              height="900px"
                            />
                          </div>
                        )}
                      </div>
                    </Collapse>
                  </div>
                );
              }
            })}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
