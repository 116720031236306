export function signInRequest(cpf, senha) {
  return {
    type: '@doctor/SIGN_IN_REQUEST',
    payload: { cpf, senha },
  };
}

export function signInSuccess(token, doctor) {
  return { type: '@doctor/SIGN_IN_SUCCESS', payload: { token, doctor } };
}

export function signUpRequest(cpf, validacao, senha, confirmPassword) {
  return {
    type: '@doctor/SIGN_UP_REQUEST',
    payload: { cpf, validacao, senha, confirmPassword },
  };
}

export function signInRequestExt(cpf, senha) {
  return {
    type: '@doctor/SIGN_IN_REQUEST_EXT',
    payload: { cpf, senha },
  };
}

export function signUpRequestExt(medicoExterno) {
  return { type: '@doctor/SIGN_UP_REQUEST_EXT', payload: { medicoExterno } };
}

export function signFailure() {
  return { type: '@doctor/SIGN_FAILURE' };
}

export function signOutMed() {
  return { type: '@doctor/SIGN_OUT' };
}
