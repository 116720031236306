import produce from 'immer';

const INITIAL_STATE = { token: null, logado: false, loading: false };

export default function funcionario(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@funcionario/SIGN_IN_REQUEST': {
        draft.loading = true;
        break;
      }
      case '@funcionario/SIGN_IN_SUCCESS': {
        draft.token = action.payload.token;
        draft.funcionario = action.payload.funcionario;
        draft.logado = true;
        draft.loading = false;
        break;
      }
      case '@funcionario/SIGN_FAILURE': {
        draft.loading = false;
        break;
      }
      case '@funcionario/SIGN_OUT': {
        draft.token = null;
        draft.logado = false;
        draft.funcionario = null;
        break;
      }
      default:
    }
  });
}
