/* eslint-disable prefer-destructuring */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import { Text, Element } from 'slate';

// Serialize the Slate nodes to HTML tags.
export const serialize = nodes => {
  return nodes
    .map(node => {
      if (Text.isText(node)) {
        let { text } = node;

        // Create an array to hold the opening and closing tags
        let openTags = '';
        let closeTags = '';

        if (node.bold) {
          openTags += '<strong>';
          closeTags = `</strong>${closeTags}`;
        }

        if (node.italic) {
          openTags += '<em>';
          closeTags = `</em>${closeTags}`;
        }

        if (node.underline) {
          openTags += '<u>';
          closeTags = `</u>${closeTags}`;
        }

        // Apply all the formatting marks at once
        text = `${openTags}${text}${closeTags}`;

        return text;
      }

      if (Element.isElement(node)) {
        const children = serialize(node.children);
        switch (node.type) {
          case 'paragraph':
            return `<p>${children}</p>`;
          case 'quote':
            return `<blockquote>${children}</blockquote>`;
          default:
            return children;
        }
      }
    })
    .join('');
};

// Deserialize HTML tags to Slate nodes to display content in editor.
export const deserialize = node => {
  if (node.nodeType === Node.TEXT_NODE) {
    let { parentNode } = node;
    let newNode = { text: node.nodeValue };

    while (parentNode.nodeName !== '#document') {
      switch (parentNode.nodeName) {
        case 'STRONG':
          newNode = { ...newNode, bold: true };
          break;
        case 'EM':
          newNode = { ...newNode, italic: true };
          break;
        case 'U':
          newNode = { ...newNode, underline: true };
          break;
        default:
          break;
      }
      parentNode = parentNode.parentNode;
    }

    return newNode;
  }

  if (node.nodeType === Node.ELEMENT_NODE) {
    let children = Array.from(node.childNodes)
      .map(deserialize)
      .flat();

    // If there are no child nodes, create an empty text node within the paragraph
    if (children.length === 0) {
      children = [{ text: '' }];
    }

    switch (node.nodeName) {
      case 'STRONG':
        return children.map(child => ({ ...child, bold: true }));
      case 'EM':
        return children.map(child => ({ ...child, italic: true }));
      case 'U':
        return children.map(child => ({ ...child, underline: true }));
      case 'UL':
        return { type: 'bulleted-list', children };
      case 'LI':
        return { type: 'list-item', children };
      case 'P':
      default:
        return { type: 'paragraph', children };
    }
  }
};

export const extractText = node => {
  if (Text.isText(node)) {
    let result = node.text;

    if (node.bold) {
      result = `<strong>${result}</strong>`;
    }

    if (node.italic) {
      result = `<em>${result}</em>`;
    }

    if (node.underline) {
      result = `<u>${result}</u>`;
    }

    return result;
  }

  if (node.type === 'paragraph') {
    return `<p>${node.children.map(extractText).join('')}</p>`;
  }

  if (node.type === 'break') {
    return '<br>';
  }

  if (node.type === 'bulleted-list') {
    const listItems = node.children.map(extractText).join('');
    return `<ul>${listItems}</ul>`;
  }

  if (node.type === 'list-item') {
    return `<li>${node.children.map(extractText).join('')}</li>`;
  }

  return node.children.map(extractText).join('');
};
