import React from 'react';
import { Container } from 'react-bootstrap';
import NavBar from '../pages/patient/Index/NavBar';

export default function PacienteLayout({ children }) {
  return (
    <Container fluid>
      <NavBar />
      {children}
    </Container>
  );
}
