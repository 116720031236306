import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { addDays, parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from '../../../services/api';

// createSlice makes all action creators and reducers in the same file so no separation of logic is necessary

/** *************STATE SLICE************** */
export const sliceAvaliacao = createSlice({
  name: 'avaliacao',
  initialState: {
    avaliacao: [],
    avaliacoes: [],
    marcapasso: [],
  },
  reducers: {
    avaliacaoSuccess: (state, action) => {
      const { avaliacao, avaliacoes } = action.payload;
      if (avaliacao !== undefined) {
        state.avaliacao = avaliacao;
      }
      if (avaliacoes !== undefined) {
        state.avaliacoes = avaliacoes;
      }
    },
    avaliacaoRequest: state => {
      state.avaliacao = [];
    },
    avaliacoesRequest: state => {
      state.avaliacoes = [];
    },
    avaliacaoReset: state => {
      state.avaliacao = [];
      state.avaliacoes = [];
      state.marcapasso = [];
    },
    avaliacaoFailure: state => {
      state.avaliacao = [];
      state.avaliacoes = [];
    },
    marcapassoSuccess: (state, action) => {
      const { marcapasso } = action.payload;
      if (marcapasso !== undefined) {
        state.marcapasso = marcapasso;
      }
    },
    marcapassoRequest: state => {
      state.marcapasso = [];
    },
    marcapassoFailure: state => {
      state.marcapasso = [];
    },
  },
});

/** *************EXPORTED ACTIONS & REDUCERS************** */

export const {
  avaliacaoRequest,
  avaliacoesRequest,
  avaliacaoSuccess,
  avaliacaoFailure,
  avaliacaoReset,
  marcapassoRequest,
  marcapassoSuccess,
  marcapassoFailure,
} = sliceAvaliacao.actions;

export default sliceAvaliacao.reducer;

// API REQUEST ACTIONS HANDLED WITH REDUX-THUNK MIDDLEWARE BUILT INTO REDUX TOOLKIT -->

/** *************THUNKS************** */
/** AVALIACAO */
export const selectAvaliacaoById = payload => {
  return async dispatch => {
    try {
      dispatch(avaliacaoRequest());
      const result = await api.get(`/avaliacao?id=${payload}`);
      let c = 0;
      const avaliacao = result.data.map(a => ({
        ...a,
        dataAvaliacao: format(
          addDays(parseISO(a.DATA_AVALIACAO), 1),
          'dd/MM/yyyy',
          {
            locale: pt,
          }
        ),
        dataNasc: format(addDays(parseISO(a.DTNPAC), 1), 'dd/MM/yyyy', {
          locale: pt,
        }),
        idadePAC: Math.floor(
          (parseISO(a.DATA_AVALIACAO) - parseISO(a.DTNPAC)) /
            (1000 * 3600 * 24) /
            365.25
        ),
        counter: (c += 1),
      }));
      dispatch(avaliacaoSuccess({ avaliacao }));
      return avaliacao;
    } catch (err) {
      toast.error(err.response.data.error);
      dispatch(avaliacaoFailure());
    }
  };
};

export const selectAvaliacaoByPac = payload => {
  return async dispatch => {
    try {
      dispatch(avaliacoesRequest());
      const result = await api.get(`/paciente/avaliacao?id=${payload}`);
      let c = 0;
      const avaliacoes = result.data.map(a => ({
        ...a,
        dataAvaliacao: format(
          addDays(parseISO(a.DATA_AVALIACAO), 1),
          'dd/MM/yyyy',
          { locale: pt }
        ),
        dataNasc: format(addDays(parseISO(a.DTNPAC), 1), 'dd/MM/yyyy', {
          locale: pt,
        }),
        idadePAC: Math.floor(
          (parseISO(a.DATA_AVALIACAO) - parseISO(a.DTNPAC)) /
            (1000 * 3600 * 24) /
            365.25
        ),
        counter: (c += 1),
      }));

      dispatch(avaliacaoSuccess({ avaliacoes }));
      return avaliacoes;
    } catch (err) {
      toast.error(err.response.data.error);
      dispatch(avaliacaoFailure());
    }
  };
};

/** MARCAPASSO */
export const selectMarcapassoById = payload => {
  return async dispatch => {
    try {
      dispatch(marcapassoRequest());
      const result = await api.get(`/marcapasso?id=${payload}`);
      let c = 0;
      const marcapasso = {
        ...result.data,
        dataMarca:
          result.data.DATAMARCA &&
          format(addDays(parseISO(result.data.DATAMARCA), 1), 'dd/MM/yyyy', {
            locale: pt,
          }),
        dataElAt:
          result.data.DTIMPELAT &&
          format(addDays(parseISO(result.data.DTIMPELAT), 1), 'dd/MM/yyyy', {
            locale: pt,
          }),
        dataElVEDir:
          result.data.DTIMPELVE &&
          format(addDays(parseISO(result.data.DTIMPELVE), 1), 'dd/MM/yyyy', {
            locale: pt,
          }),
        dataElVEEsq:
          result.data.DTIMPELVEESQ &&
          format(addDays(parseISO(result.data.DTIMPELVEESQ), 1), 'dd/MM/yyyy', {
            locale: pt,
          }),
        dataGer:
          result.data.DTIMPGER &&
          format(addDays(parseISO(result.data.DTIMPGER), 1), 'dd/MM/yyyy', {
            locale: pt,
          }),
        counter: (c += 1),
      };
      dispatch(marcapassoSuccess({ marcapasso }));
      return marcapasso;
    } catch (err) {
      toast.error(err.response.data.error);
      dispatch(marcapassoFailure());
    }
  };
};
