// convenios e procedimentos para MED EXTERNO
const dbatividade = {
  convenios: [
    { codConv: 27, descricao: '' },
    { codConv: 29, descricao: '' },
    { codConv: 32, descricao: '' },
    { codConv: 45, descricao: '' },
    { codConv: 46, descricao: '' },
    { codConv: 47, descricao: '' },
    { codConv: 48, descricao: '' },
    { codConv: 49, descricao: '' },
    { codConv: 50, descricao: '' },
    { codConv: 51, descricao: '' },
    { codConv: 53, descricao: '' },
    { codConv: 55, descricao: '' },
    { codConv: 57, descricao: '' },
    { codConv: 59, descricao: '' },
    { codConv: 63, descricao: '' },
    { codConv: 84, descricao: '' },
    { codConv: 97, descricao: '' },
    { codConv: 701, descricao: '' },
    { codConv: 705, descricao: '' },
    { codConv: 720, descricao: '' },
    { codConv: 806, descricao: '' },
  ],
  procedimentos: [
    { codProcedi: 11, descricao: '' },
    { codProcedi: 712, descricao: '' },
    { codProcedi: 12, descricao: '' },
    { codProcedi: 13, descricao: '' },
    { codProcedi: 713, descricao: '' },
    { codProcedi: 714, descricao: '' },
  ],
  atividade: [
    { codProcedi: 4, descricao: 'Ergo' },
    { codProcedi: 6, descricao: 'Holter' },
    { codProcedi: 7, descricao: 'Mapa' },
    { codProcedi: 12, descricao: 'Trans' },
    { codProcedi: 14, descricao: 'Carótida' },
    { codProcedi: 15, descricao: 'Carótida' },
    { codProcedi: 17, descricao: 'Fetal' },
  ],
};

export default dbatividade;
