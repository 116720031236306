/* eslint-disable consistent-return */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { addDays, parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from '../../../services/api';

// createSlice makes all action creators and reducers in the same file so no separation of logic is necessary

/** *************STATE SLICE************** */
export const sliceErgo = createSlice({
  name: 'ergo',
  initialState: {
    loading: false,
    ergo: {},
  },
  reducers: {
    ergoSuccess: (state, action) => {
      const { ergo } = action.payload;
      state.loading = false;
      state.ergo = ergo;
    },
    ergoRequest: (state, action) => {
      const { ergo } = action.payload;
      state.loading = true;
      state.ergo = ergo;
    },
    ergoFailure: state => {
      state.loading = false;
      state.ergo = {};
    },
    ergoReset: state => {
      state.loading = false;
      state.ergo = {};
    },
  },
});

/** *************EXPORTED ACTIONS & REDUCERS************** */

export const {
  ergoRequest,
  ergoSuccess,
  ergoFailure,
  ergoReset,
} = sliceErgo.actions;

export default sliceErgo.reducer;

/** *************THUNKS************** */
export const selectErgoById = payload => {
  return async dispatch => {
    try {
      dispatch(ergoReset());
      const { data } = await api.get(`ergo/${payload}`);
      const ergo = {
        ...data,
        dataErgo:
          data.DATAERGO &&
          format(addDays(parseISO(data.DATAERGO), 1), 'dd/MM/yyyy', {
            locale: pt,
          }),
        dataNasc: format(addDays(parseISO(data.DTNPAC), 1), 'dd/MM/yyyy', {
          locale: pt,
        }),
        idadePAC: Math.floor(
          (parseISO(data.DATAERGO) - parseISO(data.DTNPAC)) /
            (1000 * 3600 * 24) /
            365.25
        ),
      };
      dispatch(ergoSuccess({ ergo }));
      return ergo;
    } catch (error) {
      toast.error(`ERRO ao buscar Teste Ergometrico.`);
      dispatch(ergoFailure());
    }
  };
};
