import { toast } from 'react-toastify';
import { addDays, parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from './api';
import {
  loadingOpen,
  loadingClose,
} from '../redux/features/context/contextSlice';

async function fetchAgendas({
  idMedico,
  dateAgendaFormatted,
  setAgendamentos,
  setCount,
  setIdMedico,
  setAgendaMed,
  funcionario,
  dispatch,
}) {
  let c = 0;
  dispatch(loadingOpen());
  try {
    let response;
    if (idMedico) {
      response = await api.get(
        `medico/agendas?id=${idMedico}&dt=${dateAgendaFormatted}`
      );
    } else if (funcionario) {
      setIdMedico(5);
      setAgendaMed('Dr Fabio');
      response = await api.get(
        `medico/agendas?id=${5}&dt=${dateAgendaFormatted}`
      );
    } else {
      return toast.warn('Médico não identificado!');
    }

    const data = response?.data?.map(agenda => ({
      ...agenda,
      dataFormatada: format(
        addDays(parseISO(agenda.DATAAGENDA), 1),
        'dd/MM/yyyy',
        { locale: pt }
      ),
      counter: (c += 1),
    }));

    setAgendamentos(data);
    setCount(c);
  } catch (error) {
    toast.error(error.response.data.message);
  } finally {
    dispatch(loadingClose());
  }
}

export default fetchAgendas;
