import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { parseISO, format, addDays } from 'date-fns';
import pt from 'date-fns/locale/pt';
import { Table, Form, Card, Button, Col } from 'react-bootstrap';
import {
  loadingOpen,
  loadingClose,
  printOpen,
  printClose,
} from '../../../redux/features/context/contextSlice';
import api from '../../../services/api';
import { prontuarioSuccess } from '../../../redux/features/prontuario/prontuarioSlice';
import FormReceita from '../../../pages/laudo/receita/FormReceita';
import Loading from '../../../pages/Index/Loading';

export default function ReceitaList() {
  const dispatch = useDispatch();
  const medico = useSelector(state => state.doctor.doctor);
  const { loading } = useSelector(state => state.contexto);
  const [pacientes, setPacientes] = useState([]);
  const [loadingPac, setLoadingPac] = useState(false);
  const [count, setCount] = useState(0);
  const [receitas, setReceitas] = useState([]);
  const [receita] = useState([]);
  const [search, setSearch] = useState(false);
  const [locatedReceita, setLocatedReceita] = useState(false);
  const [total, setTotal] = useState(0);
  const [nomePac, setNomePac] = useState('');

  const handleImprimirReceita = receita => {
    dispatch(prontuarioSuccess({ receita }));
  };

  async function handlePrimeiraReceita() {
    toast.warn(
      'Primeira Receita em Desenvolvimento.\nEntre em contato com o Administrador do sistema!'
    );
  }

  async function handleReceitas(paciente) {
    dispatch(loadingOpen());
    setLocatedReceita(false);
    setSearch(false);
    let c = 0;
    if (paciente.CODPAC !== 0) {
      // /paciente/:codpac/receitas
      api
        .get(`paciente/${paciente.CODPAC}/receitas`)
        .then(result => {
          const data = result.data.map(receita => ({
            ...receita,
            dataFormatada: format(
              addDays(parseISO(receita.DATARECEITA), 1),
              'dd/MM/yyyy',
              { locale: pt }
            ),
            counter: (c += 1),
          }));
          setReceitas(data);
          setTotal(c);
          setSearch(true);
        })
        .catch(err => toast.error(err.response.data.error));
    } else {
      toast.warn('Paciente não identificado!');
    }
    dispatch(loadingClose());
  }

  async function handlePaciente() {
    dispatch(loadingOpen());
    setLocatedReceita(false);
    let c = 0;
    if (nomePac) {
      api
        .get(`/pacientebynome`, { params: { nome: nomePac } })
        .then(result => {
          const newPacientes = result.data.map(paciente => ({
            ...paciente,
            dataFormatada:
              paciente.DTNPAC &&
              format(addDays(parseISO(paciente.DTNPAC), 1), 'dd/MM/yyyy', {
                locale: pt,
              }),
            counter: (c += 1),
          }));
          setPacientes(newPacientes);
          setCount(c);
          setLoadingPac(true);
          setSearch(false);
        })
        .catch(err => toast.error(err.response.data.error));
    } else {
      toast.warn('Médico não identificado!');
    }
    dispatch(loadingClose());
  }

  function checkEnter(e) {
    if (e.key === 'Enter') {
      handlePaciente(); // submit the form
    }
  }

  return (
    <>
      <Card>
        <Card.Header>
          <h4 className="mb-0">
            <span className="text-success">Receitas do Paciente</span>
          </h4>
        </Card.Header>
        <Card.Body>
          <Form.Row>
            <Col sm={10}>
              <Form.Control
                style={{ textTransform: 'uppercase' }}
                value={nomePac}
                required
                type="text"
                placeholder="Nome do Paciente"
                onChange={e => setNomePac(e.target.value)}
                onKeyPress={e => checkEnter(e)}
              />
            </Col>
            <Col sm={2}>
              <Button type="button" onClick={() => handlePaciente()}>
                Localizar
              </Button>
            </Col>
          </Form.Row>
        </Card.Body>
      </Card>

      {loadingPac ? (
        <Col sm>
          <Table
            responsive="sm"
            striped
            bordered
            hover
            size="sm"
            variant="success"
          >
            <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th>Dt Nasc</th>
                <th>Celular</th>
                <th>Código</th>
                <th>#</th>
              </tr>
            </thead>
            <tbody>
              {pacientes.map(pac => (
                <tr key={pac.counter}>
                  <td>{pac.counter}</td>
                  <td>
                    <Button variant="link" onClick={() => handleReceitas(pac)}>
                      {pac.NOMEPAC}
                    </Button>
                  </td>
                  <td>{pac.dataFormatada}</td>
                  <td>{pac.CELULARPAC}</td>
                  <td>{pac.CODPAC}</td>
                  <td>
                    <Button
                      variant="success"
                      onClick={() => handleReceitas(pac)}
                    >
                      Receitas
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
            <tfoot>
              <tr>
                <td style={{ textAlign: 'right' }} colSpan="4">
                  TOTAL:
                </td>
                <td style={{ textAlign: 'left' }} colSpan="2">
                  {count}
                </td>
              </tr>
            </tfoot>
          </Table>
        </Col>
      ) : (
        <div />
      )}

      {search ? (
        <Col sm>
          {receitas.length > 0 ? (
            <>
              <Table
                responsive="sm"
                striped
                bordered
                hover
                size="sm"
                variant="success"
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Código</th>
                    <th>Data</th>
                    <th>#</th>
                  </tr>
                </thead>
                <tbody>
                  {receitas.map(rec => (
                    <tr key={rec.counter}>
                      <td>{rec.counter}</td>
                      <td>{rec.CODRECEITA}</td>
                      <td>{rec.dataFormatada}</td>
                      <td>
                        <Button
                          size="sm"
                          variant="outline-primary"
                          onClick={() => {
                            dispatch(printClose());
                            handleImprimirReceita(rec);
                          }}
                        >
                          Imprimir
                        </Button>
                        &nbsp;
                        <Button
                          size="sm"
                          variant="outline-primary"
                          onClick={() => {
                            dispatch(printOpen());
                            handleImprimirReceita(rec);
                          }}
                        >
                          Imprimir Timbrado
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot>
                  <tr>
                    <td style={{ textAlign: 'right' }} colSpan="3">
                      TOTAL:
                    </td>
                    <td style={{ textAlign: 'left' }} colSpan="1">
                      {total}
                    </td>
                  </tr>
                </tfoot>
              </Table>
            </>
          ) : (
            <Button
              size="lg"
              block
              variant="outline-success"
              onClick={() => handlePrimeiraReceita()}
            >
              Primeira Receita
            </Button>
          )}
        </Col>
      ) : (
        <div />
      )}
      {medico.tipo !== 'Externo' && locatedReceita ? (
        <FormReceita rec={receita} />
      ) : (
        <div />
      )}
      {loading ? <Loading /> : null}
    </>
  );
}
