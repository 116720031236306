import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Form, Card, Button } from 'react-bootstrap';
import { signInRequest } from '../../../store/modules/auth/patient/actions';
import history from '../../../services/history';
import logo from '../../../assets/logo.png';

export default function SignInPac() {
  const dispatch = useDispatch();
  const [validated, setValidated] = useState(false);

  async function handleSubmit(event) {
    event.preventDefault();
    const cpf = event.currentTarget.elements.formBasicCPF.value;
    const senha = event.currentTarget.elements.formBasicSenha.value;
    dispatch(signInRequest(cpf, senha));
    setValidated(true);
  }

  return (
    <div className="row justify-content-md-center p-5">
      <div className="col-lg-auto">
        <Card className="m-2">
          <Card.Body>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
              <div className="p-3">
                <div className="text-center">
                  <img
                    className="img-fluid rounded-circle center-block hoverable img-profile mx-2 mb-2"
                    src={logo}
                    alt="logo"
                    width={50}
                    height={50}
                  />
                </div>
                <Form.Group controlId="formBasicCPF">
                  <Form.Label>CPF do Paciente</Form.Label>
                  <Form.Control required type="text" placeholder="Seu CPF" />
                  <Form.Control.Feedback type="invalid">
                    Digite seu CPF.
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group controlId="formBasicSenha">
                  <Form.Label>Senha</Form.Label>
                  <Form.Control
                    required
                    type="password"
                    placeholder="Sua senha secreta"
                  />
                  <Form.Control.Feedback type="invalid">
                    Digite sua senha.
                  </Form.Control.Feedback>
                </Form.Group>
                <div className="text-center p-1">
                  <Button variant="primary" block type="submit">
                    Entrar
                  </Button>
                </div>
                <div className="text-center p-1">
                  <Button
                    variant="warning"
                    block
                    onClick={() => history.push('/perfilpaciente')}
                  >
                    Esqueci minha senha
                  </Button>
                </div>
              </div>
            </Form>
          </Card.Body>
        </Card>
      </div>
    </div>
  );
}
