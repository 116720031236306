import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Form, Col, Button, Modal } from 'react-bootstrap';
import { format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from '../../../services/api';

export default function Receita(props) {
  const { rec } = props;
  const medico = useSelector(state => state.doctor.doctor);
  const [descricao, setDescricao] = useState([]);
  const [show, setShow] = useState(true);
  const [linhas, setLinhas] = useState(0);

  useEffect(() => {
    async function loadReceita() {
      let lines = rec.DESCRICAO.split('\n'); // lines is an array of strings
      let newLine = '';
      let j = 0;
      for (j = 0; j < lines.length; j++) {
        if (j === 0) {
          lines.splice(0, 1);
          const dataExtenso = format(new Date(), "dd 'de' MMMM 'de' yyyy", {
            locale: pt,
          });
          lines[j] = `Marília, ${dataExtenso} \n`;
        }
        newLine += lines[j];
      }
      setDescricao(newLine.trimEnd());
      setLinhas(j + 5);
    }

    loadReceita();
    // eslint-disable-next-line
  }, []);

  const handleClose = () => setShow(false);

  const handleGravar = () => {
    const receita = Object.assign(rec, {
      codPaciente: rec.CODPAC,
      codMedico: medico.codMed,
      codConsulta: rec.CODCONSULTA,
      dataRec: new Date(),
      tipo: rec.TIPORECEITA,
      descricao: descricao,
      status: 'EMITIDA',
    });

    api
      .post(`/receita`, receita)
      .then(() => toast.success('Receita Inserida com Sucesso!'))
      .catch(err => toast.error(err.response.data.error));
  };

  return (
    <Modal show={show} size="lg" centered onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>EMITIR NOVA RECEITA</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Form noValidate>
          <Form.Row>
            <Form.Group as={Col} sm controlId="editNomePAC">
              <Form.Label>Nome do Paciente</Form.Label>
              <Form.Control readOnly defaultValue={rec.NOMEPAC} />
            </Form.Group>
          </Form.Row>
          <Form.Row>
            <Form.Group as={Col} sm controlId="editNOMEMED">
              <Form.Label>Nome do Médico</Form.Label>
              <Form.Control readOnly defaultValue={medico.apelido} />
            </Form.Group>
          </Form.Row>

          <Form.Row>
            <Col sm>
              <Form.Group size="sm" controlId="obs">
                <Form.Label>Descrição da Receita</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={linhas}
                  defaultValue={descricao.trimEnd()}
                  onChange={e => setDescricao(e.target.value)}
                />
              </Form.Group>
            </Col>
          </Form.Row>
        </Form>
        <Button variant="primary" size="lg" onClick={handleGravar}>
          Gravar
        </Button>{' '}
        <Button variant="secondary" size="lg" onClick={() => handleClose()}>
          Fechar
        </Button>
      </Modal.Body>
    </Modal>
  );
}
