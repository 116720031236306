import { combineReducers } from 'redux';

import patient from './auth/patient/reducer';
import funcionario from './auth/funcionario/reducer';
import doctor from './auth/doctor/reducer';
import certificate from '../../redux/features/certificate/certificateSlice';
import contexto from '../../redux/features/context/contextSlice';
import convenio from '../../redux/features/context/convenioSlice';
import procedimento from '../../redux/features/context/procedimentoSlice';
import faturamento from '../../redux/features/context/faturamentoSlice';
import prontuario from '../../redux/features/prontuario/prontuarioSlice';
import laboratorio from '../../redux/features/prontuario/laboratorioSlice';
import agenda from '../../redux/features/prontuario/agendaSlice';
import medico from '../../redux/features/prontuario/medicoSlice';
import paciente from '../../redux/features/prontuario/pacienteSlice';
import avaliacao from '../../redux/features/prontuario/avaliacaoSlice';
import eco from '../../redux/features/laudo/ecoSlice';
import ecg from '../../redux/features/laudo/ecgSlice';
import ergo from '../../redux/features/laudo/ergoSlice';
import stress from '../../redux/features/laudo/stressSlice';
import trans from '../../redux/features/laudo/transSlice';
import fetal from '../../redux/features/laudo/fetalSlice';
import mapa from '../../redux/features/laudo/mapaSlice';
import holter from '../../redux/features/laudo/holterSlice';

export default combineReducers({
  certificate,
  patient,
  funcionario,
  doctor,
  paciente,
  medico,
  contexto,
  prontuario,
  agenda,
  eco,
  trans,
  ergo,
  stress,
  fetal,
  ecg,
  mapa,
  holter,
  convenio,
  procedimento,
  faturamento,
  laboratorio,
  avaliacao,
});
