// Médicos do icm
const medicoCPFs = [
  { cpf: '01534934880' },
  { cpf: '08396808805' },
  { cpf: '16384220873' },
  { cpf: '11016742886' },
  { cpf: '06798172806' },
  { cpf: '27003275864' },
  { cpf: '22356439811' },
  { cpf: '29538354826' },
  { cpf: '09339882733' },
  { cpf: '22776600860' },
  { cpf: '84398949704' },
  { cpf: '12215675780' },
  { cpf: '98765432100' },
  { cpf: '16188168880' },
  { cpf: '21964622840' },
  { cpf: '42394547863' },
];

export default medicoCPFs;
