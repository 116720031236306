/* eslint-disable consistent-return */
import { takeLatest, call, put, all } from 'redux-saga/effects';
import { toast } from 'react-toastify';
import validarCPF from 'validar-cpf';
import history from '../../../../services/history';
import api from '../../../../services/api';
import { signInSuccess, signFailure } from './actions';
import cpfs from '../../../../util/medicos';

export function* signIn({ payload }) {
  try {
    const { cpf, senha } = payload;

    if (cpf === '12345678900') {
      const response = yield call(api.post, 'msessions', { cpf, senha });

      const { token, medico } = response.data;
      api.defaults.headers.Authorization = `Bearer ${token}`;

      yield put(signInSuccess(token, medico));

      history.push('/frmatv');
      return;
    }

    if (!validarCPF(cpf)) {
      toast.error('CPF do Médico é inválido. Confira seu CPF.');
      return;
    }

    const isValidCPFExterno = cpfs.some(c => c.cpf === cpf);
    if (!isValidCPFExterno) {
      toast.error('Médico não autorizado!');
      return;
    }

    const response = yield call(api.post, 'msessions', { cpf, senha });
    const { token, medico } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;

    yield put(signInSuccess(token, medico));

    if (medico.tipo === 'Agenda') history.push('/agenda');
    else history.push('/frmatv');
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put(signFailure());
  }
}

export function* signUp({ payload }) {
  try {
    const { validacao, cpf, senha, confirmPassword } = payload;

    if (!validarCPF(cpf)) {
      toast.error('CPF do Médico é inválido.');
    } else if (senha !== confirmPassword) {
      toast.error('Senhas digitadas não conferem.');
    } else {
      yield call(api.put, 'updatemed', {
        validacao,
        cpf,
        senha,
        confirmPassword,
      });

      history.push('/');
    }
  } catch (error) {
    toast.error(`Falha na atualização do Médico, verifique seus dados.`);
    yield put(signFailure());
  }
}

export function* signInExterno({ payload }) {
  try {
    const { cpf, senha } = payload;
    if (cpf.replace(/[^\d]+/g, '') === '12345678900') {
      const response = yield call(api.post, 'msessions', { cpf, senha });
      const { token, medico } = response.data;

      api.defaults.headers.Authorization = `Bearer ${token}`;

      yield put(signInSuccess(token, medico));
      history.push('/frmatv');
      return;
    }

    if (!validarCPF(cpf)) return toast.error('CPF do Médico é inválido.');

    const response = yield call(api.post, 'msessions', { cpf, senha });

    const { token, medico } = response.data;

    api.defaults.headers.Authorization = `Bearer ${token}`;
    yield put(signInSuccess(token, medico));
    history.push('/frmatv');
  } catch (error) {
    toast.error(error?.response?.data?.message);
    yield put(signFailure());
  }
}

export function* signUpExterno({ payload }) {
  const { medicoExterno } = payload;
  try {
    if (!validarCPF(medicoExterno.cpf)) {
      toast.error('CPF do Médico é inválido.');
    } else if (medicoExterno.senha !== medicoExterno.confirmPassword) {
      toast.error('Senhas digitadas não conferem.');
    } else {
      yield call(api.put, 'updatemedico', { medicoExterno });
      toast.success('Dados Atualizados com Sucesso!');
      history.push('/externo');
    }
  } catch (error) {
    toast.error(`Falha na atualização do Médico.`);
    yield put(signFailure());
  }
}

export function setToken({ payload }) {
  if (!payload) return;

  const { token } = payload.doctor;

  if (token) {
    api.defaults.headers.Authorization = `Bearer ${token}`;
  }
}

export function signOut() {
  history.push('/');
}

export default all([
  takeLatest('persist/REHYDRATE', setToken),
  takeLatest('@doctor/SIGN_IN_REQUEST', signIn),
  takeLatest('@doctor/SIGN_UP_REQUEST', signUp),
  takeLatest('@doctor/SIGN_IN_REQUEST_EXT', signInExterno),
  takeLatest('@doctor/SIGN_UP_REQUEST_EXT', signUpExterno),
  takeLatest('@doctor/SIGN_OUT', signOut),
]);
