import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, Card } from 'react-bootstrap';
import medicoAgenda from '../../../util/medicoAgenda';

export default function FormAgendaDiaria() {
  const { agendadiaria } = useSelector(state => state.faturamento);
  const [selectedMedico, setSelectedMedico] = useState([]);

  const medicoMap = {
    'JOAO C. MORON SAES BRAGA': 'Dr João',
    ESTUDOS: 'Estudo',
    'ALEXANDRE RODRIGUES': 'Dr Alexandre',
    'CASSIA SPINOLA CUSTODIO RODRIGUES': 'Drª Cassia',
    'ECG EXTERN': 'EcgExt',
    'ECO INTERNADO': 'EcoInt',
    'FABIO VILLAÇA GUIMARÃES FILHO': 'Dr Fabio',
    'FERNANDA DI TULLIO T. VILELA': 'Drª Fernanda',
    'IGOR RIBEIRO DE CASTRO BIENERT': 'Dr Igor',
    'LUIZ GUSTAVO VARGAS BATISTA DO NASCIMENTO': 'Dr Luiz',
    'MARINA MONTEBELLO PIEDADE': 'Drª Marina',
    'METODO GRAFICO': 'MG',
    'RAPHAEL APARECIDO BARRETO DA SILVA': 'Dr Raphael',
    'ROBERTO DAHER': 'Dr Roberto',
    'VALDYR DATILO': 'Dr Valdyr',
  };

  const handleSelectMedico = agenda => {
    setSelectedMedico(prevSelectedMedico => {
      if (prevSelectedMedico.includes(agenda.medico)) {
        return prevSelectedMedico.filter(medico => medico !== agenda.medico);
      } else {
        return [...prevSelectedMedico, agenda.medico];
      }
    });
  };

  const filterAgendas = useMemo(() => {
    return agendadiaria.filter(
      a =>
        selectedMedico.length === 0 ||
        selectedMedico.includes(medicoMap[a.NOMEMED])
    );
  }, [agendadiaria, selectedMedico]);

  function formatarData(data) {
    const dataFormatada = new Date(data);
    return dataFormatada.toLocaleDateString('pt-BR');
  }

  function formatarHorario(horario) {
    const hora = Math.floor(horario / 100);
    const minutos = horario % 100;
    return (
      hora.toString().padStart(2, '0') +
      ':' +
      minutos.toString().padStart(2, '0')
    );
  }

  // Função para montar o relatório em HTML separado por médico com largura de colunas padronizada
  function montarRelatorioPorMedicoHTML(agendas) {
    const relatorioPorMedico = {};
    agendas.forEach(agendamento => {
      const nomeMedico = agendamento.NOMEMED;
      if (!relatorioPorMedico[nomeMedico]) relatorioPorMedico[nomeMedico] = [];
      relatorioPorMedico[nomeMedico].push(agendamento);
    });

    let html = '';
    for (const medico in relatorioPorMedico) {
      html += `<br></br><h4>Médico: ${medico} - ${formatarData(
        agendadiaria[0].DATAAGENDA
      )}</h4>`;
      html +=
        '<table border="1" style="width: 100%; border-collapse: collapse;font-size: 0.8em;"><tr><th style="width: 5%;">Horário</th><th style="width: 30%;">Paciente</th><th style="width: 20%;">Procedimento</th><th style="width: 15%;">Convênio</th><th style="width: 12%;">Celular</th><th style="width: 18%;">Anotação</th></tr>';
      // eslint-disable-next-line
      relatorioPorMedico[medico].forEach(agendamento => {
        html += `<tr><td>${formatarHorario(agendamento.HORARIO)}</td><td>${
          agendamento.NOMEPAC
        }</td><td>${agendamento.DESCPROCEDI}</td><td>${
          agendamento.SIGLACONV
        }</td><td>${agendamento.CELULARPAC}</td><td></td></tr>`;
      });
      html += '</table>';
    }
    return html;
  }

  useEffect(() => {
    const relatorioHTML = montarRelatorioPorMedicoHTML(filterAgendas);
    document.getElementById('fechamento').innerHTML = relatorioHTML;
    // eslint-disable-next-line
  }, [filterAgendas]);

  return (
    <Card id="cardHeader" style={{ marginTop: 10 }}>
      <Card.Header className="text-center">
        <Card.Title style={{ fontWeight: 'bold' }}>AGENDA DIÁRIA</Card.Title>
      </Card.Header>

      <div
        style={{
          justifyContent: 'center',
          display: 'flex',
          marginBottom: 5,
          gap: 5,
          marginTop: 10,
        }}
      >
        <Button onClick={() => window.print()}>Imprimir</Button>
        <Button variant="warning" onClick={() => setSelectedMedico([])}>
          Limpar Filtros
        </Button>
      </div>

      <div>
        {medicoAgenda.map(agenda => (
          <Form.Check
            id={agenda.codigo}
            key={agenda.codigo}
            inline
            checked={selectedMedico.includes(agenda.medico)}
            type="checkbox"
            label={agenda.medico}
            size="sm"
            onClick={() => handleSelectMedico(agenda)}
          />
        ))}
      </div>
      <div style={{ marginBottom: 10 }} id="fechamento" />
    </Card>
  );
}
