/* eslint-disable no-return-assign */
/* eslint-disable consistent-return */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { addDays, parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from '../../../services/api';

// createSlice makes all action creators and reducers in the same file so no separation of logic is necessary

export const sliceLaboratorio = createSlice({
  name: 'laboratorio',
  initialState: {
    exames: [],
    tipoexame: [],
    viewexames: [],
    tabelaexames: [],
    viewlabresults: [],
  },
  reducers: {
    laboratorioSuccess: (state, action) => {
      const {
        exames,
        tipoexame,
        viewexames,
        tabelaexames,
        viewlabresults,
      } = action.payload;
      if (exames !== undefined) {
        state.exames = exames;
      }
      if (viewexames !== undefined) {
        state.viewexames = viewexames;
      }
      if (viewlabresults !== undefined) {
        state.viewlabresults = viewlabresults;
      }
      if (tabelaexames !== undefined) {
        state.tabelaexames = tabelaexames;
      }
      if (tipoexame !== undefined) {
        state.tipoexame = tipoexame;
      }
    },
    laboratorioRequest: state => {
      state.exames = [];
      state.viewexames = [];
      state.tabelaexames = [];
    },
    laboratorioReset: state => {
      state.exames = [];
      state.viewexames = [];
      state.viewlabresults = [];
      state.tabelaexames = [];
      state.tipoexame = [];
    },
    tabelaReset: state => {
      state.tabelaexames = [];
    },
    examesReset: state => {
      state.exames = [];
    },

    viewexamesReset: state => {
      state.viewexames = [];
    },
    viewlabresultsReset: state => {
      state.viewlabresults = [];
    },
    laboratorioFailure: state => {
      state.exames = [];
      state.viewexames = [];
      state.tabelaexames = [];
    },
  },
});

/** *************EXPORTED ACTIONS & REDUCERS************** */

export const {
  laboratorioRequest,
  laboratorioSuccess,
  laboratorioFailure,
  laboratorioReset,
  viewlabresultsReset,
  tabelaReset,
  examesReset,
  viewexamesReset,
} = sliceLaboratorio.actions;

export default sliceLaboratorio.reducer;

// API REQUEST ACTIONS HANDLED WITH REDUX-THUNK MIDDLEWARE BUILT INTO REDUX TOOLKIT -->

/** *************THUNKS************** */

// /tipoexame
export const selectAllTipoExame = () => {
  return async dispatch => {
    try {
      const result = await api.get(`tipoexame`);
      dispatch(laboratorioSuccess({ tipoexame: result.data }));
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(laboratorioFailure());
    }
  };
};

// /exameslab
export const getExameLabByPac = payload => {
  return async dispatch => {
    try {
      dispatch(examesReset());
      const result = await api.get(`/exameslab`, {
        params: { CODPAC: payload.CODPAC, CODCONSULTA: payload.CODCONSULTA },
      });
      const exames = result.data;
      dispatch(laboratorioSuccess({ exames }));
      return exames;
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(laboratorioFailure());
    }
  };
};

// /viewexames (group list exams)
export const getViewExameLabByPac = payload => {
  return async dispatch => {
    try {
      dispatch(viewexamesReset());
      const { data } = await api.get(`/viewexames`, {
        params: { CODPAC: payload.CODPAC },
      });
      dispatch(laboratorioSuccess({ viewexames: data }));
      return data;
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(laboratorioFailure());
    }
  };
};

// /viewlabresults
export const getViewLabResultsByPac = payload => {
  return async dispatch => {
    try {
      dispatch(viewlabresultsReset());
      const response = await api.get(`/viewlabresults`, {
        params: { CODPAC: payload.CODPAC },
      });
      let c = 0;
      const viewlabresults = response.data.map(lab => ({
        ...lab,
        dataExame: format(addDays(parseISO(lab.DATALABOR), 1), 'dd/MM/yyyy', {
          locale: pt,
        }),
        counter: (c += 1),
      }));

      dispatch(laboratorioSuccess({ viewlabresults }));
      return viewlabresults;
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(laboratorioFailure());
    }
  };
};

// /tabelaexames
export const getTabelaExameLabByPac = payload => {
  return async dispatch => {
    try {
      dispatch(tabelaReset());
      const result = await api.get(`/tabelaexames`, {
        params: { CODPAC: payload.CODPAC },
      });
      let c = 0;
      const tabelaexames = result.data.map(lab => ({
        ...lab,
        dataExame: format(addDays(parseISO(lab.DATALABOR), 1), 'dd/MM/yyyy', {
          locale: pt,
        }),
        counter: (c += 1),
      }));
      dispatch(laboratorioSuccess({ tabelaexames }));
      return tabelaexames;
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(laboratorioFailure());
    }
  };
};
