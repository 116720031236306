import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Nav, Navbar } from 'react-bootstrap';
import logo from '../../../assets/logo.png';
import { signOutMed } from '../../../store/modules/auth/doctor/actions';
import { signOutFunc } from '../../../store/modules/auth/funcionario/actions';
import styles from '../../../styles/styles.module.css';
import history from '../../../services/history';

export default function NavBar() {
  const profile = useSelector(state => state.doctor.profile);
  const funcionario = useSelector(state => state.funcionario.funcionario);
  const dispatch = useDispatch();

  const handleClickAutenticar = event => {
    event.preventDefault();
    window.location.assign(
      'https://certificado.vidaas.com.br/v0/oauth/authorize?lifetime=43200&scope=signature_session&client_id=ab46d834-4f16-4b89-b1a9-f231737513ad&code_challenge=E9Melhoa2OwvFrEMTJguCHaoeK1t8URWbuGJSstw-cM&code_challenge_method=S256&response_type=code&redirect_uri=http://icm-marilia.com/tokenizer'
    );
  };

  return (
    <>
      {profile && profile.tipo === 'Externo' ? (
        <Navbar bg="success" expand="lg" className={styles.navbarLink}>
          <Navbar.Brand onClick={() => history.push('/frmhome')}>
            <img src={logo} alt="logo" width={50} height={50} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link onClick={() => history.push('/frmatv')}>
                Exames
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  dispatch(signOutMed());
                  dispatch(signOutFunc());
                }}
              >
                Sair
              </Nav.Link>
            </Nav>
            <Navbar.Text>
              Usuário:{' '}
              {profile
                ? `${profile.apelido} - ${profile.valida}`
                : new Date().toDateString()}
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
      ) : (
        <Navbar bg="success" expand="lg" className={styles.navbarLink}>
          <Navbar.Brand onClick={() => history.push('/frmhome')}>
            <img src={logo} alt="logo" width={50} height={50} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Nav.Link onClick={() => history.push('/agenda')}>
                Agenda
              </Nav.Link>
              <Nav.Link onClick={() => history.push('/frmatv')}>
                Exames
              </Nav.Link>
              <Nav.Link onClick={e => handleClickAutenticar(e)}>
                Autenticar
              </Nav.Link>
              <Nav.Link onClick={() => history.push('/frmmedico')}>
                Médicos
              </Nav.Link>
              <Nav.Link
                onClick={() => {
                  dispatch(signOutMed());
                  dispatch(signOutFunc());
                }}
              >
                Sair
              </Nav.Link>
            </Nav>
            <Navbar.Text>
              Usuário: {profile ? profile?.apelido : funcionario?.nome}
            </Navbar.Text>
          </Navbar.Collapse>
        </Navbar>
      )}
    </>
  );
}
