import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthLayout from '../components/AuthLayout';
import PacienteLayout from '../components/PacienteLayout';
import MedicoLayout from '../components/MedicoLayout';
import { store } from '../store';

export default function RouteWrapper({
  component: Component,
  isPrivate = false,
  ...rest
}) {
  const { signed } = store.getState().patient;
  const { logged } = store.getState().doctor;
  const { logado } = store.getState().funcionario;

  if (!signed && !logged && !logado && isPrivate) return <Redirect to="/" />;

  if (signed && !isPrivate) return <Redirect to="/dashboardpac" />;

  let Layout;

  function setLayout() {
    if (signed) {
      Layout = signed ? PacienteLayout : AuthLayout;
      return Layout;
    }
    if (logged || logado) {
      Layout = logged || logado ? MedicoLayout : AuthLayout;
      return Layout;
    }
    if (!(logged && signed)) Layout = AuthLayout;
    return Layout;
  }

  setLayout();

  return (
    <Route
      {...rest}
      render={props => (
        <Layout>
          <Component {...props} />
        </Layout>
      )}
    />
  );
}
