import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import md5 from 'md5';
import {
  Form,
  Col,
  Button,
  Card,
  Modal,
  Collapse,
  Alert,
} from 'react-bootstrap';
import header from '../../../assets/icm-header.jpg';
import blank from '../../../assets/icm-blank.jpg';
import styles from '../../../styles/styles.module.css';
import api from '../../../services/api';
import s3 from '../../../util/s3';

export default function ReportProntuario() {
  const { prontuario } = useSelector(state => state.prontuario);
  const medico = useSelector(state => state.doctor.profile);
  const { printing } = useSelector(state => state.contexto);
  const { funcionario } = useSelector(state => state.funcionario);
  const [open, setOpen] = useState({});
  const [showBioModal, setShowBioModal] = useState(false);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(null);
  const [urls, setURLs] = useState([]);
  const [files, setFiles] = useState([]);
  const isAllowedToDeleteFiles =
    medico ||
    funcionario?.codFunc === '90035' ||
    funcionario?.codFunc === '90026' ||
    funcionario?.codFunc === '90001';

  useEffect(() => {
    s3.listObjectsV2(
      { Bucket: 'prevencor', Prefix: `bio/${prontuario.atual?.CODCONSULTA}` },
      (err, data) => {
        if (err) toast.error('Erro ao buscar arquivos. Entre em contato.');
        else
          setFiles(
            data.Contents.filter(
              item => item.Key.includes('.pdf') || item.Key.includes('.mp4')
            )
          );
      }
    );
  }, [prontuario.atual?.CODCONSULTA]);

  useEffect(() => {
    async function getFiles(f) {
      f = f.Key.split('/');

      return await api.get(
        `https://j87jesh814.execute-api.us-east-1.amazonaws.com/dev/image-upload/folder/bio/laudo/${prontuario.atual?.CODCONSULTA}/key/${f[2]}`
      );
    }

    async function showFiles() {
      const array = [];

      for (let file of files) {
        const url = await getFiles(file);
        const obj = { url, ...file }; // coloco num obj só a presigned URL e o file, pq preciso da Key para deletar depois
        array.push(obj);
      }

      setURLs(array);
    }

    showFiles();
  }, [prontuario.atual?.CODCONSULTA, files]);

  function removeDiv(divName) {
    let newdiv = document.createElement('div');
    let originalContents = document.body.innerHTML;
    newdiv.innerHTML = originalContents;
    document.getElementById(divName).remove(newdiv);
    window.print();
    document.body.innerHTML = originalContents;
  }

  function prefixo() {
    if (
      prontuario?.atual?.CODREALIZOU === 1 ||
      prontuario?.atual?.CODREALIZOU === 5 ||
      prontuario?.atual?.CODREALIZOU === 2796 ||
      prontuario?.atual?.CODREALIZOU === 958
    ) {
      return 'Prof. Dr. ';
    } else {
      return 'Dr. ';
    }
  }

  function handleClick(id) {
    setOpen(prevState => ({ ...prevState, [id]: !prevState[id] }));
  }

  async function deleteS3Object(file) {
    try {
      await s3.deleteObject({ Bucket: 'prevencor', Key: file }).promise();
      toast.success('O arquivo foi deletado com sucesso!');
      setOpenDeleteAlert(null);
      setShowBioModal(false);
    } catch (error) {
      toast.error('Erro ao deletar arquivo. Entre em contato.');
    }
  }

  return (
    <>
      <Card>
        {printing ? (
          <div className={styles.imageReport}>
            <img width="550" height="100" src={header} alt="header" />
          </div>
        ) : (
          <div className={styles.imageReport}>
            <img width="550" height="100" src={blank} alt="header" />
          </div>
        )}

        <Card.Header className="text-center">
          <Card.Title className={styles.titleReport}>
            PRONTUÁRIO ELETRÔNICO
          </Card.Title>
          <strong className={styles.titleReport}>
            {prontuario.atual?.DESCPROCEDI}
          </strong>
        </Card.Header>
        <Card.Body>
          <Form noValidate className={styles.contentReport}>
            <strong className="mr-auto">Nome do Paciente: </strong>
            {`${prontuario.atual?.CODPAC} - ${prontuario.atual?.NOMEPAC}`}
            <br></br>
            <strong className="mr-auto">Data de Nasc: </strong>
            {`${prontuario.atual?.dataNasc}`}
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Idade: </strong>
            {`${prontuario.atual?.idadePAC}  anos`}
            <br></br>
            <strong className="mr-auto">Convênio: </strong>
            {prontuario.atual?.SIGLACONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Código: </strong>
            {prontuario.atual?.CODCONSULTA}&nbsp;&nbsp;&nbsp;
            <strong className="mr-auto">Data do Prontuário: </strong>
            {prontuario.atual?.dataFormatada}
            <br></br>
            <strong className="mr-auto">
              Médico que Realizou: {prefixo()}{' '}
            </strong>
            {`${prontuario.atual?.REALIZOU}`}
            <hr></hr>
            <Form.Row>
              <Col sm>
                <Form.Group size="sm" controlId="obs">
                  <strong>Queixa Principal e História</strong>
                  <div className="row">
                    <div className="col-12">
                      {prontuario.atual?.QUEIXAS.split('\n').map((item, i) => {
                        return <div key={i}>{item}</div>;
                      })}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group size="sm" controlId="editMedicacoes">
                  <strong>Medicações em Uso</strong>
                  <div className="row">
                    <div className="col-12">
                      {prontuario.atual?.MEDICAMENTOS.split('\n').map(
                        (item, i) => {
                          return <div key={i}>{item}</div>;
                        }
                      )}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group size="sm" controlId="habitos">
                  <strong>Hábitos</strong>
                  <div className="row">
                    <div className="col-12">
                      {prontuario.atual?.HABITOS.split('\n').map((item, i) => {
                        return <div key={i}>{item}</div>;
                      })}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group controlId="editAntec">
                  <strong>ISDA</strong>
                  <div className="row">
                    <div className="col-12">
                      {prontuario.atual?.ISDA.split('\n').map((item, i) => {
                        return <div key={i}>{item}</div>;
                      })}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group size="sm" controlId="exComplementares">
                  <strong>Antecedentes Pessoais</strong>
                  <div className="row">
                    <div className="col-12">
                      {prontuario.atual?.ANTECEDENTES_PESSOAIS.split('\n').map(
                        (item, i) => {
                          return <div key={i}>{item}</div>;
                        }
                      )}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group size="sm" controlId="editObs">
                  <strong>Antecedentes Familares</strong>
                  <div className="row">
                    <div className="col-12">
                      {prontuario.atual?.ANTECEDENTES_FLIA.split('\n').map(
                        (item, i) => {
                          return <div key={i}>{item}</div>;
                        }
                      )}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group size="sm" controlId="editExame">
                  <strong>Exame Complementares</strong>
                  <div className="row">
                    <div className="col-12">
                      {prontuario.atual?.EXAMES_COMPLEMENTARES.split('\n').map(
                        (item, i) => {
                          return <div key={i}>{item}</div>;
                        }
                      )}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group size="sm" controlId="editISDA">
                  <strong>Exame Físico</strong>
                  <div className="row">
                    <div className="col-12">
                      {prontuario.atual?.EXAME_FISICO.split('\n').map(
                        (item, i) => {
                          return <div key={i}>{item}</div>;
                        }
                      )}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group size="sm" controlId="editHipotese">
                  <strong>Hipótese Diagnóstica</strong>
                  <div className="row">
                    <div className="col-12">
                      {prontuario.atual?.HIPOTESE_DIAGNOSTICA.split('\n').map(
                        (item, i) => (
                          <div key={i}>{item}</div>
                        )
                      )}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group size="sm" controlId="conduta">
                  <strong>Conduta</strong>
                  <div className="row">
                    <div className="col-12">
                      {prontuario.atual?.CONDUTA.split('\n').map((item, i) => {
                        return <div key={i}>{item}</div>;
                      })}
                    </div>
                  </div>
                </Form.Group>
                <Form.Group size="sm" controlId="exComplementares">
                  {prontuario.atual?.CID ? (
                    <>
                      <strong>Observações</strong>
                      <div className="row">
                        <div className="col-12">
                          {prontuario.atual?.OBSERVACOES.split('\n').map(
                            (item, i) => {
                              return <div key={i}>{item}</div>;
                            }
                          )}
                        </div>
                      </div>
                    </>
                  ) : null}
                </Form.Group>
              </Col>
            </Form.Row>
            <Form.Row>
              <Form.Group as={Col} controlId="cid1">
                {prontuario.atual?.CID ? (
                  <>
                    <strong>CID1</strong>
                    <Form.Control
                      plaintext
                      defaultValue={prontuario.atual.CID}
                    />{' '}
                  </>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} controlId="cid3">
                {prontuario.atual?.CID3 ? (
                  <>
                    <strong>Indicação</strong>
                    <Form.Control
                      plaintext
                      defaultValue={prontuario.atual.CID3}
                    />{' '}
                  </>
                ) : null}
              </Form.Group>
              <Form.Group as={Col} controlId="cid2">
                {prontuario.atual?.CID2 ? (
                  <>
                    <strong>CID2</strong>
                    <Form.Control
                      plaintext
                      defaultValue={prontuario.atual.CID2}
                    />{' '}
                  </>
                ) : null}
              </Form.Group>
            </Form.Row>
            <Card className="mt-3 text-center">
              <Card.Body>
                <Card.Text>
                  <i className="mr-auto">
                    Conferido e assinado eletronicamente por:
                  </i>
                  <strong className="mr-auto">
                    {' '}
                    {prefixo()} {prontuario.atual?.REALIZOU}
                  </strong>
                  <br></br>
                  <i className="mr-auto">
                    Assinatura Digital:{' '}
                    {md5(JSON.stringify(prontuario?.atual || ''))}
                  </i>
                </Card.Text>
              </Card.Body>
              <Card.Footer id="removeMe" className="mt-3 text-center">
                <>
                  <Button
                    variant="primary"
                    onClick={() => removeDiv('removeMe')}
                  >
                    Imprimir
                  </Button>{' '}
                  <Button
                    variant="success"
                    onClick={() => setShowBioModal(true)}
                  >
                    Ver Bioimpedância
                  </Button>
                </>
              </Card.Footer>
            </Card>
          </Form>
        </Card.Body>
      </Card>

      {showBioModal && (
        <Modal
          show={showBioModal}
          onHide={() => setShowBioModal(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Bioimpedância</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {urls.map((url, idx) => {
              if (isAllowedToDeleteFiles) {
                return (
                  <div>
                    <Card>
                      <Alert
                        show={openDeleteAlert === url.Key}
                        variant="danger"
                        onClose={() => setOpenDeleteAlert(null)}
                        dismissible
                      >
                        <Alert.Heading>
                          Você tem certeza que quer deletar a imagem?
                        </Alert.Heading>
                        <hr />
                        <div className="d-flex justify-content-">
                          <Button
                            onClick={() => deleteS3Object(url.Key)}
                            variant="danger"
                          >
                            Confirmar
                          </Button>
                        </div>
                      </Alert>
                      <div class="btn-group">
                        <button
                          class="btn btn-primary"
                          onClick={() => handleClick(url.url.data.preSignedUrl)}
                          aria-controls="example-collapse-text"
                          aria-expanded={open}
                          style={{ color: 'white' }}
                        >
                          Imagem {idx + 1}
                        </button>
                        <button
                          class="btn btn-danger"
                          onClick={() => setOpenDeleteAlert(url.Key)}
                          style={{ color: 'white' }}
                        >
                          Deletar
                        </button>
                      </div>
                    </Card>
                    <Collapse in={open[url.url.data.preSignedUrl]}>
                      <div
                        key={url.url.data.preSignedUrl}
                        id="collapse-div"
                        className="collapsedText"
                      >
                        {url.url.data.preSignedUrl.includes('.webm') ||
                        url.url.data.preSignedUrl.includes('.mp4') ? (
                          <div
                            style={{ textAlign: 'center', display: 'block' }}
                          >
                            <video
                              id="video-laudo"
                              width="500"
                              height="500"
                              controls
                            >
                              <source
                                src={url.url.data.preSignedUrl}
                                type={
                                  url.url.data.preSignedUrl.endsWith('.mp4')
                                    ? 'video/mp4'
                                    : 'video/webm'
                                }
                              />
                            </video>
                          </div>
                        ) : (
                          <div
                            style={{ textAlign: 'center', display: 'block' }}
                          >
                            <embed
                              src={url.url.data.preSignedUrl}
                              width="600px"
                              height="900px"
                            />
                          </div>
                        )}
                      </div>
                    </Collapse>
                  </div>
                );
              } else {
                return (
                  <div>
                    <Card>
                      <div class="btn-group">
                        <button
                          class="btn btn-primary"
                          onClick={() => handleClick(url.url.data.preSignedUrl)}
                          aria-controls="example-collapse-text"
                          aria-expanded={open}
                          style={{ color: 'white' }}
                        >
                          Imagem {idx + 1}
                        </button>
                      </div>
                    </Card>
                    <Collapse in={open[url.url.data.preSignedUrl]}>
                      <div
                        key={url.url.data.preSignedUrl}
                        id="collapse-div"
                        className="collapsedText"
                      >
                        {url.url.data.preSignedUrl.includes('.webm') ||
                        url.url.data.preSignedUrl.includes('.mp4') ? (
                          <div
                            style={{ textAlign: 'center', display: 'block' }}
                          >
                            <video
                              id="video-laudo"
                              width="500"
                              height="500"
                              controls
                            >
                              <source
                                src={url.url.data.preSignedUrl}
                                type={
                                  url.url.data.preSignedUrl.endsWith('.mp4')
                                    ? 'video/mp4'
                                    : 'video/webm'
                                }
                              />
                            </video>
                          </div>
                        ) : (
                          <div
                            style={{ textAlign: 'center', display: 'block' }}
                          >
                            <embed
                              src={url.url.data.preSignedUrl}
                              width="600px"
                              height="900px"
                            />
                          </div>
                        )}
                      </div>
                    </Collapse>
                  </div>
                );
              }
            })}
          </Modal.Body>
        </Modal>
      )}
    </>
  );
}
