const profissoes = [
  {
    value: 'ABASTECEDOR DE LINHA DE PRODUÇÃO',
    label: 'ABASTECEDOR DE LINHA DE PRODUÇÃO',
  },
  {
    value: 'ABASTECEDOR DE MÁQUINAS',
    label: 'ABASTECEDOR DE MÁQUINAS',
  },
  {
    value: 'ACABADOR DE EMBALAGENS',
    label: 'ACABADOR DE EMBALAGENS',
  },
  {
    value: 'ACRILISTA',
    label: 'ACRILISTA',
  },
  {
    value: 'ACUPUNTURISTA',
    label: 'ACUPUNTURISTA',
  },
  {
    value: 'ADERECISTA',
    label: 'ADERECISTA',
  },
  {
    value: 'ADESIVADOR',
    label: 'ADESIVADOR',
  },
  {
    value: 'ADESTRADOR',
    label: 'ADESTRADOR',
  },
  {
    value: 'ADMINISTRADOR DE CONTRATOS',
    label: 'ADMINISTRADOR DE CONTRATOS',
  },
  {
    value: 'ADMINISTRADOR DE DADOS',
    label: 'ADMINISTRADOR DE DADOS',
  },
  {
    value: 'ADMINISTRADOR DE E-COMMERCE',
    label: 'ADMINISTRADOR DE E-COMMERCE',
  },
  {
    value: 'ADMINISTRADOR DE EMPRESAS',
    label: 'ADMINISTRADOR DE EMPRESAS',
  },
  {
    value: 'ADMINISTRADOR DE SISTEMAS',
    label: 'ADMINISTRADOR DE SISTEMAS',
  },
  {
    value: 'ADMINISTRADOR FINANCEIRO',
    label: 'ADMINISTRADOR FINANCEIRO',
  },
  {
    value: 'ADMINISTRADOR HOSPITALAR',
    label: 'ADMINISTRADOR HOSPITALAR',
  },
  {
    value: 'ADMINISTRADOR PREDIAL',
    label: 'ADMINISTRADOR PREDIAL',
  },
  {
    value: 'ADMINISTRADOR',
    label: 'ADMINISTRADOR',
  },
  {
    value: 'ADMINISTRATIVO DE OBRAS',
    label: 'ADMINISTRATIVO DE OBRAS',
  },
  {
    value: 'ADVOGADO ADMINISTRATIVO',
    label: 'ADVOGADO ADMINISTRATIVO',
  },
  {
    value: 'ADVOGADO AMBIENTALISTA',
    label: 'ADVOGADO AMBIENTALISTA',
  },
  {
    value: 'ADVOGADO CONSUMERISTA',
    label: 'ADVOGADO CONSUMERISTA',
  },
  {
    value: 'ADVOGADO CRIMINALISTA',
    label: 'ADVOGADO CRIMINALISTA',
  },
  {
    value: 'ADVOGADO CÍVEL',
    label: 'ADVOGADO CÍVEL',
  },
  {
    value: 'ADVOGADO DE CONTRATOS',
    label: 'ADVOGADO DE CONTRATOS',
  },
  {
    value: 'ADVOGADO DE PROPRIEDADE INTELECTUAL',
    label: 'ADVOGADO DE PROPRIEDADE INTELECTUAL',
  },
  {
    value: 'ADVOGADO ELEITORAL',
    label: 'ADVOGADO ELEITORAL',
  },
  {
    value: 'ADVOGADO EMPRESARIAL',
    label: 'ADVOGADO EMPRESARIAL',
  },
  {
    value: 'ADVOGADO IMOBILIÁRIO',
    label: 'ADVOGADO IMOBILIÁRIO',
  },
  {
    value: 'ADVOGADO SECURITÁRIO',
    label: 'ADVOGADO SECURITÁRIO',
  },
  {
    value: 'ADVOGADO SOCIETÁRIO',
    label: 'ADVOGADO SOCIETÁRIO',
  },
  {
    value: 'ADVOGADO TRABALHISTA',
    label: 'ADVOGADO TRABALHISTA',
  },
  {
    value: 'ADVOGADO TRIBUTARISTA',
    label: 'ADVOGADO TRIBUTARISTA',
  },
  {
    value: 'ADVOGADO',
    label: 'ADVOGADO',
  },
  {
    value: 'AFIADOR DE FERRAMENTAS',
    label: 'AFIADOR DE FERRAMENTAS',
  },
  {
    value: 'AGENTE DE AEROPORTO',
    label: 'AGENTE DE AEROPORTO',
  },
  {
    value: 'AGENTE DE SEGURANÇA',
    label: 'AGENTE DE SEGURANÇA',
  },
  {
    value: 'AGENTE DE SERVIÇOS',
    label: 'AGENTE DE SERVIÇOS',
  },
  {
    value: 'AGENTE DE VIAGENS',
    label: 'AGENTE DE VIAGENS',
  },
  {
    value: 'AGENTE FUNERÁRIO',
    label: 'AGENTE FUNERÁRIO',
  },
  {
    value: 'AJUDANTE DE CALDEIRARIA',
    label: 'AJUDANTE DE CALDEIRARIA',
  },
  {
    value: 'AJUDANTE DE CARGA E DESCARGA',
    label: 'AJUDANTE DE CARGA E DESCARGA',
  },
  {
    value: 'AJUDANTE DE CARPINTEIRO',
    label: 'AJUDANTE DE CARPINTEIRO',
  },
  {
    value: 'AJUDANTE DE CHURRASQUEIRO',
    label: 'AJUDANTE DE CHURRASQUEIRO',
  },
  {
    value: 'AJUDANTE DE DESPACHANTE ADUANEIRO',
    label: 'AJUDANTE DE DESPACHANTE ADUANEIRO',
  },
  {
    value: 'AJUDANTE DE ENCANADOR',
    label: 'AJUDANTE DE ENCANADOR',
  },
  {
    value: 'AJUDANTE DE ESTAMPARIA',
    label: 'AJUDANTE DE ESTAMPARIA',
  },
  {
    value: 'AJUDANTE DE FUNDIÇÃO',
    label: 'AJUDANTE DE FUNDIÇÃO',
  },
  {
    value: 'AJUDANTE DE FUNILARIA',
    label: 'AJUDANTE DE FUNILARIA',
  },
  {
    value: 'AJUDANTE DE INSTALAÇÃO',
    label: 'AJUDANTE DE INSTALAÇÃO',
  },
  {
    value: 'AJUDANTE DE LUBRIFICAÇÃO',
    label: 'AJUDANTE DE LUBRIFICAÇÃO',
  },
  {
    value: 'AJUDANTE DE MARCENEIRO',
    label: 'AJUDANTE DE MARCENEIRO',
  },
  {
    value: 'AJUDANTE DE METALÚRGICO',
    label: 'AJUDANTE DE METALÚRGICO',
  },
  {
    value: 'AJUDANTE DE MOTORISTA',
    label: 'AJUDANTE DE MOTORISTA',
  },
  {
    value: 'AJUDANTE DE OBRAS',
    label: 'AJUDANTE DE OBRAS',
  },
  {
    value: 'AJUDANTE DE OFF-SET',
    label: 'AJUDANTE DE OFF-SET',
  },
  {
    value: 'AJUDANTE DE PIZZAIOLO',
    label: 'AJUDANTE DE PIZZAIOLO',
  },
  {
    value: 'AJUDANTE DE SERRALHEIRO',
    label: 'AJUDANTE DE SERRALHEIRO',
  },
  {
    value: 'AJUDANTE DE TAPECEIRO',
    label: 'AJUDANTE DE TAPECEIRO',
  },
  {
    value: 'AJUDANTE DE VIDRACEIRO',
    label: 'AJUDANTE DE VIDRACEIRO',
  },
  {
    value: 'AJUDANTE GERAL',
    label: 'AJUDANTE GERAL',
  },
  {
    value: 'AJUDANTE OPERACIONAL',
    label: 'AJUDANTE OPERACIONAL',
  },
  {
    value: 'AJUSTADOR MECÂNICO',
    label: 'AJUSTADOR MECÂNICO',
  },
  {
    value: 'ALFAIATE',
    label: 'ALFAIATE',
  },
  {
    value: 'ALINHADOR',
    label: 'ALINHADOR',
  },
  {
    value: 'ALMOXARIFE',
    label: 'ALMOXARIFE',
  },
  {
    value: 'ALPINISTA INDUSTRIAL',
    label: 'ALPINISTA INDUSTRIAL',
  },
  {
    value: 'AMARRADOR',
    label: 'AMARRADOR',
  },
  {
    value: 'ANALISTA ADMINISTRATIVO DE OBRAS',
    label: 'ANALISTA ADMINISTRATIVO DE OBRAS',
  },
  {
    value: 'ANALISTA ADMINISTRATIVO',
    label: 'ANALISTA ADMINISTRATIVO',
  },
  {
    value: 'ANALISTA AMBIENTAL',
    label: 'ANALISTA AMBIENTAL',
  },
  {
    value: 'ANALISTA COMERCIAL',
    label: 'ANALISTA COMERCIAL',
  },
  {
    value: 'ANALISTA CONTÁBIL',
    label: 'ANALISTA CONTÁBIL',
  },
  {
    value: 'ANALISTA DE ALMOXARIFADO',
    label: 'ANALISTA DE ALMOXARIFADO',
  },
  {
    value: 'ANALISTA DE APURAÇÃO DE RESULTADOS',
    label: 'ANALISTA DE APURAÇÃO DE RESULTADOS',
  },
  {
    value: 'ANALISTA DE ARQUITETURA',
    label: 'ANALISTA DE ARQUITETURA',
  },
  {
    value: 'ANALISTA DE ASSISTÊNCIA TÉCNICA',
    label: 'ANALISTA DE ASSISTÊNCIA TÉCNICA',
  },
  {
    value: 'ANALISTA DE ASSUNTOS REGULATÓRIOS',
    label: 'ANALISTA DE ASSUNTOS REGULATÓRIOS',
  },
  {
    value: 'ANALISTA DE ATENDIMENTO',
    label: 'ANALISTA DE ATENDIMENTO',
  },
  {
    value: 'ANALISTA DE ATIVO FIXO',
    label: 'ANALISTA DE ATIVO FIXO',
  },
  {
    value: 'ANALISTA DE AUDITORIA',
    label: 'ANALISTA DE AUDITORIA',
  },
  {
    value: 'ANALISTA DE AUTOMAÇÃO',
    label: 'ANALISTA DE AUTOMAÇÃO',
  },
  {
    value: 'ANALISTA DE BACK OFFICE',
    label: 'ANALISTA DE BACK OFFICE',
  },
  {
    value: 'ANALISTA DE BANCO DE DADOS',
    label: 'ANALISTA DE BANCO DE DADOS',
  },
  {
    value: 'ANALISTA DE BENEFÍCIOS',
    label: 'ANALISTA DE BENEFÍCIOS',
  },
  {
    value: 'ANALISTA DE BUSINESS INTELLIGENCE',
    label: 'ANALISTA DE BUSINESS INTELLIGENCE',
  },
  {
    value: 'ANALISTA DE CADASTRO',
    label: 'ANALISTA DE CADASTRO',
  },
  {
    value: 'ANALISTA DE CAMPO',
    label: 'ANALISTA DE CAMPO',
  },
  {
    value: 'ANALISTA DE CAPTAÇÃO DE RECURSOS',
    label: 'ANALISTA DE CAPTAÇÃO DE RECURSOS',
  },
  {
    value: 'ANALISTA DE CARGOS E SALÁRIOS',
    label: 'ANALISTA DE CARGOS E SALÁRIOS',
  },
  {
    value: 'ANALISTA DE COBRANÇA',
    label: 'ANALISTA DE COBRANÇA',
  },
  {
    value: 'ANALISTA DE COMPLIANCE',
    label: 'ANALISTA DE COMPLIANCE',
  },
  {
    value: 'ANALISTA DE COMPRAS',
    label: 'ANALISTA DE COMPRAS',
  },
  {
    value: 'ANALISTA DE COMUNICAÇÃO E MARKETING',
    label: 'ANALISTA DE COMUNICAÇÃO E MARKETING',
  },
  {
    value: 'ANALISTA DE COMUNICAÇÃO INTERNA',
    label: 'ANALISTA DE COMUNICAÇÃO INTERNA',
  },
  {
    value: 'ANALISTA DE COMUNICAÇÃO',
    label: 'ANALISTA DE COMUNICAÇÃO',
  },
  {
    value: 'ANALISTA DE COMÉRCIO EXTERIOR',
    label: 'ANALISTA DE COMÉRCIO EXTERIOR',
  },
  {
    value: 'ANALISTA DE CONTAS A PAGAR E RECEBER',
    label: 'ANALISTA DE CONTAS A PAGAR E RECEBER',
  },
  {
    value: 'ANALISTA DE CONTAS A PAGAR',
    label: 'ANALISTA DE CONTAS A PAGAR',
  },
  {
    value: 'ANALISTA DE CONTAS A RECEBER',
    label: 'ANALISTA DE CONTAS A RECEBER',
  },
  {
    value: 'ANALISTA DE CONTAS MÉDICAS',
    label: 'ANALISTA DE CONTAS MÉDICAS',
  },
  {
    value: 'ANALISTA DE CONTAS',
    label: 'ANALISTA DE CONTAS',
  },
  {
    value: 'ANALISTA DE CONTEÚDO',
    label: 'ANALISTA DE CONTEÚDO',
  },
  {
    value: 'ANALISTA DE CONTRATOS',
    label: 'ANALISTA DE CONTRATOS',
  },
  {
    value: 'ANALISTA DE CONTROL DESK',
    label: 'ANALISTA DE CONTROL DESK',
  },
  {
    value: 'ANALISTA DE CONTROLADORIA',
    label: 'ANALISTA DE CONTROLADORIA',
  },
  {
    value: 'ANALISTA DE CONTROLE DE QUALIDADE',
    label: 'ANALISTA DE CONTROLE DE QUALIDADE',
  },
  {
    value: 'ANALISTA DE CONTROLES INTERNOS',
    label: 'ANALISTA DE CONTROLES INTERNOS',
  },
  {
    value: 'ANALISTA DE CREDENCIAMENTO',
    label: 'ANALISTA DE CREDENCIAMENTO',
  },
  {
    value: 'ANALISTA DE CRIAÇÃO',
    label: 'ANALISTA DE CRIAÇÃO',
  },
  {
    value: 'ANALISTA DE CRM',
    label: 'ANALISTA DE CRM',
  },
  {
    value: 'ANALISTA DE CRÉDITO E COBRANÇA',
    label: 'ANALISTA DE CRÉDITO E COBRANÇA',
  },
  {
    value: 'ANALISTA DE CRÉDITO IMOBILIÁRIO',
    label: 'ANALISTA DE CRÉDITO IMOBILIÁRIO',
  },
  {
    value: 'ANALISTA DE CRÉDITO',
    label: 'ANALISTA DE CRÉDITO',
  },
  {
    value: 'ANALISTA DE CUSTOMER SERVICE',
    label: 'ANALISTA DE CUSTOMER SERVICE',
  },
  {
    value: 'ANALISTA DE CUSTOS',
    label: 'ANALISTA DE CUSTOS',
  },
  {
    value: 'ANALISTA DE CÂMBIO',
    label: 'ANALISTA DE CÂMBIO',
  },
  {
    value: 'ANALISTA DE DBM',
    label: 'ANALISTA DE DBM',
  },
  {
    value: 'ANALISTA DE DEPARTAMENTO PESSOAL',
    label: 'ANALISTA DE DEPARTAMENTO PESSOAL',
  },
  {
    value: 'ANALISTA DE DESEMBARAÇO ADUANEIRO',
    label: 'ANALISTA DE DESEMBARAÇO ADUANEIRO',
  },
  {
    value: 'ANALISTA DE DESENVOLVIMENTO ANALÍTICO',
    label: 'ANALISTA DE DESENVOLVIMENTO ANALÍTICO',
  },
  {
    value: 'ANALISTA DE DOCUMENTAÇÃO',
    label: 'ANALISTA DE DOCUMENTAÇÃO',
  },
  {
    value: 'ANALISTA DE E-COMMERCE',
    label: 'ANALISTA DE E-COMMERCE',
  },
  {
    value: 'ANALISTA DE ENDOMARKETING',
    label: 'ANALISTA DE ENDOMARKETING',
  },
  {
    value: 'ANALISTA DE ENGENHARIA DE PRODUTO',
    label: 'ANALISTA DE ENGENHARIA DE PRODUTO',
  },
  {
    value: 'ANALISTA DE ENGENHARIA',
    label: 'ANALISTA DE ENGENHARIA',
  },
  {
    value: 'ANALISTA DE ESTOQUE',
    label: 'ANALISTA DE ESTOQUE',
  },
  {
    value: 'ANALISTA DE ESTRATÉGIA DE MERCADO',
    label: 'ANALISTA DE ESTRATÉGIA DE MERCADO',
  },
  {
    value: 'ANALISTA DE EVENTOS',
    label: 'ANALISTA DE EVENTOS',
  },
  {
    value: 'ANALISTA DE EXPATRIADOS',
    label: 'ANALISTA DE EXPATRIADOS',
  },
  {
    value: 'ANALISTA DE EXPEDIÇÃO',
    label: 'ANALISTA DE EXPEDIÇÃO',
  },
  {
    value: 'ANALISTA DE FACILITIES',
    label: 'ANALISTA DE FACILITIES',
  },
  {
    value: 'ANALISTA DE FARMACOVIGILÂNCIA',
    label: 'ANALISTA DE FARMACOVIGILÂNCIA',
  },
  {
    value: 'ANALISTA DE FATURAMENTO',
    label: 'ANALISTA DE FATURAMENTO',
  },
  {
    value: 'ANALISTA DE FOLHA DE PAGAMENTO',
    label: 'ANALISTA DE FOLHA DE PAGAMENTO',
  },
  {
    value: 'ANALISTA DE FRANQUIAS',
    label: 'ANALISTA DE FRANQUIAS',
  },
  {
    value: 'ANALISTA DE FROTA',
    label: 'ANALISTA DE FROTA',
  },
  {
    value: 'ANALISTA DE FUSÕES E AQUISIÇÕES',
    label: 'ANALISTA DE FUSÕES E AQUISIÇÕES',
  },
  {
    value: 'ANALISTA DE GARANTIA DA QUALIDADE',
    label: 'ANALISTA DE GARANTIA DA QUALIDADE',
  },
  {
    value: 'ANALISTA DE GARANTIA',
    label: 'ANALISTA DE GARANTIA',
  },
  {
    value: 'ANALISTA DE GEOPROCESSAMENTO',
    label: 'ANALISTA DE GEOPROCESSAMENTO',
  },
  {
    value: 'ANALISTA DE GESTÃO DE INCIDENTES',
    label: 'ANALISTA DE GESTÃO DE INCIDENTES',
  },
  {
    value: 'ANALISTA DE GOVERNANÇA DE TI',
    label: 'ANALISTA DE GOVERNANÇA DE TI',
  },
  {
    value: 'ANALISTA DE HELP DESK',
    label: 'ANALISTA DE HELP DESK',
  },
  {
    value: 'ANALISTA DE IMPLANTAÇÃO',
    label: 'ANALISTA DE IMPLANTAÇÃO',
  },
  {
    value: 'ANALISTA DE INCORPORAÇÃO',
    label: 'ANALISTA DE INCORPORAÇÃO',
  },
  {
    value: 'ANALISTA DE INDICADORES',
    label: 'ANALISTA DE INDICADORES',
  },
  {
    value: 'ANALISTA DE INFORMAÇÃO',
    label: 'ANALISTA DE INFORMAÇÃO',
  },
  {
    value: 'ANALISTA DE INFORMAÇÕES GERENCIAIS',
    label: 'ANALISTA DE INFORMAÇÕES GERENCIAIS',
  },
  {
    value: 'ANALISTA DE INFRAESTRUTURA',
    label: 'ANALISTA DE INFRAESTRUTURA',
  },
  {
    value: 'ANALISTA DE INTELIGÊNCIA COMERCIAL',
    label: 'ANALISTA DE INTELIGÊNCIA COMERCIAL',
  },
  {
    value: 'ANALISTA DE INTELIGÊNCIA DE MERCADO',
    label: 'ANALISTA DE INTELIGÊNCIA DE MERCADO',
  },
  {
    value: 'ANALISTA DE INVENTÁRIO',
    label: 'ANALISTA DE INVENTÁRIO',
  },
  {
    value: 'ANALISTA DE INVESTIMENTOS',
    label: 'ANALISTA DE INVESTIMENTOS',
  },
  {
    value: 'ANALISTA DE LABORATÓRIO',
    label: 'ANALISTA DE LABORATÓRIO',
  },
  {
    value: 'ANALISTA DE LEGALIZAÇÃO',
    label: 'ANALISTA DE LEGALIZAÇÃO',
  },
  {
    value: 'ANALISTA DE LICITAÇÃO',
    label: 'ANALISTA DE LICITAÇÃO',
  },
  {
    value: 'ANALISTA DE LOGÍSTICA',
    label: 'ANALISTA DE LOGÍSTICA',
  },
  {
    value: 'ANALISTA DE MANUTENÇÃO',
    label: 'ANALISTA DE MANUTENÇÃO',
  },
  {
    value: 'ANALISTA DE MARCAS E PATENTES',
    label: 'ANALISTA DE MARCAS E PATENTES',
  },
  {
    value: 'ANALISTA DE MARKETING DIGITAL',
    label: 'ANALISTA DE MARKETING DIGITAL',
  },
  {
    value: 'ANALISTA DE MARKETING ONLINE',
    label: 'ANALISTA DE MARKETING ONLINE',
  },
  {
    value: 'ANALISTA DE MARKETING',
    label: 'ANALISTA DE MARKETING',
  },
  {
    value: 'ANALISTA DE MELHORIA CONTÍNUA',
    label: 'ANALISTA DE MELHORIA CONTÍNUA',
  },
  {
    value: 'ANALISTA DE MERCADO',
    label: 'ANALISTA DE MERCADO',
  },
  {
    value: 'ANALISTA DE MERCHANDISING',
    label: 'ANALISTA DE MERCHANDISING',
  },
  {
    value: 'ANALISTA DE MIS',
    label: 'ANALISTA DE MIS',
  },
  {
    value: 'ANALISTA DE MONITORAMENTO',
    label: 'ANALISTA DE MONITORAMENTO',
  },
  {
    value: 'ANALISTA DE MÉTRICAS',
    label: 'ANALISTA DE MÉTRICAS',
  },
  {
    value: 'ANALISTA DE MÍDIA ONLINE',
    label: 'ANALISTA DE MÍDIA ONLINE',
  },
  {
    value: 'ANALISTA DE MÍDIA',
    label: 'ANALISTA DE MÍDIA',
  },
  {
    value: 'ANALISTA DE MÍDIAS SOCIAIS',
    label: 'ANALISTA DE MÍDIAS SOCIAIS',
  },
  {
    value: 'ANALISTA DE NEGÓCIOS DE TI',
    label: 'ANALISTA DE NEGÓCIOS DE TI',
  },
  {
    value: 'ANALISTA DE NEGÓCIOS',
    label: 'ANALISTA DE NEGÓCIOS',
  },
  {
    value: 'ANALISTA DE OPERAÇÕES',
    label: 'ANALISTA DE OPERAÇÕES',
  },
  {
    value: 'ANALISTA DE OUTSOURCING',
    label: 'ANALISTA DE OUTSOURCING',
  },
  {
    value: 'ANALISTA DE OUVIDORIA',
    label: 'ANALISTA DE OUVIDORIA',
  },
  {
    value: 'ANALISTA DE PATRIMÔNIO',
    label: 'ANALISTA DE PATRIMÔNIO',
  },
  {
    value: 'ANALISTA DE PCM',
    label: 'ANALISTA DE PCM',
  },
  {
    value: 'ANALISTA DE PCP',
    label: 'ANALISTA DE PCP',
  },
  {
    value: 'ANALISTA DE PESQUISA DE MERCADO',
    label: 'ANALISTA DE PESQUISA DE MERCADO',
  },
  {
    value: 'ANALISTA DE PLANEJAMENTO ESTRATÉGICO',
    label: 'ANALISTA DE PLANEJAMENTO ESTRATÉGICO',
  },
  {
    value: 'ANALISTA DE PLANEJAMENTO FINANCEIRO',
    label: 'ANALISTA DE PLANEJAMENTO FINANCEIRO',
  },
  {
    value: 'ANALISTA DE PLANEJAMENTO',
    label: 'ANALISTA DE PLANEJAMENTO',
  },
  {
    value: 'ANALISTA DE PREVENÇÃO DE PERDAS',
    label: 'ANALISTA DE PREVENÇÃO DE PERDAS',
  },
  {
    value: 'ANALISTA DE PRICING',
    label: 'ANALISTA DE PRICING',
  },
  {
    value: 'ANALISTA DE PROCESSOS E QUALIDADE',
    label: 'ANALISTA DE PROCESSOS E QUALIDADE',
  },
  {
    value: 'ANALISTA DE PROCESSOS GERENCIAIS',
    label: 'ANALISTA DE PROCESSOS GERENCIAIS',
  },
  {
    value: 'ANALISTA DE PROCESSOS INDUSTRIAIS',
    label: 'ANALISTA DE PROCESSOS INDUSTRIAIS',
  },
  {
    value: 'ANALISTA DE PROCESSOS',
    label: 'ANALISTA DE PROCESSOS',
  },
  {
    value: 'ANALISTA DE PRODUTO',
    label: 'ANALISTA DE PRODUTO',
  },
  {
    value: 'ANALISTA DE PRODUÇÃO',
    label: 'ANALISTA DE PRODUÇÃO',
  },
  {
    value: 'ANALISTA DE PROJETOS',
    label: 'ANALISTA DE PROJETOS',
  },
  {
    value: 'ANALISTA DE PUBLICIDADE E PROPAGANDA',
    label: 'ANALISTA DE PUBLICIDADE E PROPAGANDA',
  },
  {
    value: 'ANALISTA DE PÓS-VENDA',
    label: 'ANALISTA DE PÓS-VENDA',
  },
  {
    value: 'ANALISTA DE QSMS',
    label: 'ANALISTA DE QSMS',
  },
  {
    value: 'ANALISTA DE QUALIDADE',
    label: 'ANALISTA DE QUALIDADE',
  },
  {
    value: 'ANALISTA DE RECRUTAMENTO E SELEÇÃO',
    label: 'ANALISTA DE RECRUTAMENTO E SELEÇÃO',
  },
  {
    value: 'ANALISTA DE RECURSOS HUMANOS',
    label: 'ANALISTA DE RECURSOS HUMANOS',
  },
  {
    value: 'ANALISTA DE REDES SOCIAIS',
    label: 'ANALISTA DE REDES SOCIAIS',
  },
  {
    value: 'ANALISTA DE REDES',
    label: 'ANALISTA DE REDES',
  },
  {
    value: 'ANALISTA DE RELACIONAMENTO',
    label: 'ANALISTA DE RELACIONAMENTO',
  },
  {
    value: 'ANALISTA DE RELATÓRIOS',
    label: 'ANALISTA DE RELATÓRIOS',
  },
  {
    value: 'ANALISTA DE RELAÇÕES INSTITUCIONAIS',
    label: 'ANALISTA DE RELAÇÕES INSTITUCIONAIS',
  },
  {
    value: 'ANALISTA DE RELAÇÕES INTERNACIONAIS',
    label: 'ANALISTA DE RELAÇÕES INTERNACIONAIS',
  },
  {
    value: 'ANALISTA DE RELAÇÕES TRABALHISTAS',
    label: 'ANALISTA DE RELAÇÕES TRABALHISTAS',
  },
  {
    value: 'ANALISTA DE REMUNERAÇÃO',
    label: 'ANALISTA DE REMUNERAÇÃO',
  },
  {
    value: 'ANALISTA DE REQUISITOS',
    label: 'ANALISTA DE REQUISITOS',
  },
  {
    value: 'ANALISTA DE RESPONSABILIDADE SOCIAL',
    label: 'ANALISTA DE RESPONSABILIDADE SOCIAL',
  },
  {
    value: 'ANALISTA DE RETENÇÃO',
    label: 'ANALISTA DE RETENÇÃO',
  },
  {
    value: 'ANALISTA DE RISCO',
    label: 'ANALISTA DE RISCO',
  },
  {
    value: 'ANALISTA DE SAC',
    label: 'ANALISTA DE SAC',
  },
  {
    value: 'ANALISTA DE SEGURANÇA DA INFORMAÇÃO',
    label: 'ANALISTA DE SEGURANÇA DA INFORMAÇÃO',
  },
  {
    value: 'ANALISTA DE SEGURANÇA DO TRABALHO',
    label: 'ANALISTA DE SEGURANÇA DO TRABALHO',
  },
  {
    value: 'ANALISTA DE SEGURANÇA PATRIMONIAL',
    label: 'ANALISTA DE SEGURANÇA PATRIMONIAL',
  },
  {
    value: 'ANALISTA DE SEGUROS',
    label: 'ANALISTA DE SEGUROS',
  },
  {
    value: 'ANALISTA DE SEM',
    label: 'ANALISTA DE SEM',
  },
  {
    value: 'ANALISTA DE SEO',
    label: 'ANALISTA DE SEO',
  },
  {
    value: 'ANALISTA DE SERVICE DESK',
    label: 'ANALISTA DE SERVICE DESK',
  },
  {
    value: 'ANALISTA DE SINISTRO',
    label: 'ANALISTA DE SINISTRO',
  },
  {
    value: 'ANALISTA DE SISTEMA DE GESTÃO INTEGRADA',
    label: 'ANALISTA DE SISTEMA DE GESTÃO INTEGRADA',
  },
  {
    value: 'ANALISTA DE SISTEMAS .NET',
    label: 'ANALISTA DE SISTEMAS .NET',
  },
  {
    value: 'ANALISTA DE SISTEMAS COBOL',
    label: 'ANALISTA DE SISTEMAS COBOL',
  },
  {
    value: 'ANALISTA DE SISTEMAS DELPHI',
    label: 'ANALISTA DE SISTEMAS DELPHI',
  },
  {
    value: 'ANALISTA DE SISTEMAS ERP',
    label: 'ANALISTA DE SISTEMAS ERP',
  },
  {
    value: 'ANALISTA DE SISTEMAS JAVA',
    label: 'ANALISTA DE SISTEMAS JAVA',
  },
  {
    value: 'ANALISTA DE SISTEMAS LINUX',
    label: 'ANALISTA DE SISTEMAS LINUX',
  },
  {
    value: 'ANALISTA DE SISTEMAS MAINFRAME',
    label: 'ANALISTA DE SISTEMAS MAINFRAME',
  },
  {
    value: 'ANALISTA DE SISTEMAS MICROSIGA',
    label: 'ANALISTA DE SISTEMAS MICROSIGA',
  },
  {
    value: 'ANALISTA DE SISTEMAS ORACLE',
    label: 'ANALISTA DE SISTEMAS ORACLE',
  },
  {
    value: 'ANALISTA DE SISTEMAS PHP',
    label: 'ANALISTA DE SISTEMAS PHP',
  },
  {
    value: 'ANALISTA DE SISTEMAS PL SQL',
    label: 'ANALISTA DE SISTEMAS PL SQL',
  },
  {
    value: 'ANALISTA DE SISTEMAS SHAREPOINT',
    label: 'ANALISTA DE SISTEMAS SHAREPOINT',
  },
  {
    value: 'ANALISTA DE SISTEMAS SQL',
    label: 'ANALISTA DE SISTEMAS SQL',
  },
  {
    value: 'ANALISTA DE SISTEMAS WEB',
    label: 'ANALISTA DE SISTEMAS WEB',
  },
  {
    value: 'ANALISTA DE SISTEMAS',
    label: 'ANALISTA DE SISTEMAS',
  },
  {
    value: 'ANALISTA DE SISTEMAS',
    label: 'ANALISTA DE SISTEMAS',
  },
  {
    value: 'ANALISTA DE SUBSCRIÇÃO',
    label: 'ANALISTA DE SUBSCRIÇÃO',
  },
  {
    value: 'ANALISTA DE SUPORTE MAINFRAME',
    label: 'ANALISTA DE SUPORTE MAINFRAME',
  },
  {
    value: 'ANALISTA DE SUPORTE MIDDLEWARE',
    label: 'ANALISTA DE SUPORTE MIDDLEWARE',
  },
  {
    value: 'ANALISTA DE SUPORTE TÉCNICO',
    label: 'ANALISTA DE SUPORTE TÉCNICO',
  },
  {
    value: 'ANALISTA DE SUPORTE WEBSPHERE',
    label: 'ANALISTA DE SUPORTE WEBSPHERE',
  },
  {
    value: 'ANALISTA DE SUPRIMENTOS',
    label: 'ANALISTA DE SUPRIMENTOS',
  },
  {
    value: 'ANALISTA DE SUSTENTABILIDADE',
    label: 'ANALISTA DE SUSTENTABILIDADE',
  },
  {
    value: 'ANALISTA DE TECNOLOGIA DA INFORMAÇÃO',
    label: 'ANALISTA DE TECNOLOGIA DA INFORMAÇÃO',
  },
  {
    value: 'ANALISTA DE TELECOMUNICAÇÕES',
    label: 'ANALISTA DE TELECOMUNICAÇÕES',
  },
  {
    value: 'ANALISTA DE TESOURARIA',
    label: 'ANALISTA DE TESOURARIA',
  },
  {
    value: 'ANALISTA DE TESTES',
    label: 'ANALISTA DE TESTES',
  },
  {
    value: 'ANALISTA DE TRADE MARKETING',
    label: 'ANALISTA DE TRADE MARKETING',
  },
  {
    value: 'ANALISTA DE TRANSPORTES',
    label: 'ANALISTA DE TRANSPORTES',
  },
  {
    value: 'ANALISTA DE TREINAMENTO E DESENVOLVIMENTO',
    label: 'ANALISTA DE TREINAMENTO E DESENVOLVIMENTO',
  },
  {
    value: 'ANALISTA DE USABILIDADE',
    label: 'ANALISTA DE USABILIDADE',
  },
  {
    value: 'ANALISTA DE VALIDAÇÃO',
    label: 'ANALISTA DE VALIDAÇÃO',
  },
  {
    value: 'ANALISTA DE VENDAS',
    label: 'ANALISTA DE VENDAS',
  },
  {
    value: 'ANALISTA DE VIAGENS',
    label: 'ANALISTA DE VIAGENS',
  },
  {
    value: 'ANALISTA DE VISUAL MERCHANDISING',
    label: 'ANALISTA DE VISUAL MERCHANDISING',
  },
  {
    value: 'ANALISTA DE WEB ANALYTICS',
    label: 'ANALISTA DE WEB ANALYTICS',
  },
  {
    value: 'ANALISTA DE WEB DESIGN',
    label: 'ANALISTA DE WEB DESIGN',
  },
  {
    value: 'ANALISTA DESENVOLVEDOR MOBILE',
    label: 'ANALISTA DESENVOLVEDOR MOBILE',
  },
  {
    value: 'ANALISTA ECONÔMICO FINANCEIRO',
    label: 'ANALISTA ECONÔMICO FINANCEIRO',
  },
  {
    value: 'ANALISTA FINANCEIRO',
    label: 'ANALISTA FINANCEIRO',
  },
  {
    value: 'ANALISTA FISCAL',
    label: 'ANALISTA FISCAL',
  },
  {
    value: 'ANALISTA FUNCIONAL',
    label: 'ANALISTA FUNCIONAL',
  },
  {
    value: 'ANALISTA INDUSTRIAL',
    label: 'ANALISTA INDUSTRIAL',
  },
  {
    value: 'ANALISTA JURÍDICO',
    label: 'ANALISTA JURÍDICO',
  },
  {
    value: 'ANALISTA PEDAGÓGICO',
    label: 'ANALISTA PEDAGÓGICO',
  },
  {
    value: 'ANALISTA PLANEJAMENTO E ORÇAMENTO',
    label: 'ANALISTA PLANEJAMENTO E ORÇAMENTO',
  },
  {
    value: 'ANALISTA PROGRAMADOR .NET',
    label: 'ANALISTA PROGRAMADOR .NET',
  },
  {
    value: 'ANALISTA PROGRAMADOR ADVPL',
    label: 'ANALISTA PROGRAMADOR ADVPL',
  },
  {
    value: 'ANALISTA PROGRAMADOR ANDROID',
    label: 'ANALISTA PROGRAMADOR ANDROID',
  },
  {
    value: 'ANALISTA PROGRAMADOR ASP.NET',
    label: 'ANALISTA PROGRAMADOR ASP.NET',
  },
  {
    value: 'ANALISTA PROGRAMADOR C#',
    label: 'ANALISTA PROGRAMADOR C#',
  },
  {
    value: 'ANALISTA PROGRAMADOR C++',
    label: 'ANALISTA PROGRAMADOR C++',
  },
  {
    value: 'ANALISTA PROGRAMADOR COBOL',
    label: 'ANALISTA PROGRAMADOR COBOL',
  },
  {
    value: 'ANALISTA PROGRAMADOR DELPHI',
    label: 'ANALISTA PROGRAMADOR DELPHI',
  },
  {
    value: 'ANALISTA PROGRAMADOR FRONT-END',
    label: 'ANALISTA PROGRAMADOR FRONT-END',
  },
  {
    value: 'ANALISTA PROGRAMADOR JAVA',
    label: 'ANALISTA PROGRAMADOR JAVA',
  },
  {
    value: 'ANALISTA PROGRAMADOR MAINFRAME',
    label: 'ANALISTA PROGRAMADOR MAINFRAME',
  },
  {
    value: 'ANALISTA PROGRAMADOR ORACLE',
    label: 'ANALISTA PROGRAMADOR ORACLE',
  },
  {
    value: 'ANALISTA PROGRAMADOR PHP',
    label: 'ANALISTA PROGRAMADOR PHP',
  },
  {
    value: 'ANALISTA PROGRAMADOR PL SQL',
    label: 'ANALISTA PROGRAMADOR PL SQL',
  },
  {
    value: 'ANALISTA PROGRAMADOR PROGRESS',
    label: 'ANALISTA PROGRAMADOR PROGRESS',
  },
  {
    value: 'ANALISTA PROGRAMADOR PROTHEUS',
    label: 'ANALISTA PROGRAMADOR PROTHEUS',
  },
  {
    value: 'ANALISTA PROGRAMADOR SHAREPOINT',
    label: 'ANALISTA PROGRAMADOR SHAREPOINT',
  },
  {
    value: 'ANALISTA PROGRAMADOR SQL',
    label: 'ANALISTA PROGRAMADOR SQL',
  },
  {
    value: 'ANALISTA PROGRAMADOR VB6',
    label: 'ANALISTA PROGRAMADOR VB6',
  },
  {
    value: 'ANALISTA PROGRAMADOR WEB',
    label: 'ANALISTA PROGRAMADOR WEB',
  },
  {
    value: 'ANALISTA PROGRAMADOR WEBSPHERE',
    label: 'ANALISTA PROGRAMADOR WEBSPHERE',
  },
  {
    value: 'ANALISTA PROGRAMADOR',
    label: 'ANALISTA PROGRAMADOR',
  },
  {
    value: 'ANALISTA QUÍMICO',
    label: 'ANALISTA QUÍMICO',
  },
  {
    value: 'ANALISTA SAP',
    label: 'ANALISTA SAP',
  },
  {
    value: 'ANALISTA SOA',
    label: 'ANALISTA SOA',
  },
  {
    value: 'ANALISTA TRIBUTÁRIO',
    label: 'ANALISTA TRIBUTÁRIO',
  },
  {
    value: 'ANALISTA TÉCNICO',
    label: 'ANALISTA TÉCNICO',
  },
  {
    value: 'ANIMADOR DE FESTAS',
    label: 'ANIMADOR DE FESTAS',
  },
  {
    value: 'ANIMADOR DIGITAL',
    label: 'ANIMADOR DIGITAL',
  },
  {
    value: 'ANTROPÓLOGO',
    label: 'ANTROPÓLOGO',
  },
  {
    value: 'APONTADOR DE OBRAS',
    label: 'APONTADOR DE OBRAS',
  },
  {
    value: 'APONTADOR DE PRODUÇÃO',
    label: 'APONTADOR DE PRODUÇÃO',
  },
  {
    value: 'APRENDIZ NA ÁREA ADMINISTRATIVA',
    label: 'APRENDIZ NA ÁREA ADMINISTRATIVA',
  },
  {
    value: 'APRENDIZ NA ÁREA COMERCIAL',
    label: 'APRENDIZ NA ÁREA COMERCIAL',
  },
  {
    value: 'APRENDIZ NA ÁREA CONTÁBIL',
    label: 'APRENDIZ NA ÁREA CONTÁBIL',
  },
  {
    value: 'APRENDIZ NA ÁREA DE ATENDIMENTO',
    label: 'APRENDIZ NA ÁREA DE ATENDIMENTO',
  },
  {
    value: 'APRENDIZ NA ÁREA DE COBRANÇA',
    label: 'APRENDIZ NA ÁREA DE COBRANÇA',
  },
  {
    value: 'APRENDIZ NA ÁREA DE CONSTRUÇÃO CIVIL',
    label: 'APRENDIZ NA ÁREA DE CONSTRUÇÃO CIVIL',
  },
  {
    value: 'APRENDIZ NA ÁREA DE COZINHA',
    label: 'APRENDIZ NA ÁREA DE COZINHA',
  },
  {
    value: 'APRENDIZ NA ÁREA DE DEPARTAMENTO PESSOAL',
    label: 'APRENDIZ NA ÁREA DE DEPARTAMENTO PESSOAL',
  },
  {
    value: 'APRENDIZ NA ÁREA DE INFORMÁTICA',
    label: 'APRENDIZ NA ÁREA DE INFORMÁTICA',
  },
  {
    value: 'APRENDIZ NA ÁREA DE LOGÍSTICA',
    label: 'APRENDIZ NA ÁREA DE LOGÍSTICA',
  },
  {
    value: 'APRENDIZ NA ÁREA DE MARKETING',
    label: 'APRENDIZ NA ÁREA DE MARKETING',
  },
  {
    value: 'APRENDIZ NA ÁREA DE PRODUÇÃO',
    label: 'APRENDIZ NA ÁREA DE PRODUÇÃO',
  },
  {
    value: 'APRENDIZ NA ÁREA DE RECEPÇÃO',
    label: 'APRENDIZ NA ÁREA DE RECEPÇÃO',
  },
  {
    value: 'APRENDIZ NA ÁREA DE RECURSOS HUMANOS',
    label: 'APRENDIZ NA ÁREA DE RECURSOS HUMANOS',
  },
  {
    value: 'APRENDIZ NA ÁREA DE VENDAS',
    label: 'APRENDIZ NA ÁREA DE VENDAS',
  },
  {
    value: 'APRENDIZ NA ÁREA ELÉTRICA',
    label: 'APRENDIZ NA ÁREA ELÉTRICA',
  },
  {
    value: 'APRENDIZ NA ÁREA FINANCEIRA',
    label: 'APRENDIZ NA ÁREA FINANCEIRA',
  },
  {
    value: 'APRENDIZ NA ÁREA JURÍDICA',
    label: 'APRENDIZ NA ÁREA JURÍDICA',
  },
  {
    value: 'APRENDIZ NA ÁREA MECÂNICA',
    label: 'APRENDIZ NA ÁREA MECÂNICA',
  },
  {
    value: 'APRENDIZ NA ÁREA OPERACIONAL',
    label: 'APRENDIZ NA ÁREA OPERACIONAL',
  },
  {
    value: 'APRENDIZ',
    label: 'APRENDIZ',
  },
  {
    value: 'APRESENTADOR DE PROGRAMA DE TELEVISÃO',
    label: 'APRESENTADOR DE PROGRAMA DE TELEVISÃO',
  },
  {
    value: 'AQUARISTA',
    label: 'AQUARISTA',
  },
  {
    value: 'ARMADOR',
    label: 'ARMADOR',
  },
  {
    value: 'ARMAZENISTA',
    label: 'ARMAZENISTA',
  },
  {
    value: 'ARQUEÓLOGO',
    label: 'ARQUEÓLOGO',
  },
  {
    value: 'ARQUITETO .NET',
    label: 'ARQUITETO .NET',
  },
  {
    value: 'ARQUITETO COORDENADOR',
    label: 'ARQUITETO COORDENADOR',
  },
  {
    value: 'ARQUITETO DE EDIFICAÇÕES',
    label: 'ARQUITETO DE EDIFICAÇÕES',
  },
  {
    value: 'ARQUITETO DE INFORMAÇÃO',
    label: 'ARQUITETO DE INFORMAÇÃO',
  },
  {
    value: 'ARQUITETO DE INTERIORES',
    label: 'ARQUITETO DE INTERIORES',
  },
  {
    value: 'ARQUITETO DE SISTEMAS',
    label: 'ARQUITETO DE SISTEMAS',
  },
  {
    value: 'ARQUITETO DE SOLUÇÕES',
    label: 'ARQUITETO DE SOLUÇÕES',
  },
  {
    value: 'ARQUITETO ESPECIFICADOR',
    label: 'ARQUITETO ESPECIFICADOR',
  },
  {
    value: 'ARQUITETO JAVA',
    label: 'ARQUITETO JAVA',
  },
  {
    value: 'ARQUITETO PAISAGISTA',
    label: 'ARQUITETO PAISAGISTA',
  },
  {
    value: 'ARQUITETO URBANISTA',
    label: 'ARQUITETO URBANISTA',
  },
  {
    value: 'ARQUITETO VENDEDOR',
    label: 'ARQUITETO VENDEDOR',
  },
  {
    value: 'ARQUITETO',
    label: 'ARQUITETO',
  },
  {
    value: 'ARQUIVISTA',
    label: 'ARQUIVISTA',
  },
  {
    value: 'ARREMATADOR',
    label: 'ARREMATADOR',
  },
  {
    value: 'ARTE-FINALISTA',
    label: 'ARTE-FINALISTA',
  },
  {
    value: 'ARTESÃO',
    label: 'ARTESÃO',
  },
  {
    value: 'ARTICULADOR SOCIAL',
    label: 'ARTICULADOR SOCIAL',
  },
  {
    value: 'ASCENSORISTA',
    label: 'ASCENSORISTA',
  },
  {
    value: 'ASSESSOR CIENTÍFICO',
    label: 'ASSESSOR CIENTÍFICO',
  },
  {
    value: 'ASSESSOR DE COMUNICAÇÃO',
    label: 'ASSESSOR DE COMUNICAÇÃO',
  },
  {
    value: 'ASSESSOR DE IMPRENSA',
    label: 'ASSESSOR DE IMPRENSA',
  },
  {
    value: 'ASSESSOR DE INVESTIMENTOS',
    label: 'ASSESSOR DE INVESTIMENTOS',
  },
  {
    value: 'ASSESSOR DE MARKETING',
    label: 'ASSESSOR DE MARKETING',
  },
  {
    value: 'ASSESSOR JURÍDICO',
    label: 'ASSESSOR JURÍDICO',
  },
  {
    value: 'ASSISTENTE ADMINISTRATIVO DE MANUTENÇÃO',
    label: 'ASSISTENTE ADMINISTRATIVO DE MANUTENÇÃO',
  },
  {
    value: 'ASSISTENTE ADMINISTRATIVO',
    label: 'ASSISTENTE ADMINISTRATIVO',
  },
  {
    value: 'ASSISTENTE COMERCIAL',
    label: 'ASSISTENTE COMERCIAL',
  },
  {
    value: 'ASSISTENTE CONTÁBIL',
    label: 'ASSISTENTE CONTÁBIL',
  },
  {
    value: 'ASSISTENTE DE ALIMENTOS E BEBIDAS',
    label: 'ASSISTENTE DE ALIMENTOS E BEBIDAS',
  },
  {
    value: 'ASSISTENTE DE ALMOXARIFADO',
    label: 'ASSISTENTE DE ALMOXARIFADO',
  },
  {
    value: 'ASSISTENTE DE ARQUITETURA',
    label: 'ASSISTENTE DE ARQUITETURA',
  },
  {
    value: 'ASSISTENTE DE ARQUIVO',
    label: 'ASSISTENTE DE ARQUIVO',
  },
  {
    value: 'ASSISTENTE DE ARTE',
    label: 'ASSISTENTE DE ARTE',
  },
  {
    value: 'ASSISTENTE DE ASSUNTOS REGULATÓRIOS',
    label: 'ASSISTENTE DE ASSUNTOS REGULATÓRIOS',
  },
  {
    value: 'ASSISTENTE DE ATENDIMENTO PUBLICITÁRIO',
    label: 'ASSISTENTE DE ATENDIMENTO PUBLICITÁRIO',
  },
  {
    value: 'ASSISTENTE DE ATENDIMENTO',
    label: 'ASSISTENTE DE ATENDIMENTO',
  },
  {
    value: 'ASSISTENTE DE ATIVO FIXO',
    label: 'ASSISTENTE DE ATIVO FIXO',
  },
  {
    value: 'ASSISTENTE DE AUDITORIA CONTÁBIL',
    label: 'ASSISTENTE DE AUDITORIA CONTÁBIL',
  },
  {
    value: 'ASSISTENTE DE AUDITORIA',
    label: 'ASSISTENTE DE AUDITORIA',
  },
  {
    value: 'ASSISTENTE DE BACK OFFICE',
    label: 'ASSISTENTE DE BACK OFFICE',
  },
  {
    value: 'ASSISTENTE DE BENEFÍCIOS',
    label: 'ASSISTENTE DE BENEFÍCIOS',
  },
  {
    value: 'ASSISTENTE DE CADASTRO',
    label: 'ASSISTENTE DE CADASTRO',
  },
  {
    value: 'ASSISTENTE DE CARGOS E SALÁRIOS',
    label: 'ASSISTENTE DE CARGOS E SALÁRIOS',
  },
  {
    value: 'ASSISTENTE DE COBRANÇA',
    label: 'ASSISTENTE DE COBRANÇA',
  },
  {
    value: 'ASSISTENTE DE COMPLIANCE',
    label: 'ASSISTENTE DE COMPLIANCE',
  },
  {
    value: 'ASSISTENTE DE COMPRAS',
    label: 'ASSISTENTE DE COMPRAS',
  },
  {
    value: 'ASSISTENTE DE COMUNICAÇÃO E MARKETING',
    label: 'ASSISTENTE DE COMUNICAÇÃO E MARKETING',
  },
  {
    value: 'ASSISTENTE DE COMUNICAÇÃO',
    label: 'ASSISTENTE DE COMUNICAÇÃO',
  },
  {
    value: 'ASSISTENTE DE COMÉRCIO EXTERIOR',
    label: 'ASSISTENTE DE COMÉRCIO EXTERIOR',
  },
  {
    value: 'ASSISTENTE DE CONDOMÍNIO',
    label: 'ASSISTENTE DE CONDOMÍNIO',
  },
  {
    value: 'ASSISTENTE DE CONTAS A PAGAR E RECEBER',
    label: 'ASSISTENTE DE CONTAS A PAGAR E RECEBER',
  },
  {
    value: 'ASSISTENTE DE CONTAS A PAGAR',
    label: 'ASSISTENTE DE CONTAS A PAGAR',
  },
  {
    value: 'ASSISTENTE DE CONTAS A RECEBER',
    label: 'ASSISTENTE DE CONTAS A RECEBER',
  },
  {
    value: 'ASSISTENTE DE CONTAS MÉDICAS',
    label: 'ASSISTENTE DE CONTAS MÉDICAS',
  },
  {
    value: 'ASSISTENTE DE CONTEÚDO WEB',
    label: 'ASSISTENTE DE CONTEÚDO WEB',
  },
  {
    value: 'ASSISTENTE DE CONTRATOS',
    label: 'ASSISTENTE DE CONTRATOS',
  },
  {
    value: 'ASSISTENTE DE CONTROL DESK',
    label: 'ASSISTENTE DE CONTROL DESK',
  },
  {
    value: 'ASSISTENTE DE CONTROLADORIA',
    label: 'ASSISTENTE DE CONTROLADORIA',
  },
  {
    value: 'ASSISTENTE DE CONTROLE DE QUALIDADE',
    label: 'ASSISTENTE DE CONTROLE DE QUALIDADE',
  },
  {
    value: 'ASSISTENTE DE CONTROLES INTERNOS',
    label: 'ASSISTENTE DE CONTROLES INTERNOS',
  },
  {
    value: 'ASSISTENTE DE CREDENCIAMENTO',
    label: 'ASSISTENTE DE CREDENCIAMENTO',
  },
  {
    value: 'ASSISTENTE DE CRIAÇÃO',
    label: 'ASSISTENTE DE CRIAÇÃO',
  },
  {
    value: 'ASSISTENTE DE CRM',
    label: 'ASSISTENTE DE CRM',
  },
  {
    value: 'ASSISTENTE DE CRÉDITO E COBRANÇA',
    label: 'ASSISTENTE DE CRÉDITO E COBRANÇA',
  },
  {
    value: 'ASSISTENTE DE CRÉDITO IMOBILIÁRIO',
    label: 'ASSISTENTE DE CRÉDITO IMOBILIÁRIO',
  },
  {
    value: 'ASSISTENTE DE CUSTOMER SERVICE',
    label: 'ASSISTENTE DE CUSTOMER SERVICE',
  },
  {
    value: 'ASSISTENTE DE CUSTOS',
    label: 'ASSISTENTE DE CUSTOS',
  },
  {
    value: 'ASSISTENTE DE CÂMBIO',
    label: 'ASSISTENTE DE CÂMBIO',
  },
  {
    value: 'ASSISTENTE DE DEPARTAMENTO PESSOAL',
    label: 'ASSISTENTE DE DEPARTAMENTO PESSOAL',
  },
  {
    value: 'ASSISTENTE DE DESEMBARAÇO ADUANEIRO',
    label: 'ASSISTENTE DE DESEMBARAÇO ADUANEIRO',
  },
  {
    value: 'ASSISTENTE DE DESIGN GRÁFICO',
    label: 'ASSISTENTE DE DESIGN GRÁFICO',
  },
  {
    value: 'ASSISTENTE DE DESIGN',
    label: 'ASSISTENTE DE DESIGN',
  },
  {
    value: 'ASSISTENTE DE DIRETORIA',
    label: 'ASSISTENTE DE DIRETORIA',
  },
  {
    value: 'ASSISTENTE DE DOCUMENTAÇÃO',
    label: 'ASSISTENTE DE DOCUMENTAÇÃO',
  },
  {
    value: 'ASSISTENTE DE E-COMMERCE',
    label: 'ASSISTENTE DE E-COMMERCE',
  },
  {
    value: 'ASSISTENTE DE ENDOMARKETING',
    label: 'ASSISTENTE DE ENDOMARKETING',
  },
  {
    value: 'ASSISTENTE DE ENGENHARIA',
    label: 'ASSISTENTE DE ENGENHARIA',
  },
  {
    value: 'ASSISTENTE DE ESTILO',
    label: 'ASSISTENTE DE ESTILO',
  },
  {
    value: 'ASSISTENTE DE ESTOQUE',
    label: 'ASSISTENTE DE ESTOQUE',
  },
  {
    value: 'ASSISTENTE DE EVENTOS',
    label: 'ASSISTENTE DE EVENTOS',
  },
  {
    value: 'ASSISTENTE DE EXPEDIÇÃO',
    label: 'ASSISTENTE DE EXPEDIÇÃO',
  },
  {
    value: 'ASSISTENTE DE FACILITIES',
    label: 'ASSISTENTE DE FACILITIES',
  },
  {
    value: 'ASSISTENTE DE FATURAMENTO HOSPITALAR',
    label: 'ASSISTENTE DE FATURAMENTO HOSPITALAR',
  },
  {
    value: 'ASSISTENTE DE FATURAMENTO',
    label: 'ASSISTENTE DE FATURAMENTO',
  },
  {
    value: 'ASSISTENTE DE FOTÓGRAFO',
    label: 'ASSISTENTE DE FOTÓGRAFO',
  },
  {
    value: 'ASSISTENTE DE FRANQUIAS',
    label: 'ASSISTENTE DE FRANQUIAS',
  },
  {
    value: 'ASSISTENTE DE FROTA',
    label: 'ASSISTENTE DE FROTA',
  },
  {
    value: 'ASSISTENTE DE GARANTIA DA QUALIDADE',
    label: 'ASSISTENTE DE GARANTIA DA QUALIDADE',
  },
  {
    value: 'ASSISTENTE DE GOVERNANÇA',
    label: 'ASSISTENTE DE GOVERNANÇA',
  },
  {
    value: 'ASSISTENTE DE IMPLANTAÇÃO',
    label: 'ASSISTENTE DE IMPLANTAÇÃO',
  },
  {
    value: 'ASSISTENTE DE INFORMÁTICA',
    label: 'ASSISTENTE DE INFORMÁTICA',
  },
  {
    value: 'ASSISTENTE DE INFRAESTRUTURA',
    label: 'ASSISTENTE DE INFRAESTRUTURA',
  },
  {
    value: 'ASSISTENTE DE INTELIGÊNCIA DE MERCADO',
    label: 'ASSISTENTE DE INTELIGÊNCIA DE MERCADO',
  },
  {
    value: 'ASSISTENTE DE INVENTÁRIO',
    label: 'ASSISTENTE DE INVENTÁRIO',
  },
  {
    value: 'ASSISTENTE DE LEGALIZAÇÃO',
    label: 'ASSISTENTE DE LEGALIZAÇÃO',
  },
  {
    value: 'ASSISTENTE DE LICITAÇÃO',
    label: 'ASSISTENTE DE LICITAÇÃO',
  },
  {
    value: 'ASSISTENTE DE LOCAÇÃO',
    label: 'ASSISTENTE DE LOCAÇÃO',
  },
  {
    value: 'ASSISTENTE DE LOGÍSTICA',
    label: 'ASSISTENTE DE LOGÍSTICA',
  },
  {
    value: 'ASSISTENTE DE LOJA',
    label: 'ASSISTENTE DE LOJA',
  },
  {
    value: 'ASSISTENTE DE MANUTENÇÃO PREDIAL',
    label: 'ASSISTENTE DE MANUTENÇÃO PREDIAL',
  },
  {
    value: 'ASSISTENTE DE MARKETING DIGITAL',
    label: 'ASSISTENTE DE MARKETING DIGITAL',
  },
  {
    value: 'ASSISTENTE DE MARKETING',
    label: 'ASSISTENTE DE MARKETING',
  },
  {
    value: 'ASSISTENTE DE MEIO AMBIENTE',
    label: 'ASSISTENTE DE MEIO AMBIENTE',
  },
  {
    value: 'ASSISTENTE DE MERCHANDISING',
    label: 'ASSISTENTE DE MERCHANDISING',
  },
  {
    value: 'ASSISTENTE DE MODELAGEM',
    label: 'ASSISTENTE DE MODELAGEM',
  },
  {
    value: 'ASSISTENTE DE MONITORAMENTO',
    label: 'ASSISTENTE DE MONITORAMENTO',
  },
  {
    value: 'ASSISTENTE DE MÍDIA',
    label: 'ASSISTENTE DE MÍDIA',
  },
  {
    value: 'ASSISTENTE DE MÍDIAS SOCIAIS',
    label: 'ASSISTENTE DE MÍDIAS SOCIAIS',
  },
  {
    value: 'ASSISTENTE DE NEGÓCIOS',
    label: 'ASSISTENTE DE NEGÓCIOS',
  },
  {
    value: 'ASSISTENTE DE NUTRIÇÃO',
    label: 'ASSISTENTE DE NUTRIÇÃO',
  },
  {
    value: 'ASSISTENTE DE ORÇAMENTO',
    label: 'ASSISTENTE DE ORÇAMENTO',
  },
  {
    value: 'ASSISTENTE DE OUVIDORIA',
    label: 'ASSISTENTE DE OUVIDORIA',
  },
  {
    value: 'ASSISTENTE DE PATRIMÔNIO',
    label: 'ASSISTENTE DE PATRIMÔNIO',
  },
  {
    value: 'ASSISTENTE DE PCP',
    label: 'ASSISTENTE DE PCP',
  },
  {
    value: 'ASSISTENTE DE PESQUISA DE MERCADO',
    label: 'ASSISTENTE DE PESQUISA DE MERCADO',
  },
  {
    value: 'ASSISTENTE DE PESQUISA E DESENVOLVIMENTO',
    label: 'ASSISTENTE DE PESQUISA E DESENVOLVIMENTO',
  },
  {
    value: 'ASSISTENTE DE PESQUISA',
    label: 'ASSISTENTE DE PESQUISA',
  },
  {
    value: 'ASSISTENTE DE PLANEJAMENTO',
    label: 'ASSISTENTE DE PLANEJAMENTO',
  },
  {
    value: 'ASSISTENTE DE PREVENÇÃO DE PERDAS',
    label: 'ASSISTENTE DE PREVENÇÃO DE PERDAS',
  },
  {
    value: 'ASSISTENTE DE PRICING',
    label: 'ASSISTENTE DE PRICING',
  },
  {
    value: 'ASSISTENTE DE PROCESSAMENTO DE DADOS',
    label: 'ASSISTENTE DE PROCESSAMENTO DE DADOS',
  },
  {
    value: 'ASSISTENTE DE PROCESSOS',
    label: 'ASSISTENTE DE PROCESSOS',
  },
  {
    value: 'ASSISTENTE DE PRODUTO',
    label: 'ASSISTENTE DE PRODUTO',
  },
  {
    value: 'ASSISTENTE DE PRODUÇÃO EDITORIAL',
    label: 'ASSISTENTE DE PRODUÇÃO EDITORIAL',
  },
  {
    value: 'ASSISTENTE DE PRODUÇÃO',
    label: 'ASSISTENTE DE PRODUÇÃO',
  },
  {
    value: 'ASSISTENTE DE PROGRAMAÇÃO',
    label: 'ASSISTENTE DE PROGRAMAÇÃO',
  },
  {
    value: 'ASSISTENTE DE PROJETOS',
    label: 'ASSISTENTE DE PROJETOS',
  },
  {
    value: 'ASSISTENTE DE PUBLICIDADE E PROPAGANDA',
    label: 'ASSISTENTE DE PUBLICIDADE E PROPAGANDA',
  },
  {
    value: 'ASSISTENTE DE PÓS-VENDA',
    label: 'ASSISTENTE DE PÓS-VENDA',
  },
  {
    value: 'ASSISTENTE DE RASTREAMENTO',
    label: 'ASSISTENTE DE RASTREAMENTO',
  },
  {
    value: 'ASSISTENTE DE RECEBIMENTO FISCAL',
    label: 'ASSISTENTE DE RECEBIMENTO FISCAL',
  },
  {
    value: 'ASSISTENTE DE RECRUTAMENTO E SELEÇÃO',
    label: 'ASSISTENTE DE RECRUTAMENTO E SELEÇÃO',
  },
  {
    value: 'ASSISTENTE DE RECURSOS HUMANOS',
    label: 'ASSISTENTE DE RECURSOS HUMANOS',
  },
  {
    value: 'ASSISTENTE DE RELACIONAMENTO',
    label: 'ASSISTENTE DE RELACIONAMENTO',
  },
  {
    value: 'ASSISTENTE DE RELAÇÕES PÚBLICAS',
    label: 'ASSISTENTE DE RELAÇÕES PÚBLICAS',
  },
  {
    value: 'ASSISTENTE DE REPARTIÇÃO PÚBLICA',
    label: 'ASSISTENTE DE REPARTIÇÃO PÚBLICA',
  },
  {
    value: 'ASSISTENTE DE RESERVAS',
    label: 'ASSISTENTE DE RESERVAS',
  },
  {
    value: 'ASSISTENTE DE RISCO',
    label: 'ASSISTENTE DE RISCO',
  },
  {
    value: 'ASSISTENTE DE SAC',
    label: 'ASSISTENTE DE SAC',
  },
  {
    value: 'ASSISTENTE DE SECRETARIA ESCOLAR',
    label: 'ASSISTENTE DE SECRETARIA ESCOLAR',
  },
  {
    value: 'ASSISTENTE DE SECRETARIA',
    label: 'ASSISTENTE DE SECRETARIA',
  },
  {
    value: 'ASSISTENTE DE SEGURANÇA DA INFORMAÇÃO',
    label: 'ASSISTENTE DE SEGURANÇA DA INFORMAÇÃO',
  },
  {
    value: 'ASSISTENTE DE SEGURANÇA DO TRABALHO',
    label: 'ASSISTENTE DE SEGURANÇA DO TRABALHO',
  },
  {
    value: 'ASSISTENTE DE SEGURANÇA PATRIMONIAL',
    label: 'ASSISTENTE DE SEGURANÇA PATRIMONIAL',
  },
  {
    value: 'ASSISTENTE DE SEGUROS',
    label: 'ASSISTENTE DE SEGUROS',
  },
  {
    value: 'ASSISTENTE DE SERVICE DESK',
    label: 'ASSISTENTE DE SERVICE DESK',
  },
  {
    value: 'ASSISTENTE DE SINISTRO',
    label: 'ASSISTENTE DE SINISTRO',
  },
  {
    value: 'ASSISTENTE DE SISTEMAS',
    label: 'ASSISTENTE DE SISTEMAS',
  },
  {
    value: 'ASSISTENTE DE SUPERVISÃO',
    label: 'ASSISTENTE DE SUPERVISÃO',
  },
  {
    value: 'ASSISTENTE DE SUPORTE TÉCNICO',
    label: 'ASSISTENTE DE SUPORTE TÉCNICO',
  },
  {
    value: 'ASSISTENTE DE SUPRIMENTOS',
    label: 'ASSISTENTE DE SUPRIMENTOS',
  },
  {
    value: 'ASSISTENTE DE TECNOLOGIA DA INFORMAÇÃO',
    label: 'ASSISTENTE DE TECNOLOGIA DA INFORMAÇÃO',
  },
  {
    value: 'ASSISTENTE DE TELECOMUNICAÇÕES',
    label: 'ASSISTENTE DE TELECOMUNICAÇÕES',
  },
  {
    value: 'ASSISTENTE DE TELEMARKETING',
    label: 'ASSISTENTE DE TELEMARKETING',
  },
  {
    value: 'ASSISTENTE DE TELEVENDAS',
    label: 'ASSISTENTE DE TELEVENDAS',
  },
  {
    value: 'ASSISTENTE DE TESOURARIA',
    label: 'ASSISTENTE DE TESOURARIA',
  },
  {
    value: 'ASSISTENTE DE TESTES',
    label: 'ASSISTENTE DE TESTES',
  },
  {
    value: 'ASSISTENTE DE TRADE MARKETING',
    label: 'ASSISTENTE DE TRADE MARKETING',
  },
  {
    value: 'ASSISTENTE DE TRANSPORTES',
    label: 'ASSISTENTE DE TRANSPORTES',
  },
  {
    value: 'ASSISTENTE DE TREINAMENTO E DESENVOLVIMENTO',
    label: 'ASSISTENTE DE TREINAMENTO E DESENVOLVIMENTO',
  },
  {
    value: 'ASSISTENTE DE VENDAS',
    label: 'ASSISTENTE DE VENDAS',
  },
  {
    value: 'ASSISTENTE DE VISUAL MERCHANDISING',
    label: 'ASSISTENTE DE VISUAL MERCHANDISING',
  },
  {
    value: 'ASSISTENTE DE WEB DESIGN',
    label: 'ASSISTENTE DE WEB DESIGN',
  },
  {
    value: 'ASSISTENTE EDITORIAL',
    label: 'ASSISTENTE EDITORIAL',
  },
  {
    value: 'ASSISTENTE EXECUTIVO',
    label: 'ASSISTENTE EXECUTIVO',
  },
  {
    value: 'ASSISTENTE FINANCEIRO',
    label: 'ASSISTENTE FINANCEIRO',
  },
  {
    value: 'ASSISTENTE FINANCEIRO',
    label: 'ASSISTENTE FINANCEIRO',
  },
  {
    value: 'ASSISTENTE FISCAL',
    label: 'ASSISTENTE FISCAL',
  },
  {
    value: 'ASSISTENTE INDUSTRIAL',
    label: 'ASSISTENTE INDUSTRIAL',
  },
  {
    value: 'ASSISTENTE JURÍDICO',
    label: 'ASSISTENTE JURÍDICO',
  },
  {
    value: 'ASSISTENTE OPERACIONAL',
    label: 'ASSISTENTE OPERACIONAL',
  },
  {
    value: 'ASSISTENTE PARALEGAL',
    label: 'ASSISTENTE PARALEGAL',
  },
  {
    value: 'ASSISTENTE PEDAGÓGICO',
    label: 'ASSISTENTE PEDAGÓGICO',
  },
  {
    value: 'ASSISTENTE SOCIAL',
    label: 'ASSISTENTE SOCIAL',
  },
  {
    value: 'ASSISTENTE SOCIETÁRIO',
    label: 'ASSISTENTE SOCIETÁRIO',
  },
  {
    value: 'ASSISTENTE TRIBUTÁRIO',
    label: 'ASSISTENTE TRIBUTÁRIO',
  },
  {
    value: 'ASSISTENTE TÉCNICO DE CAMPO',
    label: 'ASSISTENTE TÉCNICO DE CAMPO',
  },
  {
    value: 'ASSISTENTE TÉCNICO DE EDIFICAÇÕES',
    label: 'ASSISTENTE TÉCNICO DE EDIFICAÇÕES',
  },
  {
    value: 'ASSISTENTE TÉCNICO DE VENDAS',
    label: 'ASSISTENTE TÉCNICO DE VENDAS',
  },
  {
    value: 'ASSISTENTE TÉCNICO EM ELETRÔNICA',
    label: 'ASSISTENTE TÉCNICO EM ELETRÔNICA',
  },
  {
    value: 'ASSISTENTE TÉCNICO',
    label: 'ASSISTENTE TÉCNICO',
  },
  {
    value: 'ASSOALHADOR',
    label: 'ASSOALHADOR',
  },
  {
    value: 'ATENDENTE DE ASSISTÊNCIA TÉCNICA',
    label: 'ATENDENTE DE ASSISTÊNCIA TÉCNICA',
  },
  {
    value: 'ATENDENTE DE CÂMBIO',
    label: 'ATENDENTE DE CÂMBIO',
  },
  {
    value: 'ATENDENTE DE DELIVERY',
    label: 'ATENDENTE DE DELIVERY',
  },
  {
    value: 'ATENDENTE DE E-COMMERCE',
    label: 'ATENDENTE DE E-COMMERCE',
  },
  {
    value: 'ATENDENTE DE HELP DESK',
    label: 'ATENDENTE DE HELP DESK',
  },
  {
    value: 'ATENDENTE DE MARKETING',
    label: 'ATENDENTE DE MARKETING',
  },
  {
    value: 'ATENDENTE DE NUTRIÇÃO',
    label: 'ATENDENTE DE NUTRIÇÃO',
  },
  {
    value: 'ATENDENTE DE PISTA',
    label: 'ATENDENTE DE PISTA',
  },
  {
    value: 'ATENDENTE DE PÓS-VENDA',
    label: 'ATENDENTE DE PÓS-VENDA',
  },
  {
    value: 'ATENDENTE DE RESTAURANTE',
    label: 'ATENDENTE DE RESTAURANTE',
  },
  {
    value: 'ATENDENTE DE SAC',
    label: 'ATENDENTE DE SAC',
  },
  {
    value: 'ATENDENTE DE SERVICE DESK',
    label: 'ATENDENTE DE SERVICE DESK',
  },
  {
    value: 'ATENDENTE DE SINISTRO',
    label: 'ATENDENTE DE SINISTRO',
  },
  {
    value: 'ATENDENTE DE SUPORTE TÉCNICO',
    label: 'ATENDENTE DE SUPORTE TÉCNICO',
  },
  {
    value: 'ATENDENTE DE TURISMO',
    label: 'ATENDENTE DE TURISMO',
  },
  {
    value: 'ATENDENTE',
    label: 'ATENDENTE',
  },
  {
    value: 'ATENDIMENTO PUBLICITÁRIO',
    label: 'ATENDIMENTO PUBLICITÁRIO',
  },
  {
    value: 'ATOR',
    label: 'ATOR',
  },
  {
    value: 'ATUÁRIO',
    label: 'ATUÁRIO',
  },
  {
    value: 'AUDITOR CONTÁBIL',
    label: 'AUDITOR CONTÁBIL',
  },
  {
    value: 'AUDITOR DE CONTAS MÉDICAS',
    label: 'AUDITOR DE CONTAS MÉDICAS',
  },
  {
    value: 'AUDITOR DE ESTOQUE',
    label: 'AUDITOR DE ESTOQUE',
  },
  {
    value: 'AUDITOR DE LOJA',
    label: 'AUDITOR DE LOJA',
  },
  {
    value: 'AUDITOR DE PROCESSOS',
    label: 'AUDITOR DE PROCESSOS',
  },
  {
    value: 'AUDITOR DE QUALIDADE',
    label: 'AUDITOR DE QUALIDADE',
  },
  {
    value: 'AUDITOR DE SISTEMAS',
    label: 'AUDITOR DE SISTEMAS',
  },
  {
    value: 'AUDITOR EXTERNO',
    label: 'AUDITOR EXTERNO',
  },
  {
    value: 'AUDITOR FINANCEIRO',
    label: 'AUDITOR FINANCEIRO',
  },
  {
    value: 'AUDITOR FISCAL',
    label: 'AUDITOR FISCAL',
  },
  {
    value: 'AUDITOR INTERNO',
    label: 'AUDITOR INTERNO',
  },
  {
    value: 'AUDITOR NOTURNO',
    label: 'AUDITOR NOTURNO',
  },
  {
    value: 'AUDITOR TRAINEE',
    label: 'AUDITOR TRAINEE',
  },
  {
    value: 'AUDITOR',
    label: 'AUDITOR',
  },
  {
    value: 'AUXILIAR ADMINISTRATIVO',
    label: 'AUXILIAR ADMINISTRATIVO',
  },
  {
    value: 'AUXILIAR COMERCIAL',
    label: 'AUXILIAR COMERCIAL',
  },
  {
    value: 'AUXILIAR CONTÁBIL',
    label: 'AUXILIAR CONTÁBIL',
  },
  {
    value: 'AUXILIAR DE ACABAMENTO GRÁFICO',
    label: 'AUXILIAR DE ACABAMENTO GRÁFICO',
  },
  {
    value: 'AUXILIAR DE ALMOXARIFADO',
    label: 'AUXILIAR DE ALMOXARIFADO',
  },
  {
    value: 'AUXILIAR DE ARQUITETURA',
    label: 'AUXILIAR DE ARQUITETURA',
  },
  {
    value: 'AUXILIAR DE ARQUIVO',
    label: 'AUXILIAR DE ARQUIVO',
  },
  {
    value: 'AUXILIAR DE ASSISTÊNCIA TÉCNICA',
    label: 'AUXILIAR DE ASSISTÊNCIA TÉCNICA',
  },
  {
    value: 'AUXILIAR DE ATENDIMENTO',
    label: 'AUXILIAR DE ATENDIMENTO',
  },
  {
    value: 'AUXILIAR DE AUDITORIA',
    label: 'AUXILIAR DE AUDITORIA',
  },
  {
    value: 'AUXILIAR DE AÇOUGUE',
    label: 'AUXILIAR DE AÇOUGUE',
  },
  {
    value: 'AUXILIAR DE BACK OFFICE',
    label: 'AUXILIAR DE BACK OFFICE',
  },
  {
    value: 'AUXILIAR DE BANCO DE DADOS',
    label: 'AUXILIAR DE BANCO DE DADOS',
  },
  {
    value: 'AUXILIAR DE BANCO DE SANGUE',
    label: 'AUXILIAR DE BANCO DE SANGUE',
  },
  {
    value: 'AUXILIAR DE BARMAN',
    label: 'AUXILIAR DE BARMAN',
  },
  {
    value: 'AUXILIAR DE BENEFÍCIOS',
    label: 'AUXILIAR DE BENEFÍCIOS',
  },
  {
    value: 'AUXILIAR DE BIBLIOTECA',
    label: 'AUXILIAR DE BIBLIOTECA',
  },
  {
    value: 'AUXILIAR DE BIOTÉRIO',
    label: 'AUXILIAR DE BIOTÉRIO',
  },
  {
    value: 'AUXILIAR DE BORDADO',
    label: 'AUXILIAR DE BORDADO',
  },
  {
    value: 'AUXILIAR DE BORRACHARIA',
    label: 'AUXILIAR DE BORRACHARIA',
  },
  {
    value: 'AUXILIAR DE CABELEIREIRO',
    label: 'AUXILIAR DE CABELEIREIRO',
  },
  {
    value: 'AUXILIAR DE CAMPO',
    label: 'AUXILIAR DE CAMPO',
  },
  {
    value: 'AUXILIAR DE CARGA E DESCARGA',
    label: 'AUXILIAR DE CARGA E DESCARGA',
  },
  {
    value: 'AUXILIAR DE CARTÓRIO',
    label: 'AUXILIAR DE CARTÓRIO',
  },
  {
    value: 'AUXILIAR DE CLASSE',
    label: 'AUXILIAR DE CLASSE',
  },
  {
    value: 'AUXILIAR DE COBRANÇA',
    label: 'AUXILIAR DE COBRANÇA',
  },
  {
    value: 'AUXILIAR DE COLETA',
    label: 'AUXILIAR DE COLETA',
  },
  {
    value: 'AUXILIAR DE COMPRAS',
    label: 'AUXILIAR DE COMPRAS',
  },
  {
    value: 'AUXILIAR DE COMÉRCIO EXTERIOR',
    label: 'AUXILIAR DE COMÉRCIO EXTERIOR',
  },
  {
    value: 'AUXILIAR DE CONFEITARIA',
    label: 'AUXILIAR DE CONFEITARIA',
  },
  {
    value: 'AUXILIAR DE CONTAS A PAGAR E RECEBER',
    label: 'AUXILIAR DE CONTAS A PAGAR E RECEBER',
  },
  {
    value: 'AUXILIAR DE CONTAS A PAGAR',
    label: 'AUXILIAR DE CONTAS A PAGAR',
  },
  {
    value: 'AUXILIAR DE CONTAS A RECEBER',
    label: 'AUXILIAR DE CONTAS A RECEBER',
  },
  {
    value: 'AUXILIAR DE CONTAS MÉDICAS',
    label: 'AUXILIAR DE CONTAS MÉDICAS',
  },
  {
    value: 'AUXILIAR DE CONTRATOS',
    label: 'AUXILIAR DE CONTRATOS',
  },
  {
    value: 'AUXILIAR DE CONTROLE DE QUALIDADE',
    label: 'AUXILIAR DE CONTROLE DE QUALIDADE',
  },
  {
    value: 'AUXILIAR DE CORTE',
    label: 'AUXILIAR DE CORTE',
  },
  {
    value: 'AUXILIAR DE COSTURA',
    label: 'AUXILIAR DE COSTURA',
  },
  {
    value: 'AUXILIAR DE COZINHA',
    label: 'AUXILIAR DE COZINHA',
  },
  {
    value: 'AUXILIAR DE CRIAÇÃO',
    label: 'AUXILIAR DE CRIAÇÃO',
  },
  {
    value: 'AUXILIAR DE CRÉDITO E COBRANÇA',
    label: 'AUXILIAR DE CRÉDITO E COBRANÇA',
  },
  {
    value: 'AUXILIAR DE CRÉDITO',
    label: 'AUXILIAR DE CRÉDITO',
  },
  {
    value: 'AUXILIAR DE DEPARTAMENTO PESSOAL',
    label: 'AUXILIAR DE DEPARTAMENTO PESSOAL',
  },
  {
    value: 'AUXILIAR DE DESENHISTA',
    label: 'AUXILIAR DE DESENHISTA',
  },
  {
    value: 'AUXILIAR DE DESENVOLVIMENTO DE PRODUTO',
    label: 'AUXILIAR DE DESENVOLVIMENTO DE PRODUTO',
  },
  {
    value: 'AUXILIAR DE DESIGNER GRÁFICO',
    label: 'AUXILIAR DE DESIGNER GRÁFICO',
  },
  {
    value: 'AUXILIAR DE DESPACHANTE',
    label: 'AUXILIAR DE DESPACHANTE',
  },
  {
    value: 'AUXILIAR DE E-COMMERCE',
    label: 'AUXILIAR DE E-COMMERCE',
  },
  {
    value: 'AUXILIAR DE ELETRICISTA',
    label: 'AUXILIAR DE ELETRICISTA',
  },
  {
    value: 'AUXILIAR DE ENFERMAGEM DO TRABALHO',
    label: 'AUXILIAR DE ENFERMAGEM DO TRABALHO',
  },
  {
    value: 'AUXILIAR DE ENFERMAGEM',
    label: 'AUXILIAR DE ENFERMAGEM',
  },
  {
    value: 'AUXILIAR DE ENGENHARIA CIVIL',
    label: 'AUXILIAR DE ENGENHARIA CIVIL',
  },
  {
    value: 'AUXILIAR DE ENGENHARIA',
    label: 'AUXILIAR DE ENGENHARIA',
  },
  {
    value: 'AUXILIAR DE ESCRITÓRIO',
    label: 'AUXILIAR DE ESCRITÓRIO',
  },
  {
    value: 'AUXILIAR DE ESTILO',
    label: 'AUXILIAR DE ESTILO',
  },
  {
    value: 'AUXILIAR DE ESTOQUE',
    label: 'AUXILIAR DE ESTOQUE',
  },
  {
    value: 'AUXILIAR DE ESTÉTICA',
    label: 'AUXILIAR DE ESTÉTICA',
  },
  {
    value: 'AUXILIAR DE EXPEDIÇÃO',
    label: 'AUXILIAR DE EXPEDIÇÃO',
  },
  {
    value: 'AUXILIAR DE EXTRUSÃO',
    label: 'AUXILIAR DE EXTRUSÃO',
  },
  {
    value: 'AUXILIAR DE FARMÁCIA',
    label: 'AUXILIAR DE FARMÁCIA',
  },
  {
    value: 'AUXILIAR DE FATURAMENTO HOSPITALAR',
    label: 'AUXILIAR DE FATURAMENTO HOSPITALAR',
  },
  {
    value: 'AUXILIAR DE FATURAMENTO',
    label: 'AUXILIAR DE FATURAMENTO',
  },
  {
    value: 'AUXILIAR DE FOLHA DE PAGAMENTO',
    label: 'AUXILIAR DE FOLHA DE PAGAMENTO',
  },
  {
    value: 'AUXILIAR DE FOTOGRAFIA',
    label: 'AUXILIAR DE FOTOGRAFIA',
  },
  {
    value: 'AUXILIAR DE FROTA',
    label: 'AUXILIAR DE FROTA',
  },
  {
    value: 'AUXILIAR DE GARANTIA',
    label: 'AUXILIAR DE GARANTIA',
  },
  {
    value: 'AUXILIAR DE GOVERNANÇA',
    label: 'AUXILIAR DE GOVERNANÇA',
  },
  {
    value: 'AUXILIAR DE IMPRESSÃO',
    label: 'AUXILIAR DE IMPRESSÃO',
  },
  {
    value: 'AUXILIAR DE INFORMÁTICA',
    label: 'AUXILIAR DE INFORMÁTICA',
  },
  {
    value: 'AUXILIAR DE INFRAESTRUTURA',
    label: 'AUXILIAR DE INFRAESTRUTURA',
  },
  {
    value: 'AUXILIAR DE INSTALAÇÃO',
    label: 'AUXILIAR DE INSTALAÇÃO',
  },
  {
    value: 'AUXILIAR DE INVENTÁRIO',
    label: 'AUXILIAR DE INVENTÁRIO',
  },
  {
    value: 'AUXILIAR DE JARDINAGEM',
    label: 'AUXILIAR DE JARDINAGEM',
  },
  {
    value: 'AUXILIAR DE LABORATÓRIO',
    label: 'AUXILIAR DE LABORATÓRIO',
  },
  {
    value: 'AUXILIAR DE LAVANDERIA',
    label: 'AUXILIAR DE LAVANDERIA',
  },
  {
    value: 'AUXILIAR DE LEGALIZAÇÃO',
    label: 'AUXILIAR DE LEGALIZAÇÃO',
  },
  {
    value: 'AUXILIAR DE LIMPEZA',
    label: 'AUXILIAR DE LIMPEZA',
  },
  {
    value: 'AUXILIAR DE LOCAÇÃO',
    label: 'AUXILIAR DE LOCAÇÃO',
  },
  {
    value: 'AUXILIAR DE LOGÍSTICA',
    label: 'AUXILIAR DE LOGÍSTICA',
  },
  {
    value: 'AUXILIAR DE LOJA',
    label: 'AUXILIAR DE LOJA',
  },
  {
    value: 'AUXILIAR DE MANIPULAÇÃO',
    label: 'AUXILIAR DE MANIPULAÇÃO',
  },
  {
    value: 'AUXILIAR DE MANUTENÇÃO ELÉTRICA',
    label: 'AUXILIAR DE MANUTENÇÃO ELÉTRICA',
  },
  {
    value: 'AUXILIAR DE MANUTENÇÃO HIDRÁULICA',
    label: 'AUXILIAR DE MANUTENÇÃO HIDRÁULICA',
  },
  {
    value: 'AUXILIAR DE MANUTENÇÃO INDUSTRIAL',
    label: 'AUXILIAR DE MANUTENÇÃO INDUSTRIAL',
  },
  {
    value: 'AUXILIAR DE MANUTENÇÃO MECÂNICA',
    label: 'AUXILIAR DE MANUTENÇÃO MECÂNICA',
  },
  {
    value: 'AUXILIAR DE MANUTENÇÃO PREDIAL',
    label: 'AUXILIAR DE MANUTENÇÃO PREDIAL',
  },
  {
    value: 'AUXILIAR DE MANUTENÇÃO',
    label: 'AUXILIAR DE MANUTENÇÃO',
  },
  {
    value: 'AUXILIAR DE MARKETING DIGITAL',
    label: 'AUXILIAR DE MARKETING DIGITAL',
  },
  {
    value: 'AUXILIAR DE MARKETING',
    label: 'AUXILIAR DE MARKETING',
  },
  {
    value: 'AUXILIAR DE MECÂNICO',
    label: 'AUXILIAR DE MECÂNICO',
  },
  {
    value: 'AUXILIAR DE MERCHANDISING',
    label: 'AUXILIAR DE MERCHANDISING',
  },
  {
    value: 'AUXILIAR DE MODELAGEM',
    label: 'AUXILIAR DE MODELAGEM',
  },
  {
    value: 'AUXILIAR DE MODELISTA',
    label: 'AUXILIAR DE MODELISTA',
  },
  {
    value: 'AUXILIAR DE MONITORAMENTO',
    label: 'AUXILIAR DE MONITORAMENTO',
  },
  {
    value: 'AUXILIAR DE MONTAGEM',
    label: 'AUXILIAR DE MONTAGEM',
  },
  {
    value: 'AUXILIAR DE MÍDIA',
    label: 'AUXILIAR DE MÍDIA',
  },
  {
    value: 'AUXILIAR DE OFTALMOLOGIA',
    label: 'AUXILIAR DE OFTALMOLOGIA',
  },
  {
    value: 'AUXILIAR DE ORÇAMENTO',
    label: 'AUXILIAR DE ORÇAMENTO',
  },
  {
    value: 'AUXILIAR DE OURIVES',
    label: 'AUXILIAR DE OURIVES',
  },
  {
    value: 'AUXILIAR DE PADEIRO',
    label: 'AUXILIAR DE PADEIRO',
  },
  {
    value: 'AUXILIAR DE PATRIMÔNIO',
    label: 'AUXILIAR DE PATRIMÔNIO',
  },
  {
    value: 'AUXILIAR DE PCP',
    label: 'AUXILIAR DE PCP',
  },
  {
    value: 'AUXILIAR DE PERECÍVEIS',
    label: 'AUXILIAR DE PERECÍVEIS',
  },
  {
    value: 'AUXILIAR DE PESAGEM',
    label: 'AUXILIAR DE PESAGEM',
  },
  {
    value: 'AUXILIAR DE PESQUISA DE MERCADO',
    label: 'AUXILIAR DE PESQUISA DE MERCADO',
  },
  {
    value: 'AUXILIAR DE PINTURA',
    label: 'AUXILIAR DE PINTURA',
  },
  {
    value: 'AUXILIAR DE PROCESSOS',
    label: 'AUXILIAR DE PROCESSOS',
  },
  {
    value: 'AUXILIAR DE PRODUÇÃO',
    label: 'AUXILIAR DE PRODUÇÃO',
  },
  {
    value: 'AUXILIAR DE PRÓTESE DENTÁRIA',
    label: 'AUXILIAR DE PRÓTESE DENTÁRIA',
  },
  {
    value: 'AUXILIAR DE PUBLICIDADE E PROPAGANDA',
    label: 'AUXILIAR DE PUBLICIDADE E PROPAGANDA',
  },
  {
    value: 'AUXILIAR DE PÁTIO',
    label: 'AUXILIAR DE PÁTIO',
  },
  {
    value: 'AUXILIAR DE PÓS-VENDA',
    label: 'AUXILIAR DE PÓS-VENDA',
  },
  {
    value: 'AUXILIAR DE QUALIDADE',
    label: 'AUXILIAR DE QUALIDADE',
  },
  {
    value: 'AUXILIAR DE RASTREAMENTO',
    label: 'AUXILIAR DE RASTREAMENTO',
  },
  {
    value: 'AUXILIAR DE RECEBIMENTO FISCAL',
    label: 'AUXILIAR DE RECEBIMENTO FISCAL',
  },
  {
    value: 'AUXILIAR DE RECEBIMENTO',
    label: 'AUXILIAR DE RECEBIMENTO',
  },
  {
    value: 'AUXILIAR DE RECREAÇÃO',
    label: 'AUXILIAR DE RECREAÇÃO',
  },
  {
    value: 'AUXILIAR DE RECRUTAMENTO E SELEÇÃO',
    label: 'AUXILIAR DE RECRUTAMENTO E SELEÇÃO',
  },
  {
    value: 'AUXILIAR DE RECURSOS HUMANOS',
    label: 'AUXILIAR DE RECURSOS HUMANOS',
  },
  {
    value: 'AUXILIAR DE REFRIGERAÇÃO',
    label: 'AUXILIAR DE REFRIGERAÇÃO',
  },
  {
    value: 'AUXILIAR DE REPOSIÇÃO',
    label: 'AUXILIAR DE REPOSIÇÃO',
  },
  {
    value: 'AUXILIAR DE RESTAURANTE',
    label: 'AUXILIAR DE RESTAURANTE',
  },
  {
    value: 'AUXILIAR DE SAÚDE BUCAL',
    label: 'AUXILIAR DE SAÚDE BUCAL',
  },
  {
    value: 'AUXILIAR DE SECRETARIA ESCOLAR',
    label: 'AUXILIAR DE SECRETARIA ESCOLAR',
  },
  {
    value: 'AUXILIAR DE SEGURANÇA PATRIMONIAL',
    label: 'AUXILIAR DE SEGURANÇA PATRIMONIAL',
  },
  {
    value: 'AUXILIAR DE SEGUROS',
    label: 'AUXILIAR DE SEGUROS',
  },
  {
    value: 'AUXILIAR DE SERVIÇOS GERAIS',
    label: 'AUXILIAR DE SERVIÇOS GERAIS',
  },
  {
    value: 'AUXILIAR DE SINISTRO',
    label: 'AUXILIAR DE SINISTRO',
  },
  {
    value: 'AUXILIAR DE SOLDADOR',
    label: 'AUXILIAR DE SOLDADOR',
  },
  {
    value: 'AUXILIAR DE SONDAGEM',
    label: 'AUXILIAR DE SONDAGEM',
  },
  {
    value: 'AUXILIAR DE SUPERVISÃO',
    label: 'AUXILIAR DE SUPERVISÃO',
  },
  {
    value: 'AUXILIAR DE SUPORTE TÉCNICO',
    label: 'AUXILIAR DE SUPORTE TÉCNICO',
  },
  {
    value: 'AUXILIAR DE SUPRIMENTOS',
    label: 'AUXILIAR DE SUPRIMENTOS',
  },
  {
    value: 'AUXILIAR DE SUSHIMAN',
    label: 'AUXILIAR DE SUSHIMAN',
  },
  {
    value: 'AUXILIAR DE TECELÃO',
    label: 'AUXILIAR DE TECELÃO',
  },
  {
    value: 'AUXILIAR DE TECNOLOGIA DA INFORMAÇÃO',
    label: 'AUXILIAR DE TECNOLOGIA DA INFORMAÇÃO',
  },
  {
    value: 'AUXILIAR DE TELEVENDAS',
    label: 'AUXILIAR DE TELEVENDAS',
  },
  {
    value: 'AUXILIAR DE TESOURARIA',
    label: 'AUXILIAR DE TESOURARIA',
  },
  {
    value: 'AUXILIAR DE TOPOGRAFIA',
    label: 'AUXILIAR DE TOPOGRAFIA',
  },
  {
    value: 'AUXILIAR DE TRADE MARKETING',
    label: 'AUXILIAR DE TRADE MARKETING',
  },
  {
    value: 'AUXILIAR DE TRANSPORTES',
    label: 'AUXILIAR DE TRANSPORTES',
  },
  {
    value: 'AUXILIAR DE TREINAMENTO E DESENVOLVIMENTO',
    label: 'AUXILIAR DE TREINAMENTO E DESENVOLVIMENTO',
  },
  {
    value: 'AUXILIAR DE TRIAGEM',
    label: 'AUXILIAR DE TRIAGEM',
  },
  {
    value: 'AUXILIAR DE USINAGEM',
    label: 'AUXILIAR DE USINAGEM',
  },
  {
    value: 'AUXILIAR DE VENDAS',
    label: 'AUXILIAR DE VENDAS',
  },
  {
    value: 'AUXILIAR DE VETERINÁRIO',
    label: 'AUXILIAR DE VETERINÁRIO',
  },
  {
    value: 'AUXILIAR EDITORIAL',
    label: 'AUXILIAR EDITORIAL',
  },
  {
    value: 'AUXILIAR ELETROTÉCNICO',
    label: 'AUXILIAR ELETROTÉCNICO',
  },
  {
    value: 'AUXILIAR FINANCEIRO',
    label: 'AUXILIAR FINANCEIRO',
  },
  {
    value: 'AUXILIAR FISCAL',
    label: 'AUXILIAR FISCAL',
  },
  {
    value: 'AUXILIAR INDUSTRIAL',
    label: 'AUXILIAR INDUSTRIAL',
  },
  {
    value: 'AUXILIAR OPERACIONAL',
    label: 'AUXILIAR OPERACIONAL',
  },
  {
    value: 'AUXILIAR PEDAGÓGICO',
    label: 'AUXILIAR PEDAGÓGICO',
  },
  {
    value: 'AUXILIAR PROJETISTA',
    label: 'AUXILIAR PROJETISTA',
  },
  {
    value: 'AUXILIAR QUÍMICO',
    label: 'AUXILIAR QUÍMICO',
  },
  {
    value: 'AUXILIAR TRIBUTÁRIO',
    label: 'AUXILIAR TRIBUTÁRIO',
  },
  {
    value: 'AUXILIAR TÉCNICO DE MECÂNICA',
    label: 'AUXILIAR TÉCNICO DE MECÂNICA',
  },
  {
    value: 'AUXILIAR TÉCNICO ELETRÔNICO',
    label: 'AUXILIAR TÉCNICO ELETRÔNICO',
  },
  {
    value: 'AUXILIAR TÉCNICO EM EDIFICAÇÕES',
    label: 'AUXILIAR TÉCNICO EM EDIFICAÇÕES',
  },
  {
    value: 'AUXILIAR TÉCNICO EM MECATRÔNICA',
    label: 'AUXILIAR TÉCNICO EM MECATRÔNICA',
  },
  {
    value: 'AUXILIAR TÉCNICO EM SEGURANÇA DO TRABALHO',
    label: 'AUXILIAR TÉCNICO EM SEGURANÇA DO TRABALHO',
  },
  {
    value: 'AUXILIAR TÉCNICO',
    label: 'AUXILIAR TÉCNICO',
  },
  {
    value: 'AVALIADOR DE IMÓVEIS',
    label: 'AVALIADOR DE IMÓVEIS',
  },
  {
    value: 'AVALIADOR DE VEÍCULOS',
    label: 'AVALIADOR DE VEÍCULOS',
  },
  {
    value: 'AVALIADOR FÍSICO',
    label: 'AVALIADOR FÍSICO',
  },
  {
    value: 'AZULEJISTA',
    label: 'AZULEJISTA',
  },
  {
    value: 'AÇOUGUEIRO',
    label: 'AÇOUGUEIRO',
  },
  {
    value: 'BABÁ',
    label: 'BABÁ',
  },
  {
    value: 'BACK OFFICE DE VENDAS',
    label: 'BACK OFFICE DE VENDAS',
  },
  {
    value: 'BACK OFFICE',
    label: 'BACK OFFICE',
  },
  {
    value: 'BALANCEIRO',
    label: 'BALANCEIRO',
  },
  {
    value: 'BALCONISTA',
    label: 'BALCONISTA',
  },
  {
    value: 'BAMBURISTA',
    label: 'BAMBURISTA',
  },
  {
    value: 'BARISTA',
    label: 'BARISTA',
  },
  {
    value: 'BARMAN',
    label: 'BARMAN',
  },
  {
    value: 'BERÇARISTA',
    label: 'BERÇARISTA',
  },
  {
    value: 'BIBLIOTECÁRIO',
    label: 'BIBLIOTECÁRIO',
  },
  {
    value: 'BILHETEIRO',
    label: 'BILHETEIRO',
  },
  {
    value: 'BIOLOGISTA',
    label: 'BIOLOGISTA',
  },
  {
    value: 'BIOMÉDICO',
    label: 'BIOMÉDICO',
  },
  {
    value: 'BIOQUÍMICO',
    label: 'BIOQUÍMICO',
  },
  {
    value: 'BIOTECNÓLOGO',
    label: 'BIOTECNÓLOGO',
  },
  {
    value: 'BIÓLOGO',
    label: 'BIÓLOGO',
  },
  {
    value: 'BLASTER',
    label: 'BLASTER',
  },
  {
    value: 'BLOGUEIRO',
    label: 'BLOGUEIRO',
  },
  {
    value: 'BLOQUISTA',
    label: 'BLOQUISTA',
  },
  {
    value: 'BOMBEIRO CIVIL',
    label: 'BOMBEIRO CIVIL',
  },
  {
    value: 'BOMBEIRO INDUSTRIAL',
    label: 'BOMBEIRO INDUSTRIAL',
  },
  {
    value: 'BOOKER',
    label: 'BOOKER',
  },
  {
    value: 'BORDADOR',
    label: 'BORDADOR',
  },
  {
    value: 'BORRACHEIRO',
    label: 'BORRACHEIRO',
  },
  {
    value: 'BUSINESS PARTNER',
    label: 'BUSINESS PARTNER',
  },
  {
    value: 'CABELEIREIRO',
    label: 'CABELEIREIRO',
  },
  {
    value: 'CABISTA',
    label: 'CABISTA',
  },
  {
    value: 'CAIXA DE BANCO',
    label: 'CAIXA DE BANCO',
  },
  {
    value: 'CALCETEIRO',
    label: 'CALCETEIRO',
  },
  {
    value: 'CALCULISTA',
    label: 'CALCULISTA',
  },
  {
    value: 'CALDEIREIRO',
    label: 'CALDEIREIRO',
  },
  {
    value: 'CAMAREIRO',
    label: 'CAMAREIRO',
  },
  {
    value: 'CARACTERIZADOR',
    label: 'CARACTERIZADOR',
  },
  {
    value: 'CARPINTEIRO',
    label: 'CARPINTEIRO',
  },
  {
    value: 'CARTAZISTA',
    label: 'CARTAZISTA',
  },
  {
    value: 'CARTEIRO',
    label: 'CARTEIRO',
  },
  {
    value: 'CASEIRO',
    label: 'CASEIRO',
  },
  {
    value: 'CENOTÉCNICO',
    label: 'CENOTÉCNICO',
  },
  {
    value: 'CENÓGRAFO',
    label: 'CENÓGRAFO',
  },
  {
    value: 'CEO',
    label: 'CEO',
  },
  {
    value: 'CHAPEIRO',
    label: 'CHAPEIRO',
  },
  {
    value: 'CHAVEIRO',
    label: 'CHAVEIRO',
  },
  {
    value: 'CHEFE DE BAR',
    label: 'CHEFE DE BAR',
  },
  {
    value: 'CHEFE DE COMPRAS',
    label: 'CHEFE DE COMPRAS',
  },
  {
    value: 'CHEFE DE CONFEITARIA',
    label: 'CHEFE DE CONFEITARIA',
  },
  {
    value: 'CHEFE DE COSTURA',
    label: 'CHEFE DE COSTURA',
  },
  {
    value: 'CHEFE DE COZINHA',
    label: 'CHEFE DE COZINHA',
  },
  {
    value: 'CHEFE DE DEPARTAMENTO PESSOAL',
    label: 'CHEFE DE DEPARTAMENTO PESSOAL',
  },
  {
    value: 'CHEFE DE EXPEDIÇÃO',
    label: 'CHEFE DE EXPEDIÇÃO',
  },
  {
    value: 'CHEFE DE FILA',
    label: 'CHEFE DE FILA',
  },
  {
    value: 'CHEFE DE PLANTÃO MÉDICO',
    label: 'CHEFE DE PLANTÃO MÉDICO',
  },
  {
    value: 'CHEFE DE PRODUÇÃO',
    label: 'CHEFE DE PRODUÇÃO',
  },
  {
    value: 'CHEFE DE RECEPÇÃO',
    label: 'CHEFE DE RECEPÇÃO',
  },
  {
    value: 'CHURRASQUEIRO',
    label: 'CHURRASQUEIRO',
  },
  {
    value: 'CILINDRISTA',
    label: 'CILINDRISTA',
  },
  {
    value: 'CINEGRAFISTA',
    label: 'CINEGRAFISTA',
  },
  {
    value: 'CLASSIFICADOR DE GRÃOS',
    label: 'CLASSIFICADOR DE GRÃOS',
  },
  {
    value: 'CLIENTE OCULTO',
    label: 'CLIENTE OCULTO',
  },
  {
    value: 'COACH',
    label: 'COACH',
  },
  {
    value: 'COBRADOR DE ÔNIBUS',
    label: 'COBRADOR DE ÔNIBUS',
  },
  {
    value: 'CODIFICADOR DE DADOS',
    label: 'CODIFICADOR DE DADOS',
  },
  {
    value: 'COLCHOEIRO',
    label: 'COLCHOEIRO',
  },
  {
    value: 'COLETOR DE AMOSTRAS',
    label: 'COLETOR DE AMOSTRAS',
  },
  {
    value: 'COLETOR DE LIXO',
    label: 'COLETOR DE LIXO',
  },
  {
    value: 'COLOCADOR DE PAPEL DE PAREDE',
    label: 'COLOCADOR DE PAPEL DE PAREDE',
  },
  {
    value: 'COLORISTA DE PAPEL',
    label: 'COLORISTA DE PAPEL',
  },
  {
    value: 'COLORISTA TÊXTIL',
    label: 'COLORISTA TÊXTIL',
  },
  {
    value: 'COLORISTA',
    label: 'COLORISTA',
  },
  {
    value: 'COMISSÁRIO DE AVARIAS',
    label: 'COMISSÁRIO DE AVARIAS',
  },
  {
    value: 'COMISSÁRIO DE BORDO',
    label: 'COMISSÁRIO DE BORDO',
  },
  {
    value: 'COMPRADOR DE MEDICAMENTOS',
    label: 'COMPRADOR DE MEDICAMENTOS',
  },
  {
    value: 'COMPRADOR INTERNACIONAL',
    label: 'COMPRADOR INTERNACIONAL',
  },
  {
    value: 'COMPRADOR TÉCNICO',
    label: 'COMPRADOR TÉCNICO',
  },
  {
    value: 'COMPRADOR',
    label: 'COMPRADOR',
  },
  {
    value: 'COMUNICADOR SOCIAL',
    label: 'COMUNICADOR SOCIAL',
  },
  {
    value: 'CONCIERGE',
    label: 'CONCIERGE',
  },
  {
    value: 'CONFEITEIRO',
    label: 'CONFEITEIRO',
  },
  {
    value: 'CONFERENTE DE TESOURARIA',
    label: 'CONFERENTE DE TESOURARIA',
  },
  {
    value: 'CONFERENTE LÍDER',
    label: 'CONFERENTE LÍDER',
  },
  {
    value: 'CONFERENTE',
    label: 'CONFERENTE',
  },
  {
    value: 'CONSELHEIRO TUTELAR',
    label: 'CONSELHEIRO TUTELAR',
  },
  {
    value: 'CONSULTOR ABAP',
    label: 'CONSULTOR ABAP',
  },
  {
    value: 'CONSULTOR AMBIENTAL',
    label: 'CONSULTOR AMBIENTAL',
  },
  {
    value: 'CONSULTOR CONTÁBIL',
    label: 'CONSULTOR CONTÁBIL',
  },
  {
    value: 'CONSULTOR CRM',
    label: 'CONSULTOR CRM',
  },
  {
    value: 'CONSULTOR DE ATENDIMENTO',
    label: 'CONSULTOR DE ATENDIMENTO',
  },
  {
    value: 'CONSULTOR DE BELEZA',
    label: 'CONSULTOR DE BELEZA',
  },
  {
    value: 'CONSULTOR DE BENEFÍCIOS',
    label: 'CONSULTOR DE BENEFÍCIOS',
  },
  {
    value: 'CONSULTOR DE COMUNICAÇÃO',
    label: 'CONSULTOR DE COMUNICAÇÃO',
  },
  {
    value: 'CONSULTOR DE COMÉRCIO EXTERIOR',
    label: 'CONSULTOR DE COMÉRCIO EXTERIOR',
  },
  {
    value: 'CONSULTOR DE CONTROLADORIA',
    label: 'CONSULTOR DE CONTROLADORIA',
  },
  {
    value: 'CONSULTOR DE CRÉDITO IMOBILIÁRIO',
    label: 'CONSULTOR DE CRÉDITO IMOBILIÁRIO',
  },
  {
    value: 'CONSULTOR DE ESTÉTICA',
    label: 'CONSULTOR DE ESTÉTICA',
  },
  {
    value: 'CONSULTOR DE EVENTOS',
    label: 'CONSULTOR DE EVENTOS',
  },
  {
    value: 'CONSULTOR DE FRANQUIAS',
    label: 'CONSULTOR DE FRANQUIAS',
  },
  {
    value: 'CONSULTOR DE IMIGRAÇÃO',
    label: 'CONSULTOR DE IMIGRAÇÃO',
  },
  {
    value: 'CONSULTOR DE IMPLANTAÇÃO',
    label: 'CONSULTOR DE IMPLANTAÇÃO',
  },
  {
    value: 'CONSULTOR DE INFRAESTRUTURA',
    label: 'CONSULTOR DE INFRAESTRUTURA',
  },
  {
    value: 'CONSULTOR DE INTELIGÊNCIA DE MERCADO',
    label: 'CONSULTOR DE INTELIGÊNCIA DE MERCADO',
  },
  {
    value: 'CONSULTOR DE INTERCÂMBIO',
    label: 'CONSULTOR DE INTERCÂMBIO',
  },
  {
    value: 'CONSULTOR DE INVESTIMENTOS',
    label: 'CONSULTOR DE INVESTIMENTOS',
  },
  {
    value: 'CONSULTOR DE LOGÍSTICA',
    label: 'CONSULTOR DE LOGÍSTICA',
  },
  {
    value: 'CONSULTOR DE LOJA',
    label: 'CONSULTOR DE LOJA',
  },
  {
    value: 'CONSULTOR DE MARKETING',
    label: 'CONSULTOR DE MARKETING',
  },
  {
    value: 'CONSULTOR DE MERCHANDISING',
    label: 'CONSULTOR DE MERCHANDISING',
  },
  {
    value: 'CONSULTOR DE MODA',
    label: 'CONSULTOR DE MODA',
  },
  {
    value: 'CONSULTOR DE NEGÓCIOS',
    label: 'CONSULTOR DE NEGÓCIOS',
  },
  {
    value: 'CONSULTOR DE OPERAÇÕES',
    label: 'CONSULTOR DE OPERAÇÕES',
  },
  {
    value: 'CONSULTOR DE PLANEJAMENTO ESTRATÉGICO',
    label: 'CONSULTOR DE PLANEJAMENTO ESTRATÉGICO',
  },
  {
    value: 'CONSULTOR DE PROCESSOS',
    label: 'CONSULTOR DE PROCESSOS',
  },
  {
    value: 'CONSULTOR DE PROJETOS',
    label: 'CONSULTOR DE PROJETOS',
  },
  {
    value: 'CONSULTOR DE PÓS-VENDA',
    label: 'CONSULTOR DE PÓS-VENDA',
  },
  {
    value: 'CONSULTOR DE QUALIDADE',
    label: 'CONSULTOR DE QUALIDADE',
  },
  {
    value: 'CONSULTOR DE RECRUTAMENTO E SELEÇÃO',
    label: 'CONSULTOR DE RECRUTAMENTO E SELEÇÃO',
  },
  {
    value: 'CONSULTOR DE RECURSOS HUMANOS',
    label: 'CONSULTOR DE RECURSOS HUMANOS',
  },
  {
    value: 'CONSULTOR DE REDES',
    label: 'CONSULTOR DE REDES',
  },
  {
    value: 'CONSULTOR DE RELACIONAMENTO',
    label: 'CONSULTOR DE RELACIONAMENTO',
  },
  {
    value: 'CONSULTOR DE RESPONSABILIDADE SOCIAL',
    label: 'CONSULTOR DE RESPONSABILIDADE SOCIAL',
  },
  {
    value: 'CONSULTOR DE SEGURANÇA DA INFORMAÇÃO',
    label: 'CONSULTOR DE SEGURANÇA DA INFORMAÇÃO',
  },
  {
    value: 'CONSULTOR DE SEGUROS',
    label: 'CONSULTOR DE SEGUROS',
  },
  {
    value: 'CONSULTOR DE SISTEMAS',
    label: 'CONSULTOR DE SISTEMAS',
  },
  {
    value: 'CONSULTOR DE TECNOLOGIA DA INFORMAÇÃO',
    label: 'CONSULTOR DE TECNOLOGIA DA INFORMAÇÃO',
  },
  {
    value: 'CONSULTOR DE TRADE MARKETING',
    label: 'CONSULTOR DE TRADE MARKETING',
  },
  {
    value: 'CONSULTOR DE TREINAMENTO E DESENVOLVIMENTO',
    label: 'CONSULTOR DE TREINAMENTO E DESENVOLVIMENTO',
  },
  {
    value: 'CONSULTOR DE VENDAS',
    label: 'CONSULTOR DE VENDAS',
  },
  {
    value: 'CONSULTOR EDUCACIONAL',
    label: 'CONSULTOR EDUCACIONAL',
  },
  {
    value: 'CONSULTOR EMPRESARIAL',
    label: 'CONSULTOR EMPRESARIAL',
  },
  {
    value: 'CONSULTOR ERP',
    label: 'CONSULTOR ERP',
  },
  {
    value: 'CONSULTOR FINANCEIRO',
    label: 'CONSULTOR FINANCEIRO',
  },
  {
    value: 'CONSULTOR FISCAL',
    label: 'CONSULTOR FISCAL',
  },
  {
    value: 'CONSULTOR FUNCIONAL',
    label: 'CONSULTOR FUNCIONAL',
  },
  {
    value: 'CONSULTOR GASTRONÔMICO',
    label: 'CONSULTOR GASTRONÔMICO',
  },
  {
    value: 'CONSULTOR JURÍDICO',
    label: 'CONSULTOR JURÍDICO',
  },
  {
    value: 'CONSULTOR MASTERSAF',
    label: 'CONSULTOR MASTERSAF',
  },
  {
    value: 'CONSULTOR ORACLE',
    label: 'CONSULTOR ORACLE',
  },
  {
    value: 'CONSULTOR PEDAGÓGICO',
    label: 'CONSULTOR PEDAGÓGICO',
  },
  {
    value: 'CONSULTOR SAP',
    label: 'CONSULTOR SAP',
  },
  {
    value: 'CONSULTOR SIEBEL',
    label: 'CONSULTOR SIEBEL',
  },
  {
    value: 'CONSULTOR TRIBUTÁRIO',
    label: 'CONSULTOR TRIBUTÁRIO',
  },
  {
    value: 'CONSULTOR TÉCNICO DE VEÍCULOS',
    label: 'CONSULTOR TÉCNICO DE VEÍCULOS',
  },
  {
    value: 'CONSULTOR TÉCNICO INDUSTRIAL',
    label: 'CONSULTOR TÉCNICO INDUSTRIAL',
  },
  {
    value: 'CONSULTOR TÉCNICO',
    label: 'CONSULTOR TÉCNICO',
  },
  {
    value: 'CONTABILISTA',
    label: 'CONTABILISTA',
  },
  {
    value: 'CONTADOR GERENCIAL',
    label: 'CONTADOR GERENCIAL',
  },
  {
    value: 'CONTADOR',
    label: 'CONTADOR',
  },
  {
    value: 'CONTATO PUBLICITÁRIO',
    label: 'CONTATO PUBLICITÁRIO',
  },
  {
    value: 'CONTEUDISTA',
    label: 'CONTEUDISTA',
  },
  {
    value: 'CONTRA-REGRA',
    label: 'CONTRA-REGRA',
  },
  {
    value: 'CONTROLADOR DE CUSTOS',
    label: 'CONTROLADOR DE CUSTOS',
  },
  {
    value: 'CONTROLADOR DE PRODUÇÃO',
    label: 'CONTROLADOR DE PRODUÇÃO',
  },
  {
    value: 'CONTROLADOR DE PÁTIO',
    label: 'CONTROLADOR DE PÁTIO',
  },
  {
    value: 'CONTROLADOR DE QUALIDADE',
    label: 'CONTROLADOR DE QUALIDADE',
  },
  {
    value: 'CONTROLADOR DE TRÁFEGO AÉREO',
    label: 'CONTROLADOR DE TRÁFEGO AÉREO',
  },
  {
    value: 'CONTROLADOR DE TRÁFEGO',
    label: 'CONTROLADOR DE TRÁFEGO',
  },
  {
    value: 'CONTROLLER',
    label: 'CONTROLLER',
  },
  {
    value: 'CONTÍNUO',
    label: 'CONTÍNUO',
  },
  {
    value: 'COORDENADOR ADMINISTRATIVO DE OBRAS',
    label: 'COORDENADOR ADMINISTRATIVO DE OBRAS',
  },
  {
    value: 'COORDENADOR ADMINISTRATIVO',
    label: 'COORDENADOR ADMINISTRATIVO',
  },
  {
    value: 'COORDENADOR COMERCIAL',
    label: 'COORDENADOR COMERCIAL',
  },
  {
    value: 'COORDENADOR CONTÁBIL',
    label: 'COORDENADOR CONTÁBIL',
  },
  {
    value: 'COORDENADOR DE ALIMENTOS E BEBIDAS',
    label: 'COORDENADOR DE ALIMENTOS E BEBIDAS',
  },
  {
    value: 'COORDENADOR DE ALMOXARIFADO',
    label: 'COORDENADOR DE ALMOXARIFADO',
  },
  {
    value: 'COORDENADOR DE ARMAZÉM',
    label: 'COORDENADOR DE ARMAZÉM',
  },
  {
    value: 'COORDENADOR DE ASSISTÊNCIA TÉCNICA',
    label: 'COORDENADOR DE ASSISTÊNCIA TÉCNICA',
  },
  {
    value: 'COORDENADOR DE ASSUNTOS REGULATÓRIOS',
    label: 'COORDENADOR DE ASSUNTOS REGULATÓRIOS',
  },
  {
    value: 'COORDENADOR DE ATENDIMENTO AO CLIENTE',
    label: 'COORDENADOR DE ATENDIMENTO AO CLIENTE',
  },
  {
    value: 'COORDENADOR DE AUDITORIA',
    label: 'COORDENADOR DE AUDITORIA',
  },
  {
    value: 'COORDENADOR DE BENEFÍCIOS',
    label: 'COORDENADOR DE BENEFÍCIOS',
  },
  {
    value: 'COORDENADOR DE BIBLIOTECA',
    label: 'COORDENADOR DE BIBLIOTECA',
  },
  {
    value: 'COORDENADOR DE BUSINESS INTELLIGENCE',
    label: 'COORDENADOR DE BUSINESS INTELLIGENCE',
  },
  {
    value: 'COORDENADOR DE CAMPO',
    label: 'COORDENADOR DE CAMPO',
  },
  {
    value: 'COORDENADOR DE CARGOS E SALÁRIOS',
    label: 'COORDENADOR DE CARGOS E SALÁRIOS',
  },
  {
    value: 'COORDENADOR DE COBRANÇA',
    label: 'COORDENADOR DE COBRANÇA',
  },
  {
    value: 'COORDENADOR DE COMPRAS',
    label: 'COORDENADOR DE COMPRAS',
  },
  {
    value: 'COORDENADOR DE COMUNICAÇÃO',
    label: 'COORDENADOR DE COMUNICAÇÃO',
  },
  {
    value: 'COORDENADOR DE COMÉRCIO EXTERIOR',
    label: 'COORDENADOR DE COMÉRCIO EXTERIOR',
  },
  {
    value: 'COORDENADOR DE CONTAS A PAGAR',
    label: 'COORDENADOR DE CONTAS A PAGAR',
  },
  {
    value: 'COORDENADOR DE CONTAS A RECEBER',
    label: 'COORDENADOR DE CONTAS A RECEBER',
  },
  {
    value: 'COORDENADOR DE CONTAS MÉDICAS',
    label: 'COORDENADOR DE CONTAS MÉDICAS',
  },
  {
    value: 'COORDENADOR DE CONTAS',
    label: 'COORDENADOR DE CONTAS',
  },
  {
    value: 'COORDENADOR DE CONTRATOS',
    label: 'COORDENADOR DE CONTRATOS',
  },
  {
    value: 'COORDENADOR DE CONTROLADORIA',
    label: 'COORDENADOR DE CONTROLADORIA',
  },
  {
    value: 'COORDENADOR DE CONTROLES INTERNOS',
    label: 'COORDENADOR DE CONTROLES INTERNOS',
  },
  {
    value: 'COORDENADOR DE CREDENCIAMENTO',
    label: 'COORDENADOR DE CREDENCIAMENTO',
  },
  {
    value: 'COORDENADOR DE CRM',
    label: 'COORDENADOR DE CRM',
  },
  {
    value: 'COORDENADOR DE CRÉDITO E COBRANÇA',
    label: 'COORDENADOR DE CRÉDITO E COBRANÇA',
  },
  {
    value: 'COORDENADOR DE CRÉDITO IMOBILIÁRIO',
    label: 'COORDENADOR DE CRÉDITO IMOBILIÁRIO',
  },
  {
    value: 'COORDENADOR DE CRÉDITO',
    label: 'COORDENADOR DE CRÉDITO',
  },
  {
    value: 'COORDENADOR DE CUSTOMER SERVICE',
    label: 'COORDENADOR DE CUSTOMER SERVICE',
  },
  {
    value: 'COORDENADOR DE CUSTOS',
    label: 'COORDENADOR DE CUSTOS',
  },
  {
    value: 'COORDENADOR DE DEPARTAMENTO PESSOAL',
    label: 'COORDENADOR DE DEPARTAMENTO PESSOAL',
  },
  {
    value: 'COORDENADOR DE DESENVOLVIMENTO DE SISTEMAS',
    label: 'COORDENADOR DE DESENVOLVIMENTO DE SISTEMAS',
  },
  {
    value: 'COORDENADOR DE E-COMMERCE',
    label: 'COORDENADOR DE E-COMMERCE',
  },
  {
    value: 'COORDENADOR DE ENFERMAGEM',
    label: 'COORDENADOR DE ENFERMAGEM',
  },
  {
    value: 'COORDENADOR DE ENGENHARIA',
    label: 'COORDENADOR DE ENGENHARIA',
  },
  {
    value: 'COORDENADOR DE ESTILO',
    label: 'COORDENADOR DE ESTILO',
  },
  {
    value: 'COORDENADOR DE ESTOQUE',
    label: 'COORDENADOR DE ESTOQUE',
  },
  {
    value: 'COORDENADOR DE EVENTOS',
    label: 'COORDENADOR DE EVENTOS',
  },
  {
    value: 'COORDENADOR DE EXPEDIÇÃO',
    label: 'COORDENADOR DE EXPEDIÇÃO',
  },
  {
    value: 'COORDENADOR DE FACILITIES',
    label: 'COORDENADOR DE FACILITIES',
  },
  {
    value: 'COORDENADOR DE FATURAMENTO',
    label: 'COORDENADOR DE FATURAMENTO',
  },
  {
    value: 'COORDENADOR DE FERRAMENTARIA',
    label: 'COORDENADOR DE FERRAMENTARIA',
  },
  {
    value: 'COORDENADOR DE FILIAL',
    label: 'COORDENADOR DE FILIAL',
  },
  {
    value: 'COORDENADOR DE FOLHA DE PAGAMENTO',
    label: 'COORDENADOR DE FOLHA DE PAGAMENTO',
  },
  {
    value: 'COORDENADOR DE GARANTIA DA QUALIDADE',
    label: 'COORDENADOR DE GARANTIA DA QUALIDADE',
  },
  {
    value: 'COORDENADOR DE HELP DESK',
    label: 'COORDENADOR DE HELP DESK',
  },
  {
    value: 'COORDENADOR DE HOTELARIA',
    label: 'COORDENADOR DE HOTELARIA',
  },
  {
    value: 'COORDENADOR DE IMPLANTAÇÃO',
    label: 'COORDENADOR DE IMPLANTAÇÃO',
  },
  {
    value: 'COORDENADOR DE INCORPORAÇÃO',
    label: 'COORDENADOR DE INCORPORAÇÃO',
  },
  {
    value: 'COORDENADOR DE INFRAESTRUTURA',
    label: 'COORDENADOR DE INFRAESTRUTURA',
  },
  {
    value: 'COORDENADOR DE INSTALAÇÕES',
    label: 'COORDENADOR DE INSTALAÇÕES',
  },
  {
    value: 'COORDENADOR DE INTELIGÊNCIA DE MERCADO',
    label: 'COORDENADOR DE INTELIGÊNCIA DE MERCADO',
  },
  {
    value: 'COORDENADOR DE INVENTÁRIO',
    label: 'COORDENADOR DE INVENTÁRIO',
  },
  {
    value: 'COORDENADOR DE INVESTIMENTOS',
    label: 'COORDENADOR DE INVESTIMENTOS',
  },
  {
    value: 'COORDENADOR DE LABORATÓRIO',
    label: 'COORDENADOR DE LABORATÓRIO',
  },
  {
    value: 'COORDENADOR DE LEGALIZAÇÃO',
    label: 'COORDENADOR DE LEGALIZAÇÃO',
  },
  {
    value: 'COORDENADOR DE LICITAÇÃO',
    label: 'COORDENADOR DE LICITAÇÃO',
  },
  {
    value: 'COORDENADOR DE LIMPEZA',
    label: 'COORDENADOR DE LIMPEZA',
  },
  {
    value: 'COORDENADOR DE LOGÍSTICA',
    label: 'COORDENADOR DE LOGÍSTICA',
  },
  {
    value: 'COORDENADOR DE LOJA',
    label: 'COORDENADOR DE LOJA',
  },
  {
    value: 'COORDENADOR DE MANUTENÇÃO AUTOMOTIVA',
    label: 'COORDENADOR DE MANUTENÇÃO AUTOMOTIVA',
  },
  {
    value: 'COORDENADOR DE MANUTENÇÃO INDUSTRIAL',
    label: 'COORDENADOR DE MANUTENÇÃO INDUSTRIAL',
  },
  {
    value: 'COORDENADOR DE MANUTENÇÃO PREDIAL',
    label: 'COORDENADOR DE MANUTENÇÃO PREDIAL',
  },
  {
    value: 'COORDENADOR DE MANUTENÇÃO',
    label: 'COORDENADOR DE MANUTENÇÃO',
  },
  {
    value: 'COORDENADOR DE MARKETING DIGITAL',
    label: 'COORDENADOR DE MARKETING DIGITAL',
  },
  {
    value: 'COORDENADOR DE MARKETING',
    label: 'COORDENADOR DE MARKETING',
  },
  {
    value: 'COORDENADOR DE MEIO AMBIENTE',
    label: 'COORDENADOR DE MEIO AMBIENTE',
  },
  {
    value: 'COORDENADOR DE MELHORIA CONTÍNUA',
    label: 'COORDENADOR DE MELHORIA CONTÍNUA',
  },
  {
    value: 'COORDENADOR DE MERCHANDISING',
    label: 'COORDENADOR DE MERCHANDISING',
  },
  {
    value: 'COORDENADOR DE MÍDIA',
    label: 'COORDENADOR DE MÍDIA',
  },
  {
    value: 'COORDENADOR DE MÍDIAS SOCIAIS',
    label: 'COORDENADOR DE MÍDIAS SOCIAIS',
  },
  {
    value: 'COORDENADOR DE NEGÓCIOS',
    label: 'COORDENADOR DE NEGÓCIOS',
  },
  {
    value: 'COORDENADOR DE OBRAS',
    label: 'COORDENADOR DE OBRAS',
  },
  {
    value: 'COORDENADOR DE ORÇAMENTO',
    label: 'COORDENADOR DE ORÇAMENTO',
  },
  {
    value: 'COORDENADOR DE PATRIMÔNIO',
    label: 'COORDENADOR DE PATRIMÔNIO',
  },
  {
    value: 'COORDENADOR DE PCP',
    label: 'COORDENADOR DE PCP',
  },
  {
    value: 'COORDENADOR DE PESQUISA DE MERCADO',
    label: 'COORDENADOR DE PESQUISA DE MERCADO',
  },
  {
    value: 'COORDENADOR DE PESQUISA E DESENVOLVIMENTO',
    label: 'COORDENADOR DE PESQUISA E DESENVOLVIMENTO',
  },
  {
    value: 'COORDENADOR DE PLANEJAMENTO',
    label: 'COORDENADOR DE PLANEJAMENTO',
  },
  {
    value: 'COORDENADOR DE PREVENÇÃO DE PERDAS',
    label: 'COORDENADOR DE PREVENÇÃO DE PERDAS',
  },
  {
    value: 'COORDENADOR DE PRICING',
    label: 'COORDENADOR DE PRICING',
  },
  {
    value: 'COORDENADOR DE PROCESSOS',
    label: 'COORDENADOR DE PROCESSOS',
  },
  {
    value: 'COORDENADOR DE PRODUTO',
    label: 'COORDENADOR DE PRODUTO',
  },
  {
    value: 'COORDENADOR DE PRODUÇÃO',
    label: 'COORDENADOR DE PRODUÇÃO',
  },
  {
    value: 'COORDENADOR DE PROJETO SOCIAL',
    label: 'COORDENADOR DE PROJETO SOCIAL',
  },
  {
    value: 'COORDENADOR DE PROJETOS DE CONSTRUÇÃO CIVIL',
    label: 'COORDENADOR DE PROJETOS DE CONSTRUÇÃO CIVIL',
  },
  {
    value: 'COORDENADOR DE PROJETOS',
    label: 'COORDENADOR DE PROJETOS',
  },
  {
    value: 'COORDENADOR DE PÓS-VENDA',
    label: 'COORDENADOR DE PÓS-VENDA',
  },
  {
    value: 'COORDENADOR DE QUALIDADE',
    label: 'COORDENADOR DE QUALIDADE',
  },
  {
    value: 'COORDENADOR DE RECEPÇÃO',
    label: 'COORDENADOR DE RECEPÇÃO',
  },
  {
    value: 'COORDENADOR DE RECRUTAMENTO E SELEÇÃO',
    label: 'COORDENADOR DE RECRUTAMENTO E SELEÇÃO',
  },
  {
    value: 'COORDENADOR DE RECURSOS HUMANOS',
    label: 'COORDENADOR DE RECURSOS HUMANOS',
  },
  {
    value: 'COORDENADOR DE REDES',
    label: 'COORDENADOR DE REDES',
  },
  {
    value: 'COORDENADOR DE RESERVAS',
    label: 'COORDENADOR DE RESERVAS',
  },
  {
    value: 'COORDENADOR DE RESPONSABILIDADE SOCIAL',
    label: 'COORDENADOR DE RESPONSABILIDADE SOCIAL',
  },
  {
    value: 'COORDENADOR DE RESTAURANTE',
    label: 'COORDENADOR DE RESTAURANTE',
  },
  {
    value: 'COORDENADOR DE SAÚDE, SEGURANÇA E MEIO AMBIENTE',
    label: 'COORDENADOR DE SAÚDE, SEGURANÇA E MEIO AMBIENTE',
  },
  {
    value: 'COORDENADOR DE SEGURANÇA DA INFORMAÇÃO',
    label: 'COORDENADOR DE SEGURANÇA DA INFORMAÇÃO',
  },
  {
    value: 'COORDENADOR DE SEGURANÇA DO TRABALHO',
    label: 'COORDENADOR DE SEGURANÇA DO TRABALHO',
  },
  {
    value: 'COORDENADOR DE SEGURANÇA PATRIMONIAL',
    label: 'COORDENADOR DE SEGURANÇA PATRIMONIAL',
  },
  {
    value: 'COORDENADOR DE SEGUROS',
    label: 'COORDENADOR DE SEGUROS',
  },
  {
    value: 'COORDENADOR DE SERVICE DESK',
    label: 'COORDENADOR DE SERVICE DESK',
  },
  {
    value: 'COORDENADOR DE SERVIÇOS GERAIS',
    label: 'COORDENADOR DE SERVIÇOS GERAIS',
  },
  {
    value: 'COORDENADOR DE SINISTRO',
    label: 'COORDENADOR DE SINISTRO',
  },
  {
    value: 'COORDENADOR DE SUPORTE TÉCNICO',
    label: 'COORDENADOR DE SUPORTE TÉCNICO',
  },
  {
    value: 'COORDENADOR DE SUPRIMENTOS',
    label: 'COORDENADOR DE SUPRIMENTOS',
  },
  {
    value: 'COORDENADOR DE SUSTENTABILIDADE',
    label: 'COORDENADOR DE SUSTENTABILIDADE',
  },
  {
    value: 'COORDENADOR DE TECNOLOGIA DA INFORMAÇÃO',
    label: 'COORDENADOR DE TECNOLOGIA DA INFORMAÇÃO',
  },
  {
    value: 'COORDENADOR DE TELECOMUNICAÇÕES',
    label: 'COORDENADOR DE TELECOMUNICAÇÕES',
  },
  {
    value: 'COORDENADOR DE TESOURARIA',
    label: 'COORDENADOR DE TESOURARIA',
  },
  {
    value: 'COORDENADOR DE TOPOGRAFIA',
    label: 'COORDENADOR DE TOPOGRAFIA',
  },
  {
    value: 'COORDENADOR DE TRADE MARKETING',
    label: 'COORDENADOR DE TRADE MARKETING',
  },
  {
    value: 'COORDENADOR DE TRANSPORTES',
    label: 'COORDENADOR DE TRANSPORTES',
  },
  {
    value: 'COORDENADOR DE TREINAMENTO E DESENVOLVIMENTO',
    label: 'COORDENADOR DE TREINAMENTO E DESENVOLVIMENTO',
  },
  {
    value: 'COORDENADOR DE USINAGEM',
    label: 'COORDENADOR DE USINAGEM',
  },
  {
    value: 'COORDENADOR DE VENDAS',
    label: 'COORDENADOR DE VENDAS',
  },
  {
    value: 'COORDENADOR DE VISUAL MERCHANDISING',
    label: 'COORDENADOR DE VISUAL MERCHANDISING',
  },
  {
    value: 'COORDENADOR EDITORIAL',
    label: 'COORDENADOR EDITORIAL',
  },
  {
    value: 'COORDENADOR ELETRÔNICO',
    label: 'COORDENADOR ELETRÔNICO',
  },
  {
    value: 'COORDENADOR FINANCEIRO',
    label: 'COORDENADOR FINANCEIRO',
  },
  {
    value: 'COORDENADOR FISCAL',
    label: 'COORDENADOR FISCAL',
  },
  {
    value: 'COORDENADOR IMOBILIÁRIO',
    label: 'COORDENADOR IMOBILIÁRIO',
  },
  {
    value: 'COORDENADOR INDUSTRIAL',
    label: 'COORDENADOR INDUSTRIAL',
  },
  {
    value: 'COORDENADOR JURÍDICO',
    label: 'COORDENADOR JURÍDICO',
  },
  {
    value: 'COORDENADOR MÉDICO',
    label: 'COORDENADOR MÉDICO',
  },
  {
    value: 'COORDENADOR OPERACIONAL',
    label: 'COORDENADOR OPERACIONAL',
  },
  {
    value: 'COORDENADOR PEDAGÓGICO',
    label: 'COORDENADOR PEDAGÓGICO',
  },
  {
    value: 'COORDENADOR TRIBUTÁRIO',
    label: 'COORDENADOR TRIBUTÁRIO',
  },
  {
    value: 'COPEIRO',
    label: 'COPEIRO',
  },
  {
    value: 'COPIADOR DE CHAPA',
    label: 'COPIADOR DE CHAPA',
  },
  {
    value: 'COREÓGRAFO',
    label: 'COREÓGRAFO',
  },
  {
    value: 'CORRETOR DE IMÓVEIS',
    label: 'CORRETOR DE IMÓVEIS',
  },
  {
    value: 'CORRETOR DE SEGUROS',
    label: 'CORRETOR DE SEGUROS',
  },
  {
    value: 'CORTADOR DE TECIDOS',
    label: 'CORTADOR DE TECIDOS',
  },
  {
    value: 'CORTADOR DE VIDRO',
    label: 'CORTADOR DE VIDRO',
  },
  {
    value: 'CORTADOR',
    label: 'CORTADOR',
  },
  {
    value: 'COSTUREIRO',
    label: 'COSTUREIRO',
  },
  {
    value: 'COZINHEIRO',
    label: 'COZINHEIRO',
  },
  {
    value: 'CREPEIRO',
    label: 'CREPEIRO',
  },
  {
    value: 'CRONOANALISTA',
    label: 'CRONOANALISTA',
  },
  {
    value: 'CUIDADOR DE CRIANÇAS',
    label: 'CUIDADOR DE CRIANÇAS',
  },
  {
    value: 'CUIDADOR DE IDOSOS',
    label: 'CUIDADOR DE IDOSOS',
  },
  {
    value: 'CUMIM',
    label: 'CUMIM',
  },
  {
    value: 'CURADOR DE ARTE',
    label: 'CURADOR DE ARTE',
  },
  {
    value: 'DANÇARINO',
    label: 'DANÇARINO',
  },
  {
    value: 'DATA SCIENTIST',
    label: 'DATA SCIENTIST',
  },
  {
    value: 'DBA ORACLE',
    label: 'DBA ORACLE',
  },
  {
    value: 'DBA SQL',
    label: 'DBA SQL',
  },
  {
    value: 'DBA',
    label: 'DBA',
  },
  {
    value: 'DECORADOR',
    label: 'DECORADOR',
  },
  {
    value: 'DEDETIZADOR',
    label: 'DEDETIZADOR',
  },
  {
    value: 'DEGUSTADOR',
    label: 'DEGUSTADOR',
  },
  {
    value: 'DENTISTA AUDITOR',
    label: 'DENTISTA AUDITOR',
  },
  {
    value: 'DENTISTA ENDODONTISTA',
    label: 'DENTISTA ENDODONTISTA',
  },
  {
    value: 'DENTISTA ESTOMATOLOGISTA',
    label: 'DENTISTA ESTOMATOLOGISTA',
  },
  {
    value: 'DENTISTA IMPLANTODONTISTA',
    label: 'DENTISTA IMPLANTODONTISTA',
  },
  {
    value: 'DENTISTA ODONTOPEDIATRA',
    label: 'DENTISTA ODONTOPEDIATRA',
  },
  {
    value: 'DENTISTA ORTODONTISTA',
    label: 'DENTISTA ORTODONTISTA',
  },
  {
    value: 'DENTISTA PERIODONTISTA',
    label: 'DENTISTA PERIODONTISTA',
  },
  {
    value: 'DENTISTA PROTESISTA',
    label: 'DENTISTA PROTESISTA',
  },
  {
    value: 'DENTISTA TRAUMATOLOGISTA',
    label: 'DENTISTA TRAUMATOLOGISTA',
  },
  {
    value: 'DENTISTA',
    label: 'DENTISTA',
  },
  {
    value: 'DEPARTAMENTO PESSOAL',
    label: 'DEPARTAMENTO PESSOAL',
  },
  {
    value: 'DEPILADORA',
    label: 'DEPILADORA',
  },
  {
    value: 'DERMOCONSULTOR',
    label: 'DERMOCONSULTOR',
  },
  {
    value: 'DESENHISTA CADISTA',
    label: 'DESENHISTA CADISTA',
  },
  {
    value: 'DESENHISTA COPISTA',
    label: 'DESENHISTA COPISTA',
  },
  {
    value: 'DESENHISTA ELÉTRICO',
    label: 'DESENHISTA ELÉTRICO',
  },
  {
    value: 'DESENHISTA INDUSTRIAL',
    label: 'DESENHISTA INDUSTRIAL',
  },
  {
    value: 'DESENHISTA MECÂNICO',
    label: 'DESENHISTA MECÂNICO',
  },
  {
    value: 'DESENHISTA ORÇAMENTISTA',
    label: 'DESENHISTA ORÇAMENTISTA',
  },
  {
    value: 'DESENHISTA PROJETISTA CIVIL',
    label: 'DESENHISTA PROJETISTA CIVIL',
  },
  {
    value: 'DESENHISTA PROJETISTA ELÉTRICO',
    label: 'DESENHISTA PROJETISTA ELÉTRICO',
  },
  {
    value: 'DESENHISTA PROJETISTA HIDRÁULICO',
    label: 'DESENHISTA PROJETISTA HIDRÁULICO',
  },
  {
    value: 'DESENHISTA PROJETISTA MECÂNICO',
    label: 'DESENHISTA PROJETISTA MECÂNICO',
  },
  {
    value: 'DESENHISTA PROJETISTA',
    label: 'DESENHISTA PROJETISTA',
  },
  {
    value: 'DESENHISTA TÉCNICO',
    label: 'DESENHISTA TÉCNICO',
  },
  {
    value: 'DESENHISTA',
    label: 'DESENHISTA',
  },
  {
    value: 'DESENTUPIDOR',
    label: 'DESENTUPIDOR',
  },
  {
    value: 'DESIGN',
    label: 'DESIGN',
  },
  {
    value: 'DESIGNER DE ACESSÓRIOS',
    label: 'DESIGNER DE ACESSÓRIOS',
  },
  {
    value: 'DESIGNER DE CALÇADOS',
    label: 'DESIGNER DE CALÇADOS',
  },
  {
    value: 'DESIGNER DE EMBALAGEM',
    label: 'DESIGNER DE EMBALAGEM',
  },
  {
    value: 'DESIGNER DE INTERAÇÃO',
    label: 'DESIGNER DE INTERAÇÃO',
  },
  {
    value: 'DESIGNER DE INTERFACE',
    label: 'DESIGNER DE INTERFACE',
  },
  {
    value: 'DESIGNER DE INTERIORES',
    label: 'DESIGNER DE INTERIORES',
  },
  {
    value: 'DESIGNER DE JOGOS',
    label: 'DESIGNER DE JOGOS',
  },
  {
    value: 'DESIGNER DE JOIAS',
    label: 'DESIGNER DE JOIAS',
  },
  {
    value: 'DESIGNER DE MODA',
    label: 'DESIGNER DE MODA',
  },
  {
    value: 'DESIGNER DE PRODUTO',
    label: 'DESIGNER DE PRODUTO',
  },
  {
    value: 'DESIGNER DE SOBRANCELHA',
    label: 'DESIGNER DE SOBRANCELHA',
  },
  {
    value: 'DESIGNER DIGITAL',
    label: 'DESIGNER DIGITAL',
  },
  {
    value: 'DESIGNER GRÁFICO',
    label: 'DESIGNER GRÁFICO',
  },
  {
    value: 'DESIGNER INSTRUCIONAL',
    label: 'DESIGNER INSTRUCIONAL',
  },
  {
    value: 'DESIGNER MULTIMÍDIA',
    label: 'DESIGNER MULTIMÍDIA',
  },
  {
    value: 'DESIGNER TÊXTIL',
    label: 'DESIGNER TÊXTIL',
  },
  {
    value: 'DESIGNER',
    label: 'DESIGNER',
  },
  {
    value: 'DESPACHANTE ADUANEIRO',
    label: 'DESPACHANTE ADUANEIRO',
  },
  {
    value: 'DESPACHANTE IMOBILIÁRIO',
    label: 'DESPACHANTE IMOBILIÁRIO',
  },
  {
    value: 'DESPACHANTE OPERACIONAL DE VOO',
    label: 'DESPACHANTE OPERACIONAL DE VOO',
  },
  {
    value: 'DESPACHANTE',
    label: 'DESPACHANTE',
  },
  {
    value: 'DIAGRAMADOR',
    label: 'DIAGRAMADOR',
  },
  {
    value: 'DIGITADOR DE LAUDOS',
    label: 'DIGITADOR DE LAUDOS',
  },
  {
    value: 'DIGITADOR',
    label: 'DIGITADOR',
  },
  {
    value: 'DIGITALIZADOR',
    label: 'DIGITALIZADOR',
  },
  {
    value: 'DILIGENCIADOR',
    label: 'DILIGENCIADOR',
  },
  {
    value: 'DIRETOR ADMINISTRATIVO',
    label: 'DIRETOR ADMINISTRATIVO',
  },
  {
    value: 'DIRETOR COMERCIAL',
    label: 'DIRETOR COMERCIAL',
  },
  {
    value: 'DIRETOR DE ARTE',
    label: 'DIRETOR DE ARTE',
  },
  {
    value: 'DIRETOR DE CINEMA',
    label: 'DIRETOR DE CINEMA',
  },
  {
    value: 'DIRETOR DE COMPRAS',
    label: 'DIRETOR DE COMPRAS',
  },
  {
    value: 'DIRETOR DE CRIAÇÃO',
    label: 'DIRETOR DE CRIAÇÃO',
  },
  {
    value: 'DIRETOR DE LOGÍSTICA',
    label: 'DIRETOR DE LOGÍSTICA',
  },
  {
    value: 'DIRETOR DE LOJA',
    label: 'DIRETOR DE LOJA',
  },
  {
    value: 'DIRETOR DE MARKETING',
    label: 'DIRETOR DE MARKETING',
  },
  {
    value: 'DIRETOR DE NEGÓCIOS',
    label: 'DIRETOR DE NEGÓCIOS',
  },
  {
    value: 'DIRETOR DE OBRAS',
    label: 'DIRETOR DE OBRAS',
  },
  {
    value: 'DIRETOR DE OPERAÇÕES',
    label: 'DIRETOR DE OPERAÇÕES',
  },
  {
    value: 'DIRETOR DE RECURSOS HUMANOS',
    label: 'DIRETOR DE RECURSOS HUMANOS',
  },
  {
    value: 'DIRETOR DE TEATRO',
    label: 'DIRETOR DE TEATRO',
  },
  {
    value: 'DIRETOR DE TECNOLOGIA DA INFORMAÇÃO',
    label: 'DIRETOR DE TECNOLOGIA DA INFORMAÇÃO',
  },
  {
    value: 'DIRETOR DE VENDAS',
    label: 'DIRETOR DE VENDAS',
  },
  {
    value: 'DIRETOR FINANCEIRO',
    label: 'DIRETOR FINANCEIRO',
  },
  {
    value: 'DIRETOR GERAL',
    label: 'DIRETOR GERAL',
  },
  {
    value: 'DIRETOR HOSPITALAR',
    label: 'DIRETOR HOSPITALAR',
  },
  {
    value: 'DIRETOR INDUSTRIAL',
    label: 'DIRETOR INDUSTRIAL',
  },
  {
    value: 'DIRETOR JURÍDICO',
    label: 'DIRETOR JURÍDICO',
  },
  {
    value: 'DIRETOR PEDAGÓGICO',
    label: 'DIRETOR PEDAGÓGICO',
  },
  {
    value: 'DIVULGADOR',
    label: 'DIVULGADOR',
  },
  {
    value: 'DJ',
    label: 'DJ',
  },
  {
    value: 'DOCUMENTADOR',
    label: 'DOCUMENTADOR',
  },
  {
    value: 'DOMÉSTICA',
    label: 'DOMÉSTICA',
  },
  {
    value: 'DUTEIRO',
    label: 'DUTEIRO',
  },
  {
    value: 'ECONOMISTA',
    label: 'ECONOMISTA',
  },
  {
    value: 'ECÓLOGO',
    label: 'ECÓLOGO',
  },
  {
    value: 'EDITOR DE IMAGENS',
    label: 'EDITOR DE IMAGENS',
  },
  {
    value: 'EDITOR DE MODA',
    label: 'EDITOR DE MODA',
  },
  {
    value: 'EDITOR DE TEXTO',
    label: 'EDITOR DE TEXTO',
  },
  {
    value: 'EDITOR DE VÍDEO',
    label: 'EDITOR DE VÍDEO',
  },
  {
    value: 'EDUCADOR AMBIENTAL',
    label: 'EDUCADOR AMBIENTAL',
  },
  {
    value: 'EDUCADOR SOCIAL',
    label: 'EDUCADOR SOCIAL',
  },
  {
    value: 'ELETRICISTA DE AR CONDICIONADO',
    label: 'ELETRICISTA DE AR CONDICIONADO',
  },
  {
    value: 'ELETRICISTA DE EMPILHADEIRA',
    label: 'ELETRICISTA DE EMPILHADEIRA',
  },
  {
    value: 'ELETRICISTA DE FORÇA E CONTROLE',
    label: 'ELETRICISTA DE FORÇA E CONTROLE',
  },
  {
    value: 'ELETRICISTA DE INSTALAÇÕES',
    label: 'ELETRICISTA DE INSTALAÇÕES',
  },
  {
    value: 'ELETRICISTA DE VEÍCULOS',
    label: 'ELETRICISTA DE VEÍCULOS',
  },
  {
    value: 'ELETRICISTA ELETRÔNICO',
    label: 'ELETRICISTA ELETRÔNICO',
  },
  {
    value: 'ELETRICISTA INDUSTRIAL',
    label: 'ELETRICISTA INDUSTRIAL',
  },
  {
    value: 'ELETRICISTA MONTADOR DE PAINEL',
    label: 'ELETRICISTA MONTADOR DE PAINEL',
  },
  {
    value: 'ELETRICISTA MONTADOR',
    label: 'ELETRICISTA MONTADOR',
  },
  {
    value: 'ELETRICISTA PREDIAL',
    label: 'ELETRICISTA PREDIAL',
  },
  {
    value: 'ELETRICISTA',
    label: 'ELETRICISTA',
  },
  {
    value: 'ELETROMECÂNICO DE MANUTENÇÃO',
    label: 'ELETROMECÂNICO DE MANUTENÇÃO',
  },
  {
    value: 'ELETROMECÂNICO',
    label: 'ELETROMECÂNICO',
  },
  {
    value: 'ELETROTÉCNICO',
    label: 'ELETROTÉCNICO',
  },
  {
    value: 'EMBALADOR',
    label: 'EMBALADOR',
  },
  {
    value: 'EMBRIOLOGISTA',
    label: 'EMBRIOLOGISTA',
  },
  {
    value: 'EMISSOR DE CTRC',
    label: 'EMISSOR DE CTRC',
  },
  {
    value: 'EMISSOR DE PASSAGENS',
    label: 'EMISSOR DE PASSAGENS',
  },
  {
    value: 'EMPACOTADOR',
    label: 'EMPACOTADOR',
  },
  {
    value: 'ENCADERNADOR',
    label: 'ENCADERNADOR',
  },
  {
    value: 'ENCANADOR INDUSTRIAL',
    label: 'ENCANADOR INDUSTRIAL',
  },
  {
    value: 'ENCANADOR',
    label: 'ENCANADOR',
  },
  {
    value: 'ENCAPSULADOR',
    label: 'ENCAPSULADOR',
  },
  {
    value: 'ENCARREGADO ADMINISTRATIVO DE OBRAS',
    label: 'ENCARREGADO ADMINISTRATIVO DE OBRAS',
  },
  {
    value: 'ENCARREGADO ADMINISTRATIVO',
    label: 'ENCARREGADO ADMINISTRATIVO',
  },
  {
    value: 'ENCARREGADO COMERCIAL',
    label: 'ENCARREGADO COMERCIAL',
  },
  {
    value: 'ENCARREGADO CONTÁBIL',
    label: 'ENCARREGADO CONTÁBIL',
  },
  {
    value: 'ENCARREGADO DE ALMOXARIFADO',
    label: 'ENCARREGADO DE ALMOXARIFADO',
  },
  {
    value: 'ENCARREGADO DE ASSISTÊNCIA TÉCNICA',
    label: 'ENCARREGADO DE ASSISTÊNCIA TÉCNICA',
  },
  {
    value: 'ENCARREGADO DE AÇOUGUE',
    label: 'ENCARREGADO DE AÇOUGUE',
  },
  {
    value: 'ENCARREGADO DE CALDEIRARIA',
    label: 'ENCARREGADO DE CALDEIRARIA',
  },
  {
    value: 'ENCARREGADO DE CARPINTARIA',
    label: 'ENCARREGADO DE CARPINTARIA',
  },
  {
    value: 'ENCARREGADO DE COBRANÇA',
    label: 'ENCARREGADO DE COBRANÇA',
  },
  {
    value: 'ENCARREGADO DE COMPRAS',
    label: 'ENCARREGADO DE COMPRAS',
  },
  {
    value: 'ENCARREGADO DE CORTE',
    label: 'ENCARREGADO DE CORTE',
  },
  {
    value: 'ENCARREGADO DE ELÉTRICA',
    label: 'ENCARREGADO DE ELÉTRICA',
  },
  {
    value: 'ENCARREGADO DE ESTACIONAMENTO',
    label: 'ENCARREGADO DE ESTACIONAMENTO',
  },
  {
    value: 'ENCARREGADO DE ESTAMPARIA',
    label: 'ENCARREGADO DE ESTAMPARIA',
  },
  {
    value: 'ENCARREGADO DE ESTOQUE',
    label: 'ENCARREGADO DE ESTOQUE',
  },
  {
    value: 'ENCARREGADO DE EXPEDIÇÃO',
    label: 'ENCARREGADO DE EXPEDIÇÃO',
  },
  {
    value: 'ENCARREGADO DE FATURAMENTO',
    label: 'ENCARREGADO DE FATURAMENTO',
  },
  {
    value: 'ENCARREGADO DE FROTA',
    label: 'ENCARREGADO DE FROTA',
  },
  {
    value: 'ENCARREGADO DE HIDRÁULICA',
    label: 'ENCARREGADO DE HIDRÁULICA',
  },
  {
    value: 'ENCARREGADO DE INJEÇÃO PLÁSTICA',
    label: 'ENCARREGADO DE INJEÇÃO PLÁSTICA',
  },
  {
    value: 'ENCARREGADO DE INSTALAÇÕES',
    label: 'ENCARREGADO DE INSTALAÇÕES',
  },
  {
    value: 'ENCARREGADO DE JARDINAGEM',
    label: 'ENCARREGADO DE JARDINAGEM',
  },
  {
    value: 'ENCARREGADO DE LIMPEZA',
    label: 'ENCARREGADO DE LIMPEZA',
  },
  {
    value: 'ENCARREGADO DE LOGÍSTICA',
    label: 'ENCARREGADO DE LOGÍSTICA',
  },
  {
    value: 'ENCARREGADO DE LOJA',
    label: 'ENCARREGADO DE LOJA',
  },
  {
    value: 'ENCARREGADO DE MANUTENÇÃO PREDIAL',
    label: 'ENCARREGADO DE MANUTENÇÃO PREDIAL',
  },
  {
    value: 'ENCARREGADO DE MANUTENÇÃO',
    label: 'ENCARREGADO DE MANUTENÇÃO',
  },
  {
    value: 'ENCARREGADO DE MARCENARIA',
    label: 'ENCARREGADO DE MARCENARIA',
  },
  {
    value: 'ENCARREGADO DE MECÂNICA',
    label: 'ENCARREGADO DE MECÂNICA',
  },
  {
    value: 'ENCARREGADO DE MONTAGEM',
    label: 'ENCARREGADO DE MONTAGEM',
  },
  {
    value: 'ENCARREGADO DE OBRAS',
    label: 'ENCARREGADO DE OBRAS',
  },
  {
    value: 'ENCARREGADO DE OFICINA',
    label: 'ENCARREGADO DE OFICINA',
  },
  {
    value: 'ENCARREGADO DE OPERAÇÕES',
    label: 'ENCARREGADO DE OPERAÇÕES',
  },
  {
    value: 'ENCARREGADO DE PADARIA',
    label: 'ENCARREGADO DE PADARIA',
  },
  {
    value: 'ENCARREGADO DE PEIXARIA',
    label: 'ENCARREGADO DE PEIXARIA',
  },
  {
    value: 'ENCARREGADO DE PERECÍVEIS',
    label: 'ENCARREGADO DE PERECÍVEIS',
  },
  {
    value: 'ENCARREGADO DE PREVENÇÃO DE PERDAS',
    label: 'ENCARREGADO DE PREVENÇÃO DE PERDAS',
  },
  {
    value: 'ENCARREGADO DE PRODUÇÃO',
    label: 'ENCARREGADO DE PRODUÇÃO',
  },
  {
    value: 'ENCARREGADO DE PÁTIO',
    label: 'ENCARREGADO DE PÁTIO',
  },
  {
    value: 'ENCARREGADO DE SERRALHERIA',
    label: 'ENCARREGADO DE SERRALHERIA',
  },
  {
    value: 'ENCARREGADO DE SERVIÇOS GERAIS',
    label: 'ENCARREGADO DE SERVIÇOS GERAIS',
  },
  {
    value: 'ENCARREGADO DE SOLDA',
    label: 'ENCARREGADO DE SOLDA',
  },
  {
    value: 'ENCARREGADO DE SONDAGEM',
    label: 'ENCARREGADO DE SONDAGEM',
  },
  {
    value: 'ENCARREGADO DE TRANSPORTES',
    label: 'ENCARREGADO DE TRANSPORTES',
  },
  {
    value: 'ENCARREGADO DE USINAGEM',
    label: 'ENCARREGADO DE USINAGEM',
  },
  {
    value: 'ENCARREGADO FINANCEIRO',
    label: 'ENCARREGADO FINANCEIRO',
  },
  {
    value: 'ENCARREGADO INDUSTRIAL',
    label: 'ENCARREGADO INDUSTRIAL',
  },
  {
    value: 'ENCARREGADO OPERACIONAL',
    label: 'ENCARREGADO OPERACIONAL',
  },
  {
    value: 'ENFERMEIRO AUDITOR',
    label: 'ENFERMEIRO AUDITOR',
  },
  {
    value: 'ENFERMEIRO CCIH',
    label: 'ENFERMEIRO CCIH',
  },
  {
    value: 'ENFERMEIRO CHEFE',
    label: 'ENFERMEIRO CHEFE',
  },
  {
    value: 'ENFERMEIRO CME',
    label: 'ENFERMEIRO CME',
  },
  {
    value: 'ENFERMEIRO DE CENTRO CIRÚRGICO',
    label: 'ENFERMEIRO DE CENTRO CIRÚRGICO',
  },
  {
    value: 'ENFERMEIRO DE EDUCAÇÃO CONTINUADA',
    label: 'ENFERMEIRO DE EDUCAÇÃO CONTINUADA',
  },
  {
    value: 'ENFERMEIRO DE UTI',
    label: 'ENFERMEIRO DE UTI',
  },
  {
    value: 'ENFERMEIRO DO TRABALHO',
    label: 'ENFERMEIRO DO TRABALHO',
  },
  {
    value: 'ENFERMEIRO ESTOMATERAPEUTA',
    label: 'ENFERMEIRO ESTOMATERAPEUTA',
  },
  {
    value: 'ENFERMEIRO NEFROLOGISTA',
    label: 'ENFERMEIRO NEFROLOGISTA',
  },
  {
    value: 'ENFERMEIRO OBSTETRA',
    label: 'ENFERMEIRO OBSTETRA',
  },
  {
    value: 'ENFERMEIRO RESPONSÁVEL TÉCNICO',
    label: 'ENFERMEIRO RESPONSÁVEL TÉCNICO',
  },
  {
    value: 'ENFERMEIRO SCIH',
    label: 'ENFERMEIRO SCIH',
  },
  {
    value: 'ENFERMEIRO VISITADOR',
    label: 'ENFERMEIRO VISITADOR',
  },
  {
    value: 'ENFERMEIRO',
    label: 'ENFERMEIRO',
  },
  {
    value: 'ENFESTADOR',
    label: 'ENFESTADOR',
  },
  {
    value: 'ENGENHEIRO AERONÁUTICO',
    label: 'ENGENHEIRO AERONÁUTICO',
  },
  {
    value: 'ENGENHEIRO AGRIMENSOR',
    label: 'ENGENHEIRO AGRIMENSOR',
  },
  {
    value: 'ENGENHEIRO AGRÍCOLA',
    label: 'ENGENHEIRO AGRÍCOLA',
  },
  {
    value: 'ENGENHEIRO AGRÔNOMO',
    label: 'ENGENHEIRO AGRÔNOMO',
  },
  {
    value: 'ENGENHEIRO AMBIENTAL',
    label: 'ENGENHEIRO AMBIENTAL',
  },
  {
    value: 'ENGENHEIRO AUTOMOBILÍSTICO',
    label: 'ENGENHEIRO AUTOMOBILÍSTICO',
  },
  {
    value: 'ENGENHEIRO BIOMÉDICO',
    label: 'ENGENHEIRO BIOMÉDICO',
  },
  {
    value: 'ENGENHEIRO CALCULISTA',
    label: 'ENGENHEIRO CALCULISTA',
  },
  {
    value: 'ENGENHEIRO CARTÓGRAFO',
    label: 'ENGENHEIRO CARTÓGRAFO',
  },
  {
    value: 'ENGENHEIRO CIVIL',
    label: 'ENGENHEIRO CIVIL',
  },
  {
    value: 'ENGENHEIRO CLÍNICO',
    label: 'ENGENHEIRO CLÍNICO',
  },
  {
    value: 'ENGENHEIRO DA QUALIDADE',
    label: 'ENGENHEIRO DA QUALIDADE',
  },
  {
    value: 'ENGENHEIRO DE ALIMENTOS',
    label: 'ENGENHEIRO DE ALIMENTOS',
  },
  {
    value: 'ENGENHEIRO DE APLICAÇÃO',
    label: 'ENGENHEIRO DE APLICAÇÃO',
  },
  {
    value: 'ENGENHEIRO DE AR CONDICIONADO',
    label: 'ENGENHEIRO DE AR CONDICIONADO',
  },
  {
    value: 'ENGENHEIRO DE ASSISTÊNCIA TÉCNICA',
    label: 'ENGENHEIRO DE ASSISTÊNCIA TÉCNICA',
  },
  {
    value: 'ENGENHEIRO DE AUTOMAÇÃO INDUSTRIAL',
    label: 'ENGENHEIRO DE AUTOMAÇÃO INDUSTRIAL',
  },
  {
    value: 'ENGENHEIRO DE CAMPO',
    label: 'ENGENHEIRO DE CAMPO',
  },
  {
    value: 'ENGENHEIRO DE COMPRAS',
    label: 'ENGENHEIRO DE COMPRAS',
  },
  {
    value: 'ENGENHEIRO DE COMPUTAÇÃO',
    label: 'ENGENHEIRO DE COMPUTAÇÃO',
  },
  {
    value: 'ENGENHEIRO DE CONTROLE E AUTOMAÇÃO',
    label: 'ENGENHEIRO DE CONTROLE E AUTOMAÇÃO',
  },
  {
    value: 'ENGENHEIRO DE CUSTOS',
    label: 'ENGENHEIRO DE CUSTOS',
  },
  {
    value: 'ENGENHEIRO DE INSTALAÇÕES',
    label: 'ENGENHEIRO DE INSTALAÇÕES',
  },
  {
    value: 'ENGENHEIRO DE INSTRUMENTAÇÃO',
    label: 'ENGENHEIRO DE INSTRUMENTAÇÃO',
  },
  {
    value: 'ENGENHEIRO DE MANUFATURA',
    label: 'ENGENHEIRO DE MANUFATURA',
  },
  {
    value: 'ENGENHEIRO DE MANUTENÇÃO PREDIAL',
    label: 'ENGENHEIRO DE MANUTENÇÃO PREDIAL',
  },
  {
    value: 'ENGENHEIRO DE MANUTENÇÃO',
    label: 'ENGENHEIRO DE MANUTENÇÃO',
  },
  {
    value: 'ENGENHEIRO DE MATERIAIS',
    label: 'ENGENHEIRO DE MATERIAIS',
  },
  {
    value: 'ENGENHEIRO DE MINAS',
    label: 'ENGENHEIRO DE MINAS',
  },
  {
    value: 'ENGENHEIRO DE PESCA',
    label: 'ENGENHEIRO DE PESCA',
  },
  {
    value: 'ENGENHEIRO DE PETRÓLEO',
    label: 'ENGENHEIRO DE PETRÓLEO',
  },
  {
    value: 'ENGENHEIRO DE PLANEJAMENTO',
    label: 'ENGENHEIRO DE PLANEJAMENTO',
  },
  {
    value: 'ENGENHEIRO DE PROCESSOS',
    label: 'ENGENHEIRO DE PROCESSOS',
  },
  {
    value: 'ENGENHEIRO DE PRODUTO',
    label: 'ENGENHEIRO DE PRODUTO',
  },
  {
    value: 'ENGENHEIRO DE PRODUÇÃO',
    label: 'ENGENHEIRO DE PRODUÇÃO',
  },
  {
    value: 'ENGENHEIRO DE PROJETOS',
    label: 'ENGENHEIRO DE PROJETOS',
  },
  {
    value: 'ENGENHEIRO DE PROPOSTAS',
    label: 'ENGENHEIRO DE PROPOSTAS',
  },
  {
    value: 'ENGENHEIRO DE SEGURANÇA DO TRABALHO',
    label: 'ENGENHEIRO DE SEGURANÇA DO TRABALHO',
  },
  {
    value: 'ENGENHEIRO DE SERVIÇOS',
    label: 'ENGENHEIRO DE SERVIÇOS',
  },
  {
    value: 'ENGENHEIRO DE SISTEMAS',
    label: 'ENGENHEIRO DE SISTEMAS',
  },
  {
    value: 'ENGENHEIRO DE SOFTWARE',
    label: 'ENGENHEIRO DE SOFTWARE',
  },
  {
    value: 'ENGENHEIRO DE SUPRIMENTOS',
    label: 'ENGENHEIRO DE SUPRIMENTOS',
  },
  {
    value: 'ENGENHEIRO DE TELECOMUNICAÇÕES',
    label: 'ENGENHEIRO DE TELECOMUNICAÇÕES',
  },
  {
    value: 'ENGENHEIRO DE TESTES',
    label: 'ENGENHEIRO DE TESTES',
  },
  {
    value: 'ENGENHEIRO DE VENDAS',
    label: 'ENGENHEIRO DE VENDAS',
  },
  {
    value: 'ENGENHEIRO ELETRICISTA',
    label: 'ENGENHEIRO ELETRICISTA',
  },
  {
    value: 'ENGENHEIRO ELETROELETRÔNICO',
    label: 'ENGENHEIRO ELETROELETRÔNICO',
  },
  {
    value: 'ENGENHEIRO ELETRÔNICO',
    label: 'ENGENHEIRO ELETRÔNICO',
  },
  {
    value: 'ENGENHEIRO FERROVIÁRIO',
    label: 'ENGENHEIRO FERROVIÁRIO',
  },
  {
    value: 'ENGENHEIRO FLORESTAL',
    label: 'ENGENHEIRO FLORESTAL',
  },
  {
    value: 'ENGENHEIRO GEOTÉCNICO',
    label: 'ENGENHEIRO GEOTÉCNICO',
  },
  {
    value: 'ENGENHEIRO HIDRÁULICO',
    label: 'ENGENHEIRO HIDRÁULICO',
  },
  {
    value: 'ENGENHEIRO HÍDRICO',
    label: 'ENGENHEIRO HÍDRICO',
  },
  {
    value: 'ENGENHEIRO INDUSTRIAL',
    label: 'ENGENHEIRO INDUSTRIAL',
  },
  {
    value: 'ENGENHEIRO MECATRÔNICO',
    label: 'ENGENHEIRO MECATRÔNICO',
  },
  {
    value: 'ENGENHEIRO MECÂNICO',
    label: 'ENGENHEIRO MECÂNICO',
  },
  {
    value: 'ENGENHEIRO METALÚRGICO',
    label: 'ENGENHEIRO METALÚRGICO',
  },
  {
    value: 'ENGENHEIRO NAVAL',
    label: 'ENGENHEIRO NAVAL',
  },
  {
    value: 'ENGENHEIRO ORÇAMENTISTA',
    label: 'ENGENHEIRO ORÇAMENTISTA',
  },
  {
    value: 'ENGENHEIRO QUÍMICO',
    label: 'ENGENHEIRO QUÍMICO',
  },
  {
    value: 'ENGENHEIRO RODOVIÁRIO',
    label: 'ENGENHEIRO RODOVIÁRIO',
  },
  {
    value: 'ENGENHEIRO SANITARISTA',
    label: 'ENGENHEIRO SANITARISTA',
  },
  {
    value: 'ENGENHEIRO TÊXTIL',
    label: 'ENGENHEIRO TÊXTIL',
  },
  {
    value: 'ENTREGADOR',
    label: 'ENTREGADOR',
  },
  {
    value: 'ERGONOMISTA',
    label: 'ERGONOMISTA',
  },
  {
    value: 'ESCREVENTE',
    label: 'ESCREVENTE',
  },
  {
    value: 'ESCRITURÁRIO HOSPITALAR',
    label: 'ESCRITURÁRIO HOSPITALAR',
  },
  {
    value: 'ESCRITURÁRIO',
    label: 'ESCRITURÁRIO',
  },
  {
    value: 'ESPECIALISTA CONTÁBIL',
    label: 'ESPECIALISTA CONTÁBIL',
  },
  {
    value: 'ESPECIALISTA DE PRODUTO',
    label: 'ESPECIALISTA DE PRODUTO',
  },
  {
    value: 'ESPECIALISTA DE PROJETOS',
    label: 'ESPECIALISTA DE PROJETOS',
  },
  {
    value: 'ESPECIALISTA DE SUPRIMENTOS',
    label: 'ESPECIALISTA DE SUPRIMENTOS',
  },
  {
    value: 'ESPECIALISTA EM COMPRAS',
    label: 'ESPECIALISTA EM COMPRAS',
  },
  {
    value: 'ESPECIALISTA EM E-COMMERCE',
    label: 'ESPECIALISTA EM E-COMMERCE',
  },
  {
    value: 'ESPECIALISTA EM MARKETING DIGITAL',
    label: 'ESPECIALISTA EM MARKETING DIGITAL',
  },
  {
    value: 'ESPECIALISTA EM MARKETING',
    label: 'ESPECIALISTA EM MARKETING',
  },
  {
    value: 'ESPECIALISTA EM MÍDIA',
    label: 'ESPECIALISTA EM MÍDIA',
  },
  {
    value: 'ESPECIALISTA EM RECRUTAMENTO E SELEÇÃO',
    label: 'ESPECIALISTA EM RECRUTAMENTO E SELEÇÃO',
  },
  {
    value: 'ESPECIALISTA EM RECURSOS HUMANOS',
    label: 'ESPECIALISTA EM RECURSOS HUMANOS',
  },
  {
    value: 'ESPECIALISTA EM SEGURANÇA DA INFORMAÇÃO',
    label: 'ESPECIALISTA EM SEGURANÇA DA INFORMAÇÃO',
  },
  {
    value: 'ESPECIALISTA EM TELECOMUNICAÇÕES',
    label: 'ESPECIALISTA EM TELECOMUNICAÇÕES',
  },
  {
    value: 'ESPECIALISTA EM TESOURARIA',
    label: 'ESPECIALISTA EM TESOURARIA',
  },
  {
    value: 'ESPECIALISTA EM TREINAMENTO E DESENVOLVIMENTO',
    label: 'ESPECIALISTA EM TREINAMENTO E DESENVOLVIMENTO',
  },
  {
    value: 'ESPECIALISTA FINANCEIRO',
    label: 'ESPECIALISTA FINANCEIRO',
  },
  {
    value: 'ESPECIALISTA FISCAL',
    label: 'ESPECIALISTA FISCAL',
  },
  {
    value: 'ESPECIALISTA TRIBUTÁRIO',
    label: 'ESPECIALISTA TRIBUTÁRIO',
  },
  {
    value: 'ESTAMPADOR',
    label: 'ESTAMPADOR',
  },
  {
    value: 'ESTATÍSTICO TRAINEE',
    label: 'ESTATÍSTICO TRAINEE',
  },
  {
    value: 'ESTATÍSTICO',
    label: 'ESTATÍSTICO',
  },
  {
    value: 'ESTETICISTA',
    label: 'ESTETICISTA',
  },
  {
    value: 'ESTILISTA',
    label: 'ESTILISTA',
  },
  {
    value: 'ESTOFADOR',
    label: 'ESTOFADOR',
  },
  {
    value: 'ESTOQUISTA',
    label: 'ESTOQUISTA',
  },
  {
    value: 'ESTÁGIO EM ADMINISTRAÇÃO DE EMPRESAS',
    label: 'ESTÁGIO EM ADMINISTRAÇÃO DE EMPRESAS',
  },
  {
    value: 'ESTÁGIO EM ADMINISTRAÇÃO HOSPITALAR',
    label: 'ESTÁGIO EM ADMINISTRAÇÃO HOSPITALAR',
  },
  {
    value: 'ESTÁGIO EM ANTROPOLOGIA',
    label: 'ESTÁGIO EM ANTROPOLOGIA',
  },
  {
    value: 'ESTÁGIO EM ANÁLISE DE SISTEMAS',
    label: 'ESTÁGIO EM ANÁLISE DE SISTEMAS',
  },
  {
    value: 'ESTÁGIO EM ANÁLISE E DESENVOLVIMENTO DE SISTEMAS',
    label: 'ESTÁGIO EM ANÁLISE E DESENVOLVIMENTO DE SISTEMAS',
  },
  {
    value: 'ESTÁGIO EM ARQUITETURA',
    label: 'ESTÁGIO EM ARQUITETURA',
  },
  {
    value: 'ESTÁGIO EM ARQUIVOLOGIA',
    label: 'ESTÁGIO EM ARQUIVOLOGIA',
  },
  {
    value: 'ESTÁGIO EM ARTES CÊNICAS',
    label: 'ESTÁGIO EM ARTES CÊNICAS',
  },
  {
    value: 'ESTÁGIO EM ARTES GRÁFICAS',
    label: 'ESTÁGIO EM ARTES GRÁFICAS',
  },
  {
    value: 'ESTÁGIO EM ARTES PLÁSTICAS',
    label: 'ESTÁGIO EM ARTES PLÁSTICAS',
  },
  {
    value: 'ESTÁGIO EM AUTOCAD',
    label: 'ESTÁGIO EM AUTOCAD',
  },
  {
    value: 'ESTÁGIO EM BANCO DE DADOS',
    label: 'ESTÁGIO EM BANCO DE DADOS',
  },
  {
    value: 'ESTÁGIO EM BIBLIOTECONOMIA',
    label: 'ESTÁGIO EM BIBLIOTECONOMIA',
  },
  {
    value: 'ESTÁGIO EM BIOLOGIA',
    label: 'ESTÁGIO EM BIOLOGIA',
  },
  {
    value: 'ESTÁGIO EM BIOQUÍMICA',
    label: 'ESTÁGIO EM BIOQUÍMICA',
  },
  {
    value: 'ESTÁGIO EM CINEMA',
    label: 'ESTÁGIO EM CINEMA',
  },
  {
    value: 'ESTÁGIO EM CIÊNCIA DA COMPUTAÇÃO',
    label: 'ESTÁGIO EM CIÊNCIA DA COMPUTAÇÃO',
  },
  {
    value: 'ESTÁGIO EM CIÊNCIAS AERONÁUTICAS',
    label: 'ESTÁGIO EM CIÊNCIAS AERONÁUTICAS',
  },
  {
    value: 'ESTÁGIO EM CIÊNCIAS ATUARIAIS',
    label: 'ESTÁGIO EM CIÊNCIAS ATUARIAIS',
  },
  {
    value: 'ESTÁGIO EM CIÊNCIAS BIOMÉDICAS',
    label: 'ESTÁGIO EM CIÊNCIAS BIOMÉDICAS',
  },
  {
    value: 'ESTÁGIO EM CIÊNCIAS CONTÁBEIS',
    label: 'ESTÁGIO EM CIÊNCIAS CONTÁBEIS',
  },
  {
    value: 'ESTÁGIO EM CIÊNCIAS SOCIAIS',
    label: 'ESTÁGIO EM CIÊNCIAS SOCIAIS',
  },
  {
    value: 'ESTÁGIO EM COMUNICAÇÃO E MARKETING',
    label: 'ESTÁGIO EM COMUNICAÇÃO E MARKETING',
  },
  {
    value: 'ESTÁGIO EM COMUNICAÇÃO SOCIAL',
    label: 'ESTÁGIO EM COMUNICAÇÃO SOCIAL',
  },
  {
    value: 'ESTÁGIO EM COMUNICAÇÃO',
    label: 'ESTÁGIO EM COMUNICAÇÃO',
  },
  {
    value: 'ESTÁGIO EM COMÉRCIO EXTERIOR',
    label: 'ESTÁGIO EM COMÉRCIO EXTERIOR',
  },
  {
    value: 'ESTÁGIO EM CONTROLE DE QUALIDADE',
    label: 'ESTÁGIO EM CONTROLE DE QUALIDADE',
  },
  {
    value: 'ESTÁGIO EM DESENHO INDUSTRIAL',
    label: 'ESTÁGIO EM DESENHO INDUSTRIAL',
  },
  {
    value: 'ESTÁGIO EM DESIGN DE INTERIORES',
    label: 'ESTÁGIO EM DESIGN DE INTERIORES',
  },
  {
    value: 'ESTÁGIO EM DESIGN DE JOIAS',
    label: 'ESTÁGIO EM DESIGN DE JOIAS',
  },
  {
    value: 'ESTÁGIO EM DESIGN DE PRODUTO',
    label: 'ESTÁGIO EM DESIGN DE PRODUTO',
  },
  {
    value: 'ESTÁGIO EM DESIGN DIGITAL',
    label: 'ESTÁGIO EM DESIGN DIGITAL',
  },
  {
    value: 'ESTÁGIO EM DESIGN GRÁFICO',
    label: 'ESTÁGIO EM DESIGN GRÁFICO',
  },
  {
    value: 'ESTÁGIO EM DESIGN',
    label: 'ESTÁGIO EM DESIGN',
  },
  {
    value: 'ESTÁGIO EM DIREITO CÍVEL',
    label: 'ESTÁGIO EM DIREITO CÍVEL',
  },
  {
    value: 'ESTÁGIO EM DIREITO IMOBILIÁRIO',
    label: 'ESTÁGIO EM DIREITO IMOBILIÁRIO',
  },
  {
    value: 'ESTÁGIO EM DIREITO SOCIETÁRIO',
    label: 'ESTÁGIO EM DIREITO SOCIETÁRIO',
  },
  {
    value: 'ESTÁGIO EM DIREITO TRABALHISTA',
    label: 'ESTÁGIO EM DIREITO TRABALHISTA',
  },
  {
    value: 'ESTÁGIO EM DIREITO TRIBUTÁRIO',
    label: 'ESTÁGIO EM DIREITO TRIBUTÁRIO',
  },
  {
    value: 'ESTÁGIO EM DIREITO',
    label: 'ESTÁGIO EM DIREITO',
  },
  {
    value: 'ESTÁGIO EM ECONOMIA',
    label: 'ESTÁGIO EM ECONOMIA',
  },
  {
    value: 'ESTÁGIO EM EDUCAÇÃO FÍSICA',
    label: 'ESTÁGIO EM EDUCAÇÃO FÍSICA',
  },
  {
    value: 'ESTÁGIO EM ELETROELETRÔNICA',
    label: 'ESTÁGIO EM ELETROELETRÔNICA',
  },
  {
    value: 'ESTÁGIO EM ELETROTÉCNICA',
    label: 'ESTÁGIO EM ELETROTÉCNICA',
  },
  {
    value: 'ESTÁGIO EM ELETRÔNICA',
    label: 'ESTÁGIO EM ELETRÔNICA',
  },
  {
    value: 'ESTÁGIO EM ELÉTRICA',
    label: 'ESTÁGIO EM ELÉTRICA',
  },
  {
    value: 'ESTÁGIO EM ENFERMAGEM',
    label: 'ESTÁGIO EM ENFERMAGEM',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA AERONÁUTICA',
    label: 'ESTÁGIO EM ENGENHARIA AERONÁUTICA',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA AGRONÔMICA',
    label: 'ESTÁGIO EM ENGENHARIA AGRONÔMICA',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA AMBIENTAL',
    label: 'ESTÁGIO EM ENGENHARIA AMBIENTAL',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA AUTOMOBILÍSTICA',
    label: 'ESTÁGIO EM ENGENHARIA AUTOMOBILÍSTICA',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA BIOMÉDICA',
    label: 'ESTÁGIO EM ENGENHARIA BIOMÉDICA',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA CARTOGRÁFICA',
    label: 'ESTÁGIO EM ENGENHARIA CARTOGRÁFICA',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA CIVIL',
    label: 'ESTÁGIO EM ENGENHARIA CIVIL',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA DA COMPUTAÇÃO',
    label: 'ESTÁGIO EM ENGENHARIA DA COMPUTAÇÃO',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA DA QUALIDADE',
    label: 'ESTÁGIO EM ENGENHARIA DA QUALIDADE',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA DE AGRIMENSURA',
    label: 'ESTÁGIO EM ENGENHARIA DE AGRIMENSURA',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA DE ALIMENTOS',
    label: 'ESTÁGIO EM ENGENHARIA DE ALIMENTOS',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA DE CONTROLE E AUTOMAÇÃO',
    label: 'ESTÁGIO EM ENGENHARIA DE CONTROLE E AUTOMAÇÃO',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA DE MATERIAIS',
    label: 'ESTÁGIO EM ENGENHARIA DE MATERIAIS',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA DE MINAS',
    label: 'ESTÁGIO EM ENGENHARIA DE MINAS',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA DE PRODUTO',
    label: 'ESTÁGIO EM ENGENHARIA DE PRODUTO',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA DE PRODUÇÃO',
    label: 'ESTÁGIO EM ENGENHARIA DE PRODUÇÃO',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA DE SEGURANÇA DO TRABALHO',
    label: 'ESTÁGIO EM ENGENHARIA DE SEGURANÇA DO TRABALHO',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA DE TELECOMUNICAÇÕES',
    label: 'ESTÁGIO EM ENGENHARIA DE TELECOMUNICAÇÕES',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA ELETRÔNICA',
    label: 'ESTÁGIO EM ENGENHARIA ELETRÔNICA',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA ELÉTRICA E ELETRÔNICA',
    label: 'ESTÁGIO EM ENGENHARIA ELÉTRICA E ELETRÔNICA',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA ELÉTRICA',
    label: 'ESTÁGIO EM ENGENHARIA ELÉTRICA',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA FLORESTAL',
    label: 'ESTÁGIO EM ENGENHARIA FLORESTAL',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA INDUSTRIAL',
    label: 'ESTÁGIO EM ENGENHARIA INDUSTRIAL',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA MECATRÔNICA',
    label: 'ESTÁGIO EM ENGENHARIA MECATRÔNICA',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA MECÂNICA',
    label: 'ESTÁGIO EM ENGENHARIA MECÂNICA',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA METALÚRGICA',
    label: 'ESTÁGIO EM ENGENHARIA METALÚRGICA',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA NAVAL',
    label: 'ESTÁGIO EM ENGENHARIA NAVAL',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA QUÍMICA',
    label: 'ESTÁGIO EM ENGENHARIA QUÍMICA',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA TÊXTIL',
    label: 'ESTÁGIO EM ENGENHARIA TÊXTIL',
  },
  {
    value: 'ESTÁGIO EM ENGENHARIA',
    label: 'ESTÁGIO EM ENGENHARIA',
  },
  {
    value: 'ESTÁGIO EM ENSINO MÉDIO',
    label: 'ESTÁGIO EM ENSINO MÉDIO',
  },
  {
    value: 'ESTÁGIO EM ESTATÍSTICA',
    label: 'ESTÁGIO EM ESTATÍSTICA',
  },
  {
    value: 'ESTÁGIO EM FARMÁCIA COM ÊNFASE EM BIOQUÍMICA',
    label: 'ESTÁGIO EM FARMÁCIA COM ÊNFASE EM BIOQUÍMICA',
  },
  {
    value: 'ESTÁGIO EM FARMÁCIA',
    label: 'ESTÁGIO EM FARMÁCIA',
  },
  {
    value: 'ESTÁGIO EM FILOSOFIA',
    label: 'ESTÁGIO EM FILOSOFIA',
  },
  {
    value: 'ESTÁGIO EM FISIOTERAPIA',
    label: 'ESTÁGIO EM FISIOTERAPIA',
  },
  {
    value: 'ESTÁGIO EM FONOAUDIOLOGIA',
    label: 'ESTÁGIO EM FONOAUDIOLOGIA',
  },
  {
    value: 'ESTÁGIO EM FOTOGRAFIA',
    label: 'ESTÁGIO EM FOTOGRAFIA',
  },
  {
    value: 'ESTÁGIO EM FÍSICA',
    label: 'ESTÁGIO EM FÍSICA',
  },
  {
    value: 'ESTÁGIO EM GASTRONOMIA',
    label: 'ESTÁGIO EM GASTRONOMIA',
  },
  {
    value: 'ESTÁGIO EM GEOGRAFIA',
    label: 'ESTÁGIO EM GEOGRAFIA',
  },
  {
    value: 'ESTÁGIO EM GEOLOGIA',
    label: 'ESTÁGIO EM GEOLOGIA',
  },
  {
    value: 'ESTÁGIO EM GESTÃO COMERCIAL',
    label: 'ESTÁGIO EM GESTÃO COMERCIAL',
  },
  {
    value: 'ESTÁGIO EM GESTÃO DA QUALIDADE',
    label: 'ESTÁGIO EM GESTÃO DA QUALIDADE',
  },
  {
    value: 'ESTÁGIO EM GESTÃO FINANCEIRA',
    label: 'ESTÁGIO EM GESTÃO FINANCEIRA',
  },
  {
    value: 'ESTÁGIO EM GESTÃO PÚBLICA',
    label: 'ESTÁGIO EM GESTÃO PÚBLICA',
  },
  {
    value: 'ESTÁGIO EM HISTÓRIA',
    label: 'ESTÁGIO EM HISTÓRIA',
  },
  {
    value: 'ESTÁGIO EM HOTELARIA E TURISMO',
    label: 'ESTÁGIO EM HOTELARIA E TURISMO',
  },
  {
    value: 'ESTÁGIO EM HOTELARIA',
    label: 'ESTÁGIO EM HOTELARIA',
  },
  {
    value: 'ESTÁGIO EM INFORMÁTICA',
    label: 'ESTÁGIO EM INFORMÁTICA',
  },
  {
    value: 'ESTÁGIO EM JORNALISMO',
    label: 'ESTÁGIO EM JORNALISMO',
  },
  {
    value: 'ESTÁGIO EM LETRAS',
    label: 'ESTÁGIO EM LETRAS',
  },
  {
    value: 'ESTÁGIO EM LOGÍSTICA',
    label: 'ESTÁGIO EM LOGÍSTICA',
  },
  {
    value: 'ESTÁGIO EM MAGISTÉRIO',
    label: 'ESTÁGIO EM MAGISTÉRIO',
  },
  {
    value: 'ESTÁGIO EM MARKETING',
    label: 'ESTÁGIO EM MARKETING',
  },
  {
    value: 'ESTÁGIO EM MATEMÁTICA',
    label: 'ESTÁGIO EM MATEMÁTICA',
  },
  {
    value: 'ESTÁGIO EM MEDICINA',
    label: 'ESTÁGIO EM MEDICINA',
  },
  {
    value: 'ESTÁGIO EM MEIO AMBIENTE',
    label: 'ESTÁGIO EM MEIO AMBIENTE',
  },
  {
    value: 'ESTÁGIO EM MODA',
    label: 'ESTÁGIO EM MODA',
  },
  {
    value: 'ESTÁGIO EM MUSEOLOGIA',
    label: 'ESTÁGIO EM MUSEOLOGIA',
  },
  {
    value: 'ESTÁGIO EM MÚSICA',
    label: 'ESTÁGIO EM MÚSICA',
  },
  {
    value: 'ESTÁGIO EM NATUROLOGIA',
    label: 'ESTÁGIO EM NATUROLOGIA',
  },
  {
    value: 'ESTÁGIO EM NUTRIÇÃO',
    label: 'ESTÁGIO EM NUTRIÇÃO',
  },
  {
    value: 'ESTÁGIO EM OCEANOGRAFIA',
    label: 'ESTÁGIO EM OCEANOGRAFIA',
  },
  {
    value: 'ESTÁGIO EM ODONTOLOGIA',
    label: 'ESTÁGIO EM ODONTOLOGIA',
  },
  {
    value: 'ESTÁGIO EM PEDAGOGIA',
    label: 'ESTÁGIO EM PEDAGOGIA',
  },
  {
    value: 'ESTÁGIO EM PROCESSAMENTO DE DADOS',
    label: 'ESTÁGIO EM PROCESSAMENTO DE DADOS',
  },
  {
    value: 'ESTÁGIO EM PROCESSOS GERENCIAIS',
    label: 'ESTÁGIO EM PROCESSOS GERENCIAIS',
  },
  {
    value: 'ESTÁGIO EM PRODUÇÃO EDITORIAL',
    label: 'ESTÁGIO EM PRODUÇÃO EDITORIAL',
  },
  {
    value: 'ESTÁGIO EM PSICOLOGIA',
    label: 'ESTÁGIO EM PSICOLOGIA',
  },
  {
    value: 'ESTÁGIO EM PSICOPEDAGOGIA',
    label: 'ESTÁGIO EM PSICOPEDAGOGIA',
  },
  {
    value: 'ESTÁGIO EM PUBLICIDADE E PROPAGANDA',
    label: 'ESTÁGIO EM PUBLICIDADE E PROPAGANDA',
  },
  {
    value: 'ESTÁGIO EM PUBLICIDADE',
    label: 'ESTÁGIO EM PUBLICIDADE',
  },
  {
    value: 'ESTÁGIO EM QUÍMICA',
    label: 'ESTÁGIO EM QUÍMICA',
  },
  {
    value: 'ESTÁGIO EM RADIOLOGIA',
    label: 'ESTÁGIO EM RADIOLOGIA',
  },
  {
    value: 'ESTÁGIO EM RECURSOS HUMANOS',
    label: 'ESTÁGIO EM RECURSOS HUMANOS',
  },
  {
    value: 'ESTÁGIO EM REDES DE COMPUTADORES',
    label: 'ESTÁGIO EM REDES DE COMPUTADORES',
  },
  {
    value: 'ESTÁGIO EM RELAÇÕES INTERNACIONAIS',
    label: 'ESTÁGIO EM RELAÇÕES INTERNACIONAIS',
  },
  {
    value: 'ESTÁGIO EM RELAÇÕES PÚBLICAS',
    label: 'ESTÁGIO EM RELAÇÕES PÚBLICAS',
  },
  {
    value: 'ESTÁGIO EM RÁDIO E TV',
    label: 'ESTÁGIO EM RÁDIO E TV',
  },
  {
    value: 'ESTÁGIO EM SECRETARIADO EXECUTIVO',
    label: 'ESTÁGIO EM SECRETARIADO EXECUTIVO',
  },
  {
    value: 'ESTÁGIO EM SECRETARIADO',
    label: 'ESTÁGIO EM SECRETARIADO',
  },
  {
    value: 'ESTÁGIO EM SERVIÇO SOCIAL',
    label: 'ESTÁGIO EM SERVIÇO SOCIAL',
  },
  {
    value: 'ESTÁGIO EM SISTEMAS DE INFORMAÇÃO',
    label: 'ESTÁGIO EM SISTEMAS DE INFORMAÇÃO',
  },
  {
    value: 'ESTÁGIO EM SOCIOLOGIA',
    label: 'ESTÁGIO EM SOCIOLOGIA',
  },
  {
    value: 'ESTÁGIO EM TELECOMUNICAÇÕES',
    label: 'ESTÁGIO EM TELECOMUNICAÇÕES',
  },
  {
    value: 'ESTÁGIO EM TERAPIA OCUPACIONAL',
    label: 'ESTÁGIO EM TERAPIA OCUPACIONAL',
  },
  {
    value: 'ESTÁGIO EM TRADUÇÃO',
    label: 'ESTÁGIO EM TRADUÇÃO',
  },
  {
    value: 'ESTÁGIO EM TRANSAÇÕES IMOBILIÁRIAS',
    label: 'ESTÁGIO EM TRANSAÇÕES IMOBILIÁRIAS',
  },
  {
    value: 'ESTÁGIO EM TURISMO',
    label: 'ESTÁGIO EM TURISMO',
  },
  {
    value: 'ESTÁGIO EM VETERINÁRIA',
    label: 'ESTÁGIO EM VETERINÁRIA',
  },
  {
    value: 'ESTÁGIO EM WEB DESIGN',
    label: 'ESTÁGIO EM WEB DESIGN',
  },
  {
    value: 'ESTÁGIO EM ZOOTECNIA',
    label: 'ESTÁGIO EM ZOOTECNIA',
  },
  {
    value: 'ESTÁGIO NA ÁREA ADMINISTRATIVA FINANCEIRA',
    label: 'ESTÁGIO NA ÁREA ADMINISTRATIVA FINANCEIRA',
  },
  {
    value: 'ESTÁGIO NA ÁREA ADMINISTRATIVA',
    label: 'ESTÁGIO NA ÁREA ADMINISTRATIVA',
  },
  {
    value: 'ESTÁGIO NA ÁREA AUDIOVISUAL',
    label: 'ESTÁGIO NA ÁREA AUDIOVISUAL',
  },
  {
    value: 'ESTÁGIO NA ÁREA BANCÁRIA',
    label: 'ESTÁGIO NA ÁREA BANCÁRIA',
  },
  {
    value: 'ESTÁGIO NA ÁREA COMERCIAL',
    label: 'ESTÁGIO NA ÁREA COMERCIAL',
  },
  {
    value: 'ESTÁGIO NA ÁREA DA QUALIDADE E MEIO AMBIENTE',
    label: 'ESTÁGIO NA ÁREA DA QUALIDADE E MEIO AMBIENTE',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE ALIMENTOS E BEBIDAS',
    label: 'ESTÁGIO NA ÁREA DE ALIMENTOS E BEBIDAS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE ALIMENTOS',
    label: 'ESTÁGIO NA ÁREA DE ALIMENTOS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE ALMOXARIFADO',
    label: 'ESTÁGIO NA ÁREA DE ALMOXARIFADO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE ARTE',
    label: 'ESTÁGIO NA ÁREA DE ARTE',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE ASSESSORIA DE IMPRENSA',
    label: 'ESTÁGIO NA ÁREA DE ASSESSORIA DE IMPRENSA',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE ASSISTÊNCIA TÉCNICA',
    label: 'ESTÁGIO NA ÁREA DE ASSISTÊNCIA TÉCNICA',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE ASSUNTOS REGULATÓRIOS',
    label: 'ESTÁGIO NA ÁREA DE ASSUNTOS REGULATÓRIOS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE ATENDIMENTO PUBLICITÁRIO',
    label: 'ESTÁGIO NA ÁREA DE ATENDIMENTO PUBLICITÁRIO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE ATENDIMENTO',
    label: 'ESTÁGIO NA ÁREA DE ATENDIMENTO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE AUDITORIA',
    label: 'ESTÁGIO NA ÁREA DE AUDITORIA',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE AUTOMAÇÃO INDUSTRIAL',
    label: 'ESTÁGIO NA ÁREA DE AUTOMAÇÃO INDUSTRIAL',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE BACK OFFICE',
    label: 'ESTÁGIO NA ÁREA DE BACK OFFICE',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE BENEFÍCIOS',
    label: 'ESTÁGIO NA ÁREA DE BENEFÍCIOS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE BUSINESS INTELLIGENCE',
    label: 'ESTÁGIO NA ÁREA DE BUSINESS INTELLIGENCE',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE COBRANÇA',
    label: 'ESTÁGIO NA ÁREA DE COBRANÇA',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE COMPLIANCE',
    label: 'ESTÁGIO NA ÁREA DE COMPLIANCE',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE COMPRAS',
    label: 'ESTÁGIO NA ÁREA DE COMPRAS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE CONTAS A PAGAR',
    label: 'ESTÁGIO NA ÁREA DE CONTAS A PAGAR',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE CONTAS A RECEBER',
    label: 'ESTÁGIO NA ÁREA DE CONTAS A RECEBER',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE CONTRATOS',
    label: 'ESTÁGIO NA ÁREA DE CONTRATOS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE CONTROLADORIA',
    label: 'ESTÁGIO NA ÁREA DE CONTROLADORIA',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE CONTROLES INTERNOS',
    label: 'ESTÁGIO NA ÁREA DE CONTROLES INTERNOS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE CRIAÇÃO',
    label: 'ESTÁGIO NA ÁREA DE CRIAÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE CRM',
    label: 'ESTÁGIO NA ÁREA DE CRM',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE CRÉDITO E COBRANÇA',
    label: 'ESTÁGIO NA ÁREA DE CRÉDITO E COBRANÇA',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE CUSTOS',
    label: 'ESTÁGIO NA ÁREA DE CUSTOS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE DEPARTAMENTO PESSOAL',
    label: 'ESTÁGIO NA ÁREA DE DEPARTAMENTO PESSOAL',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE DESENVOLVIMENTO .NET',
    label: 'ESTÁGIO NA ÁREA DE DESENVOLVIMENTO .NET',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE DESENVOLVIMENTO DE SISTEMAS',
    label: 'ESTÁGIO NA ÁREA DE DESENVOLVIMENTO DE SISTEMAS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE DESENVOLVIMENTO DE SOFTWARE',
    label: 'ESTÁGIO NA ÁREA DE DESENVOLVIMENTO DE SOFTWARE',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE DIAGRAMAÇÃO',
    label: 'ESTÁGIO NA ÁREA DE DIAGRAMAÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE E-COMMERCE',
    label: 'ESTÁGIO NA ÁREA DE E-COMMERCE',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE EDIFICAÇÕES',
    label: 'ESTÁGIO NA ÁREA DE EDIFICAÇÕES',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE EDIÇÃO DE IMAGENS',
    label: 'ESTÁGIO NA ÁREA DE EDIÇÃO DE IMAGENS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE EDIÇÃO DE VÍDEO',
    label: 'ESTÁGIO NA ÁREA DE EDIÇÃO DE VÍDEO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE EDUCAÇÃO INFANTIL',
    label: 'ESTÁGIO NA ÁREA DE EDUCAÇÃO INFANTIL',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE EDUCAÇÃO',
    label: 'ESTÁGIO NA ÁREA DE EDUCAÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE ENGENHARIA DE PROCESSOS',
    label: 'ESTÁGIO NA ÁREA DE ENGENHARIA DE PROCESSOS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE ENGENHARIA DE PROJETOS',
    label: 'ESTÁGIO NA ÁREA DE ENGENHARIA DE PROJETOS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE ESTOQUE',
    label: 'ESTÁGIO NA ÁREA DE ESTOQUE',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE ESTÉTICA',
    label: 'ESTÁGIO NA ÁREA DE ESTÉTICA',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE EVENTOS',
    label: 'ESTÁGIO NA ÁREA DE EVENTOS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE EXPEDIÇÃO',
    label: 'ESTÁGIO NA ÁREA DE EXPEDIÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE EXPORTAÇÃO',
    label: 'ESTÁGIO NA ÁREA DE EXPORTAÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE FACILITIES',
    label: 'ESTÁGIO NA ÁREA DE FACILITIES',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE FATURAMENTO',
    label: 'ESTÁGIO NA ÁREA DE FATURAMENTO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE FUSÕES E AQUISIÇÕES',
    label: 'ESTÁGIO NA ÁREA DE FUSÕES E AQUISIÇÕES',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE GEOPROCESSAMENTO',
    label: 'ESTÁGIO NA ÁREA DE GEOPROCESSAMENTO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE GESTÃO AMBIENTAL',
    label: 'ESTÁGIO NA ÁREA DE GESTÃO AMBIENTAL',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE GINÁSTICA',
    label: 'ESTÁGIO NA ÁREA DE GINÁSTICA',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE GOVERNANÇA',
    label: 'ESTÁGIO NA ÁREA DE GOVERNANÇA',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE IMPLANTAÇÃO DE SISTEMAS',
    label: 'ESTÁGIO NA ÁREA DE IMPLANTAÇÃO DE SISTEMAS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE IMPORTAÇÃO E EXPORTAÇÃO',
    label: 'ESTÁGIO NA ÁREA DE IMPORTAÇÃO E EXPORTAÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE IMPORTAÇÃO',
    label: 'ESTÁGIO NA ÁREA DE IMPORTAÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE INFRAESTRUTURA',
    label: 'ESTÁGIO NA ÁREA DE INFRAESTRUTURA',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE INTELIGÊNCIA DE MERCADO',
    label: 'ESTÁGIO NA ÁREA DE INTELIGÊNCIA DE MERCADO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE INVESTIMENTOS',
    label: 'ESTÁGIO NA ÁREA DE INVESTIMENTOS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE LABORATÓRIO',
    label: 'ESTÁGIO NA ÁREA DE LABORATÓRIO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE LICITAÇÃO',
    label: 'ESTÁGIO NA ÁREA DE LICITAÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE LINKS PATROCINADOS',
    label: 'ESTÁGIO NA ÁREA DE LINKS PATROCINADOS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE MANUTENÇÃO',
    label: 'ESTÁGIO NA ÁREA DE MANUTENÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE MARKETING DIGITAL',
    label: 'ESTÁGIO NA ÁREA DE MARKETING DIGITAL',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE METROLOGIA',
    label: 'ESTÁGIO NA ÁREA DE METROLOGIA',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE MICROBIOLOGIA',
    label: 'ESTÁGIO NA ÁREA DE MICROBIOLOGIA',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE MUSCULAÇÃO',
    label: 'ESTÁGIO NA ÁREA DE MUSCULAÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE MÍDIAS DIGITAIS',
    label: 'ESTÁGIO NA ÁREA DE MÍDIAS DIGITAIS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE NATAÇÃO',
    label: 'ESTÁGIO NA ÁREA DE NATAÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE NEGÓCIOS',
    label: 'ESTÁGIO NA ÁREA DE NEGÓCIOS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE OBRAS',
    label: 'ESTÁGIO NA ÁREA DE OBRAS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE ORÇAMENTOS',
    label: 'ESTÁGIO NA ÁREA DE ORÇAMENTOS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE PCP',
    label: 'ESTÁGIO NA ÁREA DE PCP',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE PESQUISA DE MERCADO',
    label: 'ESTÁGIO NA ÁREA DE PESQUISA DE MERCADO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE PLANEJAMENTO ESTRATÉGICO',
    label: 'ESTÁGIO NA ÁREA DE PLANEJAMENTO ESTRATÉGICO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE PLANEJAMENTO FINANCEIRO',
    label: 'ESTÁGIO NA ÁREA DE PLANEJAMENTO FINANCEIRO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE PLANEJAMENTO',
    label: 'ESTÁGIO NA ÁREA DE PLANEJAMENTO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE PROCESSOS INDUSTRIAIS',
    label: 'ESTÁGIO NA ÁREA DE PROCESSOS INDUSTRIAIS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE PROCESSOS',
    label: 'ESTÁGIO NA ÁREA DE PROCESSOS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE PRODUTOS',
    label: 'ESTÁGIO NA ÁREA DE PRODUTOS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE PRODUÇÃO',
    label: 'ESTÁGIO NA ÁREA DE PRODUÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE PROGRAMAÇÃO DELPHI',
    label: 'ESTÁGIO NA ÁREA DE PROGRAMAÇÃO DELPHI',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE PROGRAMAÇÃO JAVA',
    label: 'ESTÁGIO NA ÁREA DE PROGRAMAÇÃO JAVA',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE PROGRAMAÇÃO PHP',
    label: 'ESTÁGIO NA ÁREA DE PROGRAMAÇÃO PHP',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE PROGRAMAÇÃO WEB',
    label: 'ESTÁGIO NA ÁREA DE PROGRAMAÇÃO WEB',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE PROGRAMAÇÃO',
    label: 'ESTÁGIO NA ÁREA DE PROGRAMAÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE PROJETOS',
    label: 'ESTÁGIO NA ÁREA DE PROJETOS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE QUALIDADE',
    label: 'ESTÁGIO NA ÁREA DE QUALIDADE',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE RECEPÇÃO',
    label: 'ESTÁGIO NA ÁREA DE RECEPÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE RECREAÇÃO',
    label: 'ESTÁGIO NA ÁREA DE RECREAÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE RECRUTAMENTO E SELEÇÃO',
    label: 'ESTÁGIO NA ÁREA DE RECRUTAMENTO E SELEÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE REDAÇÃO PUBLICITÁRIA',
    label: 'ESTÁGIO NA ÁREA DE REDAÇÃO PUBLICITÁRIA',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE REDAÇÃO',
    label: 'ESTÁGIO NA ÁREA DE REDAÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE REDES SOCIAIS',
    label: 'ESTÁGIO NA ÁREA DE REDES SOCIAIS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE REDES',
    label: 'ESTÁGIO NA ÁREA DE REDES',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE RELACIONAMENTO',
    label: 'ESTÁGIO NA ÁREA DE RELACIONAMENTO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE REMUNERAÇÃO E BENEFÍCIOS',
    label: 'ESTÁGIO NA ÁREA DE REMUNERAÇÃO E BENEFÍCIOS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE REMUNERAÇÃO',
    label: 'ESTÁGIO NA ÁREA DE REMUNERAÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE RESERVAS',
    label: 'ESTÁGIO NA ÁREA DE RESERVAS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE RESPONSABILIDADE SOCIAL',
    label: 'ESTÁGIO NA ÁREA DE RESPONSABILIDADE SOCIAL',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE REVISÃO DE TEXTO',
    label: 'ESTÁGIO NA ÁREA DE REVISÃO DE TEXTO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE RISCO',
    label: 'ESTÁGIO NA ÁREA DE RISCO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE SAÚDE BUCAL',
    label: 'ESTÁGIO NA ÁREA DE SAÚDE BUCAL',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE SEGURANÇA DA INFORMAÇÃO',
    label: 'ESTÁGIO NA ÁREA DE SEGURANÇA DA INFORMAÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE SEGURANÇA DO TRABALHO',
    label: 'ESTÁGIO NA ÁREA DE SEGURANÇA DO TRABALHO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE SEGUROS',
    label: 'ESTÁGIO NA ÁREA DE SEGUROS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE SISTEMAS',
    label: 'ESTÁGIO NA ÁREA DE SISTEMAS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE SUPORTE TÉCNICO',
    label: 'ESTÁGIO NA ÁREA DE SUPORTE TÉCNICO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE SUPRIMENTOS',
    label: 'ESTÁGIO NA ÁREA DE SUPRIMENTOS',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE SUSTENTABILIDADE',
    label: 'ESTÁGIO NA ÁREA DE SUSTENTABILIDADE',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE TECNOLOGIA DA INFORMAÇÃO',
    label: 'ESTÁGIO NA ÁREA DE TECNOLOGIA DA INFORMAÇÃO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE TESOURARIA',
    label: 'ESTÁGIO NA ÁREA DE TESOURARIA',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE TESTES',
    label: 'ESTÁGIO NA ÁREA DE TESTES',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE TRADE MARKETING',
    label: 'ESTÁGIO NA ÁREA DE TRADE MARKETING',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE TRANSPORTES',
    label: 'ESTÁGIO NA ÁREA DE TRANSPORTES',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE TREINAMENTO E DESENVOLVIMENTO',
    label: 'ESTÁGIO NA ÁREA DE TREINAMENTO E DESENVOLVIMENTO',
  },
  {
    value: 'ESTÁGIO NA ÁREA DE VENDAS',
    label: 'ESTÁGIO NA ÁREA DE VENDAS',
  },
  {
    value: 'ESTÁGIO NA ÁREA FINANCEIRA',
    label: 'ESTÁGIO NA ÁREA FINANCEIRA',
  },
  {
    value: 'ESTÁGIO NA ÁREA FISCAL',
    label: 'ESTÁGIO NA ÁREA FISCAL',
  },
  {
    value: 'ESTÁGIO NA ÁREA IMOBILIÁRIA',
    label: 'ESTÁGIO NA ÁREA IMOBILIÁRIA',
  },
  {
    value: 'ESTÁGIO NA ÁREA INDUSTRIAL',
    label: 'ESTÁGIO NA ÁREA INDUSTRIAL',
  },
  {
    value: 'ESTÁGIO NA ÁREA MECÂNICA',
    label: 'ESTÁGIO NA ÁREA MECÂNICA',
  },
  {
    value: 'ESTÁGIO NA ÁREA TRIBUTÁRIA',
    label: 'ESTÁGIO NA ÁREA TRIBUTÁRIA',
  },
  {
    value: 'ESTÁGIO NA ÁREA TÉCNICA',
    label: 'ESTÁGIO NA ÁREA TÉCNICA',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM ADMINISTRAÇÃO DE EMPRESAS',
    label: 'ESTÁGIO TÉCNICO EM ADMINISTRAÇÃO DE EMPRESAS',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM AGRIMENSURA',
    label: 'ESTÁGIO TÉCNICO EM AGRIMENSURA',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM ALIMENTOS',
    label: 'ESTÁGIO TÉCNICO EM ALIMENTOS',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM ANÁLISES CLÍNICAS',
    label: 'ESTÁGIO TÉCNICO EM ANÁLISES CLÍNICAS',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM CONTABILIDADE',
    label: 'ESTÁGIO TÉCNICO EM CONTABILIDADE',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM EDIFICAÇÕES',
    label: 'ESTÁGIO TÉCNICO EM EDIFICAÇÕES',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM ELETROELETRÔNICA',
    label: 'ESTÁGIO TÉCNICO EM ELETROELETRÔNICA',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM ELETROMECÂNICA',
    label: 'ESTÁGIO TÉCNICO EM ELETROMECÂNICA',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM ELETROTÉCNICA',
    label: 'ESTÁGIO TÉCNICO EM ELETROTÉCNICA',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM ELETRÔNICA',
    label: 'ESTÁGIO TÉCNICO EM ELETRÔNICA',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM ELÉTRICA',
    label: 'ESTÁGIO TÉCNICO EM ELÉTRICA',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM ENFERMAGEM DO TRABALHO',
    label: 'ESTÁGIO TÉCNICO EM ENFERMAGEM DO TRABALHO',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM ENFERMAGEM',
    label: 'ESTÁGIO TÉCNICO EM ENFERMAGEM',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM FARMÁCIA',
    label: 'ESTÁGIO TÉCNICO EM FARMÁCIA',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM GESTÃO EMPRESARIAL',
    label: 'ESTÁGIO TÉCNICO EM GESTÃO EMPRESARIAL',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM INFORMÁTICA',
    label: 'ESTÁGIO TÉCNICO EM INFORMÁTICA',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM LOGÍSTICA',
    label: 'ESTÁGIO TÉCNICO EM LOGÍSTICA',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM MECATRÔNICA',
    label: 'ESTÁGIO TÉCNICO EM MECATRÔNICA',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM MECÂNICA',
    label: 'ESTÁGIO TÉCNICO EM MECÂNICA',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM MEIO AMBIENTE',
    label: 'ESTÁGIO TÉCNICO EM MEIO AMBIENTE',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM NUTRIÇÃO',
    label: 'ESTÁGIO TÉCNICO EM NUTRIÇÃO',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM PLÁSTICOS',
    label: 'ESTÁGIO TÉCNICO EM PLÁSTICOS',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM PRÓTESE DENTÁRIA',
    label: 'ESTÁGIO TÉCNICO EM PRÓTESE DENTÁRIA',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM PUBLICIDADE E PROPAGANDA',
    label: 'ESTÁGIO TÉCNICO EM PUBLICIDADE E PROPAGANDA',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM QUALIDADE',
    label: 'ESTÁGIO TÉCNICO EM QUALIDADE',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM QUÍMICA',
    label: 'ESTÁGIO TÉCNICO EM QUÍMICA',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM SECRETARIADO',
    label: 'ESTÁGIO TÉCNICO EM SECRETARIADO',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM SEGURANÇA DO TRABALHO',
    label: 'ESTÁGIO TÉCNICO EM SEGURANÇA DO TRABALHO',
  },
  {
    value: 'ESTÁGIO TÉCNICO EM TELECOMUNICAÇÕES',
    label: 'ESTÁGIO TÉCNICO EM TELECOMUNICAÇÕES',
  },
  {
    value: 'ESTÁGIO TÉCNICO NA ÁREA DE MANUTENÇÃO',
    label: 'ESTÁGIO TÉCNICO NA ÁREA DE MANUTENÇÃO',
  },
  {
    value: 'ESTÁGIO TÉCNICO NA ÁREA DE RECURSOS HUMANOS',
    label: 'ESTÁGIO TÉCNICO NA ÁREA DE RECURSOS HUMANOS',
  },
  {
    value: 'ESTÁGIO TÉCNICO NA ÁREA DE VENDAS',
    label: 'ESTÁGIO TÉCNICO NA ÁREA DE VENDAS',
  },
  {
    value: 'ETIQUETADOR',
    label: 'ETIQUETADOR',
  },
  {
    value: 'EXECUTIVO DE CONTAS',
    label: 'EXECUTIVO DE CONTAS',
  },
  {
    value: 'EXECUTIVO DE VENDAS',
    label: 'EXECUTIVO DE VENDAS',
  },
  {
    value: 'EXTRUSOR',
    label: 'EXTRUSOR',
  },
  {
    value: 'FARMACÊUTICO BIOQUÍMICO',
    label: 'FARMACÊUTICO BIOQUÍMICO',
  },
  {
    value: 'FARMACÊUTICO HOMEOPATA',
    label: 'FARMACÊUTICO HOMEOPATA',
  },
  {
    value: 'FARMACÊUTICO HOSPITALAR',
    label: 'FARMACÊUTICO HOSPITALAR',
  },
  {
    value: 'FARMACÊUTICO INDUSTRIAL',
    label: 'FARMACÊUTICO INDUSTRIAL',
  },
  {
    value: 'FARMACÊUTICO TRAINEE',
    label: 'FARMACÊUTICO TRAINEE',
  },
  {
    value: 'FARMACÊUTICO',
    label: 'FARMACÊUTICO',
  },
  {
    value: 'FATURISTA HOSPITALAR',
    label: 'FATURISTA HOSPITALAR',
  },
  {
    value: 'FATURISTA',
    label: 'FATURISTA',
  },
  {
    value: 'FERRAMENTEIRO DE MOLDES PLÁSTICOS',
    label: 'FERRAMENTEIRO DE MOLDES PLÁSTICOS',
  },
  {
    value: 'FERRAMENTEIRO',
    label: 'FERRAMENTEIRO',
  },
  {
    value: 'FERREIRO',
    label: 'FERREIRO',
  },
  {
    value: 'FIGURINISTA',
    label: 'FIGURINISTA',
  },
  {
    value: 'FISCAL DE CAIXA',
    label: 'FISCAL DE CAIXA',
  },
  {
    value: 'FISCAL DE CAMPO',
    label: 'FISCAL DE CAMPO',
  },
  {
    value: 'FISCAL DE LOJA',
    label: 'FISCAL DE LOJA',
  },
  {
    value: 'FISCAL DE OBRAS',
    label: 'FISCAL DE OBRAS',
  },
  {
    value: 'FISCAL DE PREVENÇÃO DE PERDAS',
    label: 'FISCAL DE PREVENÇÃO DE PERDAS',
  },
  {
    value: 'FISCAL DE TRÁFEGO',
    label: 'FISCAL DE TRÁFEGO',
  },
  {
    value: 'FISIOTERAPEUTA ACUPUNTURISTA',
    label: 'FISIOTERAPEUTA ACUPUNTURISTA',
  },
  {
    value: 'FISIOTERAPEUTA DERMATO FUNCIONAL',
    label: 'FISIOTERAPEUTA DERMATO FUNCIONAL',
  },
  {
    value: 'FISIOTERAPEUTA DO TRABALHO',
    label: 'FISIOTERAPEUTA DO TRABALHO',
  },
  {
    value: 'FISIOTERAPEUTA ESPORTIVO',
    label: 'FISIOTERAPEUTA ESPORTIVO',
  },
  {
    value: 'FISIOTERAPEUTA HOME CARE',
    label: 'FISIOTERAPEUTA HOME CARE',
  },
  {
    value: 'FISIOTERAPEUTA HOSPITALAR',
    label: 'FISIOTERAPEUTA HOSPITALAR',
  },
  {
    value: 'FISIOTERAPEUTA NEUROFUNCIONAL',
    label: 'FISIOTERAPEUTA NEUROFUNCIONAL',
  },
  {
    value: 'FISIOTERAPEUTA ONCOFUNCIONAL',
    label: 'FISIOTERAPEUTA ONCOFUNCIONAL',
  },
  {
    value: 'FISIOTERAPEUTA RESPIRATÓRIO',
    label: 'FISIOTERAPEUTA RESPIRATÓRIO',
  },
  {
    value: 'FISIOTERAPEUTA RPG',
    label: 'FISIOTERAPEUTA RPG',
  },
  {
    value: 'FISIOTERAPEUTA VENDEDOR',
    label: 'FISIOTERAPEUTA VENDEDOR',
  },
  {
    value: 'FISIOTERAPEUTA',
    label: 'FISIOTERAPEUTA',
  },
  {
    value: 'FLORISTA',
    label: 'FLORISTA',
  },
  {
    value: 'FONOAUDIÓLOGO',
    label: 'FONOAUDIÓLOGO',
  },
  {
    value: 'FORNEIRO',
    label: 'FORNEIRO',
  },
  {
    value: 'FOTOGRAVADOR',
    label: 'FOTOGRAVADOR',
  },
  {
    value: 'FOTÓGRAFO',
    label: 'FOTÓGRAFO',
  },
  {
    value: 'FRACIONADOR',
    label: 'FRACIONADOR',
  },
  {
    value: 'FRENTISTA',
    label: 'FRENTISTA',
  },
  {
    value: 'FRESADOR CNC',
    label: 'FRESADOR CNC',
  },
  {
    value: 'FRESADOR',
    label: 'FRESADOR',
  },
  {
    value: 'FUNDIDOR DE METAIS',
    label: 'FUNDIDOR DE METAIS',
  },
  {
    value: 'FUNILEIRO DE VEÍCULOS',
    label: 'FUNILEIRO DE VEÍCULOS',
  },
  {
    value: 'FUNILEIRO',
    label: 'FUNILEIRO',
  },
  {
    value: 'FÍSICO',
    label: 'FÍSICO',
  },
  {
    value: 'GALVANIZADOR',
    label: 'GALVANIZADOR',
  },
  {
    value: 'GARANTISTA DE VEÍCULOS',
    label: 'GARANTISTA DE VEÍCULOS',
  },
  {
    value: 'GARANTISTA',
    label: 'GARANTISTA',
  },
  {
    value: 'GARDE MANGER',
    label: 'GARDE MANGER',
  },
  {
    value: 'GARÇOM',
    label: 'GARÇOM',
  },
  {
    value: 'GASTRÔNOMO',
    label: 'GASTRÔNOMO',
  },
  {
    value: 'GEMÓLOGO',
    label: 'GEMÓLOGO',
  },
  {
    value: 'GEOFÍSICO',
    label: 'GEOFÍSICO',
  },
  {
    value: 'GERENTE ADMINISTRATIVO',
    label: 'GERENTE ADMINISTRATIVO',
  },
  {
    value: 'GERENTE AGRÍCOLA',
    label: 'GERENTE AGRÍCOLA',
  },
  {
    value: 'GERENTE BANCÁRIO',
    label: 'GERENTE BANCÁRIO',
  },
  {
    value: 'GERENTE COMERCIAL',
    label: 'GERENTE COMERCIAL',
  },
  {
    value: 'GERENTE DE AEROPORTO',
    label: 'GERENTE DE AEROPORTO',
  },
  {
    value: 'GERENTE DE ALIMENTOS E BEBIDAS',
    label: 'GERENTE DE ALIMENTOS E BEBIDAS',
  },
  {
    value: 'GERENTE DE ALMOXARIFADO',
    label: 'GERENTE DE ALMOXARIFADO',
  },
  {
    value: 'GERENTE DE ARMAZÉM',
    label: 'GERENTE DE ARMAZÉM',
  },
  {
    value: 'GERENTE DE ASSISTÊNCIA TÉCNICA',
    label: 'GERENTE DE ASSISTÊNCIA TÉCNICA',
  },
  {
    value: 'GERENTE DE ASSUNTOS REGULATÓRIOS',
    label: 'GERENTE DE ASSUNTOS REGULATÓRIOS',
  },
  {
    value: 'GERENTE DE ATENDIMENTO',
    label: 'GERENTE DE ATENDIMENTO',
  },
  {
    value: 'GERENTE DE AUDITORIA',
    label: 'GERENTE DE AUDITORIA',
  },
  {
    value: 'GERENTE DE BENEFÍCIOS',
    label: 'GERENTE DE BENEFÍCIOS',
  },
  {
    value: 'GERENTE DE CANAIS',
    label: 'GERENTE DE CANAIS',
  },
  {
    value: 'GERENTE DE CINEMA',
    label: 'GERENTE DE CINEMA',
  },
  {
    value: 'GERENTE DE COBRANÇA',
    label: 'GERENTE DE COBRANÇA',
  },
  {
    value: 'GERENTE DE COMPLIANCE',
    label: 'GERENTE DE COMPLIANCE',
  },
  {
    value: 'GERENTE DE COMPRAS',
    label: 'GERENTE DE COMPRAS',
  },
  {
    value: 'GERENTE DE COMUNICAÇÃO',
    label: 'GERENTE DE COMUNICAÇÃO',
  },
  {
    value: 'GERENTE DE COMÉRCIO EXTERIOR',
    label: 'GERENTE DE COMÉRCIO EXTERIOR',
  },
  {
    value: 'GERENTE DE CONDOMÍNIO',
    label: 'GERENTE DE CONDOMÍNIO',
  },
  {
    value: 'GERENTE DE CONFECÇÃO',
    label: 'GERENTE DE CONFECÇÃO',
  },
  {
    value: 'GERENTE DE CONTABILIDADE',
    label: 'GERENTE DE CONTABILIDADE',
  },
  {
    value: 'GERENTE DE CONTAS',
    label: 'GERENTE DE CONTAS',
  },
  {
    value: 'GERENTE DE CONTEÚDO',
    label: 'GERENTE DE CONTEÚDO',
  },
  {
    value: 'GERENTE DE CONTRATOS',
    label: 'GERENTE DE CONTRATOS',
  },
  {
    value: 'GERENTE DE CONTROLADORIA',
    label: 'GERENTE DE CONTROLADORIA',
  },
  {
    value: 'GERENTE DE CONTROLE DE QUALIDADE',
    label: 'GERENTE DE CONTROLE DE QUALIDADE',
  },
  {
    value: 'GERENTE DE CRM',
    label: 'GERENTE DE CRM',
  },
  {
    value: 'GERENTE DE CRÉDITO E COBRANÇA',
    label: 'GERENTE DE CRÉDITO E COBRANÇA',
  },
  {
    value: 'GERENTE DE CRÉDITO IMOBILIÁRIO',
    label: 'GERENTE DE CRÉDITO IMOBILIÁRIO',
  },
  {
    value: 'GERENTE DE CUSTOS',
    label: 'GERENTE DE CUSTOS',
  },
  {
    value: 'GERENTE DE DEPARTAMENTO PESSOAL',
    label: 'GERENTE DE DEPARTAMENTO PESSOAL',
  },
  {
    value: 'GERENTE DE E-COMMERCE',
    label: 'GERENTE DE E-COMMERCE',
  },
  {
    value: 'GERENTE DE ENFERMAGEM',
    label: 'GERENTE DE ENFERMAGEM',
  },
  {
    value: 'GERENTE DE ENGENHARIA',
    label: 'GERENTE DE ENGENHARIA',
  },
  {
    value: 'GERENTE DE ESTOQUE',
    label: 'GERENTE DE ESTOQUE',
  },
  {
    value: 'GERENTE DE EVENTOS',
    label: 'GERENTE DE EVENTOS',
  },
  {
    value: 'GERENTE DE EXPEDIÇÃO',
    label: 'GERENTE DE EXPEDIÇÃO',
  },
  {
    value: 'GERENTE DE FACILITIES',
    label: 'GERENTE DE FACILITIES',
  },
  {
    value: 'GERENTE DE FACTORING',
    label: 'GERENTE DE FACTORING',
  },
  {
    value: 'GERENTE DE FARMÁCIA',
    label: 'GERENTE DE FARMÁCIA',
  },
  {
    value: 'GERENTE DE FATURAMENTO',
    label: 'GERENTE DE FATURAMENTO',
  },
  {
    value: 'GERENTE DE FILIAL',
    label: 'GERENTE DE FILIAL',
  },
  {
    value: 'GERENTE DE FROTA',
    label: 'GERENTE DE FROTA',
  },
  {
    value: 'GERENTE DE FUSÕES E AQUISIÇÕES',
    label: 'GERENTE DE FUSÕES E AQUISIÇÕES',
  },
  {
    value: 'GERENTE DE GARANTIA DA QUALIDADE',
    label: 'GERENTE DE GARANTIA DA QUALIDADE',
  },
  {
    value: 'GERENTE DE HOTEL',
    label: 'GERENTE DE HOTEL',
  },
  {
    value: 'GERENTE DE INFRAESTRUTURA',
    label: 'GERENTE DE INFRAESTRUTURA',
  },
  {
    value: 'GERENTE DE INTELIGÊNCIA DE MERCADO',
    label: 'GERENTE DE INTELIGÊNCIA DE MERCADO',
  },
  {
    value: 'GERENTE DE INVENTÁRIO',
    label: 'GERENTE DE INVENTÁRIO',
  },
  {
    value: 'GERENTE DE INVESTIMENTOS',
    label: 'GERENTE DE INVESTIMENTOS',
  },
  {
    value: 'GERENTE DE LAVANDERIA',
    label: 'GERENTE DE LAVANDERIA',
  },
  {
    value: 'GERENTE DE LICITAÇÃO',
    label: 'GERENTE DE LICITAÇÃO',
  },
  {
    value: 'GERENTE DE LIMPEZA',
    label: 'GERENTE DE LIMPEZA',
  },
  {
    value: 'GERENTE DE LOCAÇÃO',
    label: 'GERENTE DE LOCAÇÃO',
  },
  {
    value: 'GERENTE DE LOGÍSTICA',
    label: 'GERENTE DE LOGÍSTICA',
  },
  {
    value: 'GERENTE DE LOJA',
    label: 'GERENTE DE LOJA',
  },
  {
    value: 'GERENTE DE MANUTENÇÃO INDUSTRIAL',
    label: 'GERENTE DE MANUTENÇÃO INDUSTRIAL',
  },
  {
    value: 'GERENTE DE MANUTENÇÃO MECÂNICA',
    label: 'GERENTE DE MANUTENÇÃO MECÂNICA',
  },
  {
    value: 'GERENTE DE MANUTENÇÃO PREDIAL',
    label: 'GERENTE DE MANUTENÇÃO PREDIAL',
  },
  {
    value: 'GERENTE DE MANUTENÇÃO',
    label: 'GERENTE DE MANUTENÇÃO',
  },
  {
    value: 'GERENTE DE MARKETING DIGITAL',
    label: 'GERENTE DE MARKETING DIGITAL',
  },
  {
    value: 'GERENTE DE MARKETING',
    label: 'GERENTE DE MARKETING',
  },
  {
    value: 'GERENTE DE MERCHANDISING',
    label: 'GERENTE DE MERCHANDISING',
  },
  {
    value: 'GERENTE DE MODA',
    label: 'GERENTE DE MODA',
  },
  {
    value: 'GERENTE DE MÍDIAS SOCIAIS',
    label: 'GERENTE DE MÍDIAS SOCIAIS',
  },
  {
    value: 'GERENTE DE NEGÓCIOS',
    label: 'GERENTE DE NEGÓCIOS',
  },
  {
    value: 'GERENTE DE NUTRIÇÃO',
    label: 'GERENTE DE NUTRIÇÃO',
  },
  {
    value: 'GERENTE DE OBRAS',
    label: 'GERENTE DE OBRAS',
  },
  {
    value: 'GERENTE DE OFICINA',
    label: 'GERENTE DE OFICINA',
  },
  {
    value: 'GERENTE DE ORÇAMENTO',
    label: 'GERENTE DE ORÇAMENTO',
  },
  {
    value: 'GERENTE DE PATRIMÔNIO',
    label: 'GERENTE DE PATRIMÔNIO',
  },
  {
    value: 'GERENTE DE PCP',
    label: 'GERENTE DE PCP',
  },
  {
    value: 'GERENTE DE PESQUISA E DESENVOLVIMENTO',
    label: 'GERENTE DE PESQUISA E DESENVOLVIMENTO',
  },
  {
    value: 'GERENTE DE PEÇAS',
    label: 'GERENTE DE PEÇAS',
  },
  {
    value: 'GERENTE DE PLANEJAMENTO',
    label: 'GERENTE DE PLANEJAMENTO',
  },
  {
    value: 'GERENTE DE PREVENÇÃO DE PERDAS',
    label: 'GERENTE DE PREVENÇÃO DE PERDAS',
  },
  {
    value: 'GERENTE DE PRICING',
    label: 'GERENTE DE PRICING',
  },
  {
    value: 'GERENTE DE PROCESSOS',
    label: 'GERENTE DE PROCESSOS',
  },
  {
    value: 'GERENTE DE PRODUTO',
    label: 'GERENTE DE PRODUTO',
  },
  {
    value: 'GERENTE DE PRODUÇÃO',
    label: 'GERENTE DE PRODUÇÃO',
  },
  {
    value: 'GERENTE DE PROJETO',
    label: 'GERENTE DE PROJETO',
  },
  {
    value: 'GERENTE DE PROJETOS PMO',
    label: 'GERENTE DE PROJETOS PMO',
  },
  {
    value: 'GERENTE DE PROJETOS WEB',
    label: 'GERENTE DE PROJETOS WEB',
  },
  {
    value: 'GERENTE DE PROJETOS',
    label: 'GERENTE DE PROJETOS',
  },
  {
    value: 'GERENTE DE PÓS-VENDA',
    label: 'GERENTE DE PÓS-VENDA',
  },
  {
    value: 'GERENTE DE QSMS',
    label: 'GERENTE DE QSMS',
  },
  {
    value: 'GERENTE DE QUALIDADE',
    label: 'GERENTE DE QUALIDADE',
  },
  {
    value: 'GERENTE DE RECEPÇÃO',
    label: 'GERENTE DE RECEPÇÃO',
  },
  {
    value: 'GERENTE DE RECRUTAMENTO E SELEÇÃO',
    label: 'GERENTE DE RECRUTAMENTO E SELEÇÃO',
  },
  {
    value: 'GERENTE DE RECURSOS HUMANOS',
    label: 'GERENTE DE RECURSOS HUMANOS',
  },
  {
    value: 'GERENTE DE RESERVAS',
    label: 'GERENTE DE RESERVAS',
  },
  {
    value: 'GERENTE DE RESPONSABILIDADE SOCIAL',
    label: 'GERENTE DE RESPONSABILIDADE SOCIAL',
  },
  {
    value: 'GERENTE DE RESTAURANTE',
    label: 'GERENTE DE RESTAURANTE',
  },
  {
    value: 'GERENTE DE SAÚDE, SEGURANÇA E MEIO AMBIENTE',
    label: 'GERENTE DE SAÚDE, SEGURANÇA E MEIO AMBIENTE',
  },
  {
    value: 'GERENTE DE SEGURANÇA DA INFORMAÇÃO',
    label: 'GERENTE DE SEGURANÇA DA INFORMAÇÃO',
  },
  {
    value: 'GERENTE DE SEGURANÇA DO TRABALHO',
    label: 'GERENTE DE SEGURANÇA DO TRABALHO',
  },
  {
    value: 'GERENTE DE SEGURANÇA PATRIMONIAL',
    label: 'GERENTE DE SEGURANÇA PATRIMONIAL',
  },
  {
    value: 'GERENTE DE SEGUROS',
    label: 'GERENTE DE SEGUROS',
  },
  {
    value: 'GERENTE DE SEM',
    label: 'GERENTE DE SEM',
  },
  {
    value: 'GERENTE DE SEO',
    label: 'GERENTE DE SEO',
  },
  {
    value: 'GERENTE DE SERVIÇOS',
    label: 'GERENTE DE SERVIÇOS',
  },
  {
    value: 'GERENTE DE SINISTROS',
    label: 'GERENTE DE SINISTROS',
  },
  {
    value: 'GERENTE DE SUPORTE TÉCNICO',
    label: 'GERENTE DE SUPORTE TÉCNICO',
  },
  {
    value: 'GERENTE DE SUPRIMENTOS',
    label: 'GERENTE DE SUPRIMENTOS',
  },
  {
    value: 'GERENTE DE TECNOLOGIA DA INFORMAÇÃO',
    label: 'GERENTE DE TECNOLOGIA DA INFORMAÇÃO',
  },
  {
    value: 'GERENTE DE TELECOMUNICAÇÕES',
    label: 'GERENTE DE TELECOMUNICAÇÕES',
  },
  {
    value: 'GERENTE DE TESOURARIA',
    label: 'GERENTE DE TESOURARIA',
  },
  {
    value: 'GERENTE DE TRADE MARKETING',
    label: 'GERENTE DE TRADE MARKETING',
  },
  {
    value: 'GERENTE DE TRANSPORTES',
    label: 'GERENTE DE TRANSPORTES',
  },
  {
    value: 'GERENTE DE TREINAMENTO E DESENVOLVIMENTO',
    label: 'GERENTE DE TREINAMENTO E DESENVOLVIMENTO',
  },
  {
    value: 'GERENTE DE TURISMO',
    label: 'GERENTE DE TURISMO',
  },
  {
    value: 'GERENTE DE VENDAS',
    label: 'GERENTE DE VENDAS',
  },
  {
    value: 'GERENTE FINANCEIRO',
    label: 'GERENTE FINANCEIRO',
  },
  {
    value: 'GERENTE FISCAL',
    label: 'GERENTE FISCAL',
  },
  {
    value: 'GERENTE GERAL',
    label: 'GERENTE GERAL',
  },
  {
    value: 'GERENTE IMOBILIÁRIO',
    label: 'GERENTE IMOBILIÁRIO',
  },
  {
    value: 'GERENTE INDUSTRIAL',
    label: 'GERENTE INDUSTRIAL',
  },
  {
    value: 'GERENTE JURÍDICO',
    label: 'GERENTE JURÍDICO',
  },
  {
    value: 'GERENTE MÉDICO',
    label: 'GERENTE MÉDICO',
  },
  {
    value: 'GERENTE OPERACIONAL',
    label: 'GERENTE OPERACIONAL',
  },
  {
    value: 'GERENTE PEDAGÓGICO',
    label: 'GERENTE PEDAGÓGICO',
  },
  {
    value: 'GERENTE PREDIAL',
    label: 'GERENTE PREDIAL',
  },
  {
    value: 'GERENTE TRIBUTÁRIO',
    label: 'GERENTE TRIBUTÁRIO',
  },
  {
    value: 'GERENTE TÉCNICO',
    label: 'GERENTE TÉCNICO',
  },
  {
    value: 'GERONTÓLOGO',
    label: 'GERONTÓLOGO',
  },
  {
    value: 'GESSEIRO',
    label: 'GESSEIRO',
  },
  {
    value: 'GESTOR AMBIENTAL',
    label: 'GESTOR AMBIENTAL',
  },
  {
    value: 'GESTOR PORTUÁRIO',
    label: 'GESTOR PORTUÁRIO',
  },
  {
    value: 'GEÓGRAFO',
    label: 'GEÓGRAFO',
  },
  {
    value: 'GEÓLOGO',
    label: 'GEÓLOGO',
  },
  {
    value: 'GOVERNANTA',
    label: 'GOVERNANTA',
  },
  {
    value: 'GREIDISTA',
    label: 'GREIDISTA',
  },
  {
    value: 'GUIA DE TURISMO',
    label: 'GUIA DE TURISMO',
  },
  {
    value: 'HISTORIADOR',
    label: 'HISTORIADOR',
  },
  {
    value: 'ILUSTRADOR',
    label: 'ILUSTRADOR',
  },
  {
    value: 'IMPERMEABILIZADOR',
    label: 'IMPERMEABILIZADOR',
  },
  {
    value: 'IMPLANTADOR DE SISTEMAS',
    label: 'IMPLANTADOR DE SISTEMAS',
  },
  {
    value: 'IMPRESSOR DE ROTOGRAVURA',
    label: 'IMPRESSOR DE ROTOGRAVURA',
  },
  {
    value: 'IMPRESSOR DIGITAL',
    label: 'IMPRESSOR DIGITAL',
  },
  {
    value: 'IMPRESSOR FLEXOGRÁFICO',
    label: 'IMPRESSOR FLEXOGRÁFICO',
  },
  {
    value: 'IMPRESSOR OFF-SET',
    label: 'IMPRESSOR OFF-SET',
  },
  {
    value: 'IMPRESSOR SERIGRÁFICO',
    label: 'IMPRESSOR SERIGRÁFICO',
  },
  {
    value: 'IMPRESSOR TAMPOGRÁFICO',
    label: 'IMPRESSOR TAMPOGRÁFICO',
  },
  {
    value: 'IMPRESSOR',
    label: 'IMPRESSOR',
  },
  {
    value: 'INSPETOR DE ALUNOS',
    label: 'INSPETOR DE ALUNOS',
  },
  {
    value: 'INSPETOR DE EQUIPAMENTOS',
    label: 'INSPETOR DE EQUIPAMENTOS',
  },
  {
    value: 'INSPETOR DE FABRICAÇÃO',
    label: 'INSPETOR DE FABRICAÇÃO',
  },
  {
    value: 'INSPETOR DE FROTA',
    label: 'INSPETOR DE FROTA',
  },
  {
    value: 'INSPETOR DE PINTURA',
    label: 'INSPETOR DE PINTURA',
  },
  {
    value: 'INSPETOR DE QUALIDADE',
    label: 'INSPETOR DE QUALIDADE',
  },
  {
    value: 'INSPETOR DE RISCOS',
    label: 'INSPETOR DE RISCOS',
  },
  {
    value: 'INSPETOR DE SEGURANÇA',
    label: 'INSPETOR DE SEGURANÇA',
  },
  {
    value: 'INSPETOR DE SOLDA',
    label: 'INSPETOR DE SOLDA',
  },
  {
    value: 'INSPETOR VEICULAR',
    label: 'INSPETOR VEICULAR',
  },
  {
    value: 'INSTALADOR DE ACESSÓRIOS',
    label: 'INSTALADOR DE ACESSÓRIOS',
  },
  {
    value: 'INSTALADOR DE ALARMES',
    label: 'INSTALADOR DE ALARMES',
  },
  {
    value: 'INSTALADOR DE AR CONDICIONADO',
    label: 'INSTALADOR DE AR CONDICIONADO',
  },
  {
    value: 'INSTALADOR DE COMUNICAÇÃO VISUAL',
    label: 'INSTALADOR DE COMUNICAÇÃO VISUAL',
  },
  {
    value: 'INSTALADOR DE EQUIPAMENTOS ELETRÔNICOS',
    label: 'INSTALADOR DE EQUIPAMENTOS ELETRÔNICOS',
  },
  {
    value: 'INSTALADOR DE INSULFILM',
    label: 'INSTALADOR DE INSULFILM',
  },
  {
    value: 'INSTALADOR DE RASTREADOR VEICULAR',
    label: 'INSTALADOR DE RASTREADOR VEICULAR',
  },
  {
    value: 'INSTALADOR DE TELECOMUNICAÇÕES',
    label: 'INSTALADOR DE TELECOMUNICAÇÕES',
  },
  {
    value: 'INSTALADOR DE TV A CABO',
    label: 'INSTALADOR DE TV A CABO',
  },
  {
    value: 'INSTALADOR DE VIDROS AUTOMOTIVOS',
    label: 'INSTALADOR DE VIDROS AUTOMOTIVOS',
  },
  {
    value: 'INSTALADOR DE VIDROS',
    label: 'INSTALADOR DE VIDROS',
  },
  {
    value: 'INSTALADOR',
    label: 'INSTALADOR',
  },
  {
    value: 'INSTRUMENTADOR CIRÚRGICO',
    label: 'INSTRUMENTADOR CIRÚRGICO',
  },
  {
    value: 'INSTRUMENTISTA',
    label: 'INSTRUMENTISTA',
  },
  {
    value: 'INSTRUTOR DE AUTOESCOLA',
    label: 'INSTRUTOR DE AUTOESCOLA',
  },
  {
    value: 'INSTRUTOR DE AVIAÇÃO',
    label: 'INSTRUTOR DE AVIAÇÃO',
  },
  {
    value: 'INSTRUTOR DE CABELEIREIRO',
    label: 'INSTRUTOR DE CABELEIREIRO',
  },
  {
    value: 'INSTRUTOR DE CURSO',
    label: 'INSTRUTOR DE CURSO',
  },
  {
    value: 'INSTRUTOR DE EXCEL',
    label: 'INSTRUTOR DE EXCEL',
  },
  {
    value: 'INSTRUTOR DE MOTORISTA',
    label: 'INSTRUTOR DE MOTORISTA',
  },
  {
    value: 'INSTRUTOR DE PILATES',
    label: 'INSTRUTOR DE PILATES',
  },
  {
    value: 'INSTRUTOR DE QUALIDADE',
    label: 'INSTRUTOR DE QUALIDADE',
  },
  {
    value: 'INSTRUTOR DE TREINAMENTO',
    label: 'INSTRUTOR DE TREINAMENTO',
  },
  {
    value: 'INTÉRPRETE DE LIBRAS',
    label: 'INTÉRPRETE DE LIBRAS',
  },
  {
    value: 'INTÉRPRETE',
    label: 'INTÉRPRETE',
  },
  {
    value: 'JARDINEIRO',
    label: 'JARDINEIRO',
  },
  {
    value: 'JATISTA',
    label: 'JATISTA',
  },
  {
    value: 'JORNALEIRO',
    label: 'JORNALEIRO',
  },
  {
    value: 'JORNALISTA',
    label: 'JORNALISTA',
  },
  {
    value: 'LABORATORISTA DE CONCRETO',
    label: 'LABORATORISTA DE CONCRETO',
  },
  {
    value: 'LABORATORISTA DE SOLOS',
    label: 'LABORATORISTA DE SOLOS',
  },
  {
    value: 'LABORATORISTA FOTOGRÁFICO',
    label: 'LABORATORISTA FOTOGRÁFICO',
  },
  {
    value: 'LACTARISTA',
    label: 'LACTARISTA',
  },
  {
    value: 'LAMINADOR',
    label: 'LAMINADOR',
  },
  {
    value: 'LANCHEIRO',
    label: 'LANCHEIRO',
  },
  {
    value: 'LAPIDADOR DE GEMAS',
    label: 'LAPIDADOR DE GEMAS',
  },
  {
    value: 'LAPIDADOR DE VIDROS',
    label: 'LAPIDADOR DE VIDROS',
  },
  {
    value: 'LAQUEADOR',
    label: 'LAQUEADOR',
  },
  {
    value: 'LAVADEIRO',
    label: 'LAVADEIRO',
  },
  {
    value: 'LAVADOR DE VEÍCULOS',
    label: 'LAVADOR DE VEÍCULOS',
  },
  {
    value: 'LAVADOR',
    label: 'LAVADOR',
  },
  {
    value: 'LAYOUTISTA',
    label: 'LAYOUTISTA',
  },
  {
    value: 'LEITURISTA',
    label: 'LEITURISTA',
  },
  {
    value: 'LETRISTA',
    label: 'LETRISTA',
  },
  {
    value: 'LIMPADOR DE VIDROS',
    label: 'LIMPADOR DE VIDROS',
  },
  {
    value: 'LIMPADOR',
    label: 'LIMPADOR',
  },
  {
    value: 'LINGOTADOR',
    label: 'LINGOTADOR',
  },
  {
    value: 'LIXADOR',
    label: 'LIXADOR',
  },
  {
    value: 'LOCUTOR',
    label: 'LOCUTOR',
  },
  {
    value: 'LUBRIFICADOR',
    label: 'LUBRIFICADOR',
  },
  {
    value: 'LUSTRADOR DE MÓVEIS',
    label: 'LUSTRADOR DE MÓVEIS',
  },
  {
    value: 'LÍDER COMERCIAL',
    label: 'LÍDER COMERCIAL',
  },
  {
    value: 'LÍDER DE ATENDIMENTO',
    label: 'LÍDER DE ATENDIMENTO',
  },
  {
    value: 'LÍDER DE CAIXA',
    label: 'LÍDER DE CAIXA',
  },
  {
    value: 'LÍDER DE COBRANÇA',
    label: 'LÍDER DE COBRANÇA',
  },
  {
    value: 'LÍDER DE CONTROLE DE QUALIDADE',
    label: 'LÍDER DE CONTROLE DE QUALIDADE',
  },
  {
    value: 'LÍDER DE EQUIPE',
    label: 'LÍDER DE EQUIPE',
  },
  {
    value: 'LÍDER DE EXPEDIÇÃO',
    label: 'LÍDER DE EXPEDIÇÃO',
  },
  {
    value: 'LÍDER DE EXTRUSÃO',
    label: 'LÍDER DE EXTRUSÃO',
  },
  {
    value: 'LÍDER DE HELP DESK',
    label: 'LÍDER DE HELP DESK',
  },
  {
    value: 'LÍDER DE LOGÍSTICA',
    label: 'LÍDER DE LOGÍSTICA',
  },
  {
    value: 'LÍDER DE LOJA',
    label: 'LÍDER DE LOJA',
  },
  {
    value: 'LÍDER DE MANUTENÇÃO ELÉTRICA',
    label: 'LÍDER DE MANUTENÇÃO ELÉTRICA',
  },
  {
    value: 'LÍDER DE MANUTENÇÃO',
    label: 'LÍDER DE MANUTENÇÃO',
  },
  {
    value: 'LÍDER DE MERCHANDISING',
    label: 'LÍDER DE MERCHANDISING',
  },
  {
    value: 'LÍDER DE MONTAGEM',
    label: 'LÍDER DE MONTAGEM',
  },
  {
    value: 'LÍDER DE PINTURA',
    label: 'LÍDER DE PINTURA',
  },
  {
    value: 'LÍDER DE PORTARIA',
    label: 'LÍDER DE PORTARIA',
  },
  {
    value: 'LÍDER DE PRODUÇÃO',
    label: 'LÍDER DE PRODUÇÃO',
  },
  {
    value: 'LÍDER DE RECEPÇÃO',
    label: 'LÍDER DE RECEPÇÃO',
  },
  {
    value: 'LÍDER DE REFRIGERAÇÃO',
    label: 'LÍDER DE REFRIGERAÇÃO',
  },
  {
    value: 'LÍDER DE TESTES',
    label: 'LÍDER DE TESTES',
  },
  {
    value: 'LÍDER DE USINAGEM',
    label: 'LÍDER DE USINAGEM',
  },
  {
    value: 'LÍDER DE VENDAS',
    label: 'LÍDER DE VENDAS',
  },
  {
    value: 'LÍDER DE VISUAL MERCHANDISING',
    label: 'LÍDER DE VISUAL MERCHANDISING',
  },
  {
    value: 'MAGAREFE',
    label: 'MAGAREFE',
  },
  {
    value: 'MANDRILHADOR',
    label: 'MANDRILHADOR',
  },
  {
    value: 'MANICURE E PEDICURE',
    label: 'MANICURE E PEDICURE',
  },
  {
    value: 'MANIPULADOR DE COSMÉTICOS',
    label: 'MANIPULADOR DE COSMÉTICOS',
  },
  {
    value: 'MANIPULADOR DE FARMÁCIA',
    label: 'MANIPULADOR DE FARMÁCIA',
  },
  {
    value: 'MANOBRISTA',
    label: 'MANOBRISTA',
  },
  {
    value: 'MAQUEIRO',
    label: 'MAQUEIRO',
  },
  {
    value: 'MAQUETISTA',
    label: 'MAQUETISTA',
  },
  {
    value: 'MAQUIADOR',
    label: 'MAQUIADOR',
  },
  {
    value: 'MAQUINISTA DE TREM',
    label: 'MAQUINISTA DE TREM',
  },
  {
    value: 'MARCENEIRO',
    label: 'MARCENEIRO',
  },
  {
    value: 'MARINHEIRO',
    label: 'MARINHEIRO',
  },
  {
    value: 'MARMORISTA',
    label: 'MARMORISTA',
  },
  {
    value: 'MARTELETEIRO',
    label: 'MARTELETEIRO',
  },
  {
    value: 'MASSEIRO',
    label: 'MASSEIRO',
  },
  {
    value: 'MASSOTERAPEUTA',
    label: 'MASSOTERAPEUTA',
  },
  {
    value: 'MATEMÁTICO',
    label: 'MATEMÁTICO',
  },
  {
    value: 'MAÇARIQUEIRO',
    label: 'MAÇARIQUEIRO',
  },
  {
    value: 'MAÎTRE',
    label: 'MAÎTRE',
  },
  {
    value: 'MECÂNICO COMPRESSOR',
    label: 'MECÂNICO COMPRESSOR',
  },
  {
    value: 'MECÂNICO DE AR CONDICIONADO',
    label: 'MECÂNICO DE AR CONDICIONADO',
  },
  {
    value: 'MECÂNICO DE BICICLETAS',
    label: 'MECÂNICO DE BICICLETAS',
  },
  {
    value: 'MECÂNICO DE EMPILHADEIRA',
    label: 'MECÂNICO DE EMPILHADEIRA',
  },
  {
    value: 'MECÂNICO DE MANUTENÇÃO DE AERONAVES',
    label: 'MECÂNICO DE MANUTENÇÃO DE AERONAVES',
  },
  {
    value: 'MECÂNICO DE MANUTENÇÃO HIDRÁULICA',
    label: 'MECÂNICO DE MANUTENÇÃO HIDRÁULICA',
  },
  {
    value: 'MECÂNICO DE MANUTENÇÃO PREDIAL',
    label: 'MECÂNICO DE MANUTENÇÃO PREDIAL',
  },
  {
    value: 'MECÂNICO DE MANUTENÇÃO',
    label: 'MECÂNICO DE MANUTENÇÃO',
  },
  {
    value: 'MECÂNICO DE MÁQUINAS',
    label: 'MECÂNICO DE MÁQUINAS',
  },
  {
    value: 'MECÂNICO DE REFRIGERAÇÃO',
    label: 'MECÂNICO DE REFRIGERAÇÃO',
  },
  {
    value: 'MECÂNICO DE SOPRO',
    label: 'MECÂNICO DE SOPRO',
  },
  {
    value: 'MECÂNICO DE USINAGEM',
    label: 'MECÂNICO DE USINAGEM',
  },
  {
    value: 'MECÂNICO DE VEÍCULOS',
    label: 'MECÂNICO DE VEÍCULOS',
  },
  {
    value: 'MECÂNICO ELETRICISTA',
    label: 'MECÂNICO ELETRICISTA',
  },
  {
    value: 'MECÂNICO HIDRÁULICO',
    label: 'MECÂNICO HIDRÁULICO',
  },
  {
    value: 'MECÂNICO INDUSTRIAL',
    label: 'MECÂNICO INDUSTRIAL',
  },
  {
    value: 'MECÂNICO LUBRIFICADOR',
    label: 'MECÂNICO LUBRIFICADOR',
  },
  {
    value: 'MECÂNICO MONTADOR',
    label: 'MECÂNICO MONTADOR',
  },
  {
    value: 'MECÂNICO',
    label: 'MECÂNICO',
  },
  {
    value: 'MECÂNICO',
    label: 'MECÂNICO',
  },
  {
    value: 'MEDIDOR DE OBRAS',
    label: 'MEDIDOR DE OBRAS',
  },
  {
    value: 'MEIO OFICIAL AJUSTADOR MECÂNICO',
    label: 'MEIO OFICIAL AJUSTADOR MECÂNICO',
  },
  {
    value: 'MEIO OFICIAL CARPINTEIRO',
    label: 'MEIO OFICIAL CARPINTEIRO',
  },
  {
    value: 'MEIO OFICIAL DE CALDEIRARIA',
    label: 'MEIO OFICIAL DE CALDEIRARIA',
  },
  {
    value: 'MEIO OFICIAL DE FERRAMENTARIA',
    label: 'MEIO OFICIAL DE FERRAMENTARIA',
  },
  {
    value: 'MEIO OFICIAL DE IMPRESSÃO FLEXOGRÁFICA',
    label: 'MEIO OFICIAL DE IMPRESSÃO FLEXOGRÁFICA',
  },
  {
    value: 'MEIO OFICIAL DE MANUTENÇÃO PREDIAL',
    label: 'MEIO OFICIAL DE MANUTENÇÃO PREDIAL',
  },
  {
    value: 'MEIO OFICIAL DE MANUTENÇÃO',
    label: 'MEIO OFICIAL DE MANUTENÇÃO',
  },
  {
    value: 'MEIO OFICIAL DE PINTURA',
    label: 'MEIO OFICIAL DE PINTURA',
  },
  {
    value: 'MEIO OFICIAL DE REFRIGERAÇÃO',
    label: 'MEIO OFICIAL DE REFRIGERAÇÃO',
  },
  {
    value: 'MEIO OFICIAL ELETRICISTA',
    label: 'MEIO OFICIAL ELETRICISTA',
  },
  {
    value: 'MEIO OFICIAL FRESADOR CNC',
    label: 'MEIO OFICIAL FRESADOR CNC',
  },
  {
    value: 'MEIO OFICIAL FRESADOR',
    label: 'MEIO OFICIAL FRESADOR',
  },
  {
    value: 'MEIO OFICIAL FUNILEIRO',
    label: 'MEIO OFICIAL FUNILEIRO',
  },
  {
    value: 'MEIO OFICIAL HIDRÁULICO',
    label: 'MEIO OFICIAL HIDRÁULICO',
  },
  {
    value: 'MEIO OFICIAL MARCENEIRO',
    label: 'MEIO OFICIAL MARCENEIRO',
  },
  {
    value: 'MEIO OFICIAL MECÂNICO DE MANUTENÇÃO',
    label: 'MEIO OFICIAL MECÂNICO DE MANUTENÇÃO',
  },
  {
    value: 'MEIO OFICIAL MECÂNICO',
    label: 'MEIO OFICIAL MECÂNICO',
  },
  {
    value: 'MEIO OFICIAL MONTADOR',
    label: 'MEIO OFICIAL MONTADOR',
  },
  {
    value: 'MEIO OFICIAL SERRALHEIRO',
    label: 'MEIO OFICIAL SERRALHEIRO',
  },
  {
    value: 'MEIO OFICIAL SOLDADOR',
    label: 'MEIO OFICIAL SOLDADOR',
  },
  {
    value: 'MEIO OFICIAL TORNEIRO MECÂNICO',
    label: 'MEIO OFICIAL TORNEIRO MECÂNICO',
  },
  {
    value: 'MENSAGEIRO DE HOTEL',
    label: 'MENSAGEIRO DE HOTEL',
  },
  {
    value: 'MENSAGEIRO',
    label: 'MENSAGEIRO',
  },
  {
    value: 'MERENDEIRA',
    label: 'MERENDEIRA',
  },
  {
    value: 'MESTRE CERVEJEIRO',
    label: 'MESTRE CERVEJEIRO',
  },
  {
    value: 'MESTRE DE CABOTAGEM',
    label: 'MESTRE DE CABOTAGEM',
  },
  {
    value: 'MESTRE DE OBRAS',
    label: 'MESTRE DE OBRAS',
  },
  {
    value: 'METEOROLOGISTA',
    label: 'METEOROLOGISTA',
  },
  {
    value: 'METROLOGISTA',
    label: 'METROLOGISTA',
  },
  {
    value: 'MICROBIOLOGISTA',
    label: 'MICROBIOLOGISTA',
  },
  {
    value: 'MODELISTA',
    label: 'MODELISTA',
  },
  {
    value: 'MODELO DE PROVA',
    label: 'MODELO DE PROVA',
  },
  {
    value: 'MODELO',
    label: 'MODELO',
  },
  {
    value: 'MOLDADOR',
    label: 'MOLDADOR',
  },
  {
    value: 'MOLDUREIRO',
    label: 'MOLDUREIRO',
  },
  {
    value: 'MOLEIRO',
    label: 'MOLEIRO',
  },
  {
    value: 'MONITOR DE ALUNOS',
    label: 'MONITOR DE ALUNOS',
  },
  {
    value: 'MONITOR DE INFORMÁTICA',
    label: 'MONITOR DE INFORMÁTICA',
  },
  {
    value: 'MONITOR DE QUALIDADE',
    label: 'MONITOR DE QUALIDADE',
  },
  {
    value: 'MONTADOR DE CALÇADOS',
    label: 'MONTADOR DE CALÇADOS',
  },
  {
    value: 'MONTADOR DE ELEVADOR',
    label: 'MONTADOR DE ELEVADOR',
  },
  {
    value: 'MONTADOR DE EQUIPAMENTOS',
    label: 'MONTADOR DE EQUIPAMENTOS',
  },
  {
    value: 'MONTADOR DE ESQUADRIAS',
    label: 'MONTADOR DE ESQUADRIAS',
  },
  {
    value: 'MONTADOR DE ESTRUTURAS METÁLICAS',
    label: 'MONTADOR DE ESTRUTURAS METÁLICAS',
  },
  {
    value: 'MONTADOR DE MÁQUINAS',
    label: 'MONTADOR DE MÁQUINAS',
  },
  {
    value: 'MONTADOR DE MÓVEIS',
    label: 'MONTADOR DE MÓVEIS',
  },
  {
    value: 'MONTADOR DE PAINÉIS',
    label: 'MONTADOR DE PAINÉIS',
  },
  {
    value: 'MONTADOR DE VEÍCULOS',
    label: 'MONTADOR DE VEÍCULOS',
  },
  {
    value: 'MONTADOR ELETRÔNICO',
    label: 'MONTADOR ELETRÔNICO',
  },
  {
    value: 'MONTADOR ELÉTRICO',
    label: 'MONTADOR ELÉTRICO',
  },
  {
    value: 'MONTADOR HIDRÁULICO',
    label: 'MONTADOR HIDRÁULICO',
  },
  {
    value: 'MONTADOR',
    label: 'MONTADOR',
  },
  {
    value: 'MORDOMO',
    label: 'MORDOMO',
  },
  {
    value: 'MOTOBOY',
    label: 'MOTOBOY',
  },
  {
    value: 'MOTORISTA ADMINISTRATIVO',
    label: 'MOTORISTA ADMINISTRATIVO',
  },
  {
    value: 'MOTORISTA CATEGORIA B',
    label: 'MOTORISTA CATEGORIA B',
  },
  {
    value: 'MOTORISTA CATEGORIA C',
    label: 'MOTORISTA CATEGORIA C',
  },
  {
    value: 'MOTORISTA CATEGORIA D',
    label: 'MOTORISTA CATEGORIA D',
  },
  {
    value: 'MOTORISTA CATEGORIA E',
    label: 'MOTORISTA CATEGORIA E',
  },
  {
    value: 'MOTORISTA DE AMBULÂNCIA',
    label: 'MOTORISTA DE AMBULÂNCIA',
  },
  {
    value: 'MOTORISTA DE CAMINHÃO',
    label: 'MOTORISTA DE CAMINHÃO',
  },
  {
    value: 'MOTORISTA DE CARRO FORTE',
    label: 'MOTORISTA DE CARRO FORTE',
  },
  {
    value: 'MOTORISTA DE GUINCHO',
    label: 'MOTORISTA DE GUINCHO',
  },
  {
    value: 'MOTORISTA DE ÔNIBUS',
    label: 'MOTORISTA DE ÔNIBUS',
  },
  {
    value: 'MOTORISTA ENTREGADOR',
    label: 'MOTORISTA ENTREGADOR',
  },
  {
    value: 'MOTORISTA ESCOLAR',
    label: 'MOTORISTA ESCOLAR',
  },
  {
    value: 'MOTORISTA EXECUTIVO',
    label: 'MOTORISTA EXECUTIVO',
  },
  {
    value: 'MOTORISTA OPERADOR DE BETONEIRA',
    label: 'MOTORISTA OPERADOR DE BETONEIRA',
  },
  {
    value: 'MOTORISTA',
    label: 'MOTORISTA',
  },
  {
    value: 'MUSEÓLOGO',
    label: 'MUSEÓLOGO',
  },
  {
    value: 'MUSICOTERAPEUTA',
    label: 'MUSICOTERAPEUTA',
  },
  {
    value: 'MÃE SOCIAL',
    label: 'MÃE SOCIAL',
  },
  {
    value: 'MÉDICO ACUPUNTURISTA',
    label: 'MÉDICO ACUPUNTURISTA',
  },
  {
    value: 'MÉDICO ALERGOLOGISTA',
    label: 'MÉDICO ALERGOLOGISTA',
  },
  {
    value: 'MÉDICO AMBULATORIAL',
    label: 'MÉDICO AMBULATORIAL',
  },
  {
    value: 'MÉDICO ANESTESISTA',
    label: 'MÉDICO ANESTESISTA',
  },
  {
    value: 'MÉDICO AUDITOR',
    label: 'MÉDICO AUDITOR',
  },
  {
    value: 'MÉDICO CARDIOLOGISTA',
    label: 'MÉDICO CARDIOLOGISTA',
  },
  {
    value: 'MÉDICO CIRURGIÃO PLÁSTICO',
    label: 'MÉDICO CIRURGIÃO PLÁSTICO',
  },
  {
    value: 'MÉDICO CIRURGIÃO TORÁCICO',
    label: 'MÉDICO CIRURGIÃO TORÁCICO',
  },
  {
    value: 'MÉDICO CIRURGIÃO VASCULAR',
    label: 'MÉDICO CIRURGIÃO VASCULAR',
  },
  {
    value: 'MÉDICO CIRURGIÃO',
    label: 'MÉDICO CIRURGIÃO',
  },
  {
    value: 'MÉDICO CLÍNICO GERAL',
    label: 'MÉDICO CLÍNICO GERAL',
  },
  {
    value: 'MÉDICO COLONOSCOPISTA',
    label: 'MÉDICO COLONOSCOPISTA',
  },
  {
    value: 'MÉDICO DERMATOLOGISTA',
    label: 'MÉDICO DERMATOLOGISTA',
  },
  {
    value: 'MÉDICO DO ESPORTE',
    label: 'MÉDICO DO ESPORTE',
  },
  {
    value: 'MÉDICO DO TRABALHO',
    label: 'MÉDICO DO TRABALHO',
  },
  {
    value: 'MÉDICO DO TRÁFEGO',
    label: 'MÉDICO DO TRÁFEGO',
  },
  {
    value: 'MÉDICO ECOCARDIOGRAFISTA',
    label: 'MÉDICO ECOCARDIOGRAFISTA',
  },
  {
    value: 'MÉDICO EMERGENCISTA',
    label: 'MÉDICO EMERGENCISTA',
  },
  {
    value: 'MÉDICO ENDOCRINOLOGISTA',
    label: 'MÉDICO ENDOCRINOLOGISTA',
  },
  {
    value: 'MÉDICO ESTETICISTA',
    label: 'MÉDICO ESTETICISTA',
  },
  {
    value: 'MÉDICO FISIATRA',
    label: 'MÉDICO FISIATRA',
  },
  {
    value: 'MÉDICO GASTROENTEROLOGISTA',
    label: 'MÉDICO GASTROENTEROLOGISTA',
  },
  {
    value: 'MÉDICO GASTROPEDIATRA',
    label: 'MÉDICO GASTROPEDIATRA',
  },
  {
    value: 'MÉDICO GENETICISTA',
    label: 'MÉDICO GENETICISTA',
  },
  {
    value: 'MÉDICO GERIATRA',
    label: 'MÉDICO GERIATRA',
  },
  {
    value: 'MÉDICO GINECOLOGISTA',
    label: 'MÉDICO GINECOLOGISTA',
  },
  {
    value: 'MÉDICO HEBIATRA',
    label: 'MÉDICO HEBIATRA',
  },
  {
    value: 'MÉDICO HEMATOLOGISTA',
    label: 'MÉDICO HEMATOLOGISTA',
  },
  {
    value: 'MÉDICO HEMOTERAPEUTA',
    label: 'MÉDICO HEMOTERAPEUTA',
  },
  {
    value: 'MÉDICO HOMEOPATA',
    label: 'MÉDICO HOMEOPATA',
  },
  {
    value: 'MÉDICO IMUNOLOGISTA',
    label: 'MÉDICO IMUNOLOGISTA',
  },
  {
    value: 'MÉDICO INFECTOLOGISTA',
    label: 'MÉDICO INFECTOLOGISTA',
  },
  {
    value: 'MÉDICO INTENSIVISTA',
    label: 'MÉDICO INTENSIVISTA',
  },
  {
    value: 'MÉDICO MAMOGRAFISTA',
    label: 'MÉDICO MAMOGRAFISTA',
  },
  {
    value: 'MÉDICO MASTOLOGISTA',
    label: 'MÉDICO MASTOLOGISTA',
  },
  {
    value: 'MÉDICO NEFROLOGISTA',
    label: 'MÉDICO NEFROLOGISTA',
  },
  {
    value: 'MÉDICO NEUROCIRURGIÃO',
    label: 'MÉDICO NEUROCIRURGIÃO',
  },
  {
    value: 'MÉDICO NEUROLOGISTA',
    label: 'MÉDICO NEUROLOGISTA',
  },
  {
    value: 'MÉDICO NUTRÓLOGO',
    label: 'MÉDICO NUTRÓLOGO',
  },
  {
    value: 'MÉDICO OBSTETRA',
    label: 'MÉDICO OBSTETRA',
  },
  {
    value: 'MÉDICO OFTALMOLOGISTA',
    label: 'MÉDICO OFTALMOLOGISTA',
  },
  {
    value: 'MÉDICO ONCOLOGISTA',
    label: 'MÉDICO ONCOLOGISTA',
  },
  {
    value: 'MÉDICO ORTOMOLECULAR',
    label: 'MÉDICO ORTOMOLECULAR',
  },
  {
    value: 'MÉDICO ORTOPEDISTA',
    label: 'MÉDICO ORTOPEDISTA',
  },
  {
    value: 'MÉDICO OTORRINOLARINGOLOGISTA',
    label: 'MÉDICO OTORRINOLARINGOLOGISTA',
  },
  {
    value: 'MÉDICO PATOLOGISTA',
    label: 'MÉDICO PATOLOGISTA',
  },
  {
    value: 'MÉDICO PEDIATRA',
    label: 'MÉDICO PEDIATRA',
  },
  {
    value: 'MÉDICO PLANTONISTA',
    label: 'MÉDICO PLANTONISTA',
  },
  {
    value: 'MÉDICO PNEUMOLOGISTA',
    label: 'MÉDICO PNEUMOLOGISTA',
  },
  {
    value: 'MÉDICO PROCTOLOGISTA',
    label: 'MÉDICO PROCTOLOGISTA',
  },
  {
    value: 'MÉDICO PSIQUIATRA',
    label: 'MÉDICO PSIQUIATRA',
  },
  {
    value: 'MÉDICO RADIOLOGISTA',
    label: 'MÉDICO RADIOLOGISTA',
  },
  {
    value: 'MÉDICO REGULADOR',
    label: 'MÉDICO REGULADOR',
  },
  {
    value: 'MÉDICO REUMATOLOGISTA',
    label: 'MÉDICO REUMATOLOGISTA',
  },
  {
    value: 'MÉDICO TRAUMATOLOGISTA',
    label: 'MÉDICO TRAUMATOLOGISTA',
  },
  {
    value: 'MÉDICO ULTRASSONOGRAFISTA',
    label: 'MÉDICO ULTRASSONOGRAFISTA',
  },
  {
    value: 'MÉDICO UROLOGISTA',
    label: 'MÉDICO UROLOGISTA',
  },
  {
    value: 'MÉDICO VISITADOR',
    label: 'MÉDICO VISITADOR',
  },
  {
    value: 'MÚSICO',
    label: 'MÚSICO',
  },
  {
    value: 'NATURÓLOGO',
    label: 'NATURÓLOGO',
  },
  {
    value: 'NEUROPSICÓLOGO',
    label: 'NEUROPSICÓLOGO',
  },
  {
    value: 'NIVELADOR',
    label: 'NIVELADOR',
  },
  {
    value: 'NUTRICIONISTA CLÍNICO',
    label: 'NUTRICIONISTA CLÍNICO',
  },
  {
    value: 'NUTRICIONISTA COMERCIAL',
    label: 'NUTRICIONISTA COMERCIAL',
  },
  {
    value: 'NUTRICIONISTA DE PRODUÇÃO',
    label: 'NUTRICIONISTA DE PRODUÇÃO',
  },
  {
    value: 'NUTRICIONISTA DE QUALIDADE',
    label: 'NUTRICIONISTA DE QUALIDADE',
  },
  {
    value: 'NUTRICIONISTA DO ESPORTE',
    label: 'NUTRICIONISTA DO ESPORTE',
  },
  {
    value: 'NUTRICIONISTA',
    label: 'NUTRICIONISTA',
  },
  {
    value: 'OCEANÓGRAFO',
    label: 'OCEANÓGRAFO',
  },
  {
    value: 'OFFICE-BOY',
    label: 'OFFICE-BOY',
  },
  {
    value: 'OFICIAL DE MANUTENÇÃO PREDIAL',
    label: 'OFICIAL DE MANUTENÇÃO PREDIAL',
  },
  {
    value: 'OFICIAL DE MANUTENÇÃO',
    label: 'OFICIAL DE MANUTENÇÃO',
  },
  {
    value: 'OFICIAL DE REDES',
    label: 'OFICIAL DE REDES',
  },
  {
    value: 'OFICIAL DE REFRIGERAÇÃO',
    label: 'OFICIAL DE REFRIGERAÇÃO',
  },
  {
    value: 'OFICIAL ELETRICISTA',
    label: 'OFICIAL ELETRICISTA',
  },
  {
    value: 'OFICIAL MECÂNICO',
    label: 'OFICIAL MECÂNICO',
  },
  {
    value: 'OFICIAL SERRALHEIRO',
    label: 'OFICIAL SERRALHEIRO',
  },
  {
    value: 'OPERADOR AUDIOVISUAL',
    label: 'OPERADOR AUDIOVISUAL',
  },
  {
    value: 'OPERADOR DE BALANÇA RODOVIÁRIA',
    label: 'OPERADOR DE BALANÇA RODOVIÁRIA',
  },
  {
    value: 'OPERADOR DE BOMBA DE CONCRETO',
    label: 'OPERADOR DE BOMBA DE CONCRETO',
  },
  {
    value: 'OPERADOR DE CAIXA',
    label: 'OPERADOR DE CAIXA',
  },
  {
    value: 'OPERADOR DE CALANDRA',
    label: 'OPERADOR DE CALANDRA',
  },
  {
    value: 'OPERADOR DE CALDEIRA',
    label: 'OPERADOR DE CALDEIRA',
  },
  {
    value: 'OPERADOR DE CALL CENTER',
    label: 'OPERADOR DE CALL CENTER',
  },
  {
    value: 'OPERADOR DE CENTRAL DE CONCRETO',
    label: 'OPERADOR DE CENTRAL DE CONCRETO',
  },
  {
    value: 'OPERADOR DE CENTRO DE USINAGEM',
    label: 'OPERADOR DE CENTRO DE USINAGEM',
  },
  {
    value: 'OPERADOR DE CFTV',
    label: 'OPERADOR DE CFTV',
  },
  {
    value: 'OPERADOR DE CNC',
    label: 'OPERADOR DE CNC',
  },
  {
    value: 'OPERADOR DE COBRANÇA',
    label: 'OPERADOR DE COBRANÇA',
  },
  {
    value: 'OPERADOR DE COLADEIRA',
    label: 'OPERADOR DE COLADEIRA',
  },
  {
    value: 'OPERADOR DE COMPUTADOR',
    label: 'OPERADOR DE COMPUTADOR',
  },
  {
    value: 'OPERADOR DE CONTROLE MESTRE',
    label: 'OPERADOR DE CONTROLE MESTRE',
  },
  {
    value: 'OPERADOR DE CORTE E DOBRA',
    label: 'OPERADOR DE CORTE E DOBRA',
  },
  {
    value: 'OPERADOR DE CORTE E SOLDA',
    label: 'OPERADOR DE CORTE E SOLDA',
  },
  {
    value: 'OPERADOR DE CORTE E VINCO',
    label: 'OPERADOR DE CORTE E VINCO',
  },
  {
    value: 'OPERADOR DE CÂMARAS FRIAS',
    label: 'OPERADOR DE CÂMARAS FRIAS',
  },
  {
    value: 'OPERADOR DE CÂMBIO',
    label: 'OPERADOR DE CÂMBIO',
  },
  {
    value: 'OPERADOR DE DOBRADEIRA',
    label: 'OPERADOR DE DOBRADEIRA',
  },
  {
    value: 'OPERADOR DE ELETROEROSÃO',
    label: 'OPERADOR DE ELETROEROSÃO',
  },
  {
    value: 'OPERADOR DE EMPILHADEIRA',
    label: 'OPERADOR DE EMPILHADEIRA',
  },
  {
    value: 'OPERADOR DE EQUIPAMENTO',
    label: 'OPERADOR DE EQUIPAMENTO',
  },
  {
    value: 'OPERADOR DE ESCAVADEIRA',
    label: 'OPERADOR DE ESCAVADEIRA',
  },
  {
    value: 'OPERADOR DE ESTACIONAMENTO',
    label: 'OPERADOR DE ESTACIONAMENTO',
  },
  {
    value: 'OPERADOR DE ESTAÇÃO DE TRATAMENTO DE ÁGUAS E EFLUENTES',
    label: 'OPERADOR DE ESTAÇÃO DE TRATAMENTO DE ÁGUAS E EFLUENTES',
  },
  {
    value: 'OPERADOR DE EXTRUSORA',
    label: 'OPERADOR DE EXTRUSORA',
  },
  {
    value: 'OPERADOR DE FACTORING',
    label: 'OPERADOR DE FACTORING',
  },
  {
    value: 'OPERADOR DE FURADEIRA',
    label: 'OPERADOR DE FURADEIRA',
  },
  {
    value: 'OPERADOR DE GUILHOTINA',
    label: 'OPERADOR DE GUILHOTINA',
  },
  {
    value: 'OPERADOR DE GUINCHO',
    label: 'OPERADOR DE GUINCHO',
  },
  {
    value: 'OPERADOR DE GUINDASTE',
    label: 'OPERADOR DE GUINDASTE',
  },
  {
    value: 'OPERADOR DE INJETORA',
    label: 'OPERADOR DE INJETORA',
  },
  {
    value: 'OPERADOR DE LAMINAÇÃO',
    label: 'OPERADOR DE LAMINAÇÃO',
  },
  {
    value: 'OPERADOR DE LIXADEIRA',
    label: 'OPERADOR DE LIXADEIRA',
  },
  {
    value: 'OPERADOR DE LOGÍSTICA',
    label: 'OPERADOR DE LOGÍSTICA',
  },
  {
    value: 'OPERADOR DE MESA',
    label: 'OPERADOR DE MESA',
  },
  {
    value: 'OPERADOR DE MONITORAMENTO',
    label: 'OPERADOR DE MONITORAMENTO',
  },
  {
    value: 'OPERADOR DE MONTAGEM',
    label: 'OPERADOR DE MONTAGEM',
  },
  {
    value: 'OPERADOR DE MOTONIVELADORA',
    label: 'OPERADOR DE MOTONIVELADORA',
  },
  {
    value: 'OPERADOR DE MOTOSSERRA',
    label: 'OPERADOR DE MOTOSSERRA',
  },
  {
    value: 'OPERADOR DE MÁQUINA AGRÍCOLA',
    label: 'OPERADOR DE MÁQUINA AGRÍCOLA',
  },
  {
    value: 'OPERADOR DE MÁQUINA COPIADORA',
    label: 'OPERADOR DE MÁQUINA COPIADORA',
  },
  {
    value: 'OPERADOR DE MÁQUINA DE CORTE',
    label: 'OPERADOR DE MÁQUINA DE CORTE',
  },
  {
    value: 'OPERADOR DE MÁQUINA DE SOPRO',
    label: 'OPERADOR DE MÁQUINA DE SOPRO',
  },
  {
    value: 'OPERADOR DE MÁQUINA PERFURATRIZ',
    label: 'OPERADOR DE MÁQUINA PERFURATRIZ',
  },
  {
    value: 'OPERADOR DE MÁQUINA',
    label: 'OPERADOR DE MÁQUINA',
  },
  {
    value: 'OPERADOR DE NEGÓCIOS',
    label: 'OPERADOR DE NEGÓCIOS',
  },
  {
    value: 'OPERADOR DE ONDULADEIRA',
    label: 'OPERADOR DE ONDULADEIRA',
  },
  {
    value: 'OPERADOR DE PERFILADEIRA',
    label: 'OPERADOR DE PERFILADEIRA',
  },
  {
    value: 'OPERADOR DE PHOTOSHOP',
    label: 'OPERADOR DE PHOTOSHOP',
  },
  {
    value: 'OPERADOR DE PLOTTER',
    label: 'OPERADOR DE PLOTTER',
  },
  {
    value: 'OPERADOR DE PONTE ROLANTE',
    label: 'OPERADOR DE PONTE ROLANTE',
  },
  {
    value: 'OPERADOR DE PRENSA',
    label: 'OPERADOR DE PRENSA',
  },
  {
    value: 'OPERADOR DE PROCESSOS QUÍMICOS',
    label: 'OPERADOR DE PROCESSOS QUÍMICOS',
  },
  {
    value: 'OPERADOR DE PUNCIONADEIRA',
    label: 'OPERADOR DE PUNCIONADEIRA',
  },
  {
    value: 'OPERADOR DE PÁ CARREGADEIRA',
    label: 'OPERADOR DE PÁ CARREGADEIRA',
  },
  {
    value: 'OPERADOR DE RAMA',
    label: 'OPERADOR DE RAMA',
  },
  {
    value: 'OPERADOR DE RASTREAMENTO',
    label: 'OPERADOR DE RASTREAMENTO',
  },
  {
    value: 'OPERADOR DE REBOCADOR',
    label: 'OPERADOR DE REBOCADOR',
  },
  {
    value: 'OPERADOR DE REDES',
    label: 'OPERADOR DE REDES',
  },
  {
    value: 'OPERADOR DE REFRIGERAÇÃO',
    label: 'OPERADOR DE REFRIGERAÇÃO',
  },
  {
    value: 'OPERADOR DE RETIFICADORA',
    label: 'OPERADOR DE RETIFICADORA',
  },
  {
    value: 'OPERADOR DE RETROESCAVADEIRA',
    label: 'OPERADOR DE RETROESCAVADEIRA',
  },
  {
    value: 'OPERADOR DE ROLO COMPACTADOR',
    label: 'OPERADOR DE ROLO COMPACTADOR',
  },
  {
    value: 'OPERADOR DE RÁDIO',
    label: 'OPERADOR DE RÁDIO',
  },
  {
    value: 'OPERADOR DE SALA DE MÁQUINAS',
    label: 'OPERADOR DE SALA DE MÁQUINAS',
  },
  {
    value: 'OPERADOR DE SERRA',
    label: 'OPERADOR DE SERRA',
  },
  {
    value: 'OPERADOR DE TELEMARKETING',
    label: 'OPERADOR DE TELEMARKETING',
  },
  {
    value: 'OPERADOR DE TELEVENDAS',
    label: 'OPERADOR DE TELEVENDAS',
  },
  {
    value: 'OPERADOR DE TORNO AUTOMÁTICO',
    label: 'OPERADOR DE TORNO AUTOMÁTICO',
  },
  {
    value: 'OPERADOR DE TORNO CNC',
    label: 'OPERADOR DE TORNO CNC',
  },
  {
    value: 'OPERADOR DE TORNO',
    label: 'OPERADOR DE TORNO',
  },
  {
    value: 'OPERADOR DE TRÁFEGO',
    label: 'OPERADOR DE TRÁFEGO',
  },
  {
    value: 'OPERADOR DE TURISMO',
    label: 'OPERADOR DE TURISMO',
  },
  {
    value: 'OPERADOR DE UTILIDADES',
    label: 'OPERADOR DE UTILIDADES',
  },
  {
    value: 'OPERADOR DE ZINCAGEM',
    label: 'OPERADOR DE ZINCAGEM',
  },
  {
    value: 'OPERADOR DE ÁUDIO',
    label: 'OPERADOR DE ÁUDIO',
  },
  {
    value: 'OPERADOR MANTENEDOR',
    label: 'OPERADOR MANTENEDOR',
  },
  {
    value: 'ORIENTADOR EDUCACIONAL',
    label: 'ORIENTADOR EDUCACIONAL',
  },
  {
    value: 'ORIENTADOR SÓCIO EDUCATIVO',
    label: 'ORIENTADOR SÓCIO EDUCATIVO',
  },
  {
    value: 'ORÇAMENTISTA CIVIL',
    label: 'ORÇAMENTISTA CIVIL',
  },
  {
    value: 'ORÇAMENTISTA',
    label: 'ORÇAMENTISTA',
  },
  {
    value: 'OURIVES',
    label: 'OURIVES',
  },
  {
    value: 'PADEIRO',
    label: 'PADEIRO',
  },
  {
    value: 'PAISAGISTA',
    label: 'PAISAGISTA',
  },
  {
    value: 'PALESTRANTE',
    label: 'PALESTRANTE',
  },
  {
    value: 'PANFLETEIRO',
    label: 'PANFLETEIRO',
  },
  {
    value: 'PASSADOR',
    label: 'PASSADOR',
  },
  {
    value: 'PEDAGOGO EMPRESARIAL',
    label: 'PEDAGOGO EMPRESARIAL',
  },
  {
    value: 'PEDAGOGO',
    label: 'PEDAGOGO',
  },
  {
    value: 'PEDREIRO',
    label: 'PEDREIRO',
  },
  {
    value: 'PEIXEIRO',
    label: 'PEIXEIRO',
  },
  {
    value: 'PERFUMISTA',
    label: 'PERFUMISTA',
  },
  {
    value: 'PERITO JUDICIAL',
    label: 'PERITO JUDICIAL',
  },
  {
    value: 'PERSONAL STYLIST',
    label: 'PERSONAL STYLIST',
  },
  {
    value: 'PERSONAL TRAINER',
    label: 'PERSONAL TRAINER',
  },
  {
    value: 'PESADOR',
    label: 'PESADOR',
  },
  {
    value: 'PESQUISADOR DE MERCADO',
    label: 'PESQUISADOR DE MERCADO',
  },
  {
    value: 'PESQUISADOR',
    label: 'PESQUISADOR',
  },
  {
    value: 'PILOTEIRO',
    label: 'PILOTEIRO',
  },
  {
    value: 'PILOTO AGRÍCOLA',
    label: 'PILOTO AGRÍCOLA',
  },
  {
    value: 'PILOTO DE AVIÃO',
    label: 'PILOTO DE AVIÃO',
  },
  {
    value: 'PILOTO DE HELICÓPTERO',
    label: 'PILOTO DE HELICÓPTERO',
  },
  {
    value: 'PILOTO DE TESTES',
    label: 'PILOTO DE TESTES',
  },
  {
    value: 'PINTOR AUTOMOTIVO',
    label: 'PINTOR AUTOMOTIVO',
  },
  {
    value: 'PINTOR DE ESTRUTURAS METÁLICAS',
    label: 'PINTOR DE ESTRUTURAS METÁLICAS',
  },
  {
    value: 'PINTOR DE MÓVEIS',
    label: 'PINTOR DE MÓVEIS',
  },
  {
    value: 'PINTOR DE OBRAS',
    label: 'PINTOR DE OBRAS',
  },
  {
    value: 'PINTOR ELETROSTÁTICO',
    label: 'PINTOR ELETROSTÁTICO',
  },
  {
    value: 'PINTOR',
    label: 'PINTOR',
  },
  {
    value: 'PIZZAIOLO',
    label: 'PIZZAIOLO',
  },
  {
    value: 'PLANEJADOR DE PRODUÇÃO',
    label: 'PLANEJADOR DE PRODUÇÃO',
  },
  {
    value: 'PLANEJADOR DE PROJETOS',
    label: 'PLANEJADOR DE PROJETOS',
  },
  {
    value: 'PODÓLOGO',
    label: 'PODÓLOGO',
  },
  {
    value: 'POLIDOR DE METAIS',
    label: 'POLIDOR DE METAIS',
  },
  {
    value: 'POLIDOR DE VEÍCULOS',
    label: 'POLIDOR DE VEÍCULOS',
  },
  {
    value: 'POLIDOR',
    label: 'POLIDOR',
  },
  {
    value: 'PORTEIRO',
    label: 'PORTEIRO',
  },
  {
    value: 'PRENSISTA',
    label: 'PRENSISTA',
  },
  {
    value: 'PREPARADOR DE AUTOS',
    label: 'PREPARADOR DE AUTOS',
  },
  {
    value: 'PREPARADOR DE CNC',
    label: 'PREPARADOR DE CNC',
  },
  {
    value: 'PREPARADOR DE MÁQUINA INJETORA',
    label: 'PREPARADOR DE MÁQUINA INJETORA',
  },
  {
    value: 'PREPARADOR DE MÁQUINAS',
    label: 'PREPARADOR DE MÁQUINAS',
  },
  {
    value: 'PREPARADOR DE PINTURA',
    label: 'PREPARADOR DE PINTURA',
  },
  {
    value: 'PREPARADOR DE TINTAS',
    label: 'PREPARADOR DE TINTAS',
  },
  {
    value: 'PREPARADOR DE TORNO',
    label: 'PREPARADOR DE TORNO',
  },
  {
    value: 'PREPARADOR FÍSICO',
    label: 'PREPARADOR FÍSICO',
  },
  {
    value: 'PROCESSISTA',
    label: 'PROCESSISTA',
  },
  {
    value: 'PRODUTOR ARTÍSTICO',
    label: 'PRODUTOR ARTÍSTICO',
  },
  {
    value: 'PRODUTOR CULTURAL',
    label: 'PRODUTOR CULTURAL',
  },
  {
    value: 'PRODUTOR DE CASTING',
    label: 'PRODUTOR DE CASTING',
  },
  {
    value: 'PRODUTOR DE CONTEÚDO',
    label: 'PRODUTOR DE CONTEÚDO',
  },
  {
    value: 'PRODUTOR DE EVENTOS',
    label: 'PRODUTOR DE EVENTOS',
  },
  {
    value: 'PRODUTOR DE MODA',
    label: 'PRODUTOR DE MODA',
  },
  {
    value: 'PRODUTOR DE TELEVISÃO',
    label: 'PRODUTOR DE TELEVISÃO',
  },
  {
    value: 'PRODUTOR DE VÍDEO',
    label: 'PRODUTOR DE VÍDEO',
  },
  {
    value: 'PRODUTOR EDITORIAL',
    label: 'PRODUTOR EDITORIAL',
  },
  {
    value: 'PRODUTOR GRÁFICO',
    label: 'PRODUTOR GRÁFICO',
  },
  {
    value: 'PRODUTOR MUSICAL',
    label: 'PRODUTOR MUSICAL',
  },
  {
    value: 'PROFESSOR',
    label: 'PROFESSOR',
  },
  {
    value: 'PROFESSOR AUXILIAR',
    label: 'PROFESSOR AUXILIAR',
  },
  {
    value: 'PROFESSOR DE ADMINISTRAÇÃO DE EMPRESAS',
    label: 'PROFESSOR DE ADMINISTRAÇÃO DE EMPRESAS',
  },
  {
    value: 'PROFESSOR DE ADMINISTRAÇÃO PÚBLICA',
    label: 'PROFESSOR DE ADMINISTRAÇÃO PÚBLICA',
  },
  {
    value: 'PROFESSOR DE AGRONOMIA',
    label: 'PROFESSOR DE AGRONOMIA',
  },
  {
    value: 'PROFESSOR DE ALEMÃO',
    label: 'PROFESSOR DE ALEMÃO',
  },
  {
    value: 'PROFESSOR DE ANATOMIA',
    label: 'PROFESSOR DE ANATOMIA',
  },
  {
    value: 'PROFESSOR DE ARQUITETURA E URBANISMO',
    label: 'PROFESSOR DE ARQUITETURA E URBANISMO',
  },
  {
    value: 'PROFESSOR DE ARTES CÊNICAS',
    label: 'PROFESSOR DE ARTES CÊNICAS',
  },
  {
    value: 'PROFESSOR DE ARTES MARCIAIS',
    label: 'PROFESSOR DE ARTES MARCIAIS',
  },
  {
    value: 'PROFESSOR DE ARTESANATO',
    label: 'PROFESSOR DE ARTESANATO',
  },
  {
    value: 'PROFESSOR DE AUTOCAD',
    label: 'PROFESSOR DE AUTOCAD',
  },
  {
    value: 'PROFESSOR DE AUTOMAÇÃO INDUSTRIAL',
    label: 'PROFESSOR DE AUTOMAÇÃO INDUSTRIAL',
  },
  {
    value: 'PROFESSOR DE BIOLOGIA',
    label: 'PROFESSOR DE BIOLOGIA',
  },
  {
    value: 'PROFESSOR DE BIOMEDICINA',
    label: 'PROFESSOR DE BIOMEDICINA',
  },
  {
    value: 'PROFESSOR DE CANTO',
    label: 'PROFESSOR DE CANTO',
  },
  {
    value: 'PROFESSOR DE CAPOEIRA',
    label: 'PROFESSOR DE CAPOEIRA',
  },
  {
    value: 'PROFESSOR DE CIRCO',
    label: 'PROFESSOR DE CIRCO',
  },
  {
    value: 'PROFESSOR DE CIÊNCIA DA COMPUTAÇÃO',
    label: 'PROFESSOR DE CIÊNCIA DA COMPUTAÇÃO',
  },
  {
    value: 'PROFESSOR DE CIÊNCIAS CONTÁBEIS',
    label: 'PROFESSOR DE CIÊNCIAS CONTÁBEIS',
  },
  {
    value: 'PROFESSOR DE COMPUTAÇÃO GRÁFICA',
    label: 'PROFESSOR DE COMPUTAÇÃO GRÁFICA',
  },
  {
    value: 'PROFESSOR DE COMUNICAÇÃO SOCIAL',
    label: 'PROFESSOR DE COMUNICAÇÃO SOCIAL',
  },
  {
    value: 'PROFESSOR DE COMUNICAÇÃO VISUAL',
    label: 'PROFESSOR DE COMUNICAÇÃO VISUAL',
  },
  {
    value: 'PROFESSOR DE COMÉRCIO EXTERIOR',
    label: 'PROFESSOR DE COMÉRCIO EXTERIOR',
  },
  {
    value: 'PROFESSOR DE CONSTRUÇÃO CIVIL',
    label: 'PROFESSOR DE CONSTRUÇÃO CIVIL',
  },
  {
    value: 'PROFESSOR DE CORELDRAW',
    label: 'PROFESSOR DE CORELDRAW',
  },
  {
    value: 'PROFESSOR DE COSTURA',
    label: 'PROFESSOR DE COSTURA',
  },
  {
    value: 'PROFESSOR DE CÁLCULO',
    label: 'PROFESSOR DE CÁLCULO',
  },
  {
    value: 'PROFESSOR DE DANÇA',
    label: 'PROFESSOR DE DANÇA',
  },
  {
    value: 'PROFESSOR DE DEPARTAMENTO PESSOAL',
    label: 'PROFESSOR DE DEPARTAMENTO PESSOAL',
  },
  {
    value: 'PROFESSOR DE DESENHO TÉCNICO',
    label: 'PROFESSOR DE DESENHO TÉCNICO',
  },
  {
    value: 'PROFESSOR DE DESIGN DE INTERIORES',
    label: 'PROFESSOR DE DESIGN DE INTERIORES',
  },
  {
    value: 'PROFESSOR DE DESIGN GRÁFICO',
    label: 'PROFESSOR DE DESIGN GRÁFICO',
  },
  {
    value: 'PROFESSOR DE DIREITO ADMINISTRATIVO',
    label: 'PROFESSOR DE DIREITO ADMINISTRATIVO',
  },
  {
    value: 'PROFESSOR DE DIREITO CONSTITUCIONAL',
    label: 'PROFESSOR DE DIREITO CONSTITUCIONAL',
  },
  {
    value: 'PROFESSOR DE DIREITO PENAL',
    label: 'PROFESSOR DE DIREITO PENAL',
  },
  {
    value: 'PROFESSOR DE DIREITO TRIBUTÁRIO',
    label: 'PROFESSOR DE DIREITO TRIBUTÁRIO',
  },
  {
    value: 'PROFESSOR DE DIREITO',
    label: 'PROFESSOR DE DIREITO',
  },
  {
    value: 'PROFESSOR DE ECONOMIA',
    label: 'PROFESSOR DE ECONOMIA',
  },
  {
    value: 'PROFESSOR DE EDIFICAÇÕES',
    label: 'PROFESSOR DE EDIFICAÇÕES',
  },
  {
    value: 'PROFESSOR DE EDUCAÇÃO ARTÍSTICA',
    label: 'PROFESSOR DE EDUCAÇÃO ARTÍSTICA',
  },
  {
    value: 'PROFESSOR DE EDUCAÇÃO ESPECIAL',
    label: 'PROFESSOR DE EDUCAÇÃO ESPECIAL',
  },
  {
    value: 'PROFESSOR DE EDUCAÇÃO FÍSICA',
    label: 'PROFESSOR DE EDUCAÇÃO FÍSICA',
  },
  {
    value: 'PROFESSOR DE EDUCAÇÃO INFANTIL',
    label: 'PROFESSOR DE EDUCAÇÃO INFANTIL',
  },
  {
    value: 'PROFESSOR DE ELETROTÉCNICA',
    label: 'PROFESSOR DE ELETROTÉCNICA',
  },
  {
    value: 'PROFESSOR DE ELETRÔNICA',
    label: 'PROFESSOR DE ELETRÔNICA',
  },
  {
    value: 'PROFESSOR DE ELÉTRICA',
    label: 'PROFESSOR DE ELÉTRICA',
  },
  {
    value: 'PROFESSOR DE EMPREENDEDORISMO',
    label: 'PROFESSOR DE EMPREENDEDORISMO',
  },
  {
    value: 'PROFESSOR DE ENFERMAGEM',
    label: 'PROFESSOR DE ENFERMAGEM',
  },
  {
    value: 'PROFESSOR DE ENGENHARIA AMBIENTAL',
    label: 'PROFESSOR DE ENGENHARIA AMBIENTAL',
  },
  {
    value: 'PROFESSOR DE ENGENHARIA CIVIL',
    label: 'PROFESSOR DE ENGENHARIA CIVIL',
  },
  {
    value: 'PROFESSOR DE ENGENHARIA DA COMPUTAÇÃO',
    label: 'PROFESSOR DE ENGENHARIA DA COMPUTAÇÃO',
  },
  {
    value: 'PROFESSOR DE ENGENHARIA DE PRODUÇÃO',
    label: 'PROFESSOR DE ENGENHARIA DE PRODUÇÃO',
  },
  {
    value: 'PROFESSOR DE ENGENHARIA ELÉTRICA',
    label: 'PROFESSOR DE ENGENHARIA ELÉTRICA',
  },
  {
    value: 'PROFESSOR DE ENGENHARIA MECATRÔNICA',
    label: 'PROFESSOR DE ENGENHARIA MECATRÔNICA',
  },
  {
    value: 'PROFESSOR DE ENGENHARIA MECÂNICA',
    label: 'PROFESSOR DE ENGENHARIA MECÂNICA',
  },
  {
    value: 'PROFESSOR DE ENGENHARIA',
    label: 'PROFESSOR DE ENGENHARIA',
  },
  {
    value: 'PROFESSOR DE ENSINO A DISTÂNCIA',
    label: 'PROFESSOR DE ENSINO A DISTÂNCIA',
  },
  {
    value: 'PROFESSOR DE ENSINO FUNDAMENTAL',
    label: 'PROFESSOR DE ENSINO FUNDAMENTAL',
  },
  {
    value: 'PROFESSOR DE ENSINO MÉDIO',
    label: 'PROFESSOR DE ENSINO MÉDIO',
  },
  {
    value: 'PROFESSOR DE ENSINO RELIGIOSO',
    label: 'PROFESSOR DE ENSINO RELIGIOSO',
  },
  {
    value: 'PROFESSOR DE ENSINO TÉCNICO',
    label: 'PROFESSOR DE ENSINO TÉCNICO',
  },
  {
    value: 'PROFESSOR DE ESPANHOL',
    label: 'PROFESSOR DE ESPANHOL',
  },
  {
    value: 'PROFESSOR DE ESTATÍSTICA',
    label: 'PROFESSOR DE ESTATÍSTICA',
  },
  {
    value: 'PROFESSOR DE ESTÉTICA E COSMETOLOGIA',
    label: 'PROFESSOR DE ESTÉTICA E COSMETOLOGIA',
  },
  {
    value: 'PROFESSOR DE EXPRESSÃO CORPORAL',
    label: 'PROFESSOR DE EXPRESSÃO CORPORAL',
  },
  {
    value: 'PROFESSOR DE FARMÁCIA',
    label: 'PROFESSOR DE FARMÁCIA',
  },
  {
    value: 'PROFESSOR DE FILOSOFIA',
    label: 'PROFESSOR DE FILOSOFIA',
  },
  {
    value: 'PROFESSOR DE FISIOTERAPIA',
    label: 'PROFESSOR DE FISIOTERAPIA',
  },
  {
    value: 'PROFESSOR DE FOTOGRAFIA',
    label: 'PROFESSOR DE FOTOGRAFIA',
  },
  {
    value: 'PROFESSOR DE FRANCÊS',
    label: 'PROFESSOR DE FRANCÊS',
  },
  {
    value: 'PROFESSOR DE FUTEBOL',
    label: 'PROFESSOR DE FUTEBOL',
  },
  {
    value: 'PROFESSOR DE FÍSICA',
    label: 'PROFESSOR DE FÍSICA',
  },
  {
    value: 'PROFESSOR DE GASTRONOMIA',
    label: 'PROFESSOR DE GASTRONOMIA',
  },
  {
    value: 'PROFESSOR DE GEOGRAFIA',
    label: 'PROFESSOR DE GEOGRAFIA',
  },
  {
    value: 'PROFESSOR DE GESTÃO AMBIENTAL',
    label: 'PROFESSOR DE GESTÃO AMBIENTAL',
  },
  {
    value: 'PROFESSOR DE GESTÃO DA QUALIDADE',
    label: 'PROFESSOR DE GESTÃO DA QUALIDADE',
  },
  {
    value: 'PROFESSOR DE GESTÃO DE PROJETOS',
    label: 'PROFESSOR DE GESTÃO DE PROJETOS',
  },
  {
    value: 'PROFESSOR DE GESTÃO FINANCEIRA',
    label: 'PROFESSOR DE GESTÃO FINANCEIRA',
  },
  {
    value: 'PROFESSOR DE GESTÃO HOSPITALAR',
    label: 'PROFESSOR DE GESTÃO HOSPITALAR',
  },
  {
    value: 'PROFESSOR DE GINÁSTICA LABORAL',
    label: 'PROFESSOR DE GINÁSTICA LABORAL',
  },
  {
    value: 'PROFESSOR DE GINÁSTICA',
    label: 'PROFESSOR DE GINÁSTICA',
  },
  {
    value: 'PROFESSOR DE GREGO',
    label: 'PROFESSOR DE GREGO',
  },
  {
    value: 'PROFESSOR DE HEBRAICO',
    label: 'PROFESSOR DE HEBRAICO',
  },
  {
    value: 'PROFESSOR DE HIDROGINÁSTICA',
    label: 'PROFESSOR DE HIDROGINÁSTICA',
  },
  {
    value: 'PROFESSOR DE HISTÓRIA',
    label: 'PROFESSOR DE HISTÓRIA',
  },
  {
    value: 'PROFESSOR DE HOLANDÊS',
    label: 'PROFESSOR DE HOLANDÊS',
  },
  {
    value: 'PROFESSOR DE HOTELARIA E TURISMO',
    label: 'PROFESSOR DE HOTELARIA E TURISMO',
  },
  {
    value: 'PROFESSOR DE HOTELARIA',
    label: 'PROFESSOR DE HOTELARIA',
  },
  {
    value: 'PROFESSOR DE IDIOMAS',
    label: 'PROFESSOR DE IDIOMAS',
  },
  {
    value: 'PROFESSOR DE INFORMÁTICA',
    label: 'PROFESSOR DE INFORMÁTICA',
  },
  {
    value: 'PROFESSOR DE INGLÊS E ESPANHOL',
    label: 'PROFESSOR DE INGLÊS E ESPANHOL',
  },
  {
    value: 'PROFESSOR DE INGLÊS E PORTUGUÊS',
    label: 'PROFESSOR DE INGLÊS E PORTUGUÊS',
  },
  {
    value: 'PROFESSOR DE INGLÊS',
    label: 'PROFESSOR DE INGLÊS',
  },
  {
    value: 'PROFESSOR DE ITALIANO',
    label: 'PROFESSOR DE ITALIANO',
  },
  {
    value: 'PROFESSOR DE JAPONÊS',
    label: 'PROFESSOR DE JAPONÊS',
  },
  {
    value: 'PROFESSOR DE JORNALISMO',
    label: 'PROFESSOR DE JORNALISMO',
  },
  {
    value: 'PROFESSOR DE LETRAS',
    label: 'PROFESSOR DE LETRAS',
  },
  {
    value: 'PROFESSOR DE LIBRAS',
    label: 'PROFESSOR DE LIBRAS',
  },
  {
    value: 'PROFESSOR DE LITERATURA',
    label: 'PROFESSOR DE LITERATURA',
  },
  {
    value: 'PROFESSOR DE LOGÍSTICA',
    label: 'PROFESSOR DE LOGÍSTICA',
  },
  {
    value: 'PROFESSOR DE MANDARIM',
    label: 'PROFESSOR DE MANDARIM',
  },
  {
    value: 'PROFESSOR DE MANICURE',
    label: 'PROFESSOR DE MANICURE',
  },
  {
    value: 'PROFESSOR DE MAQUIAGEM',
    label: 'PROFESSOR DE MAQUIAGEM',
  },
  {
    value: 'PROFESSOR DE MARKETING',
    label: 'PROFESSOR DE MARKETING',
  },
  {
    value: 'PROFESSOR DE MASSOTERAPIA',
    label: 'PROFESSOR DE MASSOTERAPIA',
  },
  {
    value: 'PROFESSOR DE MATEMÁTICA FINANCEIRA',
    label: 'PROFESSOR DE MATEMÁTICA FINANCEIRA',
  },
  {
    value: 'PROFESSOR DE MATEMÁTICA',
    label: 'PROFESSOR DE MATEMÁTICA',
  },
  {
    value: 'PROFESSOR DE MECÂNICA AERONÁUTICA',
    label: 'PROFESSOR DE MECÂNICA AERONÁUTICA',
  },
  {
    value: 'PROFESSOR DE MECÂNICA',
    label: 'PROFESSOR DE MECÂNICA',
  },
  {
    value: 'PROFESSOR DE MEDICINA DO TRABALHO',
    label: 'PROFESSOR DE MEDICINA DO TRABALHO',
  },
  {
    value: 'PROFESSOR DE MEDICINA',
    label: 'PROFESSOR DE MEDICINA',
  },
  {
    value: 'PROFESSOR DE MODA',
    label: 'PROFESSOR DE MODA',
  },
  {
    value: 'PROFESSOR DE MUSCULAÇÃO',
    label: 'PROFESSOR DE MUSCULAÇÃO',
  },
  {
    value: 'PROFESSOR DE MÉTODOS E PROCESSOS',
    label: 'PROFESSOR DE MÉTODOS E PROCESSOS',
  },
  {
    value: 'PROFESSOR DE MÚSICA',
    label: 'PROFESSOR DE MÚSICA',
  },
  {
    value: 'PROFESSOR DE NATAÇÃO',
    label: 'PROFESSOR DE NATAÇÃO',
  },
  {
    value: 'PROFESSOR DE NORUEGUÊS',
    label: 'PROFESSOR DE NORUEGUÊS',
  },
  {
    value: 'PROFESSOR DE NUTRIÇÃO',
    label: 'PROFESSOR DE NUTRIÇÃO',
  },
  {
    value: 'PROFESSOR DE ODONTOLOGIA',
    label: 'PROFESSOR DE ODONTOLOGIA',
  },
  {
    value: 'PROFESSOR DE ORACLE',
    label: 'PROFESSOR DE ORACLE',
  },
  {
    value: 'PROFESSOR DE PEDAGOGIA',
    label: 'PROFESSOR DE PEDAGOGIA',
  },
  {
    value: 'PROFESSOR DE PETRÓLEO E GÁS',
    label: 'PROFESSOR DE PETRÓLEO E GÁS',
  },
  {
    value: 'PROFESSOR DE PHOTOSHOP',
    label: 'PROFESSOR DE PHOTOSHOP',
  },
  {
    value: 'PROFESSOR DE PHP',
    label: 'PROFESSOR DE PHP',
  },
  {
    value: 'PROFESSOR DE PINTURA EM TELA',
    label: 'PROFESSOR DE PINTURA EM TELA',
  },
  {
    value: 'PROFESSOR DE PODOLOGIA',
    label: 'PROFESSOR DE PODOLOGIA',
  },
  {
    value: 'PROFESSOR DE PORTUGUÊS',
    label: 'PROFESSOR DE PORTUGUÊS',
  },
  {
    value: 'PROFESSOR DE PROGRAMAÇÃO DE COMPUTADORES',
    label: 'PROFESSOR DE PROGRAMAÇÃO DE COMPUTADORES',
  },
  {
    value: 'PROFESSOR DE PROGRAMAÇÃO DE REDES',
    label: 'PROFESSOR DE PROGRAMAÇÃO DE REDES',
  },
  {
    value: 'PROFESSOR DE PRÓTESE DENTÁRIA',
    label: 'PROFESSOR DE PRÓTESE DENTÁRIA',
  },
  {
    value: 'PROFESSOR DE PSICANÁLISE',
    label: 'PROFESSOR DE PSICANÁLISE',
  },
  {
    value: 'PROFESSOR DE PSICOLOGIA',
    label: 'PROFESSOR DE PSICOLOGIA',
  },
  {
    value: 'PROFESSOR DE PSICOPEDAGOGIA',
    label: 'PROFESSOR DE PSICOPEDAGOGIA',
  },
  {
    value: 'PROFESSOR DE PUBLICIDADE E PROPAGANDA',
    label: 'PROFESSOR DE PUBLICIDADE E PROPAGANDA',
  },
  {
    value: 'PROFESSOR DE QUÍMICA',
    label: 'PROFESSOR DE QUÍMICA',
  },
  {
    value: 'PROFESSOR DE RADIOLOGIA',
    label: 'PROFESSOR DE RADIOLOGIA',
  },
  {
    value: 'PROFESSOR DE RECREAÇÃO',
    label: 'PROFESSOR DE RECREAÇÃO',
  },
  {
    value: 'PROFESSOR DE RECURSOS HUMANOS',
    label: 'PROFESSOR DE RECURSOS HUMANOS',
  },
  {
    value: 'PROFESSOR DE REDAÇÃO',
    label: 'PROFESSOR DE REDAÇÃO',
  },
  {
    value: 'PROFESSOR DE RELAÇÕES PÚBLICAS',
    label: 'PROFESSOR DE RELAÇÕES PÚBLICAS',
  },
  {
    value: 'PROFESSOR DE ROBÓTICA',
    label: 'PROFESSOR DE ROBÓTICA',
  },
  {
    value: 'PROFESSOR DE RUSSO',
    label: 'PROFESSOR DE RUSSO',
  },
  {
    value: 'PROFESSOR DE SECRETARIADO',
    label: 'PROFESSOR DE SECRETARIADO',
  },
  {
    value: 'PROFESSOR DE SEGURANÇA DO TRABALHO',
    label: 'PROFESSOR DE SEGURANÇA DO TRABALHO',
  },
  {
    value: 'PROFESSOR DE SERVIÇO SOCIAL',
    label: 'PROFESSOR DE SERVIÇO SOCIAL',
  },
  {
    value: 'PROFESSOR DE SISTEMAS DE INFORMAÇÃO',
    label: 'PROFESSOR DE SISTEMAS DE INFORMAÇÃO',
  },
  {
    value: 'PROFESSOR DE SOCIOLOGIA',
    label: 'PROFESSOR DE SOCIOLOGIA',
  },
  {
    value: 'PROFESSOR DE SQL',
    label: 'PROFESSOR DE SQL',
  },
  {
    value: 'PROFESSOR DE SUECO',
    label: 'PROFESSOR DE SUECO',
  },
  {
    value: 'PROFESSOR DE TEATRO',
    label: 'PROFESSOR DE TEATRO',
  },
  {
    value: 'PROFESSOR DE TECNOLOGIA DA INFORMAÇÃO',
    label: 'PROFESSOR DE TECNOLOGIA DA INFORMAÇÃO',
  },
  {
    value: 'PROFESSOR DE TELEMARKETING',
    label: 'PROFESSOR DE TELEMARKETING',
  },
  {
    value: 'PROFESSOR DE TEOLOGIA',
    label: 'PROFESSOR DE TEOLOGIA',
  },
  {
    value: 'PROFESSOR DE TURISMO',
    label: 'PROFESSOR DE TURISMO',
  },
  {
    value: 'PROFESSOR DE TÊNIS',
    label: 'PROFESSOR DE TÊNIS',
  },
  {
    value: 'PROFESSOR DE VENDAS',
    label: 'PROFESSOR DE VENDAS',
  },
  {
    value: 'PROFESSOR DE VETERINÁRIA',
    label: 'PROFESSOR DE VETERINÁRIA',
  },
  {
    value: 'PROFESSOR DE VIOLÃO',
    label: 'PROFESSOR DE VIOLÃO',
  },
  {
    value: 'PROFESSOR DE VISUAL BASIC',
    label: 'PROFESSOR DE VISUAL BASIC',
  },
  {
    value: 'PROFESSOR DE WEB DESIGN',
    label: 'PROFESSOR DE WEB DESIGN',
  },
  {
    value: 'PROFESSOR DE WINDOWS',
    label: 'PROFESSOR DE WINDOWS',
  },
  {
    value: 'PROFESSOR DE XADREZ',
    label: 'PROFESSOR DE XADREZ',
  },
  {
    value: 'PROFESSOR DE YOGA',
    label: 'PROFESSOR DE YOGA',
  },
  {
    value: 'PROFESSOR DE ZOOTECNIA',
    label: 'PROFESSOR DE ZOOTECNIA',
  },
  {
    value: 'PROFESSOR DE ÁRABE',
    label: 'PROFESSOR DE ÁRABE',
  },
  {
    value: 'PROFESSOR DE ÉTICA',
    label: 'PROFESSOR DE ÉTICA',
  },
  {
    value: 'PROFESSOR UNIVERSITÁRIO',
    label: 'PROFESSOR UNIVERSITÁRIO',
  },
  {
    value: 'PROFESSOR',
    label: 'PROFESSOR',
  },
  {
    value: 'PROGRAMADOR .NET',
    label: 'PROGRAMADOR .NET',
  },
  {
    value: 'PROGRAMADOR ABAP',
    label: 'PROGRAMADOR ABAP',
  },
  {
    value: 'PROGRAMADOR ADVPL',
    label: 'PROGRAMADOR ADVPL',
  },
  {
    value: 'PROGRAMADOR ANDROID',
    label: 'PROGRAMADOR ANDROID',
  },
  {
    value: 'PROGRAMADOR ASP',
    label: 'PROGRAMADOR ASP',
  },
  {
    value: 'PROGRAMADOR ASP.NET',
    label: 'PROGRAMADOR ASP.NET',
  },
  {
    value: 'PROGRAMADOR BACK-END',
    label: 'PROGRAMADOR BACK-END',
  },
  {
    value: 'PROGRAMADOR BPM',
    label: 'PROGRAMADOR BPM',
  },
  {
    value: 'PROGRAMADOR C',
    label: 'PROGRAMADOR C',
  },
  {
    value: 'PROGRAMADOR C#',
    label: 'PROGRAMADOR C#',
  },
  {
    value: 'PROGRAMADOR C++',
    label: 'PROGRAMADOR C++',
  },
  {
    value: 'PROGRAMADOR CLIPPER',
    label: 'PROGRAMADOR CLIPPER',
  },
  {
    value: 'PROGRAMADOR CLP',
    label: 'PROGRAMADOR CLP',
  },
  {
    value: 'PROGRAMADOR CNC',
    label: 'PROGRAMADOR CNC',
  },
  {
    value: 'PROGRAMADOR COBOL',
    label: 'PROGRAMADOR COBOL',
  },
  {
    value: 'PROGRAMADOR DE BANCO DE DADOS',
    label: 'PROGRAMADOR DE BANCO DE DADOS',
  },
  {
    value: 'PROGRAMADOR DE BORDADO',
    label: 'PROGRAMADOR DE BORDADO',
  },
  {
    value: 'PROGRAMADOR DE BUSINESS INTELLIGENCE',
    label: 'PROGRAMADOR DE BUSINESS INTELLIGENCE',
  },
  {
    value: 'PROGRAMADOR DE CENTRO DE USINAGEM',
    label: 'PROGRAMADOR DE CENTRO DE USINAGEM',
  },
  {
    value: 'PROGRAMADOR DE JOGOS',
    label: 'PROGRAMADOR DE JOGOS',
  },
  {
    value: 'PROGRAMADOR DE MANUTENÇÃO',
    label: 'PROGRAMADOR DE MANUTENÇÃO',
  },
  {
    value: 'PROGRAMADOR DE MATERIAIS',
    label: 'PROGRAMADOR DE MATERIAIS',
  },
  {
    value: 'PROGRAMADOR DE PRODUÇÃO',
    label: 'PROGRAMADOR DE PRODUÇÃO',
  },
  {
    value: 'PROGRAMADOR DE SOFTWARE',
    label: 'PROGRAMADOR DE SOFTWARE',
  },
  {
    value: 'PROGRAMADOR DELPHI',
    label: 'PROGRAMADOR DELPHI',
  },
  {
    value: 'PROGRAMADOR DYNAMICS',
    label: 'PROGRAMADOR DYNAMICS',
  },
  {
    value: 'PROGRAMADOR ETL',
    label: 'PROGRAMADOR ETL',
  },
  {
    value: 'PROGRAMADOR FLASH',
    label: 'PROGRAMADOR FLASH',
  },
  {
    value: 'PROGRAMADOR FLEX',
    label: 'PROGRAMADOR FLEX',
  },
  {
    value: 'PROGRAMADOR FORMS REPORTS',
    label: 'PROGRAMADOR FORMS REPORTS',
  },
  {
    value: 'PROGRAMADOR FRONT-END',
    label: 'PROGRAMADOR FRONT-END',
  },
  {
    value: 'PROGRAMADOR GENEXUS',
    label: 'PROGRAMADOR GENEXUS',
  },
  {
    value: 'PROGRAMADOR IOS',
    label: 'PROGRAMADOR IOS',
  },
  {
    value: 'PROGRAMADOR JAVA',
    label: 'PROGRAMADOR JAVA',
  },
  {
    value: 'PROGRAMADOR JAVASCRIPT',
    label: 'PROGRAMADOR JAVASCRIPT',
  },
  {
    value: 'PROGRAMADOR LINUX',
    label: 'PROGRAMADOR LINUX',
  },
  {
    value: 'PROGRAMADOR MICROSIGA',
    label: 'PROGRAMADOR MICROSIGA',
  },
  {
    value: 'PROGRAMADOR MOBILE',
    label: 'PROGRAMADOR MOBILE',
  },
  {
    value: 'PROGRAMADOR NODE.JS',
    label: 'PROGRAMADOR NODE.JS',
  },
  {
    value: 'PROGRAMADOR ORACLE',
    label: 'PROGRAMADOR ORACLE',
  },
  {
    value: 'PROGRAMADOR PHP',
    label: 'PROGRAMADOR PHP',
  },
  {
    value: 'PROGRAMADOR PL SQL',
    label: 'PROGRAMADOR PL SQL',
  },
  {
    value: 'PROGRAMADOR POWERBUILDER',
    label: 'PROGRAMADOR POWERBUILDER',
  },
  {
    value: 'PROGRAMADOR PROGRESS',
    label: 'PROGRAMADOR PROGRESS',
  },
  {
    value: 'PROGRAMADOR PROTHEUS',
    label: 'PROGRAMADOR PROTHEUS',
  },
  {
    value: 'PROGRAMADOR PYTHON',
    label: 'PROGRAMADOR PYTHON',
  },
  {
    value: 'PROGRAMADOR QLIKVIEW',
    label: 'PROGRAMADOR QLIKVIEW',
  },
  {
    value: 'PROGRAMADOR RUBY ON RAILS',
    label: 'PROGRAMADOR RUBY ON RAILS',
  },
  {
    value: 'PROGRAMADOR SAP',
    label: 'PROGRAMADOR SAP',
  },
  {
    value: 'PROGRAMADOR SHAREPOINT',
    label: 'PROGRAMADOR SHAREPOINT',
  },
  {
    value: 'PROGRAMADOR SOA',
    label: 'PROGRAMADOR SOA',
  },
  {
    value: 'PROGRAMADOR SQL',
    label: 'PROGRAMADOR SQL',
  },
  {
    value: 'PROGRAMADOR TRAINEE',
    label: 'PROGRAMADOR TRAINEE',
  },
  {
    value: 'PROGRAMADOR VISUAL BASIC',
    label: 'PROGRAMADOR VISUAL BASIC',
  },
  {
    value: 'PROGRAMADOR VISUAL FOXPRO',
    label: 'PROGRAMADOR VISUAL FOXPRO',
  },
  {
    value: 'PROGRAMADOR VISUAL',
    label: 'PROGRAMADOR VISUAL',
  },
  {
    value: 'PROGRAMADOR WEB',
    label: 'PROGRAMADOR WEB',
  },
  {
    value: 'PROGRAMADOR',
    label: 'PROGRAMADOR',
  },
  {
    value: 'PROGRAMADOR',
    label: 'PROGRAMADOR',
  },
  {
    value: 'PROJETISTA 3D',
    label: 'PROJETISTA 3D',
  },
  {
    value: 'PROJETISTA CADISTA',
    label: 'PROJETISTA CADISTA',
  },
  {
    value: 'PROJETISTA CIVIL',
    label: 'PROJETISTA CIVIL',
  },
  {
    value: 'PROJETISTA DE AR CONDICIONADO',
    label: 'PROJETISTA DE AR CONDICIONADO',
  },
  {
    value: 'PROJETISTA DE AUTOMAÇÃO',
    label: 'PROJETISTA DE AUTOMAÇÃO',
  },
  {
    value: 'PROJETISTA DE ESTRUTURAS METÁLICAS',
    label: 'PROJETISTA DE ESTRUTURAS METÁLICAS',
  },
  {
    value: 'PROJETISTA DE FERRAMENTAS',
    label: 'PROJETISTA DE FERRAMENTAS',
  },
  {
    value: 'PROJETISTA DE ILUMINAÇÃO',
    label: 'PROJETISTA DE ILUMINAÇÃO',
  },
  {
    value: 'PROJETISTA DE INSTALAÇÕES',
    label: 'PROJETISTA DE INSTALAÇÕES',
  },
  {
    value: 'PROJETISTA DE MOLDES',
    label: 'PROJETISTA DE MOLDES',
  },
  {
    value: 'PROJETISTA DE MÁQUINAS',
    label: 'PROJETISTA DE MÁQUINAS',
  },
  {
    value: 'PROJETISTA DE MÓVEIS',
    label: 'PROJETISTA DE MÓVEIS',
  },
  {
    value: 'PROJETISTA DE PRODUTO',
    label: 'PROJETISTA DE PRODUTO',
  },
  {
    value: 'PROJETISTA DE TELECOMUNICAÇÕES',
    label: 'PROJETISTA DE TELECOMUNICAÇÕES',
  },
  {
    value: 'PROJETISTA ELETRÔNICO',
    label: 'PROJETISTA ELETRÔNICO',
  },
  {
    value: 'PROJETISTA ELÉTRICO',
    label: 'PROJETISTA ELÉTRICO',
  },
  {
    value: 'PROJETISTA HIDRÁULICO',
    label: 'PROJETISTA HIDRÁULICO',
  },
  {
    value: 'PROJETISTA MECÂNICO',
    label: 'PROJETISTA MECÂNICO',
  },
  {
    value: 'PROJETISTA ORÇAMENTISTA',
    label: 'PROJETISTA ORÇAMENTISTA',
  },
  {
    value: 'PROJETISTA',
    label: 'PROJETISTA',
  },
  {
    value: 'PROMOTOR DE CRÉDITO',
    label: 'PROMOTOR DE CRÉDITO',
  },
  {
    value: 'PROMOTOR DE EVENTOS',
    label: 'PROMOTOR DE EVENTOS',
  },
  {
    value: 'PROMOTOR DE FINANCIAMENTO',
    label: 'PROMOTOR DE FINANCIAMENTO',
  },
  {
    value: 'PROMOTOR DE MERCHANDISING',
    label: 'PROMOTOR DE MERCHANDISING',
  },
  {
    value: 'PROMOTOR DE VENDAS',
    label: 'PROMOTOR DE VENDAS',
  },
  {
    value: 'PROMOTOR TÉCNICO',
    label: 'PROMOTOR TÉCNICO',
  },
  {
    value: 'PROPAGANDISTA',
    label: 'PROPAGANDISTA',
  },
  {
    value: 'PROTÉTICO',
    label: 'PROTÉTICO',
  },
  {
    value: 'PSICOMOTRICISTA',
    label: 'PSICOMOTRICISTA',
  },
  {
    value: 'PSICOPEDAGOGO',
    label: 'PSICOPEDAGOGO',
  },
  {
    value: 'PSICÓLOGO CLÍNICO',
    label: 'PSICÓLOGO CLÍNICO',
  },
  {
    value: 'PSICÓLOGO DO TRÂNSITO',
    label: 'PSICÓLOGO DO TRÂNSITO',
  },
  {
    value: 'PSICÓLOGO ESCOLAR',
    label: 'PSICÓLOGO ESCOLAR',
  },
  {
    value: 'PSICÓLOGO HOSPITALAR',
    label: 'PSICÓLOGO HOSPITALAR',
  },
  {
    value: 'PSICÓLOGO ORGANIZACIONAL',
    label: 'PSICÓLOGO ORGANIZACIONAL',
  },
  {
    value: 'PSICÓLOGO SOCIAL',
    label: 'PSICÓLOGO SOCIAL',
  },
  {
    value: 'PSICÓLOGO',
    label: 'PSICÓLOGO',
  },
  {
    value: 'PUBLICITÁRIO',
    label: 'PUBLICITÁRIO',
  },
  {
    value: 'QUÍMICO INDUSTRIAL',
    label: 'QUÍMICO INDUSTRIAL',
  },
  {
    value: 'QUÍMICO RESPONSÁVEL',
    label: 'QUÍMICO RESPONSÁVEL',
  },
  {
    value: 'QUÍMICO',
    label: 'QUÍMICO',
  },
  {
    value: 'RADIALISTA',
    label: 'RADIALISTA',
  },
  {
    value: 'RASTELEIRO',
    label: 'RASTELEIRO',
  },
  {
    value: 'REBARBADOR DE METAIS',
    label: 'REBARBADOR DE METAIS',
  },
  {
    value: 'REBOBINADOR',
    label: 'REBOBINADOR',
  },
  {
    value: 'RECEBEDOR',
    label: 'RECEBEDOR',
  },
  {
    value: 'RECEPCIONISTA DE HOTEL',
    label: 'RECEPCIONISTA DE HOTEL',
  },
  {
    value: 'RECEPCIONISTA HOSPITALAR',
    label: 'RECEPCIONISTA HOSPITALAR',
  },
  {
    value: 'RECEPCIONISTA',
    label: 'RECEPCIONISTA',
  },
  {
    value: 'RECREADOR',
    label: 'RECREADOR',
  },
  {
    value: 'RECUPERADOR DE CRÉDITO',
    label: 'RECUPERADOR DE CRÉDITO',
  },
  {
    value: 'REDATOR PUBLICITÁRIO',
    label: 'REDATOR PUBLICITÁRIO',
  },
  {
    value: 'REDATOR WEB',
    label: 'REDATOR WEB',
  },
  {
    value: 'REDATOR',
    label: 'REDATOR',
  },
  {
    value: 'REGULADOR DE SINISTROS',
    label: 'REGULADOR DE SINISTROS',
  },
  {
    value: 'RELAÇÕES PÚBLICAS',
    label: 'RELAÇÕES PÚBLICAS',
  },
  {
    value: 'RELOJOEIRO',
    label: 'RELOJOEIRO',
  },
  {
    value: 'REPOSITOR DE PERECÍVEIS',
    label: 'REPOSITOR DE PERECÍVEIS',
  },
  {
    value: 'REPOSITOR',
    label: 'REPOSITOR',
  },
  {
    value: 'REPRESENTANTE COMERCIAL',
    label: 'REPRESENTANTE COMERCIAL',
  },
  {
    value: 'REPÓRTER',
    label: 'REPÓRTER',
  },
  {
    value: 'RETIFICADOR',
    label: 'RETIFICADOR',
  },
  {
    value: 'REVISOR DE TECIDOS',
    label: 'REVISOR DE TECIDOS',
  },
  {
    value: 'REVISOR DE TEXTO',
    label: 'REVISOR DE TEXTO',
  },
  {
    value: 'RIGGER',
    label: 'RIGGER',
  },
  {
    value: 'ROBOTISTA',
    label: 'ROBOTISTA',
  },
  {
    value: 'ROTEIRISTA',
    label: 'ROTEIRISTA',
  },
  {
    value: 'ROTEIRIZADOR',
    label: 'ROTEIRIZADOR',
  },
  {
    value: 'SALADEIRO',
    label: 'SALADEIRO',
  },
  {
    value: 'SALGADEIRO',
    label: 'SALGADEIRO',
  },
  {
    value: 'SALVA VIDAS',
    label: 'SALVA VIDAS',
  },
  {
    value: 'SAPATEIRO',
    label: 'SAPATEIRO',
  },
  {
    value: 'SCOUTER',
    label: 'SCOUTER',
  },
  {
    value: 'SCRUM MASTER',
    label: 'SCRUM MASTER',
  },
  {
    value: 'SECRETÁRIA COMERCIAL',
    label: 'SECRETÁRIA COMERCIAL',
  },
  {
    value: 'SECRETÁRIA ESCOLAR',
    label: 'SECRETÁRIA ESCOLAR',
  },
  {
    value: 'SECRETÁRIA EXECUTIVA',
    label: 'SECRETÁRIA EXECUTIVA',
  },
  {
    value: 'SECRETÁRIA FINANCEIRA',
    label: 'SECRETÁRIA FINANCEIRA',
  },
  {
    value: 'SECRETÁRIA',
    label: 'SECRETÁRIA',
  },
  {
    value: 'SEGURANÇA PATRIMONIAL',
    label: 'SEGURANÇA PATRIMONIAL',
  },
  {
    value: 'MILITAR',
    label: 'MILITAR',
  },
  {
    value: 'SEGURANÇA',
    label: 'SEGURANÇA',
  },
  {
    value: 'SELECIONADOR',
    label: 'SELECIONADOR',
  },
  {
    value: 'SEPARADOR DE MERCADORIAS',
    label: 'SEPARADOR DE MERCADORIAS',
  },
  {
    value: 'SERRALHEIRO',
    label: 'SERRALHEIRO',
  },
  {
    value: 'SERVENTE DE OBRAS',
    label: 'SERVENTE DE OBRAS',
  },
  {
    value: 'SINALEIRO',
    label: 'SINALEIRO',
  },
  {
    value: 'SOCIÓLOGO',
    label: 'SOCIÓLOGO',
  },
  {
    value: 'SOCORRISTA',
    label: 'SOCORRISTA',
  },
  {
    value: 'SOLDADOR MONTADOR',
    label: 'SOLDADOR MONTADOR',
  },
  {
    value: 'SOLDADOR',
    label: 'SOLDADOR',
  },
  {
    value: 'SOMMELIER',
    label: 'SOMMELIER',
  },
  {
    value: 'SONDADOR',
    label: 'SONDADOR',
  },
  {
    value: 'SONOPLASTA',
    label: 'SONOPLASTA',
  },
  {
    value: 'SORVETEIRO',
    label: 'SORVETEIRO',
  },
  {
    value: 'STEWARD',
    label: 'STEWARD',
  },
  {
    value: 'SUPERINTENDENTE COMERCIAL',
    label: 'SUPERINTENDENTE COMERCIAL',
  },
  {
    value: 'SUPERINTENDENTE DE OPERAÇÕES',
    label: 'SUPERINTENDENTE DE OPERAÇÕES',
  },
  {
    value: 'SUPERVISOR ADMINISTRATIVO',
    label: 'SUPERVISOR ADMINISTRATIVO',
  },
  {
    value: 'SUPERVISOR AGRÍCOLA',
    label: 'SUPERVISOR AGRÍCOLA',
  },
  {
    value: 'SUPERVISOR COMERCIAL',
    label: 'SUPERVISOR COMERCIAL',
  },
  {
    value: 'SUPERVISOR CONTÁBIL',
    label: 'SUPERVISOR CONTÁBIL',
  },
  {
    value: 'SUPERVISOR DE ALIMENTOS E BEBIDAS',
    label: 'SUPERVISOR DE ALIMENTOS E BEBIDAS',
  },
  {
    value: 'SUPERVISOR DE ALMOXARIFADO',
    label: 'SUPERVISOR DE ALMOXARIFADO',
  },
  {
    value: 'SUPERVISOR DE ARQUIVO',
    label: 'SUPERVISOR DE ARQUIVO',
  },
  {
    value: 'SUPERVISOR DE ASSISTÊNCIA TÉCNICA',
    label: 'SUPERVISOR DE ASSISTÊNCIA TÉCNICA',
  },
  {
    value: 'SUPERVISOR DE ATENDIMENTO AO CLIENTE',
    label: 'SUPERVISOR DE ATENDIMENTO AO CLIENTE',
  },
  {
    value: 'SUPERVISOR DE AUDITORIA',
    label: 'SUPERVISOR DE AUDITORIA',
  },
  {
    value: 'SUPERVISOR DE AUTOMAÇÃO',
    label: 'SUPERVISOR DE AUTOMAÇÃO',
  },
  {
    value: 'SUPERVISOR DE BACK OFFICE',
    label: 'SUPERVISOR DE BACK OFFICE',
  },
  {
    value: 'SUPERVISOR DE CAIXA',
    label: 'SUPERVISOR DE CAIXA',
  },
  {
    value: 'SUPERVISOR DE CALDEIRARIA',
    label: 'SUPERVISOR DE CALDEIRARIA',
  },
  {
    value: 'SUPERVISOR DE CALL CENTER',
    label: 'SUPERVISOR DE CALL CENTER',
  },
  {
    value: 'SUPERVISOR DE CAMPO',
    label: 'SUPERVISOR DE CAMPO',
  },
  {
    value: 'SUPERVISOR DE COBRANÇA',
    label: 'SUPERVISOR DE COBRANÇA',
  },
  {
    value: 'SUPERVISOR DE COMPRAS',
    label: 'SUPERVISOR DE COMPRAS',
  },
  {
    value: 'SUPERVISOR DE COMÉRCIO EXTERIOR',
    label: 'SUPERVISOR DE COMÉRCIO EXTERIOR',
  },
  {
    value: 'SUPERVISOR DE CONDOMÍNIO',
    label: 'SUPERVISOR DE CONDOMÍNIO',
  },
  {
    value: 'SUPERVISOR DE CONTAS A PAGAR',
    label: 'SUPERVISOR DE CONTAS A PAGAR',
  },
  {
    value: 'SUPERVISOR DE CONTAS A RECEBER',
    label: 'SUPERVISOR DE CONTAS A RECEBER',
  },
  {
    value: 'SUPERVISOR DE CONTAS',
    label: 'SUPERVISOR DE CONTAS',
  },
  {
    value: 'SUPERVISOR DE CONTRATOS',
    label: 'SUPERVISOR DE CONTRATOS',
  },
  {
    value: 'SUPERVISOR DE CONTROLADORIA',
    label: 'SUPERVISOR DE CONTROLADORIA',
  },
  {
    value: 'SUPERVISOR DE CONTROLE DE QUALIDADE',
    label: 'SUPERVISOR DE CONTROLE DE QUALIDADE',
  },
  {
    value: 'SUPERVISOR DE COSTURA',
    label: 'SUPERVISOR DE COSTURA',
  },
  {
    value: 'SUPERVISOR DE CRÉDITO E COBRANÇA',
    label: 'SUPERVISOR DE CRÉDITO E COBRANÇA',
  },
  {
    value: 'SUPERVISOR DE CRÉDITO IMOBILIÁRIO',
    label: 'SUPERVISOR DE CRÉDITO IMOBILIÁRIO',
  },
  {
    value: 'SUPERVISOR DE CRÉDITO',
    label: 'SUPERVISOR DE CRÉDITO',
  },
  {
    value: 'SUPERVISOR DE CUSTOS',
    label: 'SUPERVISOR DE CUSTOS',
  },
  {
    value: 'SUPERVISOR DE DEPARTAMENTO PESSOAL',
    label: 'SUPERVISOR DE DEPARTAMENTO PESSOAL',
  },
  {
    value: 'SUPERVISOR DE E-COMMERCE',
    label: 'SUPERVISOR DE E-COMMERCE',
  },
  {
    value: 'SUPERVISOR DE ELÉTRICA',
    label: 'SUPERVISOR DE ELÉTRICA',
  },
  {
    value: 'SUPERVISOR DE ENGENHARIA',
    label: 'SUPERVISOR DE ENGENHARIA',
  },
  {
    value: 'SUPERVISOR DE ESTAMPARIA',
    label: 'SUPERVISOR DE ESTAMPARIA',
  },
  {
    value: 'SUPERVISOR DE EVENTOS',
    label: 'SUPERVISOR DE EVENTOS',
  },
  {
    value: 'SUPERVISOR DE EXPEDIÇÃO',
    label: 'SUPERVISOR DE EXPEDIÇÃO',
  },
  {
    value: 'SUPERVISOR DE FACILITIES',
    label: 'SUPERVISOR DE FACILITIES',
  },
  {
    value: 'SUPERVISOR DE FARMÁCIA',
    label: 'SUPERVISOR DE FARMÁCIA',
  },
  {
    value: 'SUPERVISOR DE FATURAMENTO',
    label: 'SUPERVISOR DE FATURAMENTO',
  },
  {
    value: 'SUPERVISOR DE FERRAMENTARIA',
    label: 'SUPERVISOR DE FERRAMENTARIA',
  },
  {
    value: 'SUPERVISOR DE FILIAL',
    label: 'SUPERVISOR DE FILIAL',
  },
  {
    value: 'SUPERVISOR DE FOLHA DE PAGAMENTO',
    label: 'SUPERVISOR DE FOLHA DE PAGAMENTO',
  },
  {
    value: 'SUPERVISOR DE FROTA',
    label: 'SUPERVISOR DE FROTA',
  },
  {
    value: 'SUPERVISOR DE FUNDIÇÃO',
    label: 'SUPERVISOR DE FUNDIÇÃO',
  },
  {
    value: 'SUPERVISOR DE GARANTIA DA QUALIDADE',
    label: 'SUPERVISOR DE GARANTIA DA QUALIDADE',
  },
  {
    value: 'SUPERVISOR DE HOTELARIA',
    label: 'SUPERVISOR DE HOTELARIA',
  },
  {
    value: 'SUPERVISOR DE INFRAESTRUTURA',
    label: 'SUPERVISOR DE INFRAESTRUTURA',
  },
  {
    value: 'SUPERVISOR DE INJEÇÃO PLÁSTICA',
    label: 'SUPERVISOR DE INJEÇÃO PLÁSTICA',
  },
  {
    value: 'SUPERVISOR DE INSTALAÇÃO',
    label: 'SUPERVISOR DE INSTALAÇÃO',
  },
  {
    value: 'SUPERVISOR DE INTELIGÊNCIA DE MERCADO',
    label: 'SUPERVISOR DE INTELIGÊNCIA DE MERCADO',
  },
  {
    value: 'SUPERVISOR DE LABORATÓRIO',
    label: 'SUPERVISOR DE LABORATÓRIO',
  },
  {
    value: 'SUPERVISOR DE LAVANDERIA',
    label: 'SUPERVISOR DE LAVANDERIA',
  },
  {
    value: 'SUPERVISOR DE LIMPEZA',
    label: 'SUPERVISOR DE LIMPEZA',
  },
  {
    value: 'SUPERVISOR DE LOCAÇÃO',
    label: 'SUPERVISOR DE LOCAÇÃO',
  },
  {
    value: 'SUPERVISOR DE LOGÍSTICA',
    label: 'SUPERVISOR DE LOGÍSTICA',
  },
  {
    value: 'SUPERVISOR DE LOJA',
    label: 'SUPERVISOR DE LOJA',
  },
  {
    value: 'SUPERVISOR DE MANUTENÇÃO AUTOMOTIVA',
    label: 'SUPERVISOR DE MANUTENÇÃO AUTOMOTIVA',
  },
  {
    value: 'SUPERVISOR DE MANUTENÇÃO ELETROMECÂNICA',
    label: 'SUPERVISOR DE MANUTENÇÃO ELETROMECÂNICA',
  },
  {
    value: 'SUPERVISOR DE MANUTENÇÃO ELÉTRICA',
    label: 'SUPERVISOR DE MANUTENÇÃO ELÉTRICA',
  },
  {
    value: 'SUPERVISOR DE MANUTENÇÃO INDUSTRIAL',
    label: 'SUPERVISOR DE MANUTENÇÃO INDUSTRIAL',
  },
  {
    value: 'SUPERVISOR DE MANUTENÇÃO MECÂNICA',
    label: 'SUPERVISOR DE MANUTENÇÃO MECÂNICA',
  },
  {
    value: 'SUPERVISOR DE MANUTENÇÃO PREDIAL',
    label: 'SUPERVISOR DE MANUTENÇÃO PREDIAL',
  },
  {
    value: 'SUPERVISOR DE MANUTENÇÃO',
    label: 'SUPERVISOR DE MANUTENÇÃO',
  },
  {
    value: 'SUPERVISOR DE MARKETING',
    label: 'SUPERVISOR DE MARKETING',
  },
  {
    value: 'SUPERVISOR DE MEIO AMBIENTE',
    label: 'SUPERVISOR DE MEIO AMBIENTE',
  },
  {
    value: 'SUPERVISOR DE MERCHANDISING',
    label: 'SUPERVISOR DE MERCHANDISING',
  },
  {
    value: 'SUPERVISOR DE MINERAÇÃO',
    label: 'SUPERVISOR DE MINERAÇÃO',
  },
  {
    value: 'SUPERVISOR DE MONITORAMENTO',
    label: 'SUPERVISOR DE MONITORAMENTO',
  },
  {
    value: 'SUPERVISOR DE MONITORIA',
    label: 'SUPERVISOR DE MONITORIA',
  },
  {
    value: 'SUPERVISOR DE MONTAGEM',
    label: 'SUPERVISOR DE MONTAGEM',
  },
  {
    value: 'SUPERVISOR DE NEGÓCIOS',
    label: 'SUPERVISOR DE NEGÓCIOS',
  },
  {
    value: 'SUPERVISOR DE NUTRIÇÃO',
    label: 'SUPERVISOR DE NUTRIÇÃO',
  },
  {
    value: 'SUPERVISOR DE OBRAS',
    label: 'SUPERVISOR DE OBRAS',
  },
  {
    value: 'SUPERVISOR DE OPERAÇÕES',
    label: 'SUPERVISOR DE OPERAÇÕES',
  },
  {
    value: 'SUPERVISOR DE OUVIDORIA',
    label: 'SUPERVISOR DE OUVIDORIA',
  },
  {
    value: 'SUPERVISOR DE PATRIMÔNIO',
    label: 'SUPERVISOR DE PATRIMÔNIO',
  },
  {
    value: 'SUPERVISOR DE PCP',
    label: 'SUPERVISOR DE PCP',
  },
  {
    value: 'SUPERVISOR DE PEÇAS',
    label: 'SUPERVISOR DE PEÇAS',
  },
  {
    value: 'SUPERVISOR DE PINTURA',
    label: 'SUPERVISOR DE PINTURA',
  },
  {
    value: 'SUPERVISOR DE PLANEJAMENTO',
    label: 'SUPERVISOR DE PLANEJAMENTO',
  },
  {
    value: 'SUPERVISOR DE PREVENÇÃO DE PERDAS',
    label: 'SUPERVISOR DE PREVENÇÃO DE PERDAS',
  },
  {
    value: 'SUPERVISOR DE PROCESSOS',
    label: 'SUPERVISOR DE PROCESSOS',
  },
  {
    value: 'SUPERVISOR DE PRODUTOS',
    label: 'SUPERVISOR DE PRODUTOS',
  },
  {
    value: 'SUPERVISOR DE PRODUÇÃO',
    label: 'SUPERVISOR DE PRODUÇÃO',
  },
  {
    value: 'SUPERVISOR DE PROJETO SOCIAL',
    label: 'SUPERVISOR DE PROJETO SOCIAL',
  },
  {
    value: 'SUPERVISOR DE PROJETOS',
    label: 'SUPERVISOR DE PROJETOS',
  },
  {
    value: 'SUPERVISOR DE PÓS-VENDA',
    label: 'SUPERVISOR DE PÓS-VENDA',
  },
  {
    value: 'SUPERVISOR DE QUALIDADE',
    label: 'SUPERVISOR DE QUALIDADE',
  },
  {
    value: 'SUPERVISOR DE RADIOLOGIA',
    label: 'SUPERVISOR DE RADIOLOGIA',
  },
  {
    value: 'SUPERVISOR DE RECEPÇÃO',
    label: 'SUPERVISOR DE RECEPÇÃO',
  },
  {
    value: 'SUPERVISOR DE RECRUTAMENTO E SELEÇÃO',
    label: 'SUPERVISOR DE RECRUTAMENTO E SELEÇÃO',
  },
  {
    value: 'SUPERVISOR DE RECURSOS HUMANOS',
    label: 'SUPERVISOR DE RECURSOS HUMANOS',
  },
  {
    value: 'SUPERVISOR DE REFRIGERAÇÃO',
    label: 'SUPERVISOR DE REFRIGERAÇÃO',
  },
  {
    value: 'SUPERVISOR DE RELACIONAMENTO',
    label: 'SUPERVISOR DE RELACIONAMENTO',
  },
  {
    value: 'SUPERVISOR DE RESERVAS',
    label: 'SUPERVISOR DE RESERVAS',
  },
  {
    value: 'SUPERVISOR DE RESTAURANTE',
    label: 'SUPERVISOR DE RESTAURANTE',
  },
  {
    value: 'SUPERVISOR DE SAC',
    label: 'SUPERVISOR DE SAC',
  },
  {
    value: 'SUPERVISOR DE SEGURANÇA DO TRABALHO',
    label: 'SUPERVISOR DE SEGURANÇA DO TRABALHO',
  },
  {
    value: 'SUPERVISOR DE SEGURANÇA PATRIMONIAL',
    label: 'SUPERVISOR DE SEGURANÇA PATRIMONIAL',
  },
  {
    value: 'SUPERVISOR DE SEGUROS',
    label: 'SUPERVISOR DE SEGUROS',
  },
  {
    value: 'SUPERVISOR DE SERVIÇOS GERAIS',
    label: 'SUPERVISOR DE SERVIÇOS GERAIS',
  },
  {
    value: 'SUPERVISOR DE SUPRIMENTOS',
    label: 'SUPERVISOR DE SUPRIMENTOS',
  },
  {
    value: 'SUPERVISOR DE TECNOLOGIA DA INFORMAÇÃO',
    label: 'SUPERVISOR DE TECNOLOGIA DA INFORMAÇÃO',
  },
  {
    value: 'SUPERVISOR DE TESOURARIA',
    label: 'SUPERVISOR DE TESOURARIA',
  },
  {
    value: 'SUPERVISOR DE TRADE MARKETING',
    label: 'SUPERVISOR DE TRADE MARKETING',
  },
  {
    value: 'SUPERVISOR DE TRANSPORTES',
    label: 'SUPERVISOR DE TRANSPORTES',
  },
  {
    value: 'SUPERVISOR DE TREINAMENTO E DESENVOLVIMENTO',
    label: 'SUPERVISOR DE TREINAMENTO E DESENVOLVIMENTO',
  },
  {
    value: 'SUPERVISOR DE USINAGEM',
    label: 'SUPERVISOR DE USINAGEM',
  },
  {
    value: 'SUPERVISOR DE VENDAS',
    label: 'SUPERVISOR DE VENDAS',
  },
  {
    value: 'SUPERVISOR FINANCEIRO',
    label: 'SUPERVISOR FINANCEIRO',
  },
  {
    value: 'SUPERVISOR FLORESTAL',
    label: 'SUPERVISOR FLORESTAL',
  },
  {
    value: 'SUPERVISOR INDUSTRIAL',
    label: 'SUPERVISOR INDUSTRIAL',
  },
  {
    value: 'SUPERVISOR PEDAGÓGICO',
    label: 'SUPERVISOR PEDAGÓGICO',
  },
  {
    value: 'SUPERVISOR TRIBUTÁRIO',
    label: 'SUPERVISOR TRIBUTÁRIO',
  },
  {
    value: 'SUPERVISOR TÉCNICO',
    label: 'SUPERVISOR TÉCNICO',
  },
  {
    value: 'SUPORTE TÉCNICO',
    label: 'SUPORTE TÉCNICO',
  },
  {
    value: 'SUSHIMAN',
    label: 'SUSHIMAN',
  },
  {
    value: 'SUPERVISOR',
    label: 'SUPERVISOR',
  },
  {
    value: 'SÍNDICO',
    label: 'SÍNDICO',
  },
  {
    value: 'TAPECEIRO DE VEÍCULOS',
    label: 'TAPECEIRO DE VEÍCULOS',
  },
  {
    value: 'TAPECEIRO',
    label: 'TAPECEIRO',
  },
  {
    value: 'TECELÃO',
    label: 'TECELÃO',
  },
  {
    value: 'TECNÓLOGO EM ALIMENTOS',
    label: 'TECNÓLOGO EM ALIMENTOS',
  },
  {
    value: 'TECNÓLOGO EM CONSTRUÇÃO CIVIL',
    label: 'TECNÓLOGO EM CONSTRUÇÃO CIVIL',
  },
  {
    value: 'TECNÓLOGO EM MECÂNICA',
    label: 'TECNÓLOGO EM MECÂNICA',
  },
  {
    value: 'TECNÓLOGO EM RADIOLOGIA',
    label: 'TECNÓLOGO EM RADIOLOGIA',
  },
  {
    value: 'TECNÓLOGO EM REDES DE COMPUTADORES',
    label: 'TECNÓLOGO EM REDES DE COMPUTADORES',
  },
  {
    value: 'TECNÓLOGO EM SANEAMENTO AMBIENTAL',
    label: 'TECNÓLOGO EM SANEAMENTO AMBIENTAL',
  },
  {
    value: 'TELEFONISTA RECEPCIONISTA',
    label: 'TELEFONISTA RECEPCIONISTA',
  },
  {
    value: 'TELEFONISTA',
    label: 'TELEFONISTA',
  },
  {
    value: 'TELHADISTA',
    label: 'TELHADISTA',
  },
  {
    value: 'TERAPEUTA OCUPACIONAL',
    label: 'TERAPEUTA OCUPACIONAL',
  },
  {
    value: 'TESOUREIRO',
    label: 'TESOUREIRO',
  },
  {
    value: 'TINTUREIRO',
    label: 'TINTUREIRO',
  },
  {
    value: 'TOPÓGRAFO',
    label: 'TOPÓGRAFO',
  },
  {
    value: 'TORNEIRO FERRAMENTEIRO',
    label: 'TORNEIRO FERRAMENTEIRO',
  },
  {
    value: 'TORNEIRO MECÂNICO',
    label: 'TORNEIRO MECÂNICO',
  },
  {
    value: 'TOSADOR',
    label: 'TOSADOR',
  },
  {
    value: 'TRADER',
    label: 'TRADER',
  },
  {
    value: 'TRADUTOR DE ALEMÃO',
    label: 'TRADUTOR DE ALEMÃO',
  },
  {
    value: 'TRADUTOR DE CHINÊS',
    label: 'TRADUTOR DE CHINÊS',
  },
  {
    value: 'TRADUTOR DE COREANO',
    label: 'TRADUTOR DE COREANO',
  },
  {
    value: 'TRADUTOR DE ESPANHOL',
    label: 'TRADUTOR DE ESPANHOL',
  },
  {
    value: 'TRADUTOR DE FRANCÊS',
    label: 'TRADUTOR DE FRANCÊS',
  },
  {
    value: 'TRADUTOR DE INGLÊS',
    label: 'TRADUTOR DE INGLÊS',
  },
  {
    value: 'TRADUTOR DE ITALIANO',
    label: 'TRADUTOR DE ITALIANO',
  },
  {
    value: 'TRADUTOR DE JAPONÊS',
    label: 'TRADUTOR DE JAPONÊS',
  },
  {
    value: 'TRADUTOR DE MANDARIM',
    label: 'TRADUTOR DE MANDARIM',
  },
  {
    value: 'TRADUTOR DE ÁRABE',
    label: 'TRADUTOR DE ÁRABE',
  },
  {
    value: 'TRADUTOR',
    label: 'TRADUTOR',
  },
  {
    value: 'TRAINEE EM ADMINISTRAÇÃO DE EMPRESAS',
    label: 'TRAINEE EM ADMINISTRAÇÃO DE EMPRESAS',
  },
  {
    value: 'TRAINEE EM AGRONOMIA',
    label: 'TRAINEE EM AGRONOMIA',
  },
  {
    value: 'TRAINEE EM ARQUITETURA E URBANISMO',
    label: 'TRAINEE EM ARQUITETURA E URBANISMO',
  },
  {
    value: 'TRAINEE EM CIÊNCIAS CONTÁBEIS',
    label: 'TRAINEE EM CIÊNCIAS CONTÁBEIS',
  },
  {
    value: 'TRAINEE EM DIREITO',
    label: 'TRAINEE EM DIREITO',
  },
  {
    value: 'TRAINEE EM ECONOMIA',
    label: 'TRAINEE EM ECONOMIA',
  },
  {
    value: 'TRAINEE EM ENGENHARIA CIVIL',
    label: 'TRAINEE EM ENGENHARIA CIVIL',
  },
  {
    value: 'TRAINEE EM ENGENHARIA DE PRODUÇÃO',
    label: 'TRAINEE EM ENGENHARIA DE PRODUÇÃO',
  },
  {
    value: 'TRAINEE EM ENGENHARIA ELÉTRICA',
    label: 'TRAINEE EM ENGENHARIA ELÉTRICA',
  },
  {
    value: 'TRAINEE EM ENGENHARIA MECÂNICA',
    label: 'TRAINEE EM ENGENHARIA MECÂNICA',
  },
  {
    value: 'TRAINEE EM ENGENHARIA QUÍMICA',
    label: 'TRAINEE EM ENGENHARIA QUÍMICA',
  },
  {
    value: 'TRAINEE EM ENGENHARIA',
    label: 'TRAINEE EM ENGENHARIA',
  },
  {
    value: 'TRAINEE EM MARKETING',
    label: 'TRAINEE EM MARKETING',
  },
  {
    value: 'TRAINEE EM NUTRIÇÃO',
    label: 'TRAINEE EM NUTRIÇÃO',
  },
  {
    value: 'TRAINEE EM RECURSOS HUMANOS',
    label: 'TRAINEE EM RECURSOS HUMANOS',
  },
  {
    value: 'TRAINEE EM SUPORTE TÉCNICO',
    label: 'TRAINEE EM SUPORTE TÉCNICO',
  },
  {
    value: 'TRAINEE EM VENDAS',
    label: 'TRAINEE EM VENDAS',
  },
  {
    value: 'TRAINEE NA ÁREA COMERCIAL',
    label: 'TRAINEE NA ÁREA COMERCIAL',
  },
  {
    value: 'TRAINEE NA ÁREA DE COMPRAS',
    label: 'TRAINEE NA ÁREA DE COMPRAS',
  },
  {
    value: 'TRAINEE NA ÁREA DE DEPARTAMENTO PESSOAL',
    label: 'TRAINEE NA ÁREA DE DEPARTAMENTO PESSOAL',
  },
  {
    value: 'TRAINEE NA ÁREA DE TECNOLOGIA DA INFORMAÇÃO',
    label: 'TRAINEE NA ÁREA DE TECNOLOGIA DA INFORMAÇÃO',
  },
  {
    value: 'TRAINEE NA ÁREA FINANCEIRA',
    label: 'TRAINEE NA ÁREA FINANCEIRA',
  },
  {
    value: 'TRAINEE NA ÁREA FISCAL',
    label: 'TRAINEE NA ÁREA FISCAL',
  },
  {
    value: 'TRAINEE NA ÁREA INDUSTRIAL',
    label: 'TRAINEE NA ÁREA INDUSTRIAL',
  },
  {
    value: 'TRATADOR DE PISCINA',
    label: 'TRATADOR DE PISCINA',
  },
  {
    value: 'TRATORISTA',
    label: 'TRATORISTA',
  },
  {
    value: 'TRAÇADOR DE CALDEIRARIA',
    label: 'TRAÇADOR DE CALDEIRARIA',
  },
  {
    value: 'TREFILADOR',
    label: 'TREFILADOR',
  },
  {
    value: 'TROCADOR DE MOLDES',
    label: 'TROCADOR DE MOLDES',
  },
  {
    value: 'TURISMÓLOGO',
    label: 'TURISMÓLOGO',
  },
  {
    value: 'TÉCNICO ADMINISTRATIVO',
    label: 'TÉCNICO ADMINISTRATIVO',
  },
  {
    value: 'TÉCNICO AGRÍCOLA',
    label: 'TÉCNICO AGRÍCOLA',
  },
  {
    value: 'TÉCNICO BANCÁRIO',
    label: 'TÉCNICO BANCÁRIO',
  },
  {
    value: 'TÉCNICO COMERCIAL',
    label: 'TÉCNICO COMERCIAL',
  },
  {
    value: 'TÉCNICO CONTÁBIL',
    label: 'TÉCNICO CONTÁBIL',
  },
  {
    value: 'TÉCNICO DA QUALIDADE',
    label: 'TÉCNICO DA QUALIDADE',
  },
  {
    value: 'TÉCNICO DE ARQUIVO',
    label: 'TÉCNICO DE ARQUIVO',
  },
  {
    value: 'TÉCNICO DE ASSISTÊNCIA TÉCNICA',
    label: 'TÉCNICO DE ASSISTÊNCIA TÉCNICA',
  },
  {
    value: 'TÉCNICO DE ATENDIMENTO',
    label: 'TÉCNICO DE ATENDIMENTO',
  },
  {
    value: 'TÉCNICO DE CAMPO',
    label: 'TÉCNICO DE CAMPO',
  },
  {
    value: 'TÉCNICO DE CELULAR',
    label: 'TÉCNICO DE CELULAR',
  },
  {
    value: 'TÉCNICO DE CONTROLE DE QUALIDADE',
    label: 'TÉCNICO DE CONTROLE DE QUALIDADE',
  },
  {
    value: 'TÉCNICO DE EQUIPAMENTOS MÉDICOS',
    label: 'TÉCNICO DE EQUIPAMENTOS MÉDICOS',
  },
  {
    value: 'TÉCNICO DE EQUIPAMENTOS',
    label: 'TÉCNICO DE EQUIPAMENTOS',
  },
  {
    value: 'TÉCNICO DE EVENTOS',
    label: 'TÉCNICO DE EVENTOS',
  },
  {
    value: 'TÉCNICO DE GASOTERAPIA',
    label: 'TÉCNICO DE GASOTERAPIA',
  },
  {
    value: 'TÉCNICO DE GESSO',
    label: 'TÉCNICO DE GESSO',
  },
  {
    value: 'TÉCNICO DE HELP DESK',
    label: 'TÉCNICO DE HELP DESK',
  },
  {
    value: 'TÉCNICO DE ILUMINAÇÃO',
    label: 'TÉCNICO DE ILUMINAÇÃO',
  },
  {
    value: 'TÉCNICO DE IMPLANTAÇÃO',
    label: 'TÉCNICO DE IMPLANTAÇÃO',
  },
  {
    value: 'TÉCNICO DE IMPRESSORA',
    label: 'TÉCNICO DE IMPRESSORA',
  },
  {
    value: 'TÉCNICO DE INFRAESTRUTURA',
    label: 'TÉCNICO DE INFRAESTRUTURA',
  },
  {
    value: 'TÉCNICO DE INSTALAÇÃO',
    label: 'TÉCNICO DE INSTALAÇÃO',
  },
  {
    value: 'TÉCNICO DE LABORATÓRIO INDUSTRIAL',
    label: 'TÉCNICO DE LABORATÓRIO INDUSTRIAL',
  },
  {
    value: 'TÉCNICO DE LABORATÓRIO',
    label: 'TÉCNICO DE LABORATÓRIO',
  },
  {
    value: 'TÉCNICO DE MANUTENÇÃO DE COMPUTADORES',
    label: 'TÉCNICO DE MANUTENÇÃO DE COMPUTADORES',
  },
  {
    value: 'TÉCNICO DE MANUTENÇÃO ELÉTRICA',
    label: 'TÉCNICO DE MANUTENÇÃO ELÉTRICA',
  },
  {
    value: 'TÉCNICO DE MANUTENÇÃO INDUSTRIAL',
    label: 'TÉCNICO DE MANUTENÇÃO INDUSTRIAL',
  },
  {
    value: 'TÉCNICO DE MANUTENÇÃO PREDIAL',
    label: 'TÉCNICO DE MANUTENÇÃO PREDIAL',
  },
  {
    value: 'TÉCNICO DE MATERIAIS',
    label: 'TÉCNICO DE MATERIAIS',
  },
  {
    value: 'TÉCNICO DE MONITORAMENTO',
    label: 'TÉCNICO DE MONITORAMENTO',
  },
  {
    value: 'TÉCNICO DE MONTAGEM',
    label: 'TÉCNICO DE MONTAGEM',
  },
  {
    value: 'TÉCNICO DE PLANEJAMENTO DE MANUTENÇÃO',
    label: 'TÉCNICO DE PLANEJAMENTO DE MANUTENÇÃO',
  },
  {
    value: 'TÉCNICO DE PLANEJAMENTO',
    label: 'TÉCNICO DE PLANEJAMENTO',
  },
  {
    value: 'TÉCNICO DE PROCESSOS',
    label: 'TÉCNICO DE PROCESSOS',
  },
  {
    value: 'TÉCNICO DE PRODUTO',
    label: 'TÉCNICO DE PRODUTO',
  },
  {
    value: 'TÉCNICO DE PRODUÇÃO',
    label: 'TÉCNICO DE PRODUÇÃO',
  },
  {
    value: 'TÉCNICO DE REDES',
    label: 'TÉCNICO DE REDES',
  },
  {
    value: 'TÉCNICO DE SERVICE DESK',
    label: 'TÉCNICO DE SERVICE DESK',
  },
  {
    value: 'TÉCNICO DE SINISTRO',
    label: 'TÉCNICO DE SINISTRO',
  },
  {
    value: 'TÉCNICO DE SOM',
    label: 'TÉCNICO DE SOM',
  },
  {
    value: 'TÉCNICO DE SUPORTE',
    label: 'TÉCNICO DE SUPORTE',
  },
  {
    value: 'TÉCNICO DE TACÓGRAFO',
    label: 'TÉCNICO DE TACÓGRAFO',
  },
  {
    value: 'TÉCNICO DE TESTES',
    label: 'TÉCNICO DE TESTES',
  },
  {
    value: 'TÉCNICO DE VENDAS',
    label: 'TÉCNICO DE VENDAS',
  },
  {
    value: 'TÉCNICO ELETRICISTA',
    label: 'TÉCNICO ELETRICISTA',
  },
  {
    value: 'TÉCNICO ELETROELETRÔNICO',
    label: 'TÉCNICO ELETROELETRÔNICO',
  },
  {
    value: 'TÉCNICO ELETROMECÂNICO',
    label: 'TÉCNICO ELETROMECÂNICO',
  },
  {
    value: 'TÉCNICO EM AGRIMENSURA',
    label: 'TÉCNICO EM AGRIMENSURA',
  },
  {
    value: 'TÉCNICO EM AGROPECUÁRIA',
    label: 'TÉCNICO EM AGROPECUÁRIA',
  },
  {
    value: 'TÉCNICO EM ALIMENTOS',
    label: 'TÉCNICO EM ALIMENTOS',
  },
  {
    value: 'TÉCNICO EM ANÁLISES CLÍNICAS',
    label: 'TÉCNICO EM ANÁLISES CLÍNICAS',
  },
  {
    value: 'TÉCNICO EM AUTOMAÇÃO BANCÁRIA',
    label: 'TÉCNICO EM AUTOMAÇÃO BANCÁRIA',
  },
  {
    value: 'TÉCNICO EM AUTOMAÇÃO INDUSTRIAL',
    label: 'TÉCNICO EM AUTOMAÇÃO INDUSTRIAL',
  },
  {
    value: 'TÉCNICO EM AUTOMAÇÃO PREDIAL',
    label: 'TÉCNICO EM AUTOMAÇÃO PREDIAL',
  },
  {
    value: 'TÉCNICO EM BIBLIOTECONOMIA',
    label: 'TÉCNICO EM BIBLIOTECONOMIA',
  },
  {
    value: 'TÉCNICO EM CABEAMENTO',
    label: 'TÉCNICO EM CABEAMENTO',
  },
  {
    value: 'TÉCNICO EM CFTV',
    label: 'TÉCNICO EM CFTV',
  },
  {
    value: 'TÉCNICO EM DOCUMENTAÇÃO',
    label: 'TÉCNICO EM DOCUMENTAÇÃO',
  },
  {
    value: 'TÉCNICO EM EDIFICAÇÕES',
    label: 'TÉCNICO EM EDIFICAÇÕES',
  },
  {
    value: 'TÉCNICO EM ELETROCARDIOGRAMA',
    label: 'TÉCNICO EM ELETROCARDIOGRAMA',
  },
  {
    value: 'TÉCNICO EM ELETROENCEFALOGRAMA',
    label: 'TÉCNICO EM ELETROENCEFALOGRAMA',
  },
  {
    value: 'TÉCNICO EM ELETROTÉCNICA',
    label: 'TÉCNICO EM ELETROTÉCNICA',
  },
  {
    value: 'TÉCNICO EM ELETRÔNICA',
    label: 'TÉCNICO EM ELETRÔNICA',
  },
  {
    value: 'TÉCNICO EM ENFERMAGEM DO TRABALHO',
    label: 'TÉCNICO EM ENFERMAGEM DO TRABALHO',
  },
  {
    value: 'TÉCNICO EM ENFERMAGEM',
    label: 'TÉCNICO EM ENFERMAGEM',
  },
  {
    value: 'TÉCNICO EM ESPIROMETRIA',
    label: 'TÉCNICO EM ESPIROMETRIA',
  },
  {
    value: 'TÉCNICO EM ESQUADRIAS DE ALUMÍNIO',
    label: 'TÉCNICO EM ESQUADRIAS DE ALUMÍNIO',
  },
  {
    value: 'TÉCNICO EM ESTRADAS',
    label: 'TÉCNICO EM ESTRADAS',
  },
  {
    value: 'TÉCNICO EM FARMÁCIA',
    label: 'TÉCNICO EM FARMÁCIA',
  },
  {
    value: 'TÉCNICO EM FIBRAS ÓPTICAS',
    label: 'TÉCNICO EM FIBRAS ÓPTICAS',
  },
  {
    value: 'TÉCNICO EM GASTRONOMIA',
    label: 'TÉCNICO EM GASTRONOMIA',
  },
  {
    value: 'TÉCNICO EM GEOPROCESSAMENTO',
    label: 'TÉCNICO EM GEOPROCESSAMENTO',
  },
  {
    value: 'TÉCNICO EM HEMOTERAPIA',
    label: 'TÉCNICO EM HEMOTERAPIA',
  },
  {
    value: 'TÉCNICO EM IMOBILIZAÇÃO ORTOPÉDICA',
    label: 'TÉCNICO EM IMOBILIZAÇÃO ORTOPÉDICA',
  },
  {
    value: 'TÉCNICO EM INFORMÁTICA',
    label: 'TÉCNICO EM INFORMÁTICA',
  },
  {
    value: 'TÉCNICO EM INSPEÇÃO DE EQUIPAMENTOS',
    label: 'TÉCNICO EM INSPEÇÃO DE EQUIPAMENTOS',
  },
  {
    value: 'TÉCNICO EM INSTRUMENTAÇÃO',
    label: 'TÉCNICO EM INSTRUMENTAÇÃO',
  },
  {
    value: 'TÉCNICO EM LICITAÇÃO',
    label: 'TÉCNICO EM LICITAÇÃO',
  },
  {
    value: 'TÉCNICO EM LOGÍSTICA',
    label: 'TÉCNICO EM LOGÍSTICA',
  },
  {
    value: 'TÉCNICO EM LUBRIFICAÇÃO',
    label: 'TÉCNICO EM LUBRIFICAÇÃO',
  },
  {
    value: 'TÉCNICO EM MANUTENÇÃO AUTOMOTIVA',
    label: 'TÉCNICO EM MANUTENÇÃO AUTOMOTIVA',
  },
  {
    value: 'TÉCNICO EM MANUTENÇÃO DE ELEVADORES',
    label: 'TÉCNICO EM MANUTENÇÃO DE ELEVADORES',
  },
  {
    value: 'TÉCNICO EM MANUTENÇÃO',
    label: 'TÉCNICO EM MANUTENÇÃO',
  },
  {
    value: 'TÉCNICO EM MECATRÔNICA',
    label: 'TÉCNICO EM MECATRÔNICA',
  },
  {
    value: 'TÉCNICO EM MECÂNICA',
    label: 'TÉCNICO EM MECÂNICA',
  },
  {
    value: 'TÉCNICO EM MEDIÇÃO',
    label: 'TÉCNICO EM MEDIÇÃO',
  },
  {
    value: 'TÉCNICO EM MEIO AMBIENTE',
    label: 'TÉCNICO EM MEIO AMBIENTE',
  },
  {
    value: 'TÉCNICO EM METALURGIA',
    label: 'TÉCNICO EM METALURGIA',
  },
  {
    value: 'TÉCNICO EM METROLOGIA',
    label: 'TÉCNICO EM METROLOGIA',
  },
  {
    value: 'TÉCNICO EM MINERAÇÃO',
    label: 'TÉCNICO EM MINERAÇÃO',
  },
  {
    value: 'TÉCNICO EM NUTRIÇÃO',
    label: 'TÉCNICO EM NUTRIÇÃO',
  },
  {
    value: 'TÉCNICO EM OPTOMETRIA',
    label: 'TÉCNICO EM OPTOMETRIA',
  },
  {
    value: 'TÉCNICO EM ORIENTAÇÃO E MOBILIDADE',
    label: 'TÉCNICO EM ORIENTAÇÃO E MOBILIDADE',
  },
  {
    value: 'TÉCNICO EM PANIFICAÇÃO',
    label: 'TÉCNICO EM PANIFICAÇÃO',
  },
  {
    value: 'TÉCNICO EM PLÁSTICOS',
    label: 'TÉCNICO EM PLÁSTICOS',
  },
  {
    value: 'TÉCNICO EM POLISSONOGRAFIA',
    label: 'TÉCNICO EM POLISSONOGRAFIA',
  },
  {
    value: 'TÉCNICO EM PROCESSOS INDUSTRIAIS',
    label: 'TÉCNICO EM PROCESSOS INDUSTRIAIS',
  },
  {
    value: 'TÉCNICO EM PROJETOS',
    label: 'TÉCNICO EM PROJETOS',
  },
  {
    value: 'TÉCNICO EM QUÍMICA',
    label: 'TÉCNICO EM QUÍMICA',
  },
  {
    value: 'TÉCNICO EM RADIOLOGIA',
    label: 'TÉCNICO EM RADIOLOGIA',
  },
  {
    value: 'TÉCNICO EM RECURSOS HUMANOS',
    label: 'TÉCNICO EM RECURSOS HUMANOS',
  },
  {
    value: 'TÉCNICO EM REFRIGERAÇÃO',
    label: 'TÉCNICO EM REFRIGERAÇÃO',
  },
  {
    value: 'TÉCNICO EM SANEAMENTO',
    label: 'TÉCNICO EM SANEAMENTO',
  },
  {
    value: 'TÉCNICO EM SAÚDE BUCAL',
    label: 'TÉCNICO EM SAÚDE BUCAL',
  },
  {
    value: 'TÉCNICO EM SECRETARIADO',
    label: 'TÉCNICO EM SECRETARIADO',
  },
  {
    value: 'TÉCNICO EM SEGURANÇA DO TRABALHO',
    label: 'TÉCNICO EM SEGURANÇA DO TRABALHO',
  },
  {
    value: 'TÉCNICO EM SEGURANÇA E MEIO AMBIENTE',
    label: 'TÉCNICO EM SEGURANÇA E MEIO AMBIENTE',
  },
  {
    value: 'TÉCNICO EM SEGURANÇA ELETRÔNICA',
    label: 'TÉCNICO EM SEGURANÇA ELETRÔNICA',
  },
  {
    value: 'TÉCNICO EM SEGUROS',
    label: 'TÉCNICO EM SEGUROS',
  },
  {
    value: 'TÉCNICO EM TECNOLOGIA DA INFORMAÇÃO',
    label: 'TÉCNICO EM TECNOLOGIA DA INFORMAÇÃO',
  },
  {
    value: 'TÉCNICO EM TELECOMUNICAÇÕES',
    label: 'TÉCNICO EM TELECOMUNICAÇÕES',
  },
  {
    value: 'TÉCNICO EM TURISMO',
    label: 'TÉCNICO EM TURISMO',
  },
  {
    value: 'TÉCNICO EM ÁUDIO E VÍDEO',
    label: 'TÉCNICO EM ÁUDIO E VÍDEO',
  },
  {
    value: 'TÉCNICO EM ÓPTICA',
    label: 'TÉCNICO EM ÓPTICA',
  },
  {
    value: 'TÉCNICO FINANCEIRO',
    label: 'TÉCNICO FINANCEIRO',
  },
  {
    value: 'TÉCNICO FLORESTAL',
    label: 'TÉCNICO FLORESTAL',
  },
  {
    value: 'TÉCNICO HIDRÁULICO',
    label: 'TÉCNICO HIDRÁULICO',
  },
  {
    value: 'TÉCNICO INDUSTRIAL',
    label: 'TÉCNICO INDUSTRIAL',
  },
  {
    value: 'TÉCNICO MECÂNICO DE MANUTENÇÃO',
    label: 'TÉCNICO MECÂNICO DE MANUTENÇÃO',
  },
  {
    value: 'TÉCNICO OPERACIONAL',
    label: 'TÉCNICO OPERACIONAL',
  },
  {
    value: 'TÉCNICO ORÇAMENTISTA',
    label: 'TÉCNICO ORÇAMENTISTA',
  },
  {
    value: 'TÉCNICO PROJETISTA',
    label: 'TÉCNICO PROJETISTA',
  },
  {
    value: 'TÉCNICO TÊXTIL',
    label: 'TÉCNICO TÊXTIL',
  },
  {
    value: 'VENDEDOR DE CONSÓRCIO',
    label: 'VENDEDOR DE CONSÓRCIO',
  },
  {
    value: 'VENDEDOR DE E-COMMERCE',
    label: 'VENDEDOR DE E-COMMERCE',
  },
  {
    value: 'VENDEDOR DE FRANQUIAS',
    label: 'VENDEDOR DE FRANQUIAS',
  },
  {
    value: 'VENDEDOR DE FRETE',
    label: 'VENDEDOR DE FRETE',
  },
  {
    value: 'VENDEDOR DE INFORMÁTICA',
    label: 'VENDEDOR DE INFORMÁTICA',
  },
  {
    value: 'VENDEDOR DE LOJA',
    label: 'VENDEDOR DE LOJA',
  },
  {
    value: 'VENDEDOR DE MÁQUINAS',
    label: 'VENDEDOR DE MÁQUINAS',
  },
  {
    value: 'VENDEDOR DE SEGUROS',
    label: 'VENDEDOR DE SEGUROS',
  },
  {
    value: 'VENDEDOR DE TELECOMUNICAÇÕES',
    label: 'VENDEDOR DE TELECOMUNICAÇÕES',
  },
  {
    value: 'VENDEDOR DE VEÍCULOS',
    label: 'VENDEDOR DE VEÍCULOS',
  },
  {
    value: 'VENDEDOR EXTERNO',
    label: 'VENDEDOR EXTERNO',
  },
  {
    value: 'VENDEDOR INDUSTRIAL',
    label: 'VENDEDOR INDUSTRIAL',
  },
  {
    value: 'VENDEDOR PROJETISTA',
    label: 'VENDEDOR PROJETISTA',
  },
  {
    value: 'VENDEDOR TÉCNICO',
    label: 'VENDEDOR TÉCNICO',
  },
  {
    value: 'VENDEDOR',
    label: 'VENDEDOR',
  },
  {
    value: 'VENDENDOR',
    label: 'VENDENDOR',
  },
  {
    value: 'VETERINÁRIO PATOLOGISTA',
    label: 'VETERINÁRIO PATOLOGISTA',
  },
  {
    value: 'VETERINÁRIO',
    label: 'VETERINÁRIO',
  },
  {
    value: 'VIDEOGRAFISTA',
    label: 'VIDEOGRAFISTA',
  },
  {
    value: 'VIDRACEIRO',
    label: 'VIDRACEIRO',
  },
  {
    value: 'VIGIA',
    label: 'VIGIA',
  },
  {
    value: 'VIGILANTE DE ESCOLTA',
    label: 'VIGILANTE DE ESCOLTA',
  },
  {
    value: 'VIGILANTE LÍDER',
    label: 'VIGILANTE LÍDER',
  },
  {
    value: 'VIGILANTE',
    label: 'VIGILANTE',
  },
  {
    value: 'VISITADOR DE NAVIOS',
    label: 'VISITADOR DE NAVIOS',
  },
  {
    value: 'VISTORIADOR DE IMÓVEIS',
    label: 'VISTORIADOR DE IMÓVEIS',
  },
  {
    value: 'VISTORIADOR DE VEÍCULOS',
    label: 'VISTORIADOR DE VEÍCULOS',
  },
  {
    value: 'VISUAL MERCHANDISER',
    label: 'VISUAL MERCHANDISER',
  },
  {
    value: 'VITRINISTA',
    label: 'VITRINISTA',
  },
  {
    value: 'WEB DESIGNER TRAINEE',
    label: 'WEB DESIGNER TRAINEE',
  },
  {
    value: 'WEB DESIGNER',
    label: 'WEB DESIGNER',
  },
  {
    value: 'WEB DEVELOPER',
    label: 'WEB DEVELOPER',
  },
  {
    value: 'WEBDESIGN',
    label: 'WEBDESIGN',
  },
  {
    value: 'WEBMASTER',
    label: 'WEBMASTER',
  },
  {
    value: 'ZELADOR',
    label: 'ZELADOR',
  },
  {
    value: 'ZOOTECNISTA',
    label: 'ZOOTECNISTA',
  },
];

export default profissoes;
