/* eslint-disable consistent-return */
/* eslint-disable no-return-assign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import api from '../../../services/api';

// createSlice makes all action creators and reducers in the same file so no separation of logic is necessary

/** *************STATE SLICE************** */
export const sliceMedico = createSlice({
  name: 'medico',
  initialState: {
    doctor: {},
    doctors: [],
    selectMedicos: [],
    medicos: [],
  },
  reducers: {
    medicoSuccess: (state, action) => {
      const {
        medico,
        medicos,
        selectMedicos,
        newMedico,
        listMedicos,
      } = action.payload;

      state.doctor = medico ?? state.doctor; // Atualiza se medico não for undefined
      state.doctors = medicos ?? state.doctors;
      state.selectMedicos = selectMedicos ?? state.selectMedicos;
      state.medicos = listMedicos ?? state.medicos;
      if (newMedico) {
        state.selectMedicos = [...(state.selectMedicos || []), newMedico]; // Adiciona novo médico se existente
      }
    },
    medicoRequest: state => {
      state.doctor = {};
      state.doctors = [];
      state.medicos = [];
      state.selectMedicos = [];
    },
    medicoReset: state => {
      state.doctor = {};
      state.doctors = [];
      state.medicos = [];
      state.selectMedicos = [];
    },
    medicoFailure: state => {
      state.doctor = {};
      state.doctors = [];
      state.medicos = [];
      state.selectMedicos = [];
    },
  },
});

/** *************EXPORTED ACTIONS & REDUCERS************** */

export const {
  medicoRequest,
  medicoSuccess,
  medicoFailure,
  medicoReset,
} = sliceMedico.actions;

export default sliceMedico.reducer;

// API REQUEST ACTIONS HANDLED WITH REDUX-THUNK MIDDLEWARE BUILT INTO REDUX TOOLKIT -->

/** *************THUNKS************** */

export const selectMedicoById = payload => {
  return async dispatch => {
    try {
      dispatch(medicoRequest());
      // /medico
      const result = await api.get(`medico?id=${payload.CODMEDICO}`);
      const { medico } = result.data;
      dispatch(medicoSuccess({ medico }));
      return medico;
    } catch (error) {
      toast.error(error.response.data.error);
      dispatch(medicoFailure());
    }
  };
};

export const selectAllMedicos = () => {
  return async dispatch => {
    try {
      dispatch(medicoRequest());
      const result = await api.get(`medicos`);
      const { medicos } = result.data;
      dispatch(medicoSuccess({ medicos }));
      if (medicos.length > 0) {
        const selectMedicos = medicos.map(m => ({
          value: m.CODMEDICO,
          label: m.NOMEMED,
        }));
        dispatch(medicoSuccess({ selectMedicos }));
      }
      return medicos;
    } catch (error) {
      toast.error('Erro ao buscar médicos!');
      dispatch(medicoFailure());
    }
  };
};

// /medicos/nome
export const findMedicoByName = payload => async dispatch => {
  try {
    dispatch(medicoReset());
    let c = 0;
    const response = await api.get('/medicos/nome', {
      params: { nome: payload?.nome.trim() },
    });

    if (response.data.length > 0) {
      const listMedicos = response.data.map(med => ({
        ...med,
        counter: (c += 1),
      }));
      dispatch(medicoSuccess({ listMedicos }));
      return listMedicos;
    }

    toast.warn('Nenhum Médico Localizado!');
  } catch (error) {
    toast.error(`${error.response.data.error}`);
    dispatch(medicoReset());
  }
};

// /medico
export const updateMedico = payload => async dispatch => {
  try {
    const response = await api.put('/medico', payload);
    toast.success('Médico atualizado com sucesso!');
    const medico = response.data[0];
    dispatch(medicoSuccess({ medico }));
  } catch (error) {
    toast.error(`${error.response.data.error}`);
  }
};
