import React from 'react';
import { useSelector } from 'react-redux';
import { Form, Button, Card } from 'react-bootstrap';
import espholter from '../../../assets/esp-holter.png';

export default function EspelhoHolter() {
  const { holter } = useSelector(state => state.holter);

  function prefixo() {
    if (
      holter[0]?.CODREALIZOU === 1 ||
      holter[0]?.CODREALIZOU === 5 ||
      holter[0]?.CODREALIZOU === 2796 ||
      holter[0]?.CODREALIZOU === 958
    ) {
      return 'Prof. Dr. ';
    }

    return 'Dr. ';
  }

  return (
    <Card style={{ fontSize: 24 }}>
      <Card.Header className="text-center">
        <Card.Title>LAUDO DE HOLTER</Card.Title>
      </Card.Header>
      <Card.Body>
        <Form noValidate>
          <strong className="mr-auto">Cod Holter: </strong>
          {holter[0]?.CODHOLTER}&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Nome do Paciente: </strong>
          {`${holter[0]?.CODPAC} - ${holter[0]?.NOMEPAC}`}
          <br></br>
          <strong className="mr-auto">Data de Nasc: </strong>
          {`${holter[0]?.dataNasc}`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Sexo: </strong>
          {`${holter[0]?.SEXOPAC}`}
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Idade: </strong>
          {`${holter[0]?.idadePAC} anos`}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Alt: </strong>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Peso: </strong>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">SC: </strong>
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <br></br>
          <strong className="mr-auto">Convênio: </strong>
          {holter[0]?.DESCRICONV}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <strong className="mr-auto">Data do Exame: </strong>
          {holter[0]?.dataHolter}
          <br></br>
          <strong className="mr-auto">
            Médico que Solicitou: {prefixo()}{' '}
          </strong>
          {`${holter[0]?.Solicitou}`}
          <hr></hr>
          <div>
            <img
              width="95%"
              height="60%"
              loading="lazy"
              src={espholter}
              alt="espelho holter"
            />{' '}
          </div>
          <div id="removeMe" className="text-right">
            <Button variant="outline-primary" onClick={() => window.print()}>
              Imprimir
            </Button>{' '}
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
}
