import React from 'react';

const CustomFileInput = ({ id, label, onChange, multiple }) => (
  <div style={{ display: 'flex', justifyContent: 'center' }}>
    <input
      id={id}
      type="file"
      style={{ display: 'none' }}
      multiple={multiple}
      onChange={onChange}
    />
    <label
      htmlFor={id}
      style={{
        color: 'white',
        cursor: 'pointer',
        backgroundColor: '#7F7F7F',
        padding: '5px 10px',
        borderRadius: '5px',
      }}
      onMouseEnter={e => (e.target.style.backgroundColor = '#5F5F5F')}
      onMouseLeave={e => (e.target.style.backgroundColor = '#7F7F7F')}
    >
      {label}
    </label>
  </div>
);

export default CustomFileInput;
