import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from 'react-bootstrap';
import { tokenizer } from '../../redux/features/certificate/certificateSlice';
import history from '../../services/history';

export default function Tokenizer() {
  const dispatch = useDispatch();
  const profile = useSelector(state => state.doctor.profile);
  const funcionario = useSelector(state => state.funcionario.funcionario);
  const { token } = useSelector(state => state.certificate);

  useEffect(() => {
    const searchParams = new URLSearchParams(history.location.search);
    const pCode = searchParams.get('code');
    const payload = { code: pCode };

    dispatch(tokenizer(payload));
  }, []);

  return (
    <Card className="d-none d-lg-block">
      <Card.Header>
        <h3>Certificação Digital</h3>
      </Card.Header>
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p>
            {' '}
            Olá{' '}
            <strong>
              {profile ? `${profile?.apelido}` : funcionario?.nome}
            </strong>
            ,{' '}
          </p>
          <p>
            {' '}
            Sua autenticação com <strong>Certificado Digital</strong> foi
            realizada com sucesso.{' '}
          </p>
          <p>CPF: {token?.authorized_identification}</p>
          <p>
            {' '}
            Por favor, prossiga com suas operações de maneira segura e
            confiável. Caso tenha alguma dúvida ou precise de assistência, não
            hesite em contatar nossa equipe de suporte.{' '}
          </p>
          <p>
            {' '}
            Agradecemos por utilizar nossos serviços com Segurança e Confiança.{' '}
          </p>
          <p> Atenciosamente, </p>
          <footer className="blockquote-footer">
            Instituto do Coração de Marília{' '}
            <cite title="Source Title"> - ICM</cite>
          </footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}
