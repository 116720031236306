import React from 'react';
import { useSelector } from 'react-redux';
import { Modal, Spinner } from 'react-bootstrap';

export default function Loading() {
  const { loading } = useSelector(state => state.contexto);

  return (
    <Modal size="sm" centered show={loading}>
      <Modal.Body className="text-center">
        <Spinner animation="border" variant="primary" />
        <Spinner animation="border" variant="secondary" />
        <Spinner animation="border" variant="success" />
        <Spinner animation="border" variant="danger" />
        <Spinner animation="border" variant="warning" />
        <Spinner animation="border" variant="info" />
      </Modal.Body>
    </Modal>
  );
}
