/* eslint-disable no-console */
import { createSlice } from '@reduxjs/toolkit';
import api from '../../../services/api';

// createSlice makes all action creators and reducers in the same file so no separation of logic is necessary

/** *************STATE SLICE************** */
export const sliceCertificate = createSlice({
  name: 'certificate',
  initialState: {
    certificate: {},
    token: {},
    signature: {},
  },
  reducers: {
    certificateSuccess: (state, action) => {
      const { certificate, token, signature } = action.payload;
      if (certificate !== undefined) {
        state.certificate = certificate;
      }
      if (token !== undefined) {
        state.token = token;
      }
      if (signature !== undefined) {
        state.signature = signature;
      }
    },
    certificateRequest: state => {
      state.certificate = {};
      state.token = {};
      state.signature = {};
    },
    certificateReset: state => {
      state.certificate = {};
      state.token = {};
      state.signature = {};
    },
    certificateFailure: state => {
      state.certificate = {};
      state.token = {};
      state.signature = {};
    },
  },
});

/** *************EXPORTED ACTIONS & REDUCERS************** */

export const {
  certificateRequest,
  certificateSuccess,
  certificateFailure,
  certificateReset,
} = sliceCertificate.actions;

export default sliceCertificate.reducer;

// API REQUEST ACTIONS HANDLED WITH REDUX-THUNK MIDDLEWARE BUILT INTO REDUX TOOLKIT -->

/** *************THUNKS************** */

/**
 * 26ago2023
 * {
    "name": "ICM-Instituto do Coração de Marília",
    "comments": "AWS ICM-Instituto do Coração de Marília",
    "redirect_uris": ["http://icm-marilia.com/"],
    "email": "jonmoraesNL@gmail.com "
}
 * {{
    "status": "success",
    "message": "New Client Application registered with Sucess",
    "client_id": "ab46d834-4f16-4b89-b1a9-f231737513ad",
    "client_secret": "6pnPHxafY6iBfmfC"
}} payload
 *
 */

export const tokenizer = payload => {
  return async dispatch => {
    const client_id = 'ab46d834-4f16-4b89-b1a9-f231737513ad';
    const client_secret = '6pnPHxafY6iBfmfC';
    const grant_type = 'authorization_code';
    const messages = {
      grant_type,
      client_id,
      client_secret,
      code: payload.code,
      code_verifier: 'dBjftJeZ4CVP-mB92K27uhbUJU1p1r_wW1gFWFOEjXk',
    };

    api
      .post('/client-token', messages)
      .then(response => response.data)
      .then(token => dispatch(certificateSuccess({ token })))
      .catch(error => {
        console.error(error.response.data);
        dispatch(certificateFailure());
      });
  };
};

export const digitalSignature = payload => {
  return async (dispatch, getState) => {
    try {
      const content = {
        authorization: `Bearer ${getState().certificate.token.access_token}`,
        hashes: {
          hashes: [
            {
              id: payload.id,
              alias: payload.alias,
              hash: payload.hash,
              hash_algorithm: payload.hash_algorithm,
              signature_format: payload.signature_format,
              base64_content: payload.base64_content,
            },
          ],
        },
      };

      const result = await api.post('/signature', content);
      const signature = result.data;
      dispatch(certificateSuccess({ signature }));
      return result.data;
    } catch (error) {
      dispatch(certificateFailure());
    }
  };
};
