import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import {
  Form,
  Col,
  Button,
  Card,
  Row,
  Image,
  InputGroup,
  Alert,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import api from '../../../services/api';
import history from '../../../services/history';
import header from '../../../assets/icm-header.jpg';
import styles from '../../../styles/styles.module.css';
import blank from '../../../assets/icm-blank.jpg';
import estenose from '../../../assets/estenose-carotida.png';

export default function ImagemCarotida() {
  const location = useLocation();
  const { carotida } = location.state;
  const medico = useSelector(state => state.doctor.profile);
  const initialValue = {
    direita: {
      arteriaVertebral: { velSistolica: '', velDiastolica: '' },
      arteriaCarotidaExterna: { velSistolica: '', velDiastolica: '' },
      arteriaCarotidaInterna: { velSistolica: '', velDiastolica: '' },
      arteriaCarotidaComum: { velSistolica: '', velDiastolica: '' },
    },
    esquerda: {
      arteriaVertebral: { velSistolica: '', velDiastolica: '' },
      arteriaCarotidaExterna: { velSistolica: '', velDiastolica: '' },
      arteriaCarotidaInterna: { velSistolica: '', velDiastolica: '' },
      arteriaCarotidaComum: { velSistolica: '', velDiastolica: '' },
    },
  };
  const defaultArteria = JSON.parse(carotida.carotida[0].IMAGEM);
  const [isPrinting, setIsPrinting] = useState(false);
  const [showPrintAlert, setShowPrintAlert] = useState(false);
  const [arteriaData, setArteriaData] = useState(
    defaultArteria ? structuredClone(defaultArteria) : initialValue
  );

  useEffect(() => {
    if (medico?.tipo === 'Externo') history.push('/notAllowed');
  }, [medico]);

  const handleGravarImagem = async () => {
    try {
      const { data } = await api.put(
        `/carotida/${carotida.carotida[0].CODSCAN}`,
        {
          arteriaData,
        }
      );
      toast.success(data.message);
      return data;
    } catch (error) {
      return error.response.data.error;
    }
  };

  const handleInputChange = (e, lado, artName, fieldName) => {
    const { value } = e.target;
    setArteriaData(prevData => ({
      ...prevData,
      [lado]: {
        ...prevData[lado],
        [artName]: {
          ...prevData[lado][artName],
          [fieldName]: value,
        },
      },
    }));
  };

  const renderCards = lado => (
    <Col sm={3} className="m-0 p-0">
      <Card style={{ marginBottom: 2, textAlign: 'center' }} bg="danger">
        <h3 style={{ color: 'white' }}>{lado.toUpperCase()}</h3>
      </Card>
      {Object.keys(arteriaData[lado]).map((arteria, index) => (
        <Card
          key={index}
          style={{ marginBottom: 2, textAlign: 'center' }}
          bg="light"
        >
          <Card.Body>
            <Card.Title style={{ color: 'green' }}>
              {arteria
                .replace(/([A-Z])/g, ' $1')
                .trim()
                .toUpperCase()}
            </Card.Title>
            <div>
              <Form.Group>
                <Form.Label>Vel Sistólica:</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Vel sistólica"
                    value={arteriaData[lado][arteria].velSistolica}
                    onChange={e =>
                      handleInputChange(e, lado, arteria, 'velSistolica')
                    }
                  />
                  <InputGroup.Text>cm/seg</InputGroup.Text>
                </InputGroup>
              </Form.Group>
              <Form.Group>
                <Form.Label>Vel Diastólica:</Form.Label>
                <InputGroup>
                  <Form.Control
                    type="text"
                    placeholder="Vel diastólica"
                    value={arteriaData[lado][arteria].velDiastolica}
                    onChange={e =>
                      handleInputChange(e, lado, arteria, 'velDiastolica')
                    }
                  />
                  <InputGroup.Text>cm/seg</InputGroup.Text>
                </InputGroup>
              </Form.Group>
            </div>
            <Card.Text style={{ color: 'red' }}>Fluxo laminar</Card.Text>
          </Card.Body>
        </Card>
      ))}
    </Col>
  );

  return (
    <div>
      {isPrinting ? (
        <div className={styles.imageReport}>
          <img width="600" height="110" src={header} alt="header" />
        </div>
      ) : (
        <div className={styles.imageReport}>
          <img width="600" height="110" src={blank} alt="header" />
        </div>
      )}
      <Card.Header className="mt-3 mb-3 p-2 d-flex justify-content-center align-items-center">
        <Card.Title>
          NOME: {carotida.carotida[0].NOMEPAC} - IDADE:{' '}
          {carotida.carotida[0].idadePAC} anos - DATA:{' '}
          {carotida.carotida[0].dataScanFmt}
        </Card.Title>
      </Card.Header>
      <Row className="m-0 p-0">
        {renderCards('direita')}

        <Col
          sm={6}
          className="mt-5 p-1 justify-content-center align-items-center"
        >
          <Image
            className="m-0 p-0"
            fluid
            rounded
            src="https://drluizfernandoneuro.b-cdn.net/wp-content/uploads/2022/10/anatomia-carotida.jpg"
          />

          <Row>
            <Col
              sm={12}
              className="m-0 p-3 justify-content-center align-items-center text-center"
            >
              <Image className="m-0 p-0" fluid rounded src={estenose} />
            </Col>
          </Row>
          <Row>
            <Col
              sm={12}
              className="m-0 p-3 justify-content-center align-items-center text-center"
            >
              <Form.Label>Razão VPS CI/VPS CC</Form.Label>
              <Form.Group>
                <Form.Label>{`Direita <------------> Esquerda`}</Form.Label>
                <InputGroup>
                  <Form.Control type="text" placeholder="Direita" />
                  <Form.Control type="text" placeholder="Esquerda" />
                </InputGroup>
              </Form.Group>
            </Col>
          </Row>
        </Col>

        {renderCards('esquerda')}
      </Row>
      <style>
        {`
          @media print {
            #removeMe {
              display: none;
            }
          }
        `}
      </style>
      <Card.Footer id="removeMe" className="m-0 p-2 d-grid gap-2 text-center">
        <Alert show={showPrintAlert} variant="success">
          <Alert.Heading>
            Confirma a impressão da imagem de corótida?
          </Alert.Heading>
          <hr />
          <div className="m-0 p-2 d-grid gap-2 text-center">
            <Button
              onClick={() => {
                window.print();
                setShowPrintAlert(false);
              }}
              variant="success"
            >
              Confirma impressão?
            </Button>
          </div>
        </Alert>
        <Button
          variant="success"
          onClick={e => {
            handleGravarImagem(e);
          }}
        >
          Gravar
        </Button>{' '}
        <Button
          variant="secondary"
          onClick={e => {
            e.preventDefault();
            setIsPrinting(false);
            setShowPrintAlert(true);
          }}
        >
          Imprimir
        </Button>{' '}
        <Button
          variant="secondary"
          onClick={e => {
            e.preventDefault();
            setIsPrinting(true);
            setShowPrintAlert(true);
          }}
        >
          Imprimir Timbrado
        </Button>{' '}
        <Button variant="danger" onClick={() => history.push('/agenda')}>
          Agenda
        </Button>
      </Card.Footer>
    </div>
  );
}
