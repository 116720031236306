import { toast } from 'react-toastify';
import api from './api';

const updateRequisicao = async payload => {
  try {
    const response = await api.put(
      `/requisicao/${payload.codRequisicao}`,
      payload
    );
    return response;
  } catch (error) {
    toast.error(error.response.data.error);
  }
};

const updateAtestado = async payload => {
  try {
    const response = await api.put(`/atestado/${payload.codAtestado}`, payload);
    return response;
  } catch (error) {
    toast.error(error.response.data.error);
  }
};

const updateReceita = async payload => {
  try {
    const response = await api.put(`/receita/${payload.codReceita}`, payload);
    return response;
  } catch (error) {
    toast.error(error.response.data.error);
  }
};

export { updateRequisicao, updateAtestado, updateReceita };
