import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container } from 'react-bootstrap';
import { selectAllTipoExame } from '../redux/features/prontuario/laboratorioSlice';
import { selectAllConvenios } from '../redux/features/context/convenioSlice';
import { selectAllProcedimentos } from '../redux/features/context/procedimentoSlice';
import { selectAllAgendaHorario } from '../redux/features/prontuario/agendaSlice';
import { selectAllDetalhes } from '../redux/features/context/faturamentoSlice';
import { selectAllMedicos } from '../redux/features/prontuario/medicoSlice';
import NavBar from '../pages/doctor/Index/NavBar';

export default function MedicoLayout({ children }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(selectAllTipoExame());
    dispatch(selectAllConvenios());
    dispatch(selectAllProcedimentos());
    dispatch(selectAllDetalhes());
    dispatch(selectAllAgendaHorario());
    dispatch(selectAllMedicos());
  }, [dispatch]);

  return (
    <Container fluid>
      <NavBar />
      {children}
    </Container>
  );
}
