import * as React from 'react';
import { styled } from '@mui/material/styles';
import ButtonBase from '@mui/material/ButtonBase';
import history from '../../../services/history';

const images = [
  { url: '../images/buttons/agenda.jpg', title: 'Novo Agendamento' },
  { url: '/images/buttons/paciente.jpg', title: 'Meus Agendamentos' },
  { url: '/images/buttons/laudos.jpg', title: 'Resultado de Exames' },
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  height: 200,
  [theme.breakpoints.down('sm')]: { width: '100% !important', height: 100 },
  '&:hover, &.Mui-focusVisible': {
    '& .MuiImageBackdrop-root': { opacity: 0.3 },
    '& .MuiImageMarked-root': { opacity: 0 },
    '& .MuiTypography-root': { border: '6px solid currentColor' },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
});

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create('opacity'),
}));

export default function Dashboard() {
  function handlePaciente(title) {
    if (title === 'Novo Agendamento') history.push('/panelpac');
    if (title === 'Resultado de Exames') history.push('/frmatvpac');
    if (title === 'Meus Agendamentos') history.push('/agendapac');
  }

  return (
    <div style={{ marginTop: 10 }}>
      {images.map(image => (
        <ImageButton
          onClick={() => handlePaciente(image.title)}
          key={image.title}
          style={{ width: '33%' }}
        >
          <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <span style={{ position: 'absolute', color: 'white' }}>
            {image.title}
          </span>
        </ImageButton>
      ))}
    </div>
  );
}
