import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Button, Card } from 'react-bootstrap';
import { signOutMed } from '../store/modules/auth/doctor/actions';
import { signOutPac } from '../store/modules/auth/patient/actions';
import { signOutFunc } from '../store/modules/auth/funcionario/actions';

const NotAllowed = () => {
  const medico = useSelector(state => state.doctor.profile);
  const paciente = useSelector(state => state.patient.profile);
  const { funcionario } = useSelector(state => state.funcionario);
  const dispatch = useDispatch();

  function signOut() {
    if (paciente) dispatch(signOutPac());
    if (medico) dispatch(signOutMed());
    if (funcionario) dispatch(signOutFunc());
  }

  return (
    <div className="text-center">
      <br />
      <Card>
        <Row className="justify-content-md-center">
          <Col md="auto">
            <h1>Acesso não permitido!</h1>
            <p>Você não tem acesso a esta página</p>
            <Button onClick={signOut} variant="primary">
              Voltar
            </Button>
          </Col>
        </Row>
        <br />
      </Card>
    </div>
  );
};

export default NotAllowed;
