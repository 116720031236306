import React from 'react';
import { Card } from 'react-bootstrap';

export default function Home() {
  return (
    <Card style={{ marginTop: 10 }}>
      <Card.Header>
        <Card.Title>Dashboard</Card.Title>
      </Card.Header>
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p>
            As doenças do coração são, na maioria das vezes, assintomáticas ou
            seja, não avisam. Assim é importante a prevenção, principalmente nas
            pessoas que tem familiares que já apresentaram problemas
            cardiológicos.
          </p>
        </blockquote>
      </Card.Body>
      <Card.Footer>Instituto do Coração de Marília{' - '}ICM</Card.Footer>
    </Card>
  );
}
