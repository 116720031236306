import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

export default reducers => {
  const persistedReducer = persistReducer(
    {
      key: 'icm-localDB',
      storage,
      whitelist: [
        'certificate',
        'patient',
        'funcionario',
        'doctor',
        'paciente',
        'medico',
        'prontuario',
        'agenda',
        'eco',
        'trans',
        'stress',
        'ergo',
        'scan',
        'fetal',
        'ecg',
        'mapa',
        'holter',
        'convenio',
        'procedimento',
        'faturamento',
        'laboratorio',
        'avaliacao',
      ],
    },
    reducers
  );

  return persistedReducer;
};
