import React, { useState, useRef, useEffect } from 'react';
import { Card, Form, Button, Table, Row, Col } from 'react-bootstrap';
import InputMask from 'react-bootstrap-maskedinput';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import validarCPF from 'validar-cpf';
import {
  loadingOpen,
  loadingClose,
} from '../../../redux/features/context/contextSlice';
import api from '../../../services/api';
import {
  updateMedico,
  findMedicoByName,
  medicoSuccess,
} from '../../../redux/features/prontuario/medicoSlice';
import Loading from '../../Index/Loading';

export default function FormMedico() {
  const dispatch = useDispatch();
  const { funcionario } = useSelector(state => state.funcionario);
  const { medicos } = useSelector(state => state.medico);
  const medico = useSelector(state => state.doctor.profile);
  const { loading } = useSelector(state => state.contexto);
  const [codigo, setCodigo] = useState('');
  const [nome, setNome] = useState('');
  const [email, setEmail] = useState('');
  const [cidade, setCidade] = useState('');
  const [estado, setEstado] = useState('');
  const [cep, setCEP] = useState('');
  const [cpfusuario, setCPFUsuario] = useState('111.111.111-11');
  const [rqe, setRQE] = useState('');
  const [especialidade, setEspecialidade] = useState('');
  const [endresid, setEndResid] = useState('');
  const [bairro, setBairro] = useState('');
  const [fonecomerc, setFoneComerc] = useState('');
  const [obs, setObs] = useState();
  const [ramal, setRamal] = useState('');
  const [celular, setCelular] = useState('');
  const [crm, setCRM] = useState('');
  const [apelido, setApelido] = useState('');
  const [tipoMedico, setTipoMedico] = useState('');
  const [dtAdmissao, setDtAdmissao] = useState('');
  const [search, setSearch] = useState(false);
  const [searchMedicos, setSearchMedicos] = useState(false);
  const [shouldDisable, setShouldDisable] = useState({
    updateBtn: false,
    insertBtn: false,
  });
  const [nomeMed, setNomeMed] = useState('');
  const nomeMedInput = useRef(null);

  useEffect(() => {
    nomeMedInput.current.focus();
  }, []);

  function preencheCep() {
    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then(response => response.json())
      .then(data => {
        setEndResid(data.logradouro);
        setBairro(data.bairro);
        setCidade(data.localidade);
        setEstado(data.uf);
      })
      .catch(() => toast.error('Erro ao buscar CEP!'));
  }

  const handleMedicos = async () => {
    dispatch(loadingOpen());
    setSearchMedicos(false);
    if (nomeMed !== '') {
      await dispatch(findMedicoByName({ nome: nomeMed }));
      setSearch(false);
      setSearchMedicos(true);
    } else {
      toast.warn('Preencha o Nome do Paciente!');
      nomeMedInput.current.focus();
    }
    dispatch(loadingClose());
  };

  const handleMedico = medico => {
    dispatch(loadingOpen());
    setShouldDisable({ updateBtn: false, insertBtn: true });
    setCodigo(medico.CODMEDICO);
    setNome(medico.NOMEMED);
    setEmail(medico.EMAILMED);
    setCidade(medico.CIDADEMED);
    setEstado(medico.ESTADOMED);
    setCEP(medico.CEPMED);
    setCPFUsuario(medico.CPFMED);
    setRQE(medico.RQE);
    setEspecialidade(medico.ESPECIMED);
    setEndResid(medico.ENDMED);
    setBairro(medico.BAIRROMED);
    setFoneComerc(medico.FONECOMMED);
    setObs(medico.OBSMED);
    setRamal(medico.RAMALMED);
    setCelular(medico.CELULARMED);
    setCRM(medico.CRMMED);
    setApelido(medico.CODINOME);
    setDtAdmissao(medico.DATAMED);
    setTipoMedico(medico.TIPOMED);
    setSearch(false);
    setSearchMedicos(false);
    dispatch(medicoSuccess({ medico }));
    setSearch(true);
    dispatch(loadingClose());
  };

  const handleNovoMedico = () => {
    dispatch(loadingOpen());
    setShouldDisable({ updateBtn: true, insertBtn: false });
    setCodigo('');
    setNome('');
    setEmail('');
    setCidade('');
    setEstado('');
    setCEP('');
    setCPFUsuario('');
    setRQE('');
    setEspecialidade('');
    setEndResid('');
    setBairro('');
    setFoneComerc('');
    setObs('');
    setRamal('');
    setCelular('');
    setCRM('');
    setApelido('');
    setDtAdmissao(new Date());
    setTipoMedico('');
    setSearch(false);
    setSearchMedicos(false);
    dispatch(medicoSuccess({ medico: {} }));
    setSearch(true);
    dispatch(loadingClose());
  };

  async function handleInserirMedico(e) {
    e.preventDefault();

    if (!e.currentTarget.checkValidity()) {
      e.stopPropagation();
    } else {
      const m = {
        nome,
        cpfusuario,
        codFunc: medico ? medico?.idFunc : funcionario?.codFunc,
        rqe,
        especialidade,
        cep,
        endresid,
        crm,
        bairro,
        cidade,
        estado,
        fonecomerc,
        celular,
        email,
        obs,
        apelido,
        dtAdmissao,
        tipoMedico,
      };

      try {
        await api.post('/medicos', m);
        toast.success('Médico Cadastrado com Sucesso!');
      } catch (error) {
        toast.error(error.response.data.error);
      }
    }
  }

  const handleAtualizarMedico = e => {
    e.preventDefault();
    const m = {
      codigo,
      nome,
      cpfusuario,
      codFunc: medico ? medico?.idFunc : funcionario?.codFunc,
      rqe,
      especialidade,
      cep,
      endresid,
      crm,
      bairro,
      cidade,
      estado,
      fonecomerc,
      celular,
      email,
      obs,
      apelido,
      dtAdmissao,
      tipoMedico,
    };
    dispatch(updateMedico(m));
  };

  const handleValidarNomeMed = async e => {
    e.preventDefault();
    e.persist();
    if (e.currentTarget.value.length < 5) {
      toast.warn('Nome do Médico deve conter mais do 5 caracteres!');
      document.getElementById('formNome').select();
      return false; // Retorna falso se o nome for menor que 5 letras
    }
    for (let i = 0; i < e.currentTarget.value.length; i++) {
      if (/[0-9]/.test(e.currentTarget.value[i])) {
        toast.warn('Nome do Médico NÃO pode conter números!');
        document.getElementById('formNome').select();
        return false;
      }
    }
    dispatch(loadingOpen());
    dispatch(findMedicoByName({ nome })).then(res => {
      if (res?.length > 0) {
        toast.warn('Médico "PODE ESTAR" cadastrado no Sistema com este nome!');
      }
      dispatch(loadingClose());
    });
    e.stopPropagation();
  };

  const handleValidarCPF = e => {
    e.preventDefault();
    if (!medico?.cpfusuario === '111.111.111-11')
      if (!validarCPF(cpfusuario)) {
        toast.info('Número do CPF é inválido!');
        setCPFUsuario('');
      }
  };

  const handleOnEnterPress = (e, next) => {
    e.preventDefault();
    if (e.keyCode === 13) document.getElementById(next).select();
  };

  const checkEnter = e => {
    if (e.key === 'Enter') handleMedicos();
  };

  return (
    <>
      <Card style={{ marginTop: 10 }}>
        <Card.Header>
          <h5 className="mb-0 text-success">LOCALIZAR MÉDICO</h5>
        </Card.Header>
        <Card.Body>
          <div style={{ display: 'flex', marginTop: 10 }}>
            <Form.Control
              style={{
                textTransform: 'uppercase',
                marginRight: 10,
              }}
              value={nomeMed}
              required
              ref={nomeMedInput}
              type="text"
              placeholder="Nome do Médico"
              onChange={e => setNomeMed(e.target.value)}
              onKeyUp={e => checkEnter(e)}
            />
            <Button onClick={handleMedicos} style={{ marginRight: 5 }}>
              Localizar
            </Button>
            <Button variant="success" onClick={handleNovoMedico}>
              Novo
            </Button>
          </div>
        </Card.Body>
      </Card>

      {searchMedicos && (
        <Table
          responsive="sm"
          striped
          style={{ marginTop: 10 }}
          bordered="true"
          hover="true"
          size="sm"
          color="success"
        >
          <thead>
            <tr>
              <th>#</th>
              <th>Código</th>
              <th>Nome</th>
              <th>Celular</th>
              <th>Nr Conselho</th>
              <th>CPF</th>
              <th>#</th>
            </tr>
          </thead>
          <tbody>
            {medicos.map(med => (
              <tr key={med.counter}>
                <td>{med.counter}</td>
                <td>{med.CODMEDICO}</td>
                <td>{med.NOMEMED.toUpperCase()}</td>
                <td>{med.CELULARMED}</td>
                <td>{med.CRMMED}</td>
                <td>{med.CPFMED}</td>
                <td>
                  <Button size="sm" onClick={() => handleMedico(med)}>
                    Editar
                  </Button>{' '}
                </td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td style={{ textAlign: 'right' }} colSpan="6">
                TOTAL:
              </td>
              <td style={{ textAlign: 'left' }} colSpan="1">
                {medicos.length}
              </td>
            </tr>
          </tfoot>
        </Table>
      )}

      {search && (
        <Card style={{ marginTop: 10 }}>
          <Card.Header className="text-center h4">Novo Médico</Card.Header>
          <Card.Body>
            <Form>
              <Row>
                <Col sm={2}>
                  <Form.Group controlId="formCodigo">
                    <Form.Label>Código</Form.Label>
                    <Form.Control
                      type="text"
                      readOnly
                      disabled
                      defaultValue={codigo || ''}
                    />
                  </Form.Group>
                </Col>
                <Col sm={6}>
                  <Form.Group controlId="formNome">
                    <Form.Label>Nome</Form.Label>
                    <Form.Control
                      type="text"
                      style={{
                        borderColor: 'green',
                        backgroundColor: 'rgba(255, 255, 0, 0.3)',
                      }}
                      placeholder="Digite o nome do médico"
                      required
                      value={nome || ''}
                      onChange={event => setNome(event.target.value)}
                      onBlur={e => handleValidarNomeMed(e)}
                      onKeyUp={e => handleOnEnterPress(e, 'formCodinome')}
                    />
                  </Form.Group>
                </Col>
                <Col sm={2}>
                  <Form.Group controlId="formSexo">
                    <Form.Label>Tipo</Form.Label>
                    <Col>
                      <Form.Check
                        className="my-0 mr-1"
                        style={{
                          borderColor: 'green',
                          backgroundColor: 'rgba(255, 255, 0, 0.3)',
                        }}
                        inline
                        label="Agenda"
                        type="radio"
                        id="radioAgenda"
                        checked={tipoMedico === 'Agenda'}
                        name="TIPOMED"
                        onChange={() => setTipoMedico('Agenda')}
                      />
                      <Form.Check
                        inline
                        style={{
                          borderColor: 'green',
                          backgroundColor: 'rgba(255, 255, 0, 0.3)',
                        }}
                        label="Externo"
                        type="radio"
                        id="radioExterno"
                        checked={tipoMedico === 'Externo'}
                        name="TIPOMED"
                        onChange={() => setTipoMedico('Externo')}
                      />
                    </Col>
                  </Form.Group>
                </Col>
                <Col sm={2}>
                  <Form.Group controlId="formDtAdmissao">
                    <Form.Label>Dt Admissão</Form.Label>
                    <Form.Control
                      type="text"
                      readOnly
                      disabled
                      defaultValue={dtAdmissao || ''}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="formCodinome">
                    <Form.Label>Codinome</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Digite o nome do médico"
                      style={{
                        borderColor: 'green',
                        backgroundColor: 'rgba(255, 255, 0, 0.3)',
                      }}
                      required
                      value={apelido || ''}
                      onChange={event => setApelido(event.target.value)}
                      onKeyUp={e => handleOnEnterPress(e, 'formCPF')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formCPF">
                    <Form.Label>CPF</Form.Label>
                    <InputMask
                      type="text"
                      style={{
                        textTransform: 'uppercase',
                        borderColor: 'green',
                        backgroundColor: 'rgba(255, 255, 0, 0.3)',
                      }}
                      mask="111.111.111-11"
                      onChange={e => setCPFUsuario(e.target.value)}
                      onBlur={e => handleValidarCPF(e)}
                      onKeyUp={e => handleOnEnterPress(e, 'formEmail')}
                      value={cpfusuario || ''}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formEmail">
                    <Form.Label>E-mail</Form.Label>
                    <Form.Control
                      type="text"
                      value={email || ''}
                      onChange={event => setEmail(event.target.value)}
                      onKeyUp={e => handleOnEnterPress(e, 'formCRM')}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="formCRM">
                    <Form.Label>CRM</Form.Label>
                    <Form.Control
                      type="text"
                      style={{
                        borderColor: 'green',
                        backgroundColor: 'rgba(255, 255, 0, 0.3)',
                      }}
                      required
                      value={crm || ''}
                      onChange={event => setCRM(event.target.value)}
                      onKeyUp={e => handleOnEnterPress(e, 'formEspecialidade')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formEspecialidade">
                    <Form.Label>Especialidade</Form.Label>
                    <Form.Control
                      type="text"
                      style={{
                        borderColor: 'green',
                        backgroundColor: 'rgba(255, 255, 0, 0.3)',
                      }}
                      required
                      value={especialidade || ''}
                      onChange={event => setEspecialidade(event.target.value)}
                      onKeyUp={e => handleOnEnterPress(e, 'formRQE')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formRQE">
                    <Form.Label>RQE</Form.Label>
                    <Form.Control
                      type="text"
                      value={rqe || ''}
                      onChange={event => setRQE(event.target.value)}
                      onKeyUp={e => handleOnEnterPress(e, 'formCEP')}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col sm={3}>
                  <Form.Group controlId="formCEP">
                    <Form.Label>CEP</Form.Label>
                    <InputMask
                      type="text"
                      style={{
                        textTransform: 'uppercase',
                        borderColor: 'green',
                        backgroundColor: 'rgba(255, 255, 0, 0.3)',
                      }}
                      mask="11111-111"
                      onChange={event => setCEP(event.target.value)}
                      value={cep || ''}
                      onBlur={() => preencheCep()}
                      onKeyUp={e => handleOnEnterPress(e, 'formEndereco')}
                      maxLength="8"
                    />
                  </Form.Group>
                </Col>
                <Col sm={9}>
                  <Form.Group controlId="formEndereco">
                    <Form.Label>Endereço (adicione o Número)</Form.Label>
                    <Form.Control
                      type="text"
                      value={endresid || ''}
                      onChange={event => setEndResid(event.target.value)}
                      onKeyUp={e => handleOnEnterPress(e, 'formFoneCom')}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="formBairro">
                    <Form.Label>Bairro</Form.Label>
                    <Form.Control
                      type="text"
                      value={bairro || ''}
                      onChange={event => setBairro(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formCidade">
                    <Form.Label>Cidade</Form.Label>
                    <Form.Control
                      type="text"
                      value={cidade || ''}
                      onChange={event => setCidade(event.target.value)}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formEstado">
                    <Form.Label>UF</Form.Label>
                    <Form.Control
                      type="text"
                      value={estado || ''}
                      onChange={event => setEstado(event.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group controlId="formFoneCom">
                    <Form.Label>Res/Com</Form.Label>
                    <InputMask
                      type="text"
                      style={{
                        textTransform: 'uppercase',
                        borderColor: 'green',
                        backgroundColor: 'rgba(255, 255, 0, 0.3)',
                      }}
                      mask="(111)11111-1111"
                      onChange={e => setFoneComerc(e.target.value)}
                      onKeyUp={e => handleOnEnterPress(e, 'formRamal')}
                      value={fonecomerc || ''}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formRamal">
                    <Form.Label>Ramal</Form.Label>
                    <Form.Control
                      type="text"
                      value={ramal || ''}
                      onChange={event => setRamal(event.target.value)}
                      onKeyUp={e => handleOnEnterPress(e, 'formCelular')}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formCelular">
                    <Form.Label>Fone Celular</Form.Label>
                    <InputMask
                      type="text"
                      style={{
                        textTransform: 'uppercase',
                        borderColor: 'green',
                        backgroundColor: 'rgba(255, 255, 0, 0.3)',
                      }}
                      mask="(111)11111-1111"
                      onChange={e => setCelular(e.target.value)}
                      onKeyUp={e => {
                        if (e.key === 'Enter')
                          document.getElementById('formObs').focus();
                      }}
                      value={celular || ''}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group className="mb-3" controlId="formObs">
                <Form.Label>Observações</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  value={obs || ''}
                  onChange={event => setObs(event.target.value)}
                />
              </Form.Group>

              <Card.Footer className="text-right">
                <Button
                  disabled={shouldDisable.updateBtn}
                  onClick={e => handleAtualizarMedico(e)}
                >
                  Atualizar
                </Button>
                {'  '}
                <Button
                  variant="success"
                  disabled={shouldDisable.insertBtn}
                  onClick={e => handleInserirMedico(e)}
                >
                  Inserir
                </Button>
              </Card.Footer>
            </Form>
          </Card.Body>
        </Card>
      )}

      {loading && <Loading />}
    </>
  );
}
