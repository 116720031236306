import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import { addDays, parseISO, format } from 'date-fns';
import pt from 'date-fns/locale/pt';
import api from '../../../services/api';

// createSlice makes all action creators and reducers in the same file so no separation of logic is necessary
export const sliceEcg = createSlice({
  name: 'ecg',
  initialState: { loading: false, ecg: {} },
  reducers: {
    ecgSuccess: (state, action) => {
      const { ecg } = action.payload;
      state.loading = false;
      state.ecg = ecg;
    },
    ecgRequest: (state, action) => {
      const { ecg } = action.payload;
      state.loading = true;
      state.ecg = ecg;
    },
    ecgReset: state => {
      state.loading = false;
      state.ecg = {};
    },
    ecgFailure: state => {
      state.loading = false;
      state.ecg = {};
    },
  },
});

/** *************EXPORTED ACTIONS & REDUCERS************** */
export const {
  ecgRequest,
  ecgSuccess,
  ecgFailure,
  ecgReset,
} = sliceEcg.actions;

export default sliceEcg.reducer;

// API REQUEST ACTIONS HANDLED WITH REDUX-THUNK MIDDLEWARE BUILT INTO REDUX TOOLKIT -->

/** *************THUNKS************** */
export const selectEcgById = payload => {
  return async dispatch => {
    try {
      const { data } = await api.get(`ecg/${payload}`);
      let c = 0;
      const ecg = data.map(eletro => ({
        ...eletro,
        dataEcg: format(addDays(parseISO(eletro.DATAECG), 1), 'dd/MM/yyyy', {
          locale: pt,
        }),
        dataNasc: format(addDays(parseISO(eletro.DTNPAC), 1), 'dd/MM/yyyy', {
          locale: pt,
        }),
        idadePAC: Math.floor(
          (parseISO(eletro.DATAECG) - parseISO(eletro.DTNPAC)) /
            (1000 * 3600 * 24) /
            365.25
        ),
        counter: (c += 1),
      }));
      await dispatch(ecgSuccess({ ecg }));
    } catch (error) {
      toast.error('Erro ao buscar ECG.');
      dispatch(ecgFailure());
    }
  };
};
